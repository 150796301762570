 <template>
        <AppSelectV2
            style="margin-bottom: 5px;"
            v-model="type"
            :items="types">
            <template v-slot:title>
                <AppTitleSmall class="product__additional-info_title-small">
                    Тип кнопки: 
                </AppTitleSmall>
            </template>
        </AppSelectV2>
        <ElementLink v-if="type=='link'" :model="editElement" @save="save" @del="del"/>
        <ElementReklambot v-if="type=='reklambot'" :model="editElement" @save="save" @del="del"/>
        <ElementLeadgenerator v-if="type=='leadgenerator'" :model="editElement" @save="save" @del="del"/>
        <ElementMessage v-if="type=='message'" :model="editElement" @save="save" @del="del"/>
        <ElementPhone v-if="type=='phone'" :model="editElement" @save="save" @del="del"/>
        <ElementTask v-if="type=='task'" :model="editElement" @save="save" @del="del"/>    
 </template>
 <script>
 import ElementLink from "@/components/ButtonElements/ElementLink"
import ElementReklambot from "@/components/ButtonElements/ElementReklambot"
import ElementLeadgenerator from "@/components/ButtonElements/ElementLeadgenerator"
import ElementMessage from "@/components/ButtonElements/ElementMessage"
import ElementPhone from "@/components/ButtonElements/ElementPhone"
import ElementTask from "@/components/ButtonElements/ElementTask"

 export default {
   name: "AppButtonCreate",
   components: {
    ElementLink, ElementReklambot, ElementLeadgenerator, ElementMessage, ElementPhone, ElementTask
  },
  props: {
    shopInfo:{
      type: Object,
    },
    types: {
        type: Array
    }
  },
   data() {
     return {
        editElement: {},
        type: null,

     }
   },
   mounted(){
   
   },
   methods: { 
        save(value){
            this.$emit('addButton',value);
            this.editElement =  {}
            this.type = null
        },
        del(){
            this.editElement =  {}
            this.type = null
        },
   },
   watch: {
        type(type){
            switch (type) {
                case "reklambot":
                    this.editElement =  {
                        id: null,
                        name: "",
                        link: (this.shopInfo.telegram)? this.shopInfo.telegram.link : "Подключите бота!",
                        text: "",
                        media_type: "none",
                        media: "",
                        type: this.types.find(e=>e.name==type),
                        status:false,
                    }
                    break;
                case "link":
                    this.editElement =   {
                            id: null,
                            name: "",
                            link: "",
                            type: this.types.find(e=>e.name==type),
                            status:false,

                        }
                    break;
                case "leadgenerator":
                    this.editElement =  {
                        id: null,
                        name: "",
                        type: this.types.find(e=>e.name==type),
                        text: "",
                        media_type: "none",
                        media: "",
                        
                        status:false,
                    }
                    break;
                case "message":
                    this.editElement =  {
                        id: null,
                        name: "",
                        type: this.types.find(e=>e.name==type),
                        text: "",
                        media_type: "none",
                        media: "",
  
                        status:false,
                    }
                    break;
                case "phone":
                    this.editElement =  {
                        id: null,
                        name: "",
                        type: this.types.find(e=>e.name==type),
                        text: "",
                        media_type: "none",
                        media: "",
  
                        status:false,
                    }
                    break;
                case "task":
                    this.editElement =  {
                        id: null,
                        name: "task",
                        type: this.types.find(e=>e.name==type),
                        task_id: null,
                        status:false,
                    }
                    break;
                default:
                    break;
            }
        },
   }
 }
 </script>