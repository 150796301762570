import axios from 'axios'

export const PRODUCT = `${process.env.VUE_APP_LINK}/api/v1/products`;

export default {
    getProducts(data) {
        return axios.get(`${PRODUCT}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data
        })
    },
    getProduct(id) {
        return axios.get(`${PRODUCT}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    createProduct(data) {
        return axios.post(`${PRODUCT}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateProduct(id, data) {
        return axios.put(`${PRODUCT}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateProductStatus(id, data) {
        return axios.put(`${PRODUCT}/${id}/status`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateProductPosition(id, data) {
        return axios.put(`${PRODUCT}/${id}/position`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    deleteProduct(id) {
        return axios.delete(`${PRODUCT}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
}