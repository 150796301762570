<template>
    <ckeditor 
        v-if="editorReady"
        :editor="editor"
        class="my-ckeditor"
        v-model="localValue"
        :config="config"
    />
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "CustomCKEditor",
    components: { ckeditor: CKEditor.component },
    props: {
        value: {
            type: String,
            default: ""
        },
        config: {
            type: Object,
            default: () => ({
                toolbar: false
            })
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            editorReady: false,
            localValue: this.value || " "
        };
    },
    watch: {
        value(newVal) {
            if (newVal !== this.localValue) {
                this.localValue = newVal || ""; // set to an empty string if newVal is undefined or null
            }
        },
        localValue(newVal) {
            if (newVal !== this.value) {
                this.$emit('input', newVal || ""); // set to an empty string if newVal is undefined or null
            }
        }
    },
    mounted() {
    this.editorReady = true;
    this.localValue = this.value;
}
}
</script>