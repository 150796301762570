<template>
    <div >
        <div>
           <AppButtonList v-if="buttons.length>0" :buttons="buttons" @resort="updateButtonSort" @updateButton="updateButton" @del="del" />
        </div>   
        <div v-if="addShow" style="margin:10px; margin-left:15px; margin-right:15px">
          <AppButtonCreate 
          :shopInfo="shopInfo" :types="types" 
          @updateStatus="updateStatus" 
          @addButton="addButton"
          /> 
        </div>  
        <div class="button-add__container">
          <AppButtonAdd
              @click="createButton()" 
          >
            <span class="edit-banner__name">Добавить кнопку</span>
          </AppButtonAdd>
        </div>
    </div>
  </template>
  <script>
  import api from "@/components/ButtonElements/api"
  import AppButtonCreate from "@/components/ButtonElements/AppButtonCreate"
  import AppButtonList from "@/components/ButtonElements/AppButtonList"
  export default {
    name: "AppButtons",
    components: {
        AppButtonList, AppButtonCreate
    },
    props: {
      model_id: {
        type: String, Number,
      },
      place: {
        type: String,
      },
    buttons: {
        type: Array,
      },
    shopInfo:{
        type: Object,
      },
    },
    data() {
      return {
        types: [],
        addShow: false,
      }
    },
    mounted(){
        this.localButtons = JSON.parse(JSON.stringify(this.buttons))
        this.getTypes()
    },
    methods: {
      addButton(value){
        console.log(value,"addButton")
        this.addShow = false
        value.model_id = this.model_id
        value.shop_id = this.shopInfo.id
        value.place = this.place
        value.type_id = value.type.id
        api.createButton(value).then((response)=>{
          this.$emit('updateButtons',response.data.data);
         // console.log(response)
        }).catch((error)=>{
            console.log(error)
        })  
      },
      updateButton(value){
        api.updateButton(value.id, value).then((response)=>{
          this.$emit('updateButtons',response.data.data, "update");
         // console.log(response)
        }).catch((error)=>{
            console.log(error)
        })  
      },
      updateButtonSort(array){
        api.updateButtonSort(array).then((response)=>{
            console.log(response)
        }).catch((error)=>{
            console.log(error)
        })  
      },
      del(value){
        this.addShow = false
        api.deleteButton(value.id).then(()=>{
          this.$emit('updateButtons',value, "delete");
         // console.log(response)
        }).catch((error)=>{
            console.log(error)
        })  
      },
      createButton(){
        this.addShow = !this.addShow 
     
      },
      async getTypes() {
          const response = await api.getTypes(this.place)
          this.types = response.data
      }
    },
    watch: {
      buttons() {
        this.localButtons = JSON.parse(JSON.stringify(this.buttons))
      },
      
    }
  }
  </script>
  <style scoped>
  .edit-banner__cropper-container {
    display: flex;
    align-items: center; 
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(56, 55, 55, 0.65);
    z-index: 20;
  }
  
  .crop-image-container {
    background: white;
    border: 1px solid rgba(65, 65, 65, 0.75);
    border-radius: 6px;
    padding: 15px;
    position: relative;
  }
  
  .header-cropper-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    z-index: 20;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 6px;
  }
  
  .edit-banner__delete-img {
    margin: 15px;
    cursor: pointer;
  }
  
  .image-container img {
    height: 100%;
    width: auto;
  }
  
  .edit-banner__image {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .button-add__container {
    margin: 15px 0;
    color: #20A0FF;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .edit-banner__name {
    color: var(--default_background);
  }
  </style>