<template>
  <section class="section-order orders section">
      <div class="container">

          <AppSearchAndFilter
            class="order__search"
            @goSearch="goSearch"  
            @filtered="startFiltered"
          :filterContent="filterContent"/>
          <AppSectionNote>
          Помечены заказы, требующие внимания администратора
        </AppSectionNote> 
          <ul class="order-filter__type_ul">
                  <li class="product-edit__status_li"  
                      v-for="element,index in types" 
                      :key="index">
                      <AppRadio
                      v-model = "filterType"
                      @change ="getModels"
                      :forAndId="element.name" 
                      :name="'type'"> 
                          <AppTitleSettingParam>
                              {{element.title}}
                          </AppTitleSettingParam> 
                      </AppRadio>
                  </li>
              </ul>
          <AppOrders 
            :orderMeta="orderMeta"
            @updateOrder="getModels()"
            :routeSetting="routeSetting"
            :orders="allOrders"
          />
          <br>
          <AppNavigationBottom  :meta="ordersMeta" @goPage="goPage"/>
      
      </div>
  </section>
</template> 

<script>
import { mapActions, mapGetters } from 'vuex';
//import axios from "axios"; 
//import { reactive } from "vue";

export default {
  name: 'PageOrder',
  data() {
      return {
        shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
        routeSetting: {},
        filterType: "all",
          types: [{name: "all", title:"Все"}, {name: "invoice", title:"заявка"},{name: "order", title:"заказ"}],
          filterContent: {
          searchPlaceholder:"Поиск по id заказа",
          title: 'Фильтр по заказам/заявкам', 
          filters: {
            sort: {
              title: 'Сортировать:',
              type: 'radio',
              elem: 'sort',
              name: 'sort',
              items: JSON.parse(localStorage.getItem('order_sort')),
              current: "id,desc",
            },
            status: {
              title: 'Выбрать статус заказа/заявки',
              type: 'checkbox',
              elem: 'filter',
              name: 'status',
              items: JSON.parse(localStorage.getItem('order_statuses')),
              current: [],
            },
            paystatus: {
              title: 'Выбрать статус платежа',
              type: 'checkbox',
              elem: 'filter',
              name: 'paystatus',
              items: JSON.parse(localStorage.getItem('order_paystatuses')),
              current: "",
            }
          }
        },
        page: 1,
        pageSize: 5,
        lastPage: 1,
        c_isLive: true,
        search: "",
      }
  },
computed: mapGetters(['allOrders', 'ordersMeta','shopInfo','allCategories']),
   created() {
    if (this.shop_type.id!=1) {
        this.$router.push({ name: 'PageClient'})
        return
      }
      this.getShopInfo()
     //this.loadStatusFilter()
    // this.loadStatusPayFilter()
   }, 
   methods: {
      ...mapActions(['getOrders', 'getShopInfo', 'asyncUpdateOrder']),
      Name(item) {
          this.userNotification = item; 
          this.showEmailForm = true
      }, 
      startFiltered(filter) {
        this.filter = filter.filter
        //console.log(filter.filter,"filter.filter")
        this.getModels();
      },   
      goSearch(input){
        this.search = input
        this.getModels()
      },
    
      
      goPage(page){
          this.page = page
          this.getModels()
      },
      getModels(add=false){
       
       let data = {}
       if (this.$route.params.back) {
         data = JSON.parse(localStorage.getItem('currentProductList'))
         this.$route.params.back = false
       }
       else
         data = {
            filter_type: this.filterType,
             shop_id: this.shopInfo.id,
             page:(this.page>this.ordersMeta.pages)?this.ordersMeta.pages:this.page,
             pageSize:this.pageSize,
             search: this.search,
             filter: this.filter
         }
       localStorage.setItem('currentProductList', JSON.stringify(data))
       this.getOrders(data,add)
     },
  },
  watch: {
      shopInfo(){
         this.getModels()
      },
  },


mounted() {
},

unmounted() {
  this.c_isLive = false
}
}
</script>

<style lang="scss" scoped>
 .order-filter__type_ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 25px;
      margin-bottom: 5px;
      padding-left: 5px;
  }
  .order__search {
      margin-bottom: 11px;
  }
</style>