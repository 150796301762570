<template>
  <div class="header-new-mailing">
    <span>Создание новой рассылки </span>
   
    <svg @click="close"
         width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z"
            fill="#8EA2B0"/>
    </svg>

  </div>

  <div class="flex-between">
    <div class="date-naw">{{ mailingData.date }}</div>
    <div>
      <span v-html="statusIcon[mailingData.status]"></span>
      <span class="status-message"> {{ statusMessage[mailingData.status] }} </span> 
    </div>
  </div>
  <div class="header-mailing">
    <div class="header-name">
    {{ mailingData.name }}
  </div>
  </div>
  <img class="img-mailing" v-if="mailingData.image" :src="'https://lk.ivitrina.store/medias'+mailingData.image.link+'thumb/'+ mailingData.image.name+'.webp'" alt="">
  
  <div class="header-description" v-html="mailingData.description">

  </div>

  <div class="block-mailing-result block-mailing-result_f">
    <div class="block-mailing-header">Получатели рассылки</div>
    <div class="block-mailing-value">
      {{ mailingUsers.count }} чел.
    </div>
    <div class="block-mailing-header">Дата и время рассылки</div>
    <AppCalendar :date="date" @updateDate="updateDate"/>
  </div>

  <!--
  <div class="block-mailing-result">
    <div class="block-mailing-header">Дата регистрации</div>
    <div class="block-mailing-value">
      {{ mailingUsers.dateReg }}
    </div>
  </div>
  <div class="block-mailing-result">
    <div class="block-mailing-header">Сумма среднего чека</div>
    <div class="block-mailing-value">
      {{ mailingUsers.sumAverage }}
    </div>
  </div>
  <div class="block-mailing-result">
    <div class="block-mailing-header">Сумма выкупа</div>
    <div class="block-mailing-value">
      {{ mailingUsers.sum }}
    </div>
  </div>
  <div class="block-mailing-result">
    <div class="block-mailing-header">Число покупок</div>
    <div class="block-mailing-value">
      {{ mailingUsers.numBay }}
    </div>
  </div>
  -->
  <div class="button-block">
    <AppButtonRemove v-if="!mailingData.id==undefined"
        style="width: 30px; height: 30px"
    >
   
    </AppButtonRemove>
    <AppButton 
        @click="prev"
        v-if="mailingData.status=='draft' || mailingData.status=='send'"
        style="width: 70px; height: 30px"
        :styleButton="'_blue-external'"
    >
      Назад
    </AppButton>
    <AppButton
        style="width: 90px; height: 30px; margin: 0 5px"
        :styleButton="'_blue-external'"
        @click="save('draft')"
    >
      Черновик
    </AppButton>
    <AppButton 
        @click="save('send_to')"
        v-if="mailingData.status=='draft' || mailingData.status=='send'"
        style="width: 130px; height: 30px"
        :styleButton="'_red'"
    >

      {{(new Date(newDate)<=new Date())?"Отправить сейчас":"Запланировать"}}
    </AppButton>
    <!--
    <AppButton 
        @click="save('test')"
        v-if="mailingData.status=='draft' || mailingData.status=='send'" 
        style="width: 70px; height: 30px"
        :styleButton="'_blue-external'"
    >
      Тест
    </AppButton>
    -->  
  </div>
</template>

<script>
import api from "./api"

import AppCalendar from "@/components/AppCalendar";
export default {
  name: "ChatReadySend",
  components: {AppCalendar},

  data() {
    return {
      shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
      newDate: null,
      date: this.mailingData.send_date || new Date(),
      id: this.mailingData.id,
      statusIcon: {
        Draft: '' +
            '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.17109 0.0279265C8.20538 0.044205 8.6259 0.450914 9.10559 0.931746C9.87861 1.70663 9.9793 1.81452 9.99154 1.88114C10.0218 2.04607 9.99996 2.07582 9.42222 2.65606L8.88713 3.19347L7.84638 2.15291L6.80564 1.11235L7.32337 0.594138C7.63294 0.284279 7.87252 0.0603468 7.91926 0.0371504C8.01282 -0.00928155 8.08699 -0.0119979 8.17109 0.0279265ZM7.43078 2.57791L8.46628 3.61366L6.03363 6.04662C4.69567 7.38475 3.59221 8.47959 3.58148 8.47959C3.55594 8.47959 1.51991 6.44354 1.51991 6.418C1.51991 6.39873 6.36618 1.54216 6.38541 1.54216C6.39084 1.54216 6.86125 2.00824 7.43078 2.57791ZM3.06801 8.83359C3.03438 8.86453 0.386631 9.98677 0.322833 9.99713C0.152014 10.0249 -0.0249988 9.84683 0.0029239 9.67537C0.0093721 9.63572 0.261731 9.02726 0.563722 8.32325C0.865712 7.61925 1.12557 7.01282 1.14121 6.97563L1.16962 6.90804L2.12591 7.86427C2.65188 8.3902 3.07582 8.8264 3.06801 8.83359Z" fill="#8EA2B0"/>\n' +
            '</svg>\n',
        Sent: '' +
            '<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z" fill="#8EA2B0"/>\n' +
            '</svg>\n',
        Delivered: '' +
            '<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z" fill="#2CBA15"/>\n' +
            '</svg>\n',
        Read: '' +
            '<svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z" fill="#2CBA15"/>\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3964 0.548282C10.6991 0.849835 10.9468 1.12254 10.9468 1.15426C10.9468 1.18593 9.86929 2.28919 8.55226 3.60595L6.1577 6L5 4.73356C5 4.73356 5.0029 4.76421 5.0029 4.73356C5.0029 4.66003 6.08295 3.5771 6.15631 3.5771C8.10231 5.46389 5.63855 3.05829 6.15809 3.5771L7.94667 1.78852C8.93032 0.804872 9.76016 0 9.79064 0C9.82112 0 10.0938 0.24673 10.3964 0.548282Z" fill="#2CBA15"/>\n' +
            '</svg>\n',
      },
      
      statusMessage: {
        Draft: 'Черновик',
        Sent: 'Отправлено',
        Delivered: 'Доставлено',
        Read: 'Прочитано',
      },
    }
  },
  props: {
    mailingData: [Object],
    mailingUsers: [Object],
  },
  methods: {
    updateDate(val){
      console.log(val)
      this.newDate = val
     
    },
    save(status) {
            let mailingData  = Object.assign({}, this.mailingData)
            //Есть изображение
            if (mailingData.image) mailingData.image = mailingData.image.id
            mailingData.status = status
            mailingData.send_date = this.newDate
            let data = {info:mailingData, peoples:this.mailingUsers}
            if (this.id) { 
                api.updateMailingData( this.id,  data).then(() => {
                  if (status=='test') return
                  this.close()
                }).catch((error) => {
                  console.log(error.response.data,"error.response.data")
                  if (error.response.data)
                      console.log(error.response.data,"error.response.data")
                      this.showMessageNotice('Ошибка 09',JSON.stringify( error.response.data),'error')
                  if (error.response.data.message)
                      this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
            }
            else  
                api.createMailingData(data).then((response) => {
                    if (response.data.message){
                        this.id = response.data.data
                        this.blockButton = false
                    }
                    this.close()
                    }).catch((error) => {
                        console.log( error.response.data.data)
                        
                        if (error.response.data)
                            this.showMessageNotice('Ошибка 09',JSON.stringify( error.response.data.error),'error')
                        if (error.response.data.message)
                            this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                    })
        }, 
    close() {
      this.$emit('data', {action: 'nextPage', value: {name:'ChatsList'}})
    },
    prev() {
      if (this.shop_type.name=="vizit") {
        this.$emit('data', {action: 'nextPage', value: {"name":'ChatCreate'}})
        return
      }
      this.$emit('data', {action: 'nextPage', value: {"name":'ChatSettingPublicNew'}})
    },
  },
  mounted(){
  
    this.date = this.mailingData.send_date
    console.log(this.date)
 
  },
  inject: ['showMessageNotice'],
}
</script>

<style scoped>

.date-naw {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.status-message {
  font-family: Roboto;
  color: #8EA2B0;
  font-size: 9px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.header-mailing {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 12px 0;
}

.header-description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: black;
  margin: 12px 0;
}
.header-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: black;
  margin: 12px 0;
}
.img-mailing {
  max-width: 280px;
  height: auto;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.block-mailing-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
}


.block-mailing-result {
  margin: 10px 0;
}

.block-mailing-result_f {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  padding-top: 15px;
}


.block-mailing-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.75);
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

</style>