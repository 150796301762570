<template>
    <div class="greeting">
        <div class="greeting_logo">
            <svg width="141" height="30" viewBox="0 0 141 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 29.6763V8.95683H7.44604V29.6763H0Z" fill="white"/>
                <path d="M19.9843 2.05035L25.6497 21.5288H25.8656L31.531 2.05035H40.0023L30.8835 29.6763H20.6317L11.513 2.05035H19.9843Z" fill="white"/>
                <path d="M42.7709 29.6763V8.95683H50.217V29.6763H42.7709Z" fill="white"/>
                <path d="M66.3669 8.95683V14.3525H52.7158V8.95683H66.3669ZM55.3597 3.9928H62.8058V23.0126C62.8058 23.3004 62.8552 23.5432 62.9541 23.741C63.0531 23.9299 63.2059 24.0737 63.4128 24.1727C63.6196 24.2626 63.8849 24.3076 64.2086 24.3076C64.4335 24.3076 64.6942 24.2806 64.991 24.2266C65.2968 24.1727 65.5216 24.1277 65.6655 24.0917L66.7446 29.3255C66.4119 29.4245 65.9353 29.5459 65.3147 29.6897C64.7032 29.8336 63.9748 29.9281 63.1295 29.973C61.4209 30.0629 59.9865 29.8876 58.8264 29.4469C57.6664 28.9973 56.7941 28.2914 56.2095 27.3291C55.625 26.3669 55.3417 25.1619 55.3597 23.714V3.9928Z" fill="white"/>
                <path d="M69.4054 29.6763V8.95683H76.6356V12.8957H76.8514C77.2291 11.4388 77.8271 10.3732 78.6455 9.69874C79.4728 9.02428 80.4395 8.68705 81.5456 8.68705C81.8694 8.68705 82.1886 8.71402 82.5034 8.76798C82.8271 8.81295 83.1374 8.88039 83.4341 8.97032V15.3237C83.0654 15.1978 82.6113 15.1034 82.0717 15.0405C81.5322 14.9775 81.069 14.946 80.6823 14.946C79.9539 14.946 79.2974 15.1124 78.7129 15.4451C78.1374 15.7689 77.6832 16.2275 77.3505 16.821C77.0178 17.4056 76.8514 18.0935 76.8514 18.8849V29.6763H69.4054Z" fill="white"/>
                <path d="M85.8757 29.6763V8.95683H93.3217V29.6763H85.8757Z" fill="white"/>
                <path d="M104.562 18.0216V29.6763H97.1156V8.95683H104.184V12.8957H104.4C104.849 11.5827 105.641 10.5531 106.774 9.80665C107.916 9.06025 109.247 8.68705 110.767 8.68705C112.232 8.68705 113.505 9.02428 114.584 9.69874C115.672 10.3642 116.513 11.2815 117.107 12.4505C117.709 13.6196 118.006 14.955 117.997 16.4568V29.6763H110.551V18.0216C110.56 16.9964 110.299 16.1915 109.768 15.607C109.247 15.0225 108.518 14.7302 107.583 14.7302C106.972 14.7302 106.437 14.8651 105.978 15.1349C105.528 15.3957 105.182 15.7734 104.939 16.268C104.697 16.7536 104.571 17.3381 104.562 18.0216Z" fill="white"/>
                <path d="M127.446 30C126.124 30 124.955 29.7842 123.939 29.3525C122.932 28.9119 122.14 28.2464 121.565 27.3561C120.989 26.4658 120.701 25.3327 120.701 23.9568C120.701 22.8237 120.895 21.857 121.281 21.0567C121.668 20.2473 122.208 19.5863 122.9 19.0737C123.593 18.5611 124.397 18.17 125.315 17.9002C126.241 17.6304 127.239 17.455 128.309 17.3741C129.469 17.2842 130.4 17.1763 131.102 17.0504C131.812 16.9155 132.325 16.7311 132.639 16.4973C132.954 16.2545 133.112 15.9352 133.112 15.5396V15.4856C133.112 14.946 132.905 14.5324 132.491 14.2446C132.077 13.9568 131.547 13.8129 130.899 13.8129C130.189 13.8129 129.609 13.9703 129.159 14.2851C128.719 14.5908 128.453 15.0629 128.363 15.7014H121.511C121.601 14.4424 122.001 13.2824 122.711 12.2212C123.431 11.1511 124.478 10.2968 125.854 9.65827C127.23 9.01079 128.948 8.68705 131.007 8.68705C132.491 8.68705 133.822 8.86241 135 9.21313C136.178 9.55485 137.181 10.036 138.008 10.6565C138.835 11.268 139.465 11.9874 139.897 12.8147C140.337 13.6331 140.558 14.5234 140.558 15.4856V29.6763H133.597V26.7626H133.435C133.022 27.536 132.518 28.161 131.924 28.6376C131.34 29.1142 130.67 29.4604 129.915 29.6763C129.168 29.8921 128.345 30 127.446 30ZM129.874 25.3058C130.441 25.3058 130.971 25.1888 131.466 24.955C131.969 24.7212 132.379 24.384 132.693 23.9433C133.008 23.5027 133.165 22.9676 133.165 22.3381V20.6115C132.968 20.6924 132.756 20.7689 132.531 20.8408C132.316 20.9128 132.082 20.9802 131.83 21.0432C131.587 21.1061 131.326 21.1646 131.048 21.2185C130.778 21.2725 130.495 21.3219 130.198 21.3669C129.622 21.4568 129.15 21.6052 128.781 21.812C128.422 22.0099 128.152 22.2572 127.972 22.554C127.801 22.8417 127.716 23.1655 127.716 23.5252C127.716 24.1007 127.918 24.5414 128.323 24.8471C128.728 25.1529 129.245 25.3058 129.874 25.3058Z" fill="white"/>
                <path d="M3.66907 6.79856C2.66187 6.79856 1.79856 6.46583 1.07914 5.80036C0.359712 5.13489 0 4.33453 0 3.39928C0 2.46403 0.359712 1.66367 1.07914 0.9982C1.79856 0.332733 2.66187 0 3.66907 0C4.68525 0 5.54856 0.332733 6.25899 0.9982C6.97842 1.66367 7.33813 2.46403 7.33813 3.39928C7.33813 4.33453 6.97842 5.13489 6.25899 5.80036C5.54856 6.46583 4.68525 6.79856 3.66907 6.79856Z" fill="#FF0000"/>
                <path d="M3.66907 6.79856C2.66187 6.79856 1.79856 6.46583 1.07914 5.80036C0.359712 5.13489 0 4.33453 0 3.39928C0 2.46403 0.359712 1.66367 1.07914 0.9982C1.79856 0.332733 2.66187 0 3.66907 0C4.68525 0 5.54856 0.332733 6.25899 0.9982C6.97842 1.66367 7.33813 2.46403 7.33813 3.39928C7.33813 4.33453 6.97842 5.13489 6.25899 5.80036C5.54856 6.46583 4.68525 6.79856 3.66907 6.79856Z" fill="#FF0000"/>
                <path d="M3.66907 6.79856C2.66187 6.79856 1.79856 6.46583 1.07914 5.80036C0.359712 5.13489 0 4.33453 0 3.39928C0 2.46403 0.359712 1.66367 1.07914 0.9982C1.79856 0.332733 2.66187 0 3.66907 0C4.68525 0 5.54856 0.332733 6.25899 0.9982C6.97842 1.66367 7.33813 2.46403 7.33813 3.39928C7.33813 4.33453 6.97842 5.13489 6.25899 5.80036C5.54856 6.46583 4.68525 6.79856 3.66907 6.79856Z" fill="#FF0000"/>
                <path d="M3.66907 6.79856C2.66187 6.79856 1.79856 6.46583 1.07914 5.80036C0.359712 5.13489 0 4.33453 0 3.39928C0 2.46403 0.359712 1.66367 1.07914 0.9982C1.79856 0.332733 2.66187 0 3.66907 0C4.68525 0 5.54856 0.332733 6.25899 0.9982C6.97842 1.66367 7.33813 2.46403 7.33813 3.39928C7.33813 4.33453 6.97842 5.13489 6.25899 5.80036C5.54856 6.46583 4.68525 6.79856 3.66907 6.79856Z" fill="#FF0000"/>
                <path d="M46.5039 6.79856C45.4967 6.79856 44.6334 6.46583 43.914 5.80036C43.1946 5.13489 42.8348 4.33453 42.8348 3.39928C42.8348 2.46403 43.1946 1.66367 43.914 0.9982C44.6334 0.332733 45.4967 0 46.5039 0C47.5201 0 48.3834 0.332733 49.0938 0.9982C49.8133 1.66367 50.173 2.46403 50.173 3.39928C50.173 4.33453 49.8133 5.13489 49.0938 5.80036C48.3834 6.46583 47.5201 6.79856 46.5039 6.79856Z" fill="#37F3FF"/>
                <path d="M89.3387 6.79856C88.3315 6.79856 87.4682 6.46583 86.7488 5.80036C86.0294 5.13489 85.6697 4.33453 85.6697 3.39928C85.6697 2.46403 86.0294 1.66367 86.7488 0.9982C87.4682 0.332733 88.3315 0 89.3387 0C90.3549 0 91.2182 0.332733 91.9287 0.9982C92.6481 1.66367 93.0078 2.46403 93.0078 3.39928C93.0078 4.33453 92.6481 5.13489 91.9287 5.80036C91.2182 6.46583 90.3549 6.79856 89.3387 6.79856Z" fill="#33DB18"/>
            </svg>
        </div>
        <div class="greeting_text">
            Онлайн витрина  или  другой дискриптор
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppGreeting'
}
</script>

<style lang="scss" scoped>
    .greeting {
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 20px;
    }
    .greeting_text {
        color: rgba(255, 255, 255, 0.75);

        font-size: 13px;

        text-align: center;

        width: 55%;
    }
</style>