import axios from 'axios'

export const ABOUT = `${process.env.VUE_APP_LINK}/api/v1/companies`;

export default {
    updateAbout(id, data) {
        return axios.put(`${ABOUT}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
}