<template>
      <div>
            <AppCheckbox  style="margin-bottom: 5px;" v-model="localQuestion.protect_content"  @change="updateProtectContent()">
               Защить от копирования и пересылки
            </AppCheckbox>
            <div style="display:flex; justify-content:space-between">
                <AppCheckbox  v-model="localQuestion.status"  @change="updateStatus()">
                    {{ localQuestion.status ? 'Выключить' : 'Включить' }}
                </AppCheckbox>
                <div style="display:flex; justify-content:space-between">
                    <AppButtonSave 
                        @click="save(localQuestion)"
                        style="width: 100px; height: 30px; margin-right: 10px;"
                    />
                    <AppButtonRemove 
                            @click="del(localQuestion)"
                            style="width: 30px; height: 30px; "
                        />
                </div>
            </div>
        </div>
</template>
<script>
export default {
  name: "ElementActionQuestion",
  components: {
  },
  props: ["question"],
  data() {
    return {
        localQuestion: {status:false}
    }
  },
  mounted(){
    this.localQuestion = this.question
  },
  methods: {
    updateStatus(){
        this.$emit('update:modelValue', this.localQuestion);
    },
    updateProtectContent(){
        this.$emit('update:modelValue', this.localQuestion);
    },
    save(){
        this.showMessageNotice('Ошибка 09',"Вопрос обновлен",'success'); 
        this.$emit('save');
    },
    del(){
        this.$emit('del');
    }
  },
  watch: {
    question(question){
        this.localQuestion = question
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
