<template>
    <ul class="product__list">
        <li
        class="product__item"
        v-for="product in products"
        :key="product.id">
        <img   style="width: 70px; height:70px;" v-if="product.product_image"

                            :src="getImage(product)" alt="">
            {{ product.product_name }}
            <!--
                <router-link 
                class="product__title"
                :to="`/product/${product.id}`">
                </router-link>
            -->
            <AppProductCounter v-if="type.id==1" class="product__counter">
                {{ product.counter }} шт.
            </AppProductCounter>

            <AppProductPrice v-if="type.id==1" class="product__price">
                {{ product.price }} руб.
            </AppProductPrice>
        </li>
    </ul>
</template>

<script>
function imageExists(image_url){
 
 var http = new XMLHttpRequest();

 http.open('HEAD', image_url, false);
 http.send();
if (http.status != 404) return true
 return false

}

export default {
    name: 'AppShortProducts',
    props: {
        shop_id: {
            type: [String,Number],
            require: true
        },
        products: {
            type: Array,
            require: true
        },
        type:{
            type: Object,
            require: true
        },
    },
    methods: {
        getImage(product){
            let image = 'https://lk.ivitrina.store/medias/public/shop_'+this.shop_id+'/webp/'+product.product_image+'.webp'
            if (imageExists(image)) return image
            return  'https://lk.ivitrina.store/medias/public/shop_1/webp/'+product.product_image+'.webp'
        }
    },
}
</script>

<style lang="scss" scoped>
    .product__list {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
    .product__item {
        display: grid;
        grid-template-columns: 0.15fr 0.8fr 0.3fr 0.5fr;
        align-items: center;
        column-gap: 10px;
    }
    .product__image {
        padding-top: 20%;
        position: relative;
        flex: 0 0 20%;
    }
    .product__img {
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .product__title {
        color: #20A0FF;
        font-size: 14px;
    }
    product__counter
    {
        margin-left: auto;
    }
    .product__price {
        margin-left: auto;
    }
</style>