<template>
    <div class="product-search">
        <AppSearch></AppSearch>
        <div class="product-search__text">
            <div class="product-search__maybe">
                Возможно, вы ищите:
            </div>
            <div class="product-search__option">
                Lorem ipsum dolor sit amet
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppProductSearch'
}
</script>

<style lang="scss" scoped>
    .products__container {
        padding-bottom: 25px;

        @media (max-width: 400px) {
            padding-left: 9px;
            padding-right: 9px;
        }
    }

    .product-search {
        padding: 8px 0;

        position: relative;

        &::before {
            content: "";

            background: #E4E7ED;

            display: inline-block;

            position: absolute;
            left: -2%;
            
            bottom: 100%;

            width: 103%;
            height: 1px;
        }

        &::after {
            @extend ::before;

            top: 100%;
        }
    }

    .product-search__text {
        display: flex;
        flex-direction: column;
        row-gap: 3px;

        margin-top: 12px;
    }

    .product-search__maybe {
        color: #8EA2B0;

        font-size: 11px;
    }
    
    .product-search__option {
        color: #20A0FF;

                font-size: 12px;
        font-weight: 500;
    }
</style>