<template>
    <ul class="messages">
        <li
        class="message"
        v-for="message in messages"
        :key="message.id">
            <div
            class="message__inner message__inner_from-me"
            v-if="message.fromMe">
                <date class="message__date">
                    {{ message.date }}
        
                </date>
                <div class="message__user-name">
                    {{ message.user }}
                </div>
                <div class="message__text">
                    {{ message.text }}
                </div>
            </div>
            <div
            class="message__inner"
            v-else>
                <date class="message__date">
                    {{ message.date }}
       
                </date>
                <div class="message__user-name">
                    {{ message.user }} 
                </div>
                <div class="message__text">
                    {{ message.text }}
                </div>
                <AppButton
                v-if="!message.answer"
                class="message__send"
                :styleButton="'_blue-external'"
                @click="showAnswerForm = true">
                    <span>
                        Отправить
                    </span>
                </AppButton>
            </div>
        </li>
        <li class="message">
            <div
            class="message__inner message__inner_from-me"
            v-if="showAnswerForm">
                <date class="message__date">
                    {{ dayMonthYear() }}
                    {{ hoursMinutes || hoursMinutes() }}
                </date>
                <div class="message__user-name">
                    {{ adminName }}
                </div>
                <div
                class="message__form">
                    <AppTextarea
                    v-model="answer"
                    class="message__textarea"
                    :placeholder="'Введите текст сообщения'"
                    :style="'min-height: 72px'"></AppTextarea>
                    <AppButton
                    @click="sendMessage()"
                    class="message__send"
                    :styleButton="'_blue-external'">
                        <span>
                            Отправить
                        </span>
                    </AppButton>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import { mapActions } from 'vuex';
import { dayMonthYear, hoursMinutes } from '@/helpers/global.js'
export default {
    name: 'AppMessages',
    data() {
        return {
            answer:"",
            dayMonthYear,
            hoursMinutes: null,
            showAnswerForm: false,
        }
    },
    props: {
        messages: {
            type: Array,
            required: true
        }
    },
    computed: {
        lastMessageForMe() {
            const messageForMe = this.messages.filter(message => message.fromMe !== true);
            return messageForMe;
        },
        adminName() {
            let messageFormMe = 'Служба поддержки';
            /*
            for (let i = 0; i < this.messages.length; i++) {
                // проверка отправленного сообщение от админа
                if (this.messages[i].fromMe === true) {
                    // присваивание переменной имя админа
                    messageFormMe = this.messages[i].user;
                    break;
                }
            }
            */
            return messageFormMe;
        }
    },
    methods:{
          ...mapActions(['asyncUpdateChat']),
        sendMessage(){
  
            let data = {
                answer: this.answer,
                id:this.messages[0].id
            }
            this.asyncUpdateChat(data)
              setTimeout(() => {
                  this.$router.push("/chat")
            }, 500);
        },
    },
    mounted() {
        setInterval(() => this.hoursMinutes = hoursMinutes(), 2000);
    }
}
</script>

<style lang="scss" scoped>
    .messages {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
    .message__inner {
        background: var(--chat_background_for_me);
        border: 1px solid var(--white-theme-line);
        border-radius: 6px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        padding: 16px 12px;
    }
    .message__inner_from-me {
        background: var(--chat_background_from_me);
        
        margin-left: auto;
        width: 95%;
    }
    .message__date {
        color: var(--global_title_small_color);
        font-size: 11px;
    }
    .message__user-name {
       color: var(--global_second_color);
        font-size: 12px;
        margin-bottom: 10px;
    }
    .message__text {
       color: var(--global_third_color);
        font-size: 10px;
        word-wrap: break-word;
    }
    .message__form {
        margin-top: 12px;
    }
    .message__send {
        margin-top: 10px;
        width: 82px;
        height: 30px;
        span {
            font-size: 12px;
        }
    }
</style> 