<template>
    <ul class="alert-setting__type-alert">
        <li
        class="alert-setting__type_item"
        v-for="alert in alertsTypes"
        :key="alert.id">
            <AppTitleSmall>
                {{ alert.title }}
            </AppTitleSmall>
            <AppTextareaForm :styleTextarea="'min-height: 76px;'">{{ alert.text }}</AppTextareaForm>
            <div @click="alert.status = !alert.status">
                <AppCheckbox
                v-if="alert.status"
                checked>
                    Активно
                </AppCheckbox>
                <AppCheckbox
                v-else>
                    Неактивно
                </AppCheckbox>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name: "AppAlertsType",
    props: {
        alertsTypes: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .alert-setting__type-alert {
    }
    .alert-setting__type_item {
        border-bottom: 1px solid #E0E3E9;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        padding-bottom: 18px;
        margin-bottom: 20px;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
</style>