<template>
    <div class="bot-setting block-style">
        <AppInputForm
            v-model="button.name"
            :placeholder="'Название кнопки'">
                <template v-slot:title>
                    <AppTitleSettingParam>
                        Название кнопки
                    </AppTitleSettingParam>
                </template>
        </AppInputForm> 
        <ElementGlobalMessage v-if="button" 
            title="Текст сообщения при переходе с кнопки" 
            :inmessage="button.message" 
            @updateText="updateText"
            @updateMedia="updateMedia"/>
        <ChatElementActionButton  v-model="button" :button="button"  @save="save" @del="del"/>
    </div>
</template>
<script>
import ElementGlobalMessage from "@/components/ElementGlobalMessage"
import ChatElementActionButton from "@/components/ChatButtonElements/ChatElementActionButton"
export default {
  name: "ElementMessage",
  components: {
    ElementGlobalMessage, ChatElementActionButton
  },
  props: ["model"],
  data() {
    return {
        button: {}
    }
  },
  mounted(){
   
    this.button = JSON.parse(JSON.stringify(this.model))
    
  },
  methods: {
    updateText(value){
        this.button.message.text = value

    },
    updateMedia(type, media){
        this.button.message.media_type = type
        this.button.message.media = media
    },
    save(){
        if (!this.button.name) { this.showMessageNotice('', "Укажите название кнопки",'error'); return;}
        if (!this.button.message.text) { this.showMessageNotice('', "Укажите текст сообщения",'error'); return;}
        this.$emit('save',this.button);

    },
    del(){
        this.$emit('del',this.button);
    }
  },
  watch: {
    model(){
        this.button = JSON.parse(JSON.stringify(this.model))
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
