<template>
  <div class="pop-up-container"
       
  >
  
    <div class="page-container"
         
    >
      <div class="close"
           @click="close"
      >
      </div>
      <div class="">
                <AppSectionTitle> 
                  Настройки уведомлений администратора (Username) 
                </AppSectionTitle>
                <div>
                  <AppInputForm
                    :readonly="true"
                    v-model="locPeople.name">
                        <template v-slot:title>
                        </template>
                    </AppInputForm>
                    <br>
                  <AppCheckbox
                      style="margin-bottom: 10px"
                      v-for="item,index in notices"
                      v-model="item.status"
                      :key="index"
                      :name="'user_notice'"
                      :forAndId="item.id"
                  >
                    {{ item.title }}
                  </AppCheckbox>
                </div>
            
                <div style=" display:flex; justify-content:space-between" >
                  <AppButtonSettingSave @click="update()"></AppButtonSettingSave>
                  
                  <AppButtonRemove 
                        :title="'Удалить  '"
                        @click="deleteAdmin()"
                        style="width: 150px; height: 40px; margin-left: 10px;"
                    />
                </div>
              </div>   
    </div>
  </div>
</template>

<script>
import api from "./api";

export default {
  name: "PopUpAlertsAdmin",
  props: ["people",],
  data() {
    return {
      locPeople:{},
      notices: [],
    }
  },
  mounted() {
      this.locPeople = JSON.parse(JSON.stringify(this.people))
      this.getNotice( this.locPeople.id)
    },
  methods: {
    close() {
      this.$emit('close'); 
    },
    async getNotice(people_id){
      const response = await api.getNotices(people_id)
      this.notices = response.data
    },
    update() {
        api.updateNotices({notices: this.notices, people_id:this.locPeople.id}).then((response) => {
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                    }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
      this.$emit('close'); 
    },
    deleteAdmin(){
      api.deleteAdmin(this.locPeople.id).then((response) => {
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                    }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
      this.$emit('close'); 

    },
  },
  inject: ['showMessageNotice'],
}

</script>

<style scoped>
  
.pop-up-container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.05);
}

.close {
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='19.7988' width='28' height='4' transform='rotate(-45 0 19.7988)' fill='black'/%3E%3Crect width='28' height='4' transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 22.6274 19.7988)' fill='black'/%3E%3C/svg%3E");
  width: 23px;
  height: 23px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.address-add__street-house {
  display: flex;
  justify-content: space-between;
}

.address-add__street {
  width: 185px;
}

.address-add__house {
  width: 85px;
}

.address-add__apartment {
  width: 60px;
}

.address-add__home {
  display: flex;
  justify-content: space-between;
  max-width: 145px;
  margin-top: 20px;
}

.page-container {
  padding: 10px;
  position: relative;
  width: 310px;
  height: 360px;
  margin: auto;
  margin-top: 100px;
  background: #FFFFFF;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.page {
  position: absolute;
  width: 100%;
  height: 330px;
  padding: 10px;
  border-radius: 8px;
  bottom: 0;
  left: 0;
}

.address-add__button {
  height: 40px;
  width: 100px;
  margin: auto;
  margin-top: 20%;
}

.address-add__button-city {
  height: 40px;
  width: 80px;
  margin: auto;
  margin-top: 20%;
}

.address-add__button-home {
  height: 40px;
  width: 80px;
  margin: auto;
  margin-top: 20%;
}

.address-add__cities-index {
  width: 105px;
  margin-top: 10px;
}

</style>