 <template>
        <ElementQuestion :model="editElement" @save="save" @del="del"/>
 </template>
 <script>
 import ElementQuestion from "@/components/QuestionElements/ElementQuestion"

 export default {
   name: "AppQuestionCreate",
   components: {
    ElementQuestion
  },
  props: {
    shopInfo:{
      type: Object,
    },
    types: {
        type: Array
    }
  },
   data() {
     return {
        editElement: {message:{"text":""}, error_message:{"text":""},"protect_content":false},
        type: null,

     }
   },
   mounted(){
   
   },
   methods: { 
        save(value){
            this.$emit('addQuestion',value);
            this.editElement =  {}
            this.type = null
        },
        del(){
            this.editElement =  {}
            this.type = null
        },
   },
   watch: {
  
   }
 }
 </script>