<template>
    <div class="container">
        <section class="setting" v-if="shopInfo.telegram">
            <AppSettingNavigation></AppSettingNavigation>
            <div>
                <AppSectionTitle>
                    Настройки бота 
                </AppSectionTitle>
                <div class="bot-setting block-style">
                    <div class="bot-setting__lock" v-if="shopInfo.telegram.token_status">
                        <AppTitleSettingParam>
                            Название бота
                        </AppTitleSettingParam>
                        <div class="bot-setting__text" >
                            {{ shopInfo.telegram.name }}
                        </div> 
                    </div>
                    <div class="bot-setting__lock" v-if="shopInfo.telegram.token_status">
                        <AppTitleSettingParam>
                            Ссылка на бота  
                        </AppTitleSettingParam> 
                        <div class="bot-setting__text">
                            <a :href="shopInfo.telegram.link" target="_blank">{{ shopInfo.telegram.link }}</a>
                        </div>
                    </div>
                    <AppInputForm
                    v-model="shopInfo.telegram.token"
                    :placeholder="shopInfo.telegram.token_status?'Токен скрыт':'Укажите токен'">
                        <template v-slot:title>
                            <AppTitleSettingParam>
                                Токен
                            </AppTitleSettingParam>
                        </template>
                    </AppInputForm> 
                    <AppInputForm
                    v-model="shopInfo.telegram.button">
                        <template v-slot:title>
                            <AppTitleSettingParam>
                                Название кнопки приложения
                            </AppTitleSettingParam>
                        </template>
                    </AppInputForm>
                </div>
                <AppSectionTitle>
                        Оформление сообщения после “start” 
                        
                        <AppCheckbox  style="margin-bottom: 5px; margin-top: 5px;" v-model="shopInfo.telegram.protect_content">
                            Защить от копирования и пересылки
                        </AppCheckbox>
                    </AppSectionTitle>
                <div class="bot-setting block-style">
                    <div>
                        <br>
                        <AppAddImage
                            :desctroyImage="desctroyImage"
                            :combo="true"
                            :shop_id="shopInfo.id"
                            @imgData="updateImage"
                            @deleteImg="deleteImage"
                            :button="'изображение'"  
                            :headerPart="'изображения'"
                            :ratio="[16, 9]"
                            :photo="shopInfo.telegram.image_object"
                            :media_type="media_type"
                        />

                        <AppAddVideo
                                :media_type="media_type"
                                :desctroyVideo="desctroyVideo"
                                :combo="true"
                                :shop_id="this.shopInfo.id"
                                :button="'видео'"  
                                :headerPart="'видео'"
                                :video="shopInfo.telegram.video"
                                @uploadVideo="uploadVideo"
                            />
                    </div>

                    <EditableText 
                        v-model="shopInfo.telegram.start_message"  
                        :title="'Сообщение после “start”'"
                        :LengthText="'Внимание! Максимальное количество знаков с пробелами - 1000'"   
                        :LengthTextInfo="'Использовано: '+LengthText+' / 1000'"        
                        :MaxLength="1000"
                    />
                   
                     <!--
                    <AppTextareaLite 
                        :title="'Сообщение после “start”'"
                        v-model="shopInfo.telegram.start_message"
                        :placeholder="'Введите сообщение'"
                        :LengthText="'Внимание! Максимальное количество знаков с пробелами - 1000'"   
                        :LengthTextInfo="'Использовано: '+LengthText+' / 1000'"        
                        :MaxLength="1000"
                    />
                     -->
                <!--
                    <AppDescription
                        style="border: none; padding: 0"
                        :title="'Сообщение после “start”'"
                        :description="shopInfo.telegram.start_message || ''"
                        v-model="shopInfo.telegram.start_message"
                        :LengthText="'Внимание! Максимальное количество знаков с пробелами - 1000'"   
                        :LengthTextInfo="'Использовано: '+LengthText+' / 1000'"        
                        :MaxLength="1000"
                        :height="'200px'"
                        @setDescription="descriptionUpdate"
                        :placeholder="'Сообщение после “start”'"
                    />
                -->
                    
                        <AppButtons
                            :place="'start'"
                            :shopInfo="shopInfo"
                            :buttons="buttons"  
                            @updateButtons="updateButtons"
                        />
                    
                </div>
            </div>
          <div style="max-width: 172px; margin: auto">
            <AppButtonSettingSave @click="updateBotInfo()"></AppButtonSettingSave>
          </div>
        </section>
    </div> 
</template>

<script>
import AppButtons from "@/components/AppButtons"
import apiButton from '@/components/ButtonElements/api'
import { mapActions, mapGetters } from 'vuex';
import api from "./Modules/Bot/api"



export default {
    name: 'PageBotSetting',
    components: {AppButtons},
    data() {
        return {
            media_type: "none",
            desctroyVideo: false,
            desctroyImage:false,
            description: "",
            buttons: []
        }
    },
    created() {
       this.getShopInfo();
       this.getButtons()
    },
   
    mounted() {
   
    },

    computed: {
        ...mapGetters(['shopInfo']),
        LengthText() {
          if (!this.shopInfo) return 0
          if (!this.shopInfo.telegram) return 0
          if (!this.shopInfo.telegram.start_message) return 0
            return this.shopInfo.telegram.start_message.length
            //return this.description.length
        }
    },
    methods: {
        ...mapActions(['getShopInfo']),
        descriptionUpdate(data) {
            this.description = data
            },
      
        uploadVideo(data){
            this.desctroyVideo = false
            this.desctroyImage = true
            this.shopInfo.telegram.image = null
            if (!data){ this.shopInfo.telegram.media_type = this.media_type = "none"}
            else this.shopInfo.telegram.media_type  = this.media_type = "video"
            this.shopInfo.telegram.video = data
        },
        updateButtons(button, type="add"){
            if (type=="add")
                this.buttons.push(button)
            if (type=="update"){
                let index = this.buttons.findIndex(e=>e.id==button.id)
                this.buttons[index] = button
            } 
            if (type=="delete"){
                let index = this.buttons.findIndex(e=>e.id==button.id)
                this.buttons.splice(index,1)
            }
        },
        updateImage(fileName) {
            this.desctroyVideo = true
            this.desctroyImage = false
            this.shopInfo.telegram.image = fileName
            this.shopInfo.telegram.video = null
            if (!fileName) this.shopInfo.telegram.media_type  = this.media_type = "none"
            else this.shopInfo.telegram.media_type  = this.media_type = "image"
        },
        deleteImage(){
            console.log("ddd")
            this.desctroyImage = true
            this.media_type = "none"
            this.shopInfo.telegram.media_type = "none"
            this.shopInfo.telegram.image = null

        },
        async getButtons() {
            let data = {place:'start',shop_id:localStorage.getItem("shop_id")}
            const response = await apiButton.getButtons(data)
            this.buttons = response.data.data
        },
        async updateBotInfo() {
            if (typeof this.shopInfo.telegram.image === 'string' ){
                this.shopInfo.telegram.image =   this.shopInfo.telegram.image_object
            }
            //this.shopInfo.telegram.start_message = this.description
            if (this.LengthText>1000)  {this.showMessageNotice('Ошибка 09',"Длина сообщения не должна превышать 1000 символов ",'error');   return false}
            if (!this.shopInfo.telegram.button) { this.showMessageNotice('', "Укажите название кнопки магазина",'error'); return;}
            if (!this.shopInfo.telegram.start_message) { this.showMessageNotice('', "Укажите Сообщение после “start”",'error'); return;}
            if (!this.shopInfo.telegram.token_status && !this.shopInfo.telegram.token) { this.showMessageNotice('', "Укажите токен",'error'); return;}
            if (!this.shopInfo.telegram.id)
                await api.createShopTelegram(this.shopInfo.telegram).then((response) => {
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                    }
                    }).catch((error) => {
                        if (error.response.data)
                            this.showMessageNotice('Ошибка 09', error.response.data,'error')
                        if (error.response.data.message)
                            this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                    })
            else
                    console.log(this.shopInfo.telegram.id)
               await api.updateShopTelegram(this.shopInfo.telegram.id, this.shopInfo.telegram).then((response) => {
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                    }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
            this.getShopInfo();
            
        }, 
    },
    watch: {
        shopInfo(){
            if (this.shopInfo.telegram){
            this.description = this.shopInfo.telegram.start_message
            this.media_type = this.shopInfo.telegram.media_type
       }
        },
    },
    inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
