<template>
  <div class="about-setting block-style">
    <div style="display: flex;">
          <AppButton
            style="width: 49%; height: 30px; margin-right: 20px;  margin-bottom: 10px; cursor: pointer;"
            @click="goStep(1)"
            styleButton="_blue-external"
            > 
            Основное >>
          </AppButton>
          <AppButton
            @click="goStep(2)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"
            > 
            Фильтры >>
          </AppButton>
          <AppButton
         
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
           > 
            Боты >>
          </AppButton>
          <AppButton
            @click="goStep(4)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"> 
            Настройки >>
          </AppButton>
          <AppButton
            @click="goStep(5)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer;"
            styleButton="_blue-external"> 
            Дата/Время  
          </AppButton>
    </div>
    <div class="about-setting block-style">
      <div class="header-mailing">
        Боты для рассылки

      </div>
      <AppCheckbox  @update="setAll" > 
          Все Боты 
        </AppCheckbox> 
      <div style="display: flex; flex-wrap: wrap;" v-if="availableShops.length>0">
        <MaillingElementBot style="margin-top: 12px" 
              class="button-container"
                v-for="(shop, i) in availableShops"
                :key="i" 
                :element="shop"
                :selectedShops="selectedShops"
                @addToSelected="addToSelected"
                @removeFromSelected="removeFromSelected"
                >
        </MaillingElementBot>
      </div>
      </div>
      <div style="display: flex; justify-content: right">
        <AppButton 
            @click="goStep(2)"
            style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
            :styleButton="'_blue-external'"
        >
          Назад
        </AppButton>
        <AppButton
        @click="goStep(4)"
            style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
            :styleButton="'_blue-external'"
        >
          Далее
        </AppButton>
        <AppButton
            @click="prefSave"
            style="width: 83px; height: 30px; margin-top: 16px"
            :styleButton="'_blue-external'"
        >
          Сохранить
        </AppButton>
      </div>
  </div>
</template>

<script>
import MaillingElementBot from "./MaillingElementBot"

import api from "../api"
export default {
  name: "MaillingElementSelectBots",
  components: {MaillingElementBot},

  data() {
    return {

      
      availableShops: [],

      allSelect:false,
      selectedShops:[],
    
    }
  },
  props: {
    element: [Object],
  },
  mounted(){
    this.getAvailableShops()
    
  },
  methods: {
    prefSave(){
      this.$emit('prefSave')
    },
    addToSelected(shopId) {
      this.selectedShops.push(shopId);
      this.$emit('shop_ids', this.selectedShops)
    },
    removeFromSelected(shopId) {
      console.log(shopId, "removeFromSelected")
      const index = this.selectedShops.indexOf(shopId);
      if (index > -1) {
        this.selectedShops.splice(index, 1);
      }
      this.$emit('shop_ids', this.selectedShops)
    },
    goStep(id){
      console.log(id)
      this.$emit("goStep", id);
    },
    async getAvailableShops(){
      const response = await api.getAvailableShops({"all":true})
      this.availableShops = response.data.data
      this.selectedShops = this.element.shop_ids ?? []
    },
    setAll() {
      this.allSelect=!this.allSelect
      if (! this.allSelect)  {this.selectedShops = []; 
        this.$emit('shop_ids', this.selectedShops)
        return
      }

      this.availableShops.forEach(item => {
        this.selectedShops.push(item.id);
      })
      this.$emit('shop_ids', this.selectedShops)
 
    },
    next() {
      
      let ids = []
      for (const key in this.mailingBotsList) {
        
        if (this.mailingBotsList[key] === false) {
          continue
        }
        ids.push(parseInt(key))
      }
      if (this.element.type=='individ') ids.push(parseInt(this.element.availableShop_id))
      //this.mailingBotsList = this.mailingBotsList.filter(item => item !== null );
    
  
      if (ids.length==0){
        this.showMessageNotice('Ошибка 09',"Выберите ботов для рассылки",'error')
        return
      }
      if (this.usersType.length==0){
        this.showMessageNotice('Ошибка 09',"Выберите пользователей для рассылки",'error')
        return
      }
      let shops =  this.availableShops.filter(item => ids.includes(item.id)).map(shop => shop.name);

      this.$emit("next", ids, "all", shops );

    },
    prev() {
      this.$emit("prev");
    },
    setUsersType(value) {
      this.$emit('data', {action: 'writeUsers', value: {userType: value}})
    },

    setRegDate(value) {
      this.$emit('data', {action: 'writeUsers', value: {dateReg: value}})
    },

    setAverageCheck(value) {
      this.$emit('data', {action: 'writeUsers', value: {sumAverage: value}})
    },

    setLastBayDate(value) {
      this.$emit('data', {action: 'writeUsers', value: {dateLastBay: value}})
    },

    setRansomAmount(value) {
      this.$emit('data', {action: 'writeUsers', value: {sum: value}})
    },

    setTotalNumBay(value) {
      this.$emit('data', {action: 'writeUsers', value: {numBay: value}})
    },

    close() {
      this.$emit('data', {action: 'nextPage', value: {name:'ChatsList'}})
    },
  
  },
  watch: {
  
  
  },
  inject: ['showMessageNotice'],
}
</script>

<style scoped>
.date-reg_content {
  border-top: 1px solid #C7D1D8;
}

.date-reg_container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.date-reg_header {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  margin-top: 20px;
}

.order-filter__type_ul {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  flex-wrap: wrap; /* Разрешить перенос элементов на новую строку */
  justify-content: flex-start; /* Выравнивание элементов в начале строки */
  cursor: pointer;
}

.header-mailing {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.about-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 16px 12px;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

</style>