<template>
  <section class="section-chat chat section">
    <div class="container">
      <component
          :is="currentPage"
          @data="exec"
          :mailingData="mailingData"
          :mailingUsers="mailingUsers"
          :shop_id="shop_id"
          :mailingList="mailing"
      /> 
    </div>
  </section>
</template>

<script>
import api from './api'
import ChatCreate from './ChatCreate';
import ChatReadySend from './ChatReadySend';
import ChatSettingPublicNew from './ChatSettingPublicNew';
import ChatsList from './ChatsList';
import ChatClients from './ChatClients';

function getDateNow() {
  let date = new Date();
  return `${date.getUTCDate()}.${date.getUTCMonth()}.${date.getUTCFullYear()}`
}

export default {
  name: 'PageChat',
  components: {
    ChatCreate,
    ChatReadySend,
    ChatSettingPublicNew,
    ChatsList,
    ChatClients,
  },
  data() {
    return {
        shop_id: localStorage.getItem('shop_id') ,
        mailingData: {
          send_date: new Date(),
          shop_id:  localStorage.getItem('shop_id') ,
          image: '', 
          video: {},
          media_type: 'none',
          name: '',
          description: '',
          status: 'Draft', // Draft Sent Delivered Read
          date: getDateNow(),
      },
      mailingUsers: {
        userType: '',
        dateReg: '',
        dateLastBay: '',
        sumAverage: '',
        sum: '',
        numBay: '',
      },
      currentPage: 'ChatsList',
      mailing: [
        {
          "image":[],
          "header":"Lorem ipsum","description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed pellentesque lacus, ut ultricies mauris. Curabitur lobortis ornare diam id sagittis. ",
          "status":"Draft",
          "date":"12.10.2022",
          "numbRecipient":"123",
          "numberViews":"202",
        },
        {
          "image":[],
          "header":"Lorem ipsum","description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed pellentesque lacus, ut ultricies mauris. Curabitur lobortis ornare diam id sagittis. ",
          "status":"Draft",
          "date":"12.10.2022",
          "numbRecipient":"123",
          "numberViews":"202",
        },
      ],
      search: {},
    };
  },
  created() {
  
  },
  methods: {
    exec(data) { 
      this[data.action](data.value);
    },
    Name(item) {
      this.userNotification = item;
      this.showEmailForm = true
    },
    nextPage(value) {
      this.mailingUsers.all = value.all
      this.mailingUsers.count = value.count
      this.mailingUsers.elements = value.elements
      

      this.currentPage = value.name
    },
    writeData(data) {
     

      if (data)  {
        Object.assign(this.mailingData, data)
      }
        
      else
        Object.assign(this.mailingData, {
              id: null,
              send_date: new Date(),
              shop_id:  localStorage.getItem('shop_id') ,
              image: '',
              video: null,
              button_text: '',
              button_link: '',
              name: '',
              media_type: 'none',
              description: '',
              status: 'draft', // Draft Sent Delivered Read
              date: getDateNow(),
              recipients_count: 0, 
              readers_count: 0
          })
      
      
    },
    filterContent(data) {
      console.log(`данные для фильтрации  ${data}`)
    },
    writeUsers(data) {
      Object.assign(this.mailingUsers, data);
    },
   
    sendMessage(chat, status_id) {
      chat.answer = (chat.editAnswer) ? chat.editAnswer : chat.answer
      if (!chat.answer || chat.answer.length < 3) {
        this.showMessageNotice('Ошибка 09', "Напишите ответ", 'error');
        return
      }
      let data = {
        answer: chat.answer,
        status_id: status_id,
      }
      api.updateChat(chat.id, data).then((response) => {
        if (response.data.message) {
          this.showMessageNotice('', response.data.message, 'success')
          this.getModels();
        }
      }).catch((error) => {
        if (error.response.data)
          this.showMessageNotice('Ошибка 09', error.response.data, 'error')
        if (error.response.data.message)
          this.showMessageNotice('Ошибка 09', error.response.data.message, 'error')
      })
    },
    goSearch(input) {
      this.search = input
      this.getModels()
    },
    deleteChat(id) {
      api.deleteMailling(id).then(() => {
          this.getModels();
      }).catch((error) => {
        if (error.response.data)
          this.showMessageNotice('Ошибка 09', error.response.data, 'error')
        if (error.response.data.message)
          this.showMessageNotice('Ошибка 09', error.response.data.message, 'error')
      });
    },
    goPage(page) {
      this.page = page
      this.getModels()
    },
    startFiltered() {
      for (const filterName in this.filterContent.filters) {
        let filter = this.filterContent.filters[filterName];
        this.search[filterName] = ''
        filter.items.forEach(itm => {
          if (itm.selected) {
            this.search[filterName] += itm.id + ',';
          }
        })
        if (this.search[filterName]) this.search[filterName] = this.search[filterName].replace(/,$/g, '');
        else delete this.search[filterName];
      }
      this.getModels();
    },
    getModels() {

      let data = {
        shop_id: this.shopInfo.id,
        page: this.page,
        pageSize: this.pageSize,
        search: this.search
      }
      this.getChats(data)
    }
  },
  provide() {
    return {
      startFiltered: this.startFiltered.bind(this),
    }
  },
  watch: {
    shopInfo() {
      this.getModels()
    },
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
.chat {
  padding-top: 0;

  &::before {
    content: none;
  }
}

.chat__search {
  margin-bottom: 11px;
}
</style>