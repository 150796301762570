<template>
    <div class="filter">
        <AppButton
        class="filter__button"
        @click="showContent = !showContent">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.21622 5.92308C4.42512 6.19594 4.36267 5.96857 4.36267 11.4074C4.36267 11.8941 4.97785 12.1726 5.41143 11.8815C7.26417 10.6207 7.63434 10.5115 7.63434 9.9217C7.63434 5.95833 7.58316 6.18122 7.78079 5.92308L10.7894 2.22656H1.20761L4.21622 5.92308Z" fill="white"/>
                <path d="M11.926 0.301406C11.8185 0.115547 11.6076 0 11.3755 0H0.621596C0.120326 0 -0.17462 0.510398 0.112925 0.88125C0.115288 0.884812 0.0802864 0.84157 0.635358 1.52344H11.3617C11.8347 0.942352 12.1509 0.691289 11.926 0.301406Z" fill="white"/>
            </svg>
        </AppButton>
        <div class="filter__content"
          v-if="this.showContent">
            <div class="filter__content_inner">
                <div class="filter__content_top"> 
                    <div class="filter__content_title"> {{ localFilter.title }} </div>
                    <AppX @click="showContent = false" :styleX="'_white'"/>
                </div>
                <div class="filter__content_item content-item"
                  v-for="(filter, key, i) in localFilter.filters"
                  :key="filter.title">
                    <div class="content-item__title">
                        {{ filter.title }}
                    </div>
                    <div class="content-item__list"  v-if="filter.type === 'radio'">
                      <AppRadio v-for="(item) in filter.items"   :key="item.id"
                            v-model = "filter.current"
                            :forAndId="item.id" 
                            :name="'filter_'+filter.type+'_'+i"
                        >
                        <div class="content-item__name">
                          {{ item.name }}
                        </div>
                      </AppRadio>
                    </div>
                  <div class="content-item__list"
                       v-if="filter.type === 'checkbox'">
                    <AppCheckbox
                        v-for="item in filter.items"
                        v-model="item.selected"
                        :key="item.id"
                        :name="item.name"
                        :forAndId="item.name">
                      <div class="content-item__name">
                        {{ item.name }}
                      </div>
                    </AppCheckbox>
                  </div>
                </div>
                <div class="filter__buttons">
                    <AppButton
                        @click="clearFilter"
                    :styleButton="'_white-external'">
                        Очистить фильтр
                    </AppButton>
                    <AppButton
                        @click="goFilter"
                    :styleButton="'_white'">
                        Фильтровать
                    </AppButton>
                </div>
                <br>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppFilter",
    data() {
        return {
          showContent: false,
          settingFilter: {}
        };
    },
    props: {
        filterContent: {
            type: Object
        },
    },
  computed: {

  },
    methods: {
      goFilter(){
        for (const key in this.localFilter.filters) {
          if (this.localFilter.filters[key].type!="checkbox") continue;
          let items = this.localFilter.filters[key].items
          this.localFilter.filters[key].current = []
          for (let index = 0; index < items.length; index++) {
            if (!items[index].selected) continue
            this.localFilter.filters[key].current.push(items[index].id)
          }
        }
        this.localFilter.current = []
        this.localFilter.filter = ""
        for (const key in this.localFilter.filters) {
          this.localFilter.current[key] = this.localFilter.filters[key].current
          this.localFilter.filter = this.localFilter.filter+key+":"+this.localFilter.filters[key].current+";"
        }
        this.$emit('filtered', this.localFilter)
        this.showContent = false
  
       
      },
      clearFilter() {
        for (const key in this.localFilter.filters) {
          if (this.localFilter.filters[key].type!="checkbox") {
            this.localFilter.filters[key].current = ""
            continue;
          }
          let items = this.localFilter.filters[key].items
          this.localFilter.filters[key].current = []
          for (let index = 0; index < items.length; index++) {
            items[index].selected = false
          }
        }
        this.localFilter.current = []
        this.localFilter.filter = ""
        this.$emit('filtered', this.localFilter)
        this.showContent = false;
      }
    },
  mounted() {
    this.localFilter = this.filterContent
    for (const key in this.localFilter.filters) {
          if (this.localFilter.filters[key].type!="checkbox") continue;

          let items = this.localFilter.filters[key].items
          let current = this.localFilter.filters[key].current
          for (let index = 0; index < current.length; index++) {
            items.find(e=>e.id==current[index]).selected= true
          }
        }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
    .filter {
    }
    .filter__button {
        width: 30px;
        height: 30px;
    }
    .filter__content {
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 1000;
    }
    .filter__content_inner {
        background: var(--default_background);
        border-radius: 10px;
        color: var(--default_color);
        padding: 9px 15px;
        margin-top: 5px;
        width: 97.5%;
        height: 100%;
    }
    .filter__content_top {
        display: flex;
        justify-content: space-between;
    }
    .filter__content_title {
        font-weight: 500;
        margin-bottom: 10px;
    }
    .content-item {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .content-item__list {
        border-top: 1px solid var(--default_color);
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        padding-top: 10px;
    }
    .content-item__title {
        font-size: 13px;
        font-weight: 400;
    }
    .content-item__name {
        color: var(--default_color);
        font-size: 13px;
        font-weight: 500;
    }
    .filter__buttons {
        display: flex;
        column-gap: 10px;
        height: 30px;
    }
</style>