<template>
    <div class="products-table products__container">
        <div class="products-table__content">
            <div class="products-table__names">
                <AppTitleSmall class="products-table__name">
                    Фото 
                </AppTitleSmall>
                <AppTitleSmall class="products-table__name">
                    Товар
                </AppTitleSmall>
                <AppTitleSmall class="products-table__name">
                    Цена
                </AppTitleSmall>
            </div> 
            <ul class="products"> 
                <li class="product block-style"
                    :id = "'product_'+index"
                    v-for="(product, index) in products"
                    :key="product.id">
                    <div class="product__short-info">
                        <div class="product__image" > 
                            <img class="product__img" v-if="product.media_images.length>0"
                            :src="'https://lk.ivitrina.store/medias/'+product.media_images[0].link+'webp/'+product.media_images[0].name+'.webp'" alt="">
                            <!--
                            <svg class="image__svg" width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="8" fill="#FF406E"/>
                                <path d="M9.11703 4.21045L8.83966 9.89495H7.07224L6.79487 4.21045H9.11703ZM6.73682 11.666C6.73682 11.3918 6.84862 11.1632 7.07224 10.9804C7.29585 10.7938 7.58612 10.7005 7.94305 10.7005C8.29997 10.7005 8.58809 10.7938 8.80741 10.9804C9.03102 11.1632 9.14283 11.3918 9.14283 11.666C9.14283 11.9402 9.03102 12.1706 8.80741 12.3573C8.58809 12.5401 8.29997 12.6315 7.94305 12.6315C7.58612 12.6315 7.29585 12.5401 7.07224 12.3573C6.84862 12.1706 6.73682 11.9402 6.73682 11.666Z" fill="white"/>
                            </svg>
                            -->
                        </div>
                        <div class="product__text">
                            <div class="product__title">
                                {{ product.name }}
                            </div> 
                            <div class="product__description">
                            
                             <span v-if="product.description" v-html="product.description.substr(0, 100)+'...'">  </span>
                            </div>
                        </div>
                        <AppProductPrice class="product__price" v-if="product.type_id!=2">
                            {{ product.price }} руб.
                        </AppProductPrice>
                    </div>
                    <ul class="product__additional-info"> 
                        <li class="product__additional-info_item">
                            <AppTitleSmall class="product__additional-info_title-small">
                                Артикул:
                            </AppTitleSmall>
                            <span class="product__additional-info_span">
                                {{ product.article }}
                            </span>
                        </li>
                        <li class="product__additional-info_item">
                            <AppTitleSmall class="product__additional-info_title-small">
                                Дата:
                            </AppTitleSmall>
                            <span class="product__additional-info_span">
                                {{ product.created_at }}
                            </span> 
                        </li>
                        <li v-if="[1,4].includes(product.type_id)" class="product__additional-info_item">
                            <AppTitleSmall class="product__additional-info_title-small">
                                Кол-во
                            </AppTitleSmall>
                            <span class="product__additional-info_span">
                                {{ product.quantity }}
                            </span>
                        </li>
                       
                    </ul>
                    <div class="product__category">
                        <div class="product__category_text">
                            <AppTitleSmall class="product__category_title-small">
                           
                                Тип товара:
                            </AppTitleSmall>
                            <span class="product__category_text-span">
                                {{ product.type.name }}
                            </span>
                        </div>
                    </div>
                   
                    <div class="product__category" v-if="product.stocks.length>0">
                        <div class="product__category_text">
                            <AppTitleSmall class="product__category_title-small">
                                <br>
                                Акции:
                            </AppTitleSmall>
                            <span class="product__category_text-span">
                                {{product.stocks[0].name}}
                            </span>
                        </div>
                    </div> 
                    <div class="product__status">
                        <div>
                            <AppTitleSmall class="product__category_title-small">
                                <br>
                                Рейтинг в выдаче на главной странице:
                            </AppTitleSmall>
                            <AppSelectRating class="product__status_select"
                                style="margin-bottom: 5px;"
                                v-model="product.position"
                                @update="updateProduct(product, product.position,'position')"
                                :items="ratings">
                            </AppSelectRating>
                        </div>
                    </div>
                  
                  
                    <div class="product__status" >
                        <AppSelect v-if="statuses_short && product.status_id!=4"
                            class="product__status_select"
                            @updateC="updateProduct(product, product.status_id,'status')"
                            v-model="product.status_id"
                            :block="(product.status_id==4)?true:false"
                            :items="statuses_short">
                            <template v-slot:title>
                                <AppTitleSmall class="product__additional-info_title-small">
                                    Статус: 
                                </AppTitleSmall>
                            </template>
                        </AppSelect>

                        <AppSelect  v-if="product.status_id==4"
                            class="product__status_select"
                            @updateC="updateProduct(product, product.status_id, 'status')"
                            v-model="product.status_id"
                            :block="(product.status_id==4)?true:false"
                            :items="statuses">
                            <template v-slot:title>
                                <AppTitleSmall class="product__additional-info_title-small">
                                    Статус: 
                                </AppTitleSmall>
                            </template>
                        </AppSelect>
                        <div class="product__status__buttons">
                            <router-link
                                :to="{name: 'editProduct', params: {id: product.id, shop_id:product.shop_id, editpod:JSON.stringify(product)}}"
                                >
                                    <AppButtonEdit class="product__status_button"></AppButtonEdit>
                            </router-link>
                            <AppButtonArchive @click="updateProduct(product, '3', 'status')" class="product__status_button"></AppButtonArchive>
                        </div>
                    </div>
                    <br>
                </li>
            </ul>
        </div>
    </div> 
</template>

<script>
export default {
    name: "AppProducts",
    data() {
        return {
            rating:  {},
            ratings:[
                {title:"1 ",value:1, position:1},
                {title:"2 ",value: 2, position:2},
                {title:"3 ",value:3, position:3},
                {title:"4 ",value:4, position:4},
                {title:"5 ",value:5, position:5},
                {title:"6 ",value:6, position:6},
                {title:"7 ",value:7, position:7},
                {title:"8 ",value:8, position:8},
                {title:"9 ",value:9, position:9},
                {title:"10 ",value:10, position:10},
                {title:"",value:0, position:100},
            ],
            statuses: JSON.parse(localStorage.getItem('product_statues')),
            statuses_short: JSON.parse(localStorage.getItem('product_statues')).filter((e)=>e.edit==true)
        };
    },
    props: {
        productsMeta:{
             type: Object,
            required: true
        },
        products: {
            type: Array,
            required: true
        },
    },
    components: {},
    computed: {},
 
     methods: {
        updateProduct(item, element, type){
            this.$emit('updateProduct', item, element, type)            
        },
      }, 
     
    
}
</script>

<style lang="scss" scoped>
     .image__svg {
        position: absolute;
        top: 0px;
        right: 0px;
    }
    .products-table__content { 
        background: #FFFFFF;

        border: 1px solid #E4E7ED;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 6px;

        color: rgba(0, 0, 0, 0.75);

        padding: 11px 13px;
        margin-bottom: 14px;
    }

    .products-table__names {

        display: grid;
        grid-template-columns: 0.25fr 1.25fr 0.5fr;
        grid-gap: 10px;

        position: relative;
        margin-bottom: 15px;

        &::after {
            background: #E4E7ED;

            content: "";
            
            position: absolute;
            bottom: -10px;

            height: 1px;
            width: 100%;
        }
    }

    .products-table__name {
        
        &:last-child {
            text-align: right;
        }
    }
    .products {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }

    .product {
        padding-left:8px;
        padding-right:8px;
        position: relative;
       
        padding-top: 11px;

        &::before {
            background: #E4E7ED;

            content: "";
            
            position: absolute;
            top: 0;

            height: 1px;
            width: 100%;
        }
    }

    .product__short-info {
      
        display: grid;
        align-items: start;
        grid-template-columns: 0.25fr 1.25fr 0.5fr;
        grid-gap: 10px;

        .product__price {
            font-size: 12px;

            text-align: right;
        }
    }

    .product__image {
        position: relative;

        padding-top: 100%;

        width: 100%;
        .product__img {
 
            position: absolute;
            top: 0;
            left: 0;

            object-fit: cover;

            width: 100%;
            height: 100%;
            }
    }
   
   

    .product__title {
        color: rgba(0, 0, 0, 0.75);

        font-size: 14px;
    }

    .product__description {
        color: var(--global_fourth_color);

        margin-top: 7px;

        font-size: 10px;
    }


    .product__additional-info {
        border-top: 1px solid #E4E7ED;
        border-bottom: 1px solid #E4E7ED;

        display: flex;
        justify-content: space-between;

        padding: 9px 0;
        margin: 9px 0;
    }

    .product__additional-info_span {
        font-size: 12px;
    }
    .product__category  {
        padding-bottom: 10px;
        border-bottom: 1px solid #E4E7ED;
    }
    .product__category_text-span  {
        font-size: 12px;
    }

    .product__status {
        margin-top: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .product__status__buttons {
        display: flex;
        column-gap: 5px;
    }
</style>