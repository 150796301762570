<template>
  <div class="chat-create">
    <div class="header-new-mailing">
      <span>Выбор получателей рассылки</span>
     

      <svg @click="close"
           width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z"
              fill="#8EA2B0"/>
      </svg>
      
    </div>
    <AppSearch class="search-filter__input" :placeholder="'Поиск по имени, id, username'" @input="goSearch"></AppSearch>
    <br>
    <AppCheckbox v-if="search.length<1"  @update="setAll" > 
      Все получатели
    </AppCheckbox> 
    
    <AppMailingUser style="margin-top: 12px"
                    v-for="(people, i) in allPeoples"
                    :key="i"
                    :people="people"
    >
      <template v-slot:checkbox>
        <AppCheckbox
            v-model="mailinUsersList[people.id]"
        />
      </template>
    </AppMailingUser>
    <div class="table-footer">
      <AppSelect2
          :selectPage="page"
          :options="allPages"
          @selectVariant="selectPage"
      />
      <div class="turn-pages-container flex-between-center">
        <AppButtonIcon
            @click="setPrevPage"
            style="height: 30px"
            :styleButton="'_grey' + (page === 1 ? ' _no-active' : '')"
            :icon="arrow_grey_left"
            :onlyIcon="true"
        />
        <AppButtonIcon
            @click="setNextPage"
            style="height: 30px"
            :styleButton="'_grey' + (page === this.peoplesMeta.pages ? ' _no-active' : '')"
            :icon="arrow_grey_right"
            :onlyIcon="true"
        />
      </div>
    </div>
    <div style="display: flex; justify-content: space-between">
      <AppButton
          @click="prev"
          style="width: 83px; height: 30px; margin-top: 16px"
          :styleButton="'_blue-external'"
      >
        Назад
      </AppButton>
      <AppButton
          @click="next"
          style="width: 83px; height: 30px; margin-top: 16px"
          :styleButton="'_blue-external'"
      >
        Далее
      </AppButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
function addSlowscroll(el, callback) {
  let ctx = this;
  document.addEventListener(
      'scroll', function () {
        setTimeout(function () {
          callback();
          if (ctx.c_isLive) addSlowscroll.call(ctx, el, callback);
        }, 300);
      },
      {once: true}
  );
}

export default {
  name: "ChatClients",
  data() {
    return {
      shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
      search: "",
      allSelect: false,
      pageSize: 10,
      page: 1,
      arrow_grey_right: '' +
          '<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M5.18809 7.24583L0.35083 11.3048L1.49356 12.6666L7.62192 7.52433L7.25062 7.08183L7.32138 7.02246L2.17908 0.894104L0.817219 2.03684L5.18809 7.24583Z" fill="#8EA2B0"/>\n' +
          '</svg>',
      arrow_grey_left: '' +
          '<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M2.43374 5.42079L7.271 1.36185L6.12826 -3.7148e-06L-9.3086e-05 5.1423L0.371206 5.58479L0.300449 5.64417L5.44275 11.7725L6.80461 10.6298L2.43374 5.42079Z" fill="#8EA2B0"/>\n' +
          '</svg>',
      currentPage: 1,
      totalPage: '',
      mailinUsersList: {}
    }
  },
  computed: {
    ...mapGetters(['allPeoples', 'peoplesMeta']),
    allPages(){
      return this.peoplesMeta.pages
    }
  },
  props: {
    mailingData: [Object],
    mailingUsers: [Object],
    shopInfo: [Object],
  },
  methods: {
    ...mapActions(['getPeoples']),
    async getModels(){

      let filter = "status:"+this.mailingUsers.userType

      let data = {
            shop_id: this.mailingData.shop_id,
            page:this.page,
            pageSize:this.pageSize,
            search:this.search,
            filter: filter
        }
   
         this.getPeoples(data)
      
    },
    goSearch(event) {
      this.search = event.target.value
      this.getModels()
    },
    setPrevPage() {
     
      if (this.page==1) return
    
      this.page--
      this.getModels()
    },
    setNextPage() {
      
      if (this.peoplesMeta.pages>=this.pag) {
      
        return
      }
      this.page++
      this.getModels()
    },
    selectPage(value) {
      this.page = value
      this.getModels()
    },

    setAll(val) {
      this.allSelect=!this.allSelect

      for (const key in this.mailinUsersList) {
        this.mailinUsersList[key] = val;
      }
  
    },

    close() {
      this.$emit('data', {action: 'nextPage', value: {name:'ChatsList'}})
    },
    next() {
      let key
      let select = 0
      if (this.allSelect)  {
        select = this.peoplesMeta.all
        let i =0
        for (key in this.mailinUsersList) {
          if (!this.mailinUsersList[key]) i++
        }
        select = select- i
      }
      else{ 
        for (key in this.mailinUsersList) {
          if (this.mailinUsersList[key]) select++
        }
      }
      
      if (select<1) {  this.showMessageNotice('Ошибка 09',"Выберите минимум одного получателя рассылки",'error'); return}
      console.log(this.allSelect);
      this.$emit('data', {action: 'nextPage', value: {"name":'ChatReadySend', all:this.allSelect, count:select, elements:this.mailinUsersList}})

    },
    prev() {

      if (this.shop_type.name=="vizit") {
        this.$emit('data', {action: 'nextPage', value: {"name":'ChatCreate'}})
        return
      }
      this.$emit('data', {action: 'nextPage', value: {"name":'ChatSettingPublicNew'}})
    },
    async checkEndList() {
      let scrollContainer = document.querySelector('html');
      let scrollBottom =
          scrollContainer.scrollHeight -
          scrollContainer.clientHeight -
          scrollContainer.scrollTop;

      if (scrollBottom < 300 && this.page < this.lastPage) {
        let data = await this.getModels();
        this.lastPage = data.meta.lastPage;
      }
    },
    addListeners() {
      let scrollContainer = document.querySelector('html');
      addSlowscroll.call(this, scrollContainer, this.checkEndList.bind(this));
    },
  },
  mounted() {
    this.addListeners();
    this.getModels()
  },

  watch: {
    allPeoples(allPeoples) {
      let select = false
      if (this.allSelect) {
        select = true
      }
      allPeoples.forEach(item => {
        this.mailinUsersList[item.id] = select;
      })
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style scoped>

.turn-pages-container {
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-footer {
  display: flex;
  height: 30px;
  justify-content: space-between;
  margin-top: 15px;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

</style>