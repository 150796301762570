<template>
    <section class="section-product-edit product-edit section">
        <div class="container" v-if="product">
            <div class="flex-container">
                <AppSectionTitle>
                Карточка товара 
                </AppSectionTitle>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    @click="goBack"
                >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z" fill="#8EA2B0"/>
                </svg>
            </div>
            <div class="product-edit__content">
                <div class="product-edit__status">
                    <AppTitleSettingParam>
                        Статус
                    </AppTitleSettingParam>
                    <ul class="product-edit__status_ul">
                        <li class="product-edit__status_li" 
                            v-for="element,index in statuses" 
                            :key="index">
                            <AppRadio
                            v-model = "product.status_id"
                            :forAndId="element.id" 
                            :name="'status'"> 
                                <AppTitleSettingParam>
                                    {{element.name}} 
                                </AppTitleSettingParam> 
                            </AppRadio>
                        </li>
                    </ul>
                </div>
                <div class="product-edit__info"> <!--Тип товара-->
                
                    <AppSelect
                        v-model="product.type_id"
                        :items="types">
                            <template v-slot:title>
                               Тип товара*
                            </template>
                    </AppSelect>

                </div>

                <div class="product-edit__info"> <!--основные параметры-->
                    <AppInputForm
                        inputRequired
                        :subSymbol="''"
   
                        v-model="product.name"
                        > 
                        <template v-slot:title>
                            Название товара*
                        </template>
                    </AppInputForm>
                    <AppInputForm
                        
                        :typeInput="'string100'"
                     v-model="product.article">
                        <template v-slot:title>
                            Артикул 
                        </template> 
                    </AppInputForm>
                   
                    <AppDescription 
                        :title="'Описание'"
                        :info="''"
                        :description="(product.description)?product.description:''"
                        @setDescription="setDescription"
                    />
                    <div class="product-edit__photos" style="margin-top: -10px;">
                        <div class="about-setting__upload-img"> 
                
                            <AppGallery v-if="product.media_images"
                                @updatePosition="updatePosition"
                                :imagesUrl="product.media_images"
                                @delImg="deleteElement" 
                                v-model="product.first"
                            />
                        </div>
                        <AppModalCropImage style="margin-top: -20px;"
                            :ratio="['1:1', '9:16']"
                            @addImage="addImage"
                            :type="'product'"
                            :shop_id="product.shop_id"
                            :showButtonCrop="true"
                            :title="'фото'"
                        /> 
                                    
                    </div> 
                </div>
                <div v-show="[1,3,4].includes(product.type_id)" style="margin-top: -10px;" class="product-edit__price-count"> <!--Цена кол-во-->
                    <AppInputForm
                    pattern="number"
                    inputRequired
                    v-model="product.price"
                        :typeInput="'number'"
                    >
                        <template v-slot:title>
                            Цена*
                        </template> 
                    </AppInputForm>
                    <br>
                    <AppInputForm v-show="[1,4].includes(product.type_id)"
                    inputRequired
                    v-model="product.quantity"
                    pattern="number"
                    :typeInput="'number'"
                    >
                        <template v-slot:title>
                            Количество товара*
                        </template>
                    </AppInputForm>
                </div>
                <div > <!--Характеристики-->
                    <div v-for="(property, index) in product.properties" :key="index">
                            <AppInput  v-model="property.name" @change="saveProperty(property,index)">
                                <template v-slot:title>
                                    Характеристика
                                </template>
                            </AppInput>
                            <div  class="product-edit__inputs">
                            <!--
                                <AppInputForm v-model="property.value" :typeInput="'integer'"  @change="saveProperty(property,index)">
                                    <template v-slot:title>
                                        Значение
                                    </template>
                                </AppInputForm>
                                -->
                                <AppInput v-model="property.ed"  @change="saveProperty(property,index)">
                                    <template v-slot:title>
                                    Значение
                                    </template>
                                </AppInput>
                            <AppButtonRemove @click="deleteProperty(index)" style="margin-top:22px"></AppButtonRemove>
                            </div>
                    </div>
                    <div class="product-edit__add-form">
                        <br>
                        <AppButtonAdd @click="specificationFormShow = !specificationFormShow">
                            Добавить характеристику
                        </AppButtonAdd>
                        <div
                        class="product-edit__add-form_show"
                        v-if="specificationFormShow === true">
                            <AppInput v-model="new_property.name">
                                <template v-slot:title>
                                    Характеристика
                                </template>
                            </AppInput>
                                <div class="product-edit__inputs">
                                <!--
                            <AppInputForm v-model="new_property.value"  :typeInput="'integer'">
                                <template v-slot:title>
                                    Значение
                                </template>
                            </AppInputForm>
                            -->
                            <AppInput v-model="new_property.ed">
                                <template v-slot:title>
                                    Значение
                                </template>
                            </AppInput>
                        </div>
                            <AppButtonSettingSave @click="saveProperty(new_property)"></AppButtonSettingSave>
                        </div>
                    </div>
                </div>
                <div>  <!--Ключевые слова -->
                    <AppInput v-model="product.words">
                        <template v-slot:title>
                            Ключевые слова для поиска
                        </template>
                    </AppInput>
                </div>
                <div v-show="[3].includes(product.type_id)" > <!--Ссылка на товар-->
                    <AppInputForm 
                            inputRequired
                            :typeInput="'string100'"
                            v-model="product.link"
                            >
                            <template v-slot:title>
                                Ссылка на товар*
                            </template>
                        </AppInputForm>
                </div>
                <div class="promo" v-if="categories.length>0"> <!--Категории-->
                    
                    <AppSelect
                     Ц   :style="{maxWidth: '90%'}"
                        v-model="product.category_id"
                        :items="categories">
                            <template v-slot:title>
                                Выбор категории из списка
                            </template> 
                    </AppSelect>
                    <div title="Удалить категорию">
                            <AppButtonRemove
                                :style="{cursor: 'pointer'}"
                                @click="product.category_id = null"
                            />
                        </div>
                </div>
                <div  class="product-edit__category"> 
                    <div class="promo" v-if="stocks.length>0"> <!--Акции-->
                        <AppSelect
                            :style="{maxWidth: '90%'}"
                            v-model="product.stock_id"
                            :items="stocks">
                        <template v-slot:title>
                            Выбор акции
                        </template>
                        </AppSelect>
                        <div title="Удалить акцию">
                            <AppButtonRemove
                                :style="{cursor: 'pointer'}"
                                @click="product.stock_id = null"
                            />
                        </div>
                    </div>
                </div>
                <div class="product-edit__interaction" >
                    <AppButtonSettingSave @click="save" :styleButton="(blockButton)?'_no-active':'active'"></AppButtonSettingSave>
                       
                    </div>
                    <br>
            </div>
        </div>
    </section>
</template>

<script>
import api from './api'

export default {
    name: "PageProductEdit",
    data() {
        return {
            blockButton:false,
            product_id: null,
            specificationFormShow: false,
            name:null,
            new_property: {},
     
            types: JSON.parse(localStorage.getItem('product_types')),
            categories: JSON.parse(localStorage.getItem('categories')), 
            stocks: JSON.parse(localStorage.getItem('stocks')), 
            product: {
                name: "",
                article: "",
                images: [],
                status_id: 2,
                type_id: 1,
                category_id: null,
                shop_id: localStorage.getItem('shop_id') ,
            },
        };
    },

    components: {  }, //AppUploadImage , AppCropImage
     computed: {
        statuses(){
           let items = JSON.parse(localStorage.getItem('product_statues'))
        
           if (this.product.status_id==4) {
           
            let x = []
            for (let index = 0; index < items.length; index++) {
                if (items[index].id==2) continue; //Опубликован
                if (!items[index].edit) continue;
                x.push(items[index])
            }
            return x
           }
           return items.filter(e=>e.edit==true)
        }
     },
    
     created() {
        //if (!this.shop_id) {window.location.href="/"}
     },
     async mounted(){
        if (this.$route.params.id=="create") {
            if (this.$route.params.shop_id)
                this.product.shop_id = this.$route.params.shop_id
            else
                this.product.shop_id = localStorage.getItem('shop_id')
            return
        }
        
        if (!this.$route.params.id) {window.location.href="/"}
        this.product_id = this.$route.params.id
       
        this.getProduct( this.product_id );
        
     },
     methods: { 
        updatePosition(images){  
           console.log(images)
            this.product.media_ids = []
            for (let index = 0; index < images.length; index++) {
                this.product.media_ids.push(images[index].id)
            }
        },
        goBack(){
            this.$router.push({name:"PageProduct", params: {back: true}})
        },
        async getProduct(product_id){
           
            try {
                const response = await api.getProduct(product_id)
                
                this.product = response.data.data
               
                if (this.product.stocks.length>0){
                    this.product.stock_id = this.product.stocks[0].id
                }
                if (this.product.categories.length>0){
                    this.product.category_id = this.product.categories[0].id
                }
               
            }
            catch(err) {
               // {window.location.href="/"}
            }
        },
        deleteElement(id){
                let index = this.product.media_images.findIndex(e=>e.id==id)
                this.product.media_images.splice(index,1) 
                this.product.media_ids.splice(index,1) 
        },
        setDescription(description) { //Изменение description
            this.product.editDescription = description
        },

        addImage(value){
                if (!this.product.media_images) this.product.media_images = []
                    this.product.media_images.push(value)
                if (!this.product.media_ids) this.product.media_ids = []
                    this.product.media_ids.push(value.id)
        },
        saveProperty(property, index=-1){
            if (index<0){ 
                if (!this.product.properties) this.product.properties = []
                this.product.properties.push(property); 
                this.new_property={}
                this.specificationFormShow = false
               }
            else
                this.product.properties[index]=property
        },
        deleteProperty(index=-1){
            if (index<0) return 
            this.product.properties.splice(index,1)
        },
        save() {
            
            if (this.product.name.length<3) {this.showMessageNotice('Ошибка 09', "Укажите название товара",'error'); return}
            if (this.product.media_images.length<1) {this.showMessageNotice('Ошибка 09', "Загрузите минимум 1 изображение",'error'); return}
           
            if ([3].includes(this.product.type_id))
                {
                    if (this.product.price<1) {this.showMessageNotice('Ошибка 09', "Укажите цену",'error'); return}
                    console.log(this.product.price.isInteger,":this.product.price.isInteger")
                    if (this.product.price !== ""+parseInt(this.product.price)) {this.showMessageNotice('Ошибка 09', "Для цены используйте только цифры",'error'); return}
                    if (!this.product.link)   {this.showMessageNotice('Ошибка 09', "Укажите ссылку на товар",'error'); return}
                    if (this.product.link.length<3) {this.showMessageNotice('Ошибка 09', "Ссылка слишком короткая",'error'); return}
                   
                }
            
            if ([1,4].includes(this.product.type_id))
                {
                    if (!this.product.price) {this.showMessageNotice('Ошибка 09', "Укажите цену",'error'); return}
                    if (this.product.price !== ""+parseInt(this.product.price)) {this.showMessageNotice('Ошибка 09', "Для цены используйте только цифры",'error'); return}
                   // if (!this.product.quantity) {this.showMessageNotice('Ошибка 09', "Укажите количество",'error'); return}

                }
          
            if (!this.product.media_ids) {
                this.product.media_ids = []
                for (let index = 0; index < this.product.images.length; index++) {
                    this.product.media_ids.push(this.product.images[index].id)
                }
            }
            this.product.description = (this.product.editDescription)?this.product.editDescription:this.product.description;

            this.product.category_ids = []
            if(this.product.category_id!=null) 
                this.product.category_ids.push(this.product.category_id)

            this.product.stock_ids = []
            if(this.product.stock_id!=null) 
                this.product.stock_ids.push(this.product.stock_id)            
            
            this.blockButton = true
            if (this.product.id) {
                api.updateProduct( this.product.id,  this.product).then((response) => {
                if (response.data.message){
                    this.showMessageNotice('', response.data.message,'success')
                    this.blockButton = false
                }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.errors,'error')
                })
            }
            else  
                api.createProduct(this.product).then((response) => {
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                        this.product_id  = response.data.data
                        this.getProduct( this.product_id );
                        this.blockButton = false
                    }
                    }).catch((error) => {
                        if (error.response.data)
                            this.showMessageNotice('Ошибка 09', error.response.data,'error')
                        if (error.response.data.message)
                            this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                    })
        
        }, 
    },
    /*
    watch: {
        id(){
            if (this.id!='create'){
               
                this.product = Object.assign({},this.editpod)
                return 
            }
            else {
                this.product_shop_id = this.shop_id
                this.product.images = []

            }
        },
    },
    */
    inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .product-edit__content {
        border: 1px solid rgba(142, 162, 176, 0.5);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 12px 16px 13px;
        & > * {
            border-top: 1px solid var(--global_block_line);
            padding-top: 20px;
            &:first-child {
                border-top: 0;
                padding-top: 0;
            }
        }
    }
    .product-edit__status {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
    }
    .product-edit__status_ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 13px;
    }
    .product-edit__info {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .product-edit__inputs {
        display: flex;
        column-gap: 15px;
    }
    .product-edit__add-form {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
    .product-edit__add-form_show {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px;
    }

    .flex-container svg {
      cursor: pointer;
    }

    .product-edit__show {
        row-gap: 10px;
    }
    .product-edit__images {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 10px;
    }
    .product-edit__category {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .promo {
      display: flex;
      justify-content: space-between;
      align-items: end;
    }
    .product-edit__bottom {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
    }
    .product-edit__digital__inner {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }
    .product-edit__interaction {
        margin: 16px auto 0;
        width: 95%;
        height: 40px;
    }
</style>