<template>
    <div class="counter">
        <div class="counter__decrement counter__elem"
        @click="decrement">
            <svg class="counter__svg" width="12" height="3" viewBox="0 0 12 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.2981 0.811279V2.78109H0V0.811279H11.2981Z" fill="#8EA2B0"/>
            </svg>
        </div>
        <div class="count__number">
            <input class="count__input" type="number"
            :value="counter">
        </div>
        <div class="counter__increment counter__elem"
        @click="increment">
            <svg class="counter__svg" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.2981 4.81132V6.78113H0V4.81132H11.2981ZM6.70189 0V12H4.60755V0H6.70189Z" fill="#8EA2B0"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppInputCounter',
    data() {
        return {
            counter: 0
        }
    },
    methods: {
        decrement() {
            if (this.counter > 0) return this.counter--;
        },
        increment() {
            return this.counter++
        }
    }
}
</script>

<style lang="scss" scoped>
    .counter {
        border: 1px solid #8EA2B0;
        border-radius: 4px;
        display: flex;
        align-items: center;
        min-width: 76px;
        max-width: 76px;
    }
    .counter__decrement {
        display: flex;
        align-items: center;
        
        padding: 10px 9px;
    }
    .counter__svg {
        * {
            transition: 200ms;
        }
    }
    .counter__elem {
        cursor: pointer;
        &:hover {
            .counter__svg > * {
                fill: var(--default_background_hover);
            }
        }
        &:active {
            .counter__svg > * {
                fill: var(--default_background_active);
            }
        }
    }
    .counter__increment {
        @extend .counter__decrement;
    }
    .count__input {
        background: none;
        color: var(--global_second_color);
        display: flex;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        width: 100%;
    }
</style>