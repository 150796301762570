<template>
<div class="button-container _blue">
  <div>
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  name: "AppButtonInsertText"
}
</script>

<style scoped>

.button-container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 13px;
  background: var(--default_background);
  color: var(--default_color);
  margin: 5px 5px 5px 0;
  font-size: 10px;
  cursor: pointer;
  min-width: 50px;
  min-height: 30px;
  border-radius: 6px;
  border: 1px solid #8EA2B0;
}

.button-container:hover {
  background: var(--default_background_hover);
}



</style>