import axios from 'axios'

export const URL = `${process.env.VUE_APP_LINK}/api/v1/settings/deferred_messages`;

export default {

    getDeferredMessages(data) {
        return axios.get(`${URL}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },
    createDeferredMessage(data) {
        return axios.post(`${URL}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }) 
    },
    testDeferredMessage(id, data={}) {
        return axios.post(`${URL}/send_test/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }) 
    },
    
    updateDeferredMessage(id, data) {
        return axios.put(`${URL}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
  
    deleteDeferredMessage(id) {
        return axios.delete(`${URL}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    
    
}