 <template>
        <AppSelectV2
            style="margin-bottom: 5px;"
            v-model="type"
            :items="types">
            <template v-slot:title>
                <AppTitleSmall class="product__additional-info_title-small">
                    Тип кнопки: 
                </AppTitleSmall>
            </template>
        </AppSelectV2>
        <ElementWebApp v-if="type=='webapp'" :model="editElement" @save="save" @del="del"/>
        <ElementLink v-if="type=='link'" :model="editElement" @save="save" @del="del"/>
        <ElementReklambot v-if="type=='reklambot'" :model="editElement" @save="save" @del="del"/>
        <ElementLeadgenerator v-if="type=='leadgenerator'" :model="editElement" @save="save" @del="del"/>
        <ElementMessage v-if="type=='message'" :model="editElement" @save="save" @del="del"/>
        <ElementPhone v-if="type=='phone'" :model="editElement" @save="save" @del="del"/>
        <ElementTask v-if="type=='task'" :model="editElement" @save="save" @del="del"/>    
 </template>
 <script>
 import ElementWebApp from "@/components/ChatButtonElements/ElementWebApp"
 import ElementLink from "@/components/ChatButtonElements/ElementLink"
import ElementReklambot from "@/components/ChatButtonElements/ElementReklambot"
import ElementLeadgenerator from "@/components/ChatButtonElements/ElementLeadgenerator"
import ElementMessage from "@/components/ChatButtonElements/ElementMessage"
import ElementPhone from "@/components/ChatButtonElements/ElementPhone"
import ElementTask from "@/components/ChatButtonElements/ElementTask"

 export default {
   name: "AppChatButtonCreate",
   components: {
    ElementLink, ElementReklambot, ElementLeadgenerator, ElementMessage, ElementPhone, ElementTask,
    ElementWebApp
  },
  props: {
    shopInfo:{
      type: Object,
    },
    types: {
        type: Array
    }
  },
   data() {
     return {
        shop_bot: JSON.parse(localStorage.getItem('shop_bot')),
        editElement: {message:{text:''}},
        type: null,

     }
   },
   mounted(){
        this.$emit('prefSave');
   },
   methods: { 
        save(value){
            this.$emit('addButton',value);
            this.editElement =  {message:{text:''}}
            this.type = null
        },
        del(){
            this.editElement =  {}
            this.type = null
        },
   },
   watch: {
        type(type){
            switch (type) {
                case "reklambot":
                    this.editElement =  {
                        id: null,
                        name: "",
                        link: (this.shopInfo.telegram)? this.shopInfo.telegram.link : "Подключите бота!",
                        message:{
                            text:'', 
                            media_type: "none",
                            media: "", 
                        },   
                        type: this.types.find(e=>e.name==type),
                        status:false,
                    }
                    break;
                case "link":
                    this.editElement =   {
                            id: null,
                            name: "",
                            link: "",
                            type: this.types.find(e=>e.name==type),
                            status:false,

                        }
                    break;
                case "webapp":
                    this.editElement =   {
                            id: null,
                            name: "",
                            link: this.shop_bot.web_app,
                            type: this.types.find(e=>e.name==type),
                            status:false,

                        }
                    break;
                case "leadgenerator":
                    this.editElement =  {
                        id: null,
                        name: "",
                        type: this.types.find(e=>e.name==type),
                        message:{
                            text:'', 
                            media_type: "none",
                            media: "", 
                        },        
                        status:false,
                    }
                    break;
                case "message":
                    this.editElement =  {
                        id: null,
                        name: "",
                        type: this.types.find(e=>e.name==type),
                        message:{
                            text:'', 
                            media_type: "none",
                            media: "", 
                        },   
  
                        status:false,
                    }
                    break;
                case "phone":
                    this.editElement =  {
                        id: null,
                        name: "",
                        type: this.types.find(e=>e.name==type),
                        message:{
                            text:'', 
                            media_type: "none",
                            media: "", 
                        },   
  
                        status:false,
                    }
                    break;
                case "task":
                    this.editElement =  {
                        id: null,
                        name: "task",
                        type: this.types.find(e=>e.name==type),
                        task_id: null,
                        status:false,
                    }
                    break;
                default:
                    break;
            }
        },
   }
 }
 </script>