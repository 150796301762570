<template>
    <div class="container" v-if="questions">
            <draggable class="button-system__list" :list="questions" @end="onDragEnd">
                <template #item="{ element, index }">
                    <AppButtonsBottomSheet
                        :button="element"
                        :prompt="testPrompts[index]"
                        @selectCurrent="function(state){openCurrent(index, state)}"
                        :open="setOpen[index]"
                    > 
                    <ElementQuestion v-if="element.name" :model="element" @save="save" @del="del"/>
                  
                </AppButtonsBottomSheet>
                </template>
            </draggable> 
    </div>
</template>
<script>

import ElementQuestion from "@/components/QuestionElements/ElementQuestion"


import draggable from 'vuedraggable';

export default {
  name: "AppQuestionList",
  components: {
    draggable,
    ElementQuestion
  },
  props: {
    questions:{
      type: Array,
    },
  },
  data() {
    return {
        sort: [],
        testPrompts: [],
        setOpen: [],
        editElement: {}
    }
  },
  mounted(){

  },
  methods: {
    onDragEnd() {
      for (let index = 0; index < this.questions.length; index++) {
            this.sort[index] =  this.questions[index].id
        
      }
      this.$emit('resort', this.sort);
    },
    openCurrent(i, state) {
          this.setOpen.forEach((im, i, arr)=>arr[i]=false);
          this.setOpen[i]=!state
        },
    editQuestion(button){
        if (this.editElement.id==button.id) 
            this.editElement={}
        else
            this.editElement = button
    },
    save(button){
        this.$emit('updateQuestion',button);
        this.setOpen =[]
    },
    del(button){
        this.setOpen =[]
        this.$emit('del',button);
    }
  },
  watch: {
  
  }
}
</script>
<style lang="scss" scoped>
    .catalog-setting {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .catalog-setting__add-form {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .catalog-setting__title {
        margin-bottom: 6px;
    }

    .catalog-setting__text {
        color: #000000;

        font-size: 14px;
    }

    .catalog-setting__categories {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .catalog-setting__category {
        display: flex;
        column-gap: 3px;

        height: 40px;
    }
    .edit__name {
        color: var(--default_background);
    }
    .catalog-setting__category_button {
        display: flex;
        justify-content: space-between;

        padding: 10px 15px;

        span {
            font-size: 16px;
        }
    }
</style>