<template>
    
    <ckeditor
        class="textarea"
        :editor="editor"
        :model-value="modelValue"
        :style="styleTextarea"
        @input="updateInput"
        :config="editorConfig"
        @ready="onReady">
    </ckeditor>

</template>

<script>
//import ClassicEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: 'AppTextarea',
    props: {
        modelValue: {
            type: [String, Number]
        },
        placeholder: {
            type: String
        },
        styleTextarea: {
            type: String
        }
    },
    data() {
        return {
            loader:false,
            text: "",
            editor: ClassicEditor,
            editorData: "",
            editorConfig: { 
                toolbar: {
                    items: [
                        'alignment', '|',
                        'bold', 'italic',  '|', //'strikethrough',  'bulletedList', 'numberedList'
                       
                    ],
                    shouldNotGroupWhenFull: true
                }
            }
        }
    },
    mounted() {
        
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event);
        },
        onReady(editor)  {
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        }
    }, 
    watch:{
        /*
        modelValue(){
    
            console.log(this.text,"text")
            console.log(this.modelValue,"modelValue")
            //this.editor.setData( this.modelValue )
               
        },
        */
    }
}
</script>

<style lang="scss">
    .textarea {
        background: #EEF4F8;
        border: 1px solid #E4E7ED;
        border-radius: 6px;
        color: #000;
        resize: none;
        padding: 11px;
        padding-right: 28px;
        width: 100%;
        & > * {
            background: #EEF4F8;
        }
    }
</style>