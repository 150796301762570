import { createStore } from 'vuex';
import login from '@/store/modules/login';
import categories from '@/store/modules/categories';
import deliveries from '@/store/modules/deliveries';
import company from '@/store/modules/company';
import shop from '@/store/modules/shop';
import stocks from '@/store/modules/stocks';
import setting from '@/store/modules/setting';
import settingNotificationClient from '@/store/modules/settingNotificationClient';
import products from '@/store/modules/products';
import orders from '@/store/modules/orders';
import peoples from '@/store/modules/peoples';
import chats from '@/store/modules/chats';

 
import settingNotification from  '@/store/modules/settingNotification';
//import showcaseSetting from  '@/store/modules/showcaseSetting';

export const BACKEND_URL_LOGIN = `${process.env.VUE_APP_LINK}/api/v1/auth/login`;
export const BACKEND_URL_REGISTRATION = `${process.env.VUE_APP_LINK}/api/v1/auth/register`;
export const BACKEND_URL_CATEGORIES = `${process.env.VUE_APP_LINK}/api/v1/categories`;
export const BACKEND_URL_SHOP = `${process.env.VUE_APP_LINK}/api/v1/shops`;
export const BACKEND_URL_DELIVERIES = `${process.env.VUE_APP_LINK}/api/v1/deliveries`;
export const  BACKEND_URL_PICKUPS = `${process.env.VUE_APP_LINK}/api/v1/deliveries/pickups`;
export const BACKEND_URL_CITIES = `${process.env.VUE_APP_LINK}/api/v1/cities`;
export const BACKEND_URL_COMNAPY = `${process.env.VUE_APP_LINK}/api/v1/companies`;
export const BACKEND_URL_STOCKS = `${process.env.VUE_APP_LINK}/api/v1/stocks`;
export const BACKEND_URL_SETTING= `${process.env.VUE_APP_LINK}/api/v1/settings`;
export const BACKEND_URL_NOTIFICATION= `${process.env.VUE_APP_LINK}/api/v1/settings/notifications`;
export const BACKEND_URL_PRODUCTS= `${process.env.VUE_APP_LINK}/api/v1/products`;
export const BACKEND_URL_ORDERS= `${process.env.VUE_APP_LINK}/api/v1/orders`;
export const BACKEND_URL_PEOPLES= `${process.env.VUE_APP_LINK}/api/v1/peoples`;
export const BACKEND_URL_CLIENT_NOTIFICATION= `${process.env.VUE_APP_LINK}/api/v1/settings/client_notifications`;
export const BACKEND_URL_SHOP_THEMES = `${process.env.VUE_APP_LINK}/api/v1/settings/themes`;
export const BACKEND_URL_CHATS = `${process.env.VUE_APP_LINK}/api/v1/chats`;



export const BACKEND_URL_MEDIA = `${process.env.VUE_APP_LINK}/api/v1/media`;



export default createStore({
    modules: {
        login,
        categories,
        shop,
        deliveries,
        company,
        stocks,
        setting,
        settingNotification,
        products,
        orders,
        peoples,
        settingNotificationClient,
        chats
      //  showcaseSetting
    }
})