<template>
    <svg class="arrow" width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.37426 8.35806L10 1.73232L8.26779 0.000114441L0.472844 7.79506L0.563002 7.88522L1.90735e-06 8.44822L7.79495 16.2432L9.52716 14.511L3.37426 8.35806Z" fill="#8EA2B0"/>
    </svg>
</template>

<script>
export default {
    name: 'AppArrow'
}
</script>

<style lang="scss" scoped>
    .arrow {
        cursor: pointer;
        * {
            fill: var(--default_background);
        }
        &:hover > * {
            fill: var(--default_background_hover);
        }
        &:active > * {
            fill: var(--default_background_active);
        }
        &._no-active {
            cursor: not-allowed;
            & > * {
                fill: #8EA2B0;
            }
        }
    }
</style>