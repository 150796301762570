<template>
  <div class="page"
     v-if="!load && $router.currentRoute.value.path.substring(0, 6) !== '/login' && $router.currentRoute.value.path.substring(0, 13) !== '/registration' && $router.currentRoute.value.name !== 'preview'" >
   <AppMessageNotice
       :message="currentMessage"
       :header="currentHeader"
       :showNow="showNow"
       :status="currentStatus"
   />
   <AppHeader v-if="!load"></AppHeader> 
   <main class="main">
     <AppNavigationTop v-if="!load"></AppNavigationTop>
     <router-view v-if="!load"></router-view>
   </main>
 </div>
 <div  
     class="page"
     v-if="$router.currentRoute.value.path.substring(0, 6) == '/login' || $router.currentRoute.value.path.substring(0, 13) == '/registration' || $router.currentRoute.value.name == 'preview'" >
   <router-view></router-view>
 </div>
</template>

<script>
///import PageStartPreview from '@/pages/PageStartPreview';
import AppHeader from '@/components/AppHeader';
import api from "@/api"
export default {
   name: 'AppPage',
 data() {
     return {
        shop_id: null,
       currentHeader: 'Ошибка 123',
       currentMessage: 'Отправьте отчёт об ошибке и мы исправим её в ближайшее время.',
       currentStatus: 'error',
       showNow: false,
       load:false,
     }
 },
   components: {
       AppHeader
   },
   mounted(){
    localStorage.setItem('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone)
    this.shop_id = localStorage.getItem('shop_id')
   },
   created() {
    this.load = true;
    
    const url = new URL(
      window.location
      ) 
       document.title = "iVitrina лучший магазин"
       if (!localStorage.getItem('token')) {
          if (url.searchParams.get('token')) {
            localStorage.setItem('token', url.searchParams.get('token'))
          }
          else {       
            this.$router.replace("/preview");
            setTimeout(function () {
                if (this.$router.currentRoute.value.path === '/preview') this.$router.replace("/login");
            }.bind(this), 1); //Время заставки 10000
            return
          }
       }
       if (url.searchParams.get('recipient')) 
          localStorage.setItem('recipient', url.searchParams.get('recipient'))
        
        if (localStorage.getItem('recipient')){
          this.getData("social")
        }
        else {
          this.getData("login")
        }
       
       
   },
 
 methods: {
    getData(value){ 
          //console.log(localStorage.getItem('shop_id'),"getData")
          if (value=="social" && !localStorage.getItem('shop_id')) this.getSocialData()
          else this.getDefaultData()
           
       },
    async getDefaultData(){
      try{
               const response = await api.getData(localStorage.getItem('shop_id'))
               localStorage.setItem('shop_bot', JSON.stringify(response.data.shop_bot) || null)
               localStorage.setItem('product_statues', JSON.stringify(response.data.product_statues))
               localStorage.setItem('product_types', JSON.stringify(response.data.product_types))
               localStorage.setItem('product_sort', JSON.stringify(response.data.product_sort))
               localStorage.setItem('categories', JSON.stringify(response.data.categories))
               localStorage.setItem('stocks', JSON.stringify(response.data.stocks))
               localStorage.setItem('shop_id', response.data.shop_id)
               localStorage.setItem('shop_type', JSON.stringify(response.data.shop_type)) 
               localStorage.setItem('shop_elements', JSON.stringify(response.data.shop_elements)) 
               localStorage.setItem('theme', response.data.theme || "_blue")
               //////////////
               localStorage.setItem('order_sort', JSON.stringify(response.data.orders.sort))
               localStorage.setItem('order_statuses', JSON.stringify(response.data.orders.statuses))
               localStorage.setItem('order_paystatuses', JSON.stringify(response.data.orders.paystatuses))
               //////////////
               localStorage.setItem('people_sort', JSON.stringify(response.data.peoples.sort))
               localStorage.setItem('people_types', JSON.stringify(response.data.peoples.types))
               document.body.classList.add(response.data.theme);
               //
               this.load = false
           }
           catch (err){
              localStorage.clear();
              localStorage.removeItem('token')
              this.$router.replace("/login")
               console.log(err,"err")
           }
          
    },
    async getSocialData(){
      this.$router.replace("/select_shop");
      this.load =false
    
    },
   showMessageNotice(header, message, status) {
       this.currentMessage = message;
       this.currentHeader = header;
       this.currentStatus = status;
       this.showNow = true;
       setTimeout(()=>this.showNow = false, 3000)
   }
 },
 provide() {
   return {showMessageNotice: this.showMessageNotice}
 }
}
</script>

<style lang="scss">
   .page {
       height: 100%;
   }

   .main {
       position: relative;

       padding-bottom: 62px;
   }
</style>