<template>
    <div class="clients-table__content block-style">
      <div class="order-block"
           v-for="(order, i) in orders"
           :key="i">
          <div class="client-cell_row">
            <div class="flex-start-center">
              <div class="cell-header color-blue">{{ '№ ' + order.id }}</div>
              <!--
              <AppIconAttention
                  :message="order.notes"
              />
              -->
            </div>
            <div class="cell-value">{{ order.pay_status.title }}</div>
            <div class="cell-value">{{  (order.type.id==2)?order.status.title_s:order.status.title }}</div>
          </div>
          <div class="client-row flex-justify-center">
            <div class="client-cell">
              <div class="cell-header">Сумма заказа: {{ order.sum }}  руб</div>
              <!--<div class="cell-value">{{  order.orderSum ? order.orderSum  : 'Название товара по заявке'}}</div>-->
            </div>
            <div class="client-cell">
              <router-link
                :to="{name: 'PageOrderEdit', params: {id: order.id, shop_id:order.shop_id, editpod:JSON.stringify(order), backUrl:backUrl}}">
                <AppButton
                    style="width: 88px; height: 30px"
                    :styleButton="'_blue-external'"
                >
                  Подробнее
                </AppButton>
                </router-link>
            </div>
          </div>
        <div class="line"></div>
      </div>
    </div>
  </template>
  
  <script>
  //import AppIconAttention from "@/components/UI/AppIconAttention";
  export default {
    name: "CardOrderList",
   // components: {AppIconAttention},
    props: ['orders']
  }
  </script>
  
  <style lang="scss" scoped>
  .clients-table__content {
    padding: 11px 13px 0;
    margin: 14px 0;
  }
  .clients {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  .client {
    position: relative;
    padding-top: 8px;
    &::before {
      background: var(--global_block_line);
      content: "";
      position: absolute;
      top: 0;
      height: 2px;
      width: 100%;
    }
    &:first-child {
      padding-top: 0;
      &::before {
        background: none;
        width: 0;
        height: 0;
      }
    }
  }
  .client__short-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .client__short-info_item {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .client__short-info_user {
    grid-column: 2/4;
  }
  .client__statistics {
    border-top: 1px solid var(--global_block_line);
    border-bottom: 1px solid var(--global_block_line);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 9px 0;
    margin: 9px 0;
  }
  .client__statistics_item {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .client__user {
    color: var(--global_second_color);
    font-size: 14px;
  }
  .client__statistics_item-text {
    color: rgba(0, 0, 0, 0.75);
    font-size: 12px;
    font-weight: 400;
  }
  .client__interaction_button {
    width: 94px;
    height: 30px;
  }
  .client-row {
    display: flex;
    border-bottom: 1px solid #C3CDD5;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .client-row:last-child {
    border-bottom: none;
  }
  .client-cell:first-child {
    width: 90px;
  }
  .cell-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    margin-right: 10px;
  }
  .client-cell_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #C3CDD5;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .cell-value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.75);
  }
  .client-value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.75);
  }
  .page-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.75);
    margin: 20px 0;
  }
  .color-blue {
    color: #20A0FF;
    margin-bottom: 4px;
  }
  .order-block {
    position: relative;
  }
  .line {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: calc(100% + 26px);
    left: -13px;
    background: #B9C5CF;
  }
  .order-block:last-child .line{
    display: none;
  }
  .order-block:last-child .client-row{
    border: none;
  }
  </style>