<template>
    <svg class="cross" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
    :class="styleX">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 7L0 12.25L1.75 14L7 8.75L12.25 14L14 12.25L8.75 7L14 1.75L12.25 0L7 5.25L1.75 0L0 1.75L5.25 7Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'AppX',
    props: {
        styleX: {
            type: String,
            required: false
        }
    }
    
}
</script>

<style lang="scss" scoped>
    .cross {
        * {
            transition: 10ms;
        }
        &:hover > * {
            fill: #FF0000;
        }
        &:active > * {
            fill: #B90000;
        }
        &._white {
            * {
                fill: var(--default_color);
            }
            &:hover > * {
                fill: #FF0000;
            }
            &:active > * {
                fill: #B90000;
            }
        }
    }
</style>