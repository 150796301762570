<template>
  <div class="about-setting block-style">
    <div style="display: flex;">
      
          <AppButton
            style="width: 49%; height: 30px; margin-right: 20px;  margin-bottom: 10px; cursor: pointer;"
            @click="goStep(1)"
            styleButton="_blue-external"
            > 
            Основное >>
          </AppButton>
          <AppButton
           
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            > 
            Фильтры >>
          </AppButton>
          <AppButton
          @click="goStep(3)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"> 
            Боты >>
          </AppButton>
          <AppButton
            @click="goStep(4)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"> 
            Настройки >>
          </AppButton>
          <AppButton
            @click="goStep(5)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer;"
            styleButton="_blue-external"> 
            Дата/Время  
          </AppButton>
    </div>
    <div class="about-setting block-style">
      <div class="header-mailing">
        Получатели рассылки
      </div>
      <ul class="order-filter__type_ul">
        <li class="product-edit__status_li"
            v-for="(filter, i) in filters"
            :key="i">
          <AppRadio
              @selectRadio="function(){setFilter(filter.name)}"
              :forAndId="filter.name"
              :name="'type'"
              v-model="selectFilter"
              >
            <AppTitleSettingParam>
              {{ filter.title }} 
            </AppTitleSettingParam>
          </AppRadio>
        </li>
      </ul>
    </div>
    <div class="about-setting block-style" v-if="filter && filter.db_params && filter.db_params.recipients">
     
      <div class="header-mailing"> 
       <span style="font-size: 14px; display: flex;  flex-direction: row;  align-items: center;"> 
        <AppCheckbox style="margin-left: 10px;" @update="setAll" > 
          Выбрать всех 
        </AppCheckbox> 
        <span style="margin-left: 10px;">  <strong>Фильтр: </strong> {{ filter.title }}   </span>
        <span style="margin-left: 10px;"> <strong>Количество: </strong>{{ (peoples.length==0)?"": peoples.length}} </span>
        <span style="margin-left: 10px;">  <strong> Выбрано: </strong>{{ selectedRecipients.length}} </span>
      </span> <br>  {{ (loadPeople)?"Загрузка...":"" }}
      </div>
      <div class="container" style="margin-left: -5px; width: 93%;">
          <AppSearchAndFilter 
              class="client__search"
              @goSearch="goSearch"
              @filtered="filtered"
              :hiddenFilter="true"
              :filterContent="{}"
              />
      </div>
      <!--
      <div style="display: flex; flex-wrap: wrap;" v-if="peoples.length>0 && !loadPeople">
        <MaillingElementPeople style="margin-top: 12px" 
              class="button-container"
                v-for="(people, i) in paginatedPeoples"
                :key="i" 
                :element="people"
                :selectedRecipients="selectedRecipients"
                @addToSelected="addToSelected"
                @removeFromSelected="removeFromSelected"
                >
        </MaillingElementPeople>     
      </div>
     -->
     <div  v-if="peoples.length>0 && !loadPeople">
        <MaillingElementTablePeople class="button-container"   style=" margin-bottom: 10px;"
          v-for="(people, i) in paginatedPeoples"
          :key="i" 
          :element="people"
          :selectedRecipients="selectedRecipients"
          @addToSelected="addToSelected"
          @removeFromSelected="removeFromSelected">

        </MaillingElementTablePeople>
      </div>
      <div style="display: flex; justify-content: center">
        <AppButton 
            @click="prevPage" :disabled="currentPage === 1"
            style="width: 100px; height: 30px; margin-top: 16px; margin-right: 10px; cursor: pointer;"
            
            :styleButton="'_blue-external'"
        >
          Предыдущая
        </AppButton>
        <AppButton 
            @click="nextPage" :disabled="currentPage * itemsPerPage >= peoples.length"
            style="width: 100px; height: 30px; margin-top: 16px; margin-right: 10px; cursor: pointer;"
            :styleButton="'_blue-external'"
        >
          Следующая
        </AppButton>
      </div>
    </div>
      <div style="display: flex; justify-content: right">
        <AppButton 
            @click="goStep(1)"
            style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
            :styleButton="'_blue-external'"
        >
          Назад
        </AppButton>
        <AppButton
        @click="goStep(3)"
            style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
            :styleButton="'_blue-external'"
        >
          Далее
        </AppButton>
        <AppButton
            @click="prefSave"
            style="width: 83px; height: 30px; margin-top: 16px"
            :styleButton="'_blue-external'"
        >
          Сохранить
        </AppButton>
      </div>
  </div>
</template>

<script>
import apiPeople from "../../people/api"
//import MaillingElementPeople from "../modules/MaillingElementPeople"
import MaillingElementTablePeople from "../modules/MaillingElementTablePeople"

export default {
  name: "MaillingElementFilter",
 components: { MaillingElementTablePeople},

  data() {
    return {
      loadPeople:true,
      selectedRecipients: [],
      peoples: [],
      peopleSearch: [],
      filters:[],
      selectFilter:'',
      shop_type: JSON.parse(localStorage.getItem("shop_type")),
      itemsPerPage: 30,
      currentPage:1,
      allSelect:false,
    }
  },
  props: {
    element: [Object],
  },
  mounted(){
      this.selectFilter = this.element.filter
      this.selectedRecipients = this.element.recipients ?? []
      this.getFilters()
  },
  computed:{
    paginatedPeoples() {
      
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.peopleSearch.slice(startIndex, endIndex);
    },
    filter(){
        
        let filter = this.filters.find(filter => filter.name === this.selectFilter)
        if (filter) this.getPeoples(filter) 
        return  filter
    }
  },
  methods: {
    filtered(input){
        console.log(input)
    },
    goSearch(input){
      if (!input) this.peopleSearch = this.peoples
      this.peopleSearch = this.peoples.filter(
          item => 
          (item.name && item.name.includes(input)) 
          || (item.username && item.username.includes(input)) 
          || (item.last_name && item.last_name.includes(input)) 
          || (item.first_name && item.first_name.includes(input)) 
          || (item.phone && item.phone.includes(input)) 
      );
      //console.log(this.peopleSearch)
    },
    setAll() {
      this.allSelect=!this.allSelect
      if (! this.allSelect)  {this.selectedRecipients = []; 
        this.$emit('setRecipients', this.selectedRecipients)
        return
      }
      this.peoples.forEach(item => {
        this.selectedRecipients.push(item.recipient);
      })
      this.$emit('setRecipients', this.selectedRecipients)
    },
    addToSelected(recipient) {
      this.selectedRecipients.push(recipient);
      this.$emit('setRecipients', this.selectedRecipients)
    },
    removeFromSelected(recipient) {
      this.allSelect = false
     
      const index = this.selectedRecipients.indexOf(recipient);
      if (index > -1) {
        this.selectedRecipients.splice(index, 1);
      }
      this.$emit('setRecipients', this.selectedRecipients)
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    nextPage() {
      
      if (this.currentPage * this.itemsPerPage < this.peoples.length) {
        this.currentPage += 1;
      }
      console.log(this.currentPage)
    },
    async getPeoples(filter){
      this.loadPeople = true
      const response = await apiPeople.getPeopleFromFilter({
          shopId:this.element.availableShop_id,
          'filterId':filter.id, 
          page:this.page, 
          pageSize:this.pageSize
        })
        this.peoples = this.peopleSearch= response.data.data
        this.loadPeople = false
     
    },
    async getFilters(){
      
      const response = await apiPeople.getFilters({'shop_type':this.shop_type.id})
      this.filters = response.data.data
    },
    prefSave(){
      this.$emit('prefSave')
    },
    setFilter(value) {
      this.selectFilter = value
      this.$emit('filter', value)
    },
    goStep(id){
      this.$emit("goStep", id);
    },
    
    close() {
      this.$emit('data', {action: 'nextPage', value: {name:'ChatsList'}})
    },
  
  },
  watch: {

  },
  inject: ['showMessageNotice'],
}
</script>

<style scoped>
.table {
      display: table;
      width: 100%;
      border-collapse: collapse;
    }

    .row {
      display: table-row;
    }

    .cell {
      display: table-cell;
      border: 1px solid #ccc;
      padding: 8px;
    }
.date-reg_content {
  border-top: 1px solid #C7D1D8;
}

.date-reg_container {
  display: flex;
  justify-content: start;
  align-items: center;
}
.paginate-button {
  position: relative;
  font-size: 10px;
  width: calc(30% - 10px); /* 20% ширины контейнера с небольшим отступом */
  margin: 5px; /* Отступ между кнопками */
  height: 50px;
  box-sizing: border-box; /* Учесть отступ в расчетах ширины */
  padding: 10px; /* Убираем внутренние отступы */
  text-align: left; /* Выравнивание текста по центру */
}
.date-reg_header {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  margin-top: 20px;
}

.order-filter__type_ul {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  margin-bottom: 10px;
}

.header-mailing {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.about-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 16px 12px;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

</style>