import axios from 'axios'

export const BOT = `${process.env.VUE_APP_LINK}/api/v1/shops/telegram`;


export default {
    updateShopTelegram(id, data) {
        return axios.put(`${BOT}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
} 