<template>
    <section class="client-info">
        <div class="container" v-if="People">
            <div class="flex-container">
                <AppSectionTitle class="client-info__title">
                {{People.fio}}
              
                </AppSectionTitle>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    @click="$router.push('/client')"
                >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z" fill="#8EA2B0"/>
                </svg>
            </div>

            <div class="client-info__table">
                <div class="order-edit__client" >
                    <AppInputLock>
                        <template v-slot:title>
                            Телефон
                        </template>
                        <template v-slot:description >
                            <span class="client__statistics_item-text_color">  {{People.phone}}</span>
                        </template>
                    </AppInputLock>
                    <ul   class="client__statistics">
                        <li class="client__statistics_item">
                            <AppTitleSmall>
                                Телеграм ID
                            </AppTitleSmall>
                            <div class="client__statistics_item-text_color">
                                {{ People.recipient }}
                            </div> 
                        </li>
                        <li class="client__statistics_item">
                            <AppTitleSmall>
                                Дата
                            </AppTitleSmall>
                            <AppProductPrice class="client__statistics_item-text">
                                {{  People.created_at }}
                            </AppProductPrice>
                        </li>
                        <li class="client__statistics_item">
                            <AppTitleSmall>
                                Заказы/Заявки
                            </AppTitleSmall>
                            <AppProductPrice class="client__statistics_item-text">
                                {{ People.orders.length }} 
                            </AppProductPrice>
                        </li>
                    </ul>
                    <AppInputLock v-if="People.orders[People.orders.length-1].data">
                        <template v-slot:title>
                            Адрес последней доставки
                        </template>
                        <template v-slot:description >
                              {{People.orders[People.orders.length-1].data.address}}
                        </template>
                    </AppInputLock>
                    <div class="order-edit__delivery" >
                        <AppTitleSmall class="order-edit__title-small">
                            Сумма выкупа
                        </AppTitleSmall>
                        <AppProductPrice>
                            {{ People.amount }} руб.
                        </AppProductPrice>
                    </div>
                    <div class="order-edit__delivery" >
                        <AppTitleSmall class="order-edit__title-small">
                            Средний чек
                        </AppTitleSmall>
                        <AppProductPrice>
                            {{ People.amount_avg }} руб.
                        </AppProductPrice>
                    </div>
                </div>
            </div>
        <AppSectionTitle class="client-info__title">
            Заказы и заявки
        </AppSectionTitle>
            <!--
            <AppSearchAndFilter
            class="client-info__search"
            :filterContent="filterContent"></AppSearchAndFilter>
             
            --> 
        <AppOrders 
        :orders="People.orders" :backUrl="$route.fullPath"></AppOrders>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "PageClientInfo",
    //props: ['id'],
    data() {
        return {
            id: null,
            filterContent: {
                title: 'Фильтр по товарам',
                filters: [
                    { 
                        title: 'Сортировать:',
                        type: 'radio',
                        name: 'type-sort', // для правильной работы радио
                        items: [
                            'По сумме заказа (сначала больше)',
                            'По сумме заказа (сначала меньше)',
                            'Сначала старые',
                            'Сначала новые'
                        ]
                    },
                    {
                        title: 'Выбрать статус',
                        type: 'checkbox',
                        name: 'type-sort', // для правильной работы радио
                        items: [
                            'Оформлен',
                            'Доставляется',
                            'Доставлен',
                            'Возврат',
                            'Архив'
                        ]
                    },
                    {
                        title: 'Выбрать статус платежа',
                        type: 'checkbox',
                        items: [
                            'Ожидает оплаты',
                            'Оплачен'
                        ]
                    }
                ] // Я изменил каталог, добавил страницу редактирования и
            },
            orders: []
        };
    },
    
    computed: mapGetters(['People']),
    mounted() {
    console.log(this.$route.fullPath);
       this.id = this.$route.params.id
       this.getPeople(this.id)
    },
    methods: {
        ...mapActions(['getPeople']),
        
    }
}
</script>

<style lang="scss" scoped>
    .order-edit__delivery {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .client__statistics {
        //border-top: 1px solid var(--global_block_line);
        border-bottom: 1px solid var(--global_block_line);

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        padding: 9px 0;
        margin: 9px 0;
    }
    .client__statistics_item-text {
        color: rgba(0, 0, 0, 0.75);
        margin-top:5px;
        font-size: 12px;
        font-weight: 400;
    }
    .client__statistics_item-text_color {
        color: #20A0FF;
        margin-top:5px;
        font-size: 16px;
        font-weight: 400;
    }
   .order-edit__client {
        & > * {
           border-bottom: 1px solid #E4E7ED;
            padding-bottom: 9px;
            margin-bottom: 9px;
            &:last-child {
                border-bottom: none;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    .client-info__table {
        background: #FFFFFF;
        border: 1px solid #E4E7ED;
        border-radius: 6px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        padding: 10px 13px 16px;
        margin-bottom: 16px;
        & > * {
            border-top: 1px solid var(--global_block_line);
            padding-top: 10px;
            margin-top: 10px;
            &:first-child {
                border-top: none;
                padding-top: 0;
                margin-top: 0;
            }
        }
    }
    .client-info__top {
        display: flex;
        justify-content: space-between;
        column-gap: 8px;
    }
    .client-info__info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .client-info__title-smaill {
        font-size: 12px;
    }
    .client-info__info_item {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }
    .client-info__info_button {
        width: 142px;
        height: 30px;
    }
    .client-info__info_text {
        color: var(--global_second_color);
        font-size: 14px;
    }
    .client-info__info_text_default-color {
        color: var(--default_background);
    }
    .client-info__info_text_small {
        font-size: 12px;
    }
    .client-info__result {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .client-info__price {
        font-size: 14px;
    }
    .client-info__search {
        color: rgba(0, 0, 0, 0.75);
        margin-bottom: 10px;
    }
    .client-info__search {
        margin-bottom: 11px;
    }
    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px;
    }

    .flex-container svg {
      cursor: pointer;
    }

</style>