<template>
    <div >
        <div>
           <AppQuestionList v-if="localQuestions.length>0" :questions="localQuestions" @resort="updateQuestionSort" @updateQuestion="updateQuestion" @del="del" />
        </div>   
        <div v-if="addShow" style="margin:10px; margin-left:15px; margin-right:15px">
          <AppQuestionCreate 
            @addQuestion="addQuestion"
          /> 
        </div>  
        <div class="button-add__container" v-if="localQuestions.length<1">
          <AppQuestionAdd
              @click="createQuestion()" 
          >
            <span class="edit-banner__name">Добавить действие</span>
          </AppQuestionAdd>
        </div>
    </div>
  </template>
  <script>
  import api from "@/components/QuestionElements/api"
  import AppQuestionCreate from "@/components/QuestionElements/AppQuestionCreate"
  import AppQuestionList from "@/components/QuestionElements/AppQuestionList"
  export default {
    name: "AppQuestions",
    components: {
        AppQuestionList, AppQuestionCreate
    },
    props: {
      task: {
        type: String, Number,
      },
    questions: {
        type: Array,
      },
    },
    data() {
      return {
        types: [],
        addShow: false,
        localQuestions: [],
      }
    },
    mounted(){
        this.localQuestions = JSON.parse(JSON.stringify(this.questions))
      
    },
    methods: {
      addQuestion(value){
        this.addShow = false
        value.task_id = this.task.id
        api.createQuestion(value).then((response)=>{
          this.$emit('updateQuestions',response.data.data);
        }).catch((error)=>{
            console.log(error)
        })  
      },
      updateQuestion(value){
        console.log(value,"updateQuestion")
        api.updateQuestion(value.id, value).then((response)=>{
          this.$emit('updateQuestions',response.data.data, "update");
         // console.log(response)
        }).catch((error)=>{
            console.log(error)
        })  
      },
      updateQuestionSort(array){
        api.updateQuestionSort(array).then((response)=>{
            console.log(response)
        }).catch((error)=>{
            console.log(error)
        })  
      },
      del(value){
        this.addShow = false
        api.deleteQuestion(value.id).then(()=>{
          this.$emit('updateQuestions',value, "delete");
         // console.log(response)
        }).catch((error)=>{
            console.log(error)
        })  
      },
      createQuestion(){
        this.addShow = !this.addShow 
      },
      
    },
    watch: {
      questions() {
          this.localQuestions = JSON.parse(JSON.stringify(this.questions))
      },
      
    }
  }
  </script>
  <style scoped>
  .edit-banner__cropper-container {
    display: flex;
    align-items: center; 
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(56, 55, 55, 0.65);
    z-index: 20;
  }
  
  .crop-image-container {
    background: white;
    border: 1px solid rgba(65, 65, 65, 0.75);
    border-radius: 6px;
    padding: 15px;
    position: relative;
  }
  
  .header-cropper-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    z-index: 20;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 6px;
  }
  
  .edit-banner__delete-img {
    margin: 15px;
    cursor: pointer;
  }
  
  .image-container img {
    height: 100%;
    width: auto;
  }
  
  .edit-banner__image {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .button-add__container {
    margin: 15px 0;
    color: #20A0FF;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .edit-banner__name {
    color: var(--default_background);
  }
  </style>