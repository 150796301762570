<template>
    <div class="bot-setting block-style">
        <AppSelect
              :style="{maxWidth: '100%' }"
              v-model="button.task_id"
              :items="tasks">
          <template v-slot:title>
              Выберите задание
          </template>
      </AppSelect> 
      <ElementActionButton :elements="[]"  v-model="button" :button="button"  @save="save" @del="del"/>
    </div>
</template> 
<script>
import ElementActionButton from "@/components/ButtonElements/ElementActionButton"
import api from "@/pages/setting/Modules/Task/api"
export default {
  name: "ElementTask",
  components: {
    ElementActionButton
  },
  props: ["model"],
  data() {
    return {
        button: {},
        tasks:[],
        shop_id: localStorage.getItem('shop_id'), 
    }
  },
  mounted(){
    this.button = JSON.parse(JSON.stringify(this.model))
    this.getTasks()
  },
  methods: {
    async getTasks(){
      const response = await api.getModels({shop_id: this.shop_id})
      this.tasks = response.data.data

    },  
    save(){
        console.log(this.button)
        if (!this.button.task_id) { this.showMessageNotice('', "Выберите задание",'error'); return;}
        this.$emit('save',this.button);

    },
    del(){
        this.$emit('del',this.button);
    }
  },
  watch: {
    model(){
        this.button = JSON.parse(JSON.stringify(this.model))
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
