import axios from 'axios';
import { BACKEND_URL_COMNAPY } from '@/store'

export default {
    actions: {
        async getCompany(ctx, data) {
            axios.get(BACKEND_URL_COMNAPY, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: data
            })
            .then(res => ctx.commit('setCompany', res.data.data))
        },
        
        async asyncUpdateCompany(ctx, editedDelivery) {
            axios.put(`${BACKEND_URL_COMNAPY}/${editedDelivery.id}`, editedDelivery, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('updateDelivery', res.data.data))
            .catch(err => console.log(err));

            ctx.commit('updateDelivery', editedDelivery)
           
        },
        
       
    },
    mutations: {
        setCompany(state, company) {
            for (const key in company) {
                if (company[key]==null) company[key]=""
            }
            state.company = company;
        },
        updateConpany() { //state, editedDelivery
            
           // console.log(editedDelivery,'editedDelivery') 
            /*
            state.deliveries = state.deliveries.map(delivery => {
                if (delivery.id === editedDelivery.id) {
                    delivery = editedDelivery;
                }
            });
            */
        },
      
    },
    state: {
        company: [],
    },
    getters: {
        Company(state) {
            return state.company
        },
    }
}