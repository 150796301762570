<template>
    <div class="admin-message-container">
      <div class="flex-justify-center">
        <div>
          <span v-if="message.admin_type" class="time">{{ message.admin_type.title }}</span>
          <span class="date">{{ message.date }}</span>
          <span class="time">{{ message.time }}</span>
        </div>
      </div>
      <div v-if="message.people" style="margin: 3px 0 6px 0;">
        {{ message.people.name }}
      </div>
        <div class="message-content" v-html="message.info"/>
       
        <div v-if="message.type_message=='photo'" class="message-content"  >
          <a :href="'https://lk.ivitrina.store/peoples_media/'+message.people.recipient+'/photos/'+message.media" target="_blank">
            <img style="width:275px;" :src="'https://lk.ivitrina.store/peoples_media/305781958/photos/'+message.media"/>
          </a>
        </div>
        <div v-if="message.type_message=='video'" class="message-content"  >

          <a :href="'https://lk.ivitrina.store/peoples_media/'+message.people.recipient+'/videos/'+message.media" target="_blank">
           Ссылка на видео
          </a>
        </div>
        <div v-if="message.type_message=='document'" class="message-content"  >
          <a :href="'https://lk.ivitrina.store/peoples_media/'+message.people.recipient+'/documents/'+message.media" target="_blank">
           Ссылка на документ
          </a>
        </div>
    
    </div>
  </template>
  
  <script>
  export default {
    name: "ChatAdminMessage",
    props: ['message']
  }
  </script>
  
  <style scoped>
  .admin-message-container {
    width: 304px;
    background: #F5F6F7;
    border: 1px solid rgba(142, 162, 176, 0.5);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin: 10px;
    padding: 13px;
  }
  .date, .time {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.5);
    margin-right: 5px;
  }
  .message-state {
    margin-right: 10px;
    color: #8EA2B0;
  }
  .message-content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.4);
  }
  </style>