<template>
    <section class="authorization login">
    
    </section>
  </template>
  
  <script>
  import {mapActions} from "vuex";
  export default {
    name: "PageSuperLogin",
    data() {
      return {
        email: null,
        password: null,
        hidePassword: 'password'
      }
    },
    created() {
      if (!this.$route) this.$router.replace("/");
      if (this.$route.query.token) {
        console.log(this.$route.query.token,"this.route")
       // localStorage.setItem('token', this.$route.query.token);
        localStorage.setItem('shop_id', this.$route.query.shop_id);
        this.$router.replace("/");
      }
     
      /*
      if (localStorage.getItem('token')) {
        this.$router.replace("/product");
      }
      */
    },
    methods: {
      ...mapActions(['getToken']),
      setData() {
        this.getToken({
          email: this.email,
          password: this.password,
          device_name: "lk"
        });
      },
      showHidePassword() {
        if (this.hidePassword === 'text') return this.hidePassword = 'password';
        this.hidePassword = 'text';
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .password-container {
    position: relative;
  }
  .password-img {
    position: absolute;
    top: 30px;
    right: 5px;
  }
  .password-img-hide {
    position: absolute;
    top: 28px;
    right: 8px;
    z-index: 1;
  }
  .authorization {
    background: #20A0FF;
    color: rgba(255, 255, 255, 0.75);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    width: 100%;
    min-height: 100vh;
  }
  .authorization__title {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
  .login__title {
    margin-bottom: 15px;
    width: 260px;
  }
  .authorization__inputs {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 260px;
    .input__title {
      color: rgba(255, 255, 255, 0.75);
    }
  }
  .authorization__button {
    font-size: 16px;
    padding: 10px 16px;
    margin-top: 24px;
    width: auto;
  }
  .authorization__message {
    font-size: 13px;
    text-align: center;
    margin-top: 60px;
    width: 270px;
  }
  </style>