<template>
  <section class="authorization login">
    <!-- <AppGreeting class="authorization__greeting">
    </AppGreeting> -->
    <div class="authorization__title login__title">
      Магазин
      IVitrina
    </div>
     <!--
    <div class="authorization__inputs login__inputs">
    
      <AppInput
          class="authorization__input"
          v-model="email">
        <template v-slot:title>
                    <span class="input__title">
                        Логин:  
                    </span>
        </template>
      </AppInput>
      <div class="password-container">
        <AppInput
            class="authorization__input"
            v-model="password"
            :typeInput="hidePassword"
        >
          <template v-slot:title>
                    <span class="input__title">
                        Пароль:
                    </span>
          </template>
        </AppInput>
        <div
            @click="showHidePassword"
        >
          <svg class="password-img-hide"
               v-if="hidePassword === 'text'"
               width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="15.959" y="0.213379" width="3" height="23.6555" rx="1" transform="rotate(42.4273 15.959 0.213379)"
                  fill="#20A0FF"/>
          </svg>
          <svg class="password-img"
               width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0003 4.90918C10.1948 4.90918 8.72754 6.37643 8.72754 8.1819C8.72754 9.98737 10.1948 11.4546 12.0003 11.4546C13.8057 11.4546 15.273 9.98737 15.273 8.1819C15.273 6.37643 13.8057 4.90918 12.0003 4.90918Z"
                fill="#8EA2B0"/>
            <path
                d="M12 0C6.54546 0 1.88729 3.3927 0 8.18182C1.88729 12.9709 6.54546 16.3636 12 16.3636C17.46 16.3636 22.1128 12.9709 24.0001 8.18182C22.1128 3.3927 17.46 0 12 0ZM12 13.6363C8.98911 13.6363 6.54546 11.1927 6.54546 8.18177C6.54546 5.17086 8.98911 2.72726 12 2.72726C15.0109 2.72726 17.4546 5.17091 17.4546 8.18182C17.4546 11.1927 15.0109 13.6363 12 13.6363Z"
                fill="#8EA2B0"/>
          </svg>
        </div>
      </div>
  
    </div>
   
    <AppButton
        class="authorization__button"
        :styleButton="'_red'"
        @click="setData">
      Войти
    </AppButton>
  -->
    <br>
    <TelegramLoginWidget
            mode="redirect"
            telegram-login="ivitrina_auth_bot"
            redirect-url="/api/v1/telegram/auth/callback"
        />
    <!--
    <div class="authorization__message">
      Если у вас еще нет учетной записи,
      заполните небольшую форму регистрации
    </div>
    <router-link to="/registration">
      <AppButton class="authorization__button"
                 :styleButton="'_no-bg-white-border'"
      >
        Зарегистрироваться
      </AppButton>
    </router-link>
    -->
  </section>
</template>

<script>
import TelegramLoginWidget from './Login/TelegramLOginWidget' 
import {mapActions} from "vuex";

export default {
  name: "PageLogin",
  components: {TelegramLoginWidget},
  data() {
    return {
      email: null,
      password: null,
      hidePassword: 'password'
    }
  },
  created() {
    if (localStorage.getItem('token')) {
      this.$router.replace("/product");
    }
  },
  methods: {
    ...mapActions(['getToken']),
    setData() {
      this.getToken({
        email: this.email,
        password: this.password,
        device_name: "lk"
      });
    },
    showHidePassword() {
      if (this.hidePassword === 'text') return this.hidePassword = 'password';
      this.hidePassword = 'text';
    },
  },
}
</script>

<style lang="scss" scoped>

.password-container {
  position: relative;
}

.password-img {
  position: absolute;
  top: 30px;
  right: 5px;
}

.password-img-hide {
  position: absolute;
  top: 28px;
  right: 8px;
  z-index: 1;
}

.authorization {
  background: #20A0FF;

  color: rgba(255, 255, 255, 0.75);

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 0 30px;

  width: 100%;
  min-height: 100vh;
}

.authorization__title {
  color: #FFFFFF;

  font-size: 24px;
  font-weight: 500;

  text-align: center;
}

.login__title {
  margin-bottom: 15px;
  width: 260px;
}

.authorization__inputs {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 260px;

  .input__title {
    color: rgba(255, 255, 255, 0.75);
  }
}

.authorization__button {
  font-size: 16px;

  padding: 10px 16px;
  margin-top: 24px;
  margin-bottom: 24px;

  width: auto;
}

.authorization__message {
  font-size: 13px;

  text-align: center;

  margin-top: 60px;
  width: 270px;
}
</style>