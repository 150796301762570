<template>
    <div class="container">
        <section class="setting">
            <AppSettingNavigation></AppSettingNavigation>
            <div class="delivery-setting">
                <AppSectionTitle class="delivery-setting__title">
                    Доставка
                </AppSectionTitle>
                <ul class="delivery-system__list"> 
                    <li v-for="(item, i) in deliveries"
                        :key="item.id">
                    <AppBottomSheet
                        :name="item.title"
                        v-model="item.status"
                        :prompt="testPrompts[i]"
                        @selectCurrent="function(state){openCurrent(i, state)}"
                        :open="setOpen[i]"
                    >
                        <DeliveryPickupList v-if="item.name=='delivery_pickup'" @updateDelivery="updateDelivery" :delivery="item"  @updateNotice="updateNotice" />
                        <DeliveryCourier v-if="item.name=='delivery_courier'" @updateDelivery="updateDelivery" :delivery="item" @updateNotice="updateNotice" />
                        <DeliveryCountry v-if="item.name=='delivery_country'" @updateDelivery="updateDelivery" :delivery="item"  @updateNotice="updateNotice" />
                        <DeliveryDigital v-if="item.name=='delivery_digital'" @updateDelivery="updateDelivery" :delivery="item" @updateNotice="updateNotice" />
                    </AppBottomSheet>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>

import api from "./Modules/Delivery/api";

import DeliveryCountry from './Modules/Delivery/DeliveryCountry';
import DeliveryDigital from './Modules/Delivery/DeliveryDigital';
import DeliveryCourier from './Modules/Delivery/DeliveryCourier';
import DeliveryPickupList from './Modules/Delivery/DeliveryPickupList';
import { mapActions, mapGetters } from 'vuex';
export default { 
    name: "PageDeliverySetting",
    data() {
        return {
            setOpen: [],
            deliveries: [],
            newpickup: {
                        city_id: 3,
                        status: true
                    },
            showAddressForm: false,
            showDeliveryForm: false,
          statePickup: {},
          testPrompts: [/*'Yookassa', 'Card'*/]
        };
    },
    components: { DeliveryPickupList, DeliveryCourier, DeliveryDigital, DeliveryCountry },
    computed: mapGetters(['allDeliveryTypes', 'shopInfo', 'allCities']),
    mounted(){
        this.getCities()
        this.getShopInfo()
        
    

    },
    methods: {
        openCurrent(i, state) {
          this.setOpen.forEach((im, i, arr)=>arr[i]=false);
          this.setOpen[i]=!state
          },
         ...mapActions(['getShopInfo', 'getCities',]),
        updatePickupCity(pickup){
            pickup.city = this.allCities.find(elem => elem.id==pickup.city_id);
        },    
        createDelivery(){

        },
        updateNotice(item){
          
            api.updateDeliveryNotice(item.id, item).then((response) => {
                if (response.data.message){
                    this.showMessageNotice('', response.data.message,'success')
                }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
        },
        updateDelivery(element, name){
            this.deliveries.find(e=>e.name == name).status = element.status

           // console.log(element.id)

            api.updateDelivery(element.id, element).then((response) => {
                if (response.data.message){
                    this.showMessageNotice('', response.data.message,'success')
                }
       
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })

          
                
                ///this.$router.push('/setting/delivery')
        },

        async getDeliveries() {
            let data = {shop_id: this.shopInfo.id}
            const response =  await api.getDeliveries(data)
            let deliveries = response.data.models
            let deliveryTypes =  response.data.types
            for (let index = 0; index < deliveryTypes.length; index++) {
                let element = deliveries.find(e=>e.delivery_id == deliveryTypes[index].id )
                deliveryTypes[index].data = (!element)?{
                        name:deliveryTypes[index].title, 
                        delivery_id:deliveryTypes[index].id, 
                        items:[], 
                        status:false,
                        shop_id : this.shopInfo.id
                    }:element
                deliveryTypes[index].status = (!element)?false:element.status
            }
            let deliveryTypesX = Object.assign([],  deliveryTypes)
      
            for (let index = 0; index < deliveryTypesX.length; index++) {
                if (deliveryTypesX[index].name=="delivery_pickup") deliveryTypes[0]=deliveryTypesX[index]
                if (deliveryTypesX[index].name=="delivery_courier") deliveryTypes[1]=deliveryTypesX[index]
                if (deliveryTypesX[index].name=="delivery_country") deliveryTypes[2]=deliveryTypesX[index]
                if (deliveryTypesX[index].name=="delivery_digital") deliveryTypes[3]=deliveryTypesX[index]
            }
            this.deliveries = deliveryTypes
          //  console.log(this.deliveries,"!!!!!!!")
        },
    }, 
    watch: {
        shopInfo(){
            this.getDeliveries()
            
        },
        /*
      'allDeliveries.0.pickups': {
        deep: true,
          handler (pickups) {
            for (let i = 0; i <pickups.length; i++) {
              let pic = pickups[i];
              this.statePickup[pic.id] = (!!pic.street && !!pic.house && !!pic.house);
            }
        }
       
      }
 */

        /*
        allCities(){
            console.log(this.allCities,'allCities')
        },
        */
      
    },
    inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .delivery-system__list {
            margin: 5px 0;
        }
    .delivery-setting {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        margin-bottom: 20px;
    }

    .delivery-setting__title {
        margin-bottom: 6px;
    }

    .delivery-setting__item {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .delivery-setting__items {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .delivery-setting__item_button {
        display: flex;
        column-gap: 10px;
        padding: 10px 15px;
        span {
            font-size: 16px;
        }
    }

    .delivery-setting__item_edit {
        margin-left: auto;
    }

    .delivery-setting__item_checkbox {
        align-self: flex-start;

        display: inline-block;
    }

    .delivery-setting__item_title {
        margin-top: 24px;
        margin-bottom: 15px;
    }

    .delivery-setting__items {
        display: flex; 
        flex-direction: column;
        row-gap: 8px;
    }

    .address {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        margin-bottom: 16px;
    }

    .address__button {
        display: flex;
        column-gap: 10px;

        padding: 12px;

        height: 40px;

        span {
            font-size: 12px;
        }

        &._active {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .address__svg {
        margin-left: auto;
    }

    .address__form {
        background: #FFFFFF;

        border: 1px solid #E4E7ED;
        border-top: 0;
        border-radius: 6px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

        display: flex;
        flex-direction: column;
        row-gap: 12px;

        padding: 16px 16px 19px;
    }

    .address__form-add {
        @extend .address__form;

        border-top: 1px solid #E4E7ED;
        border-radius: 6px;

        margin-top: 12px;
    }

    .address__street-house {
        display: flex;
        column-gap: 10px;
    }

    .address__street_input {
        flex: 1 1 auto;
    }

    .address__street_house {
        flex: 0 0 24%;
    }

    .address__office-button {
        display: flex;
        column-gap: 10px;
    }

    .address__office_input {
        flex: 0 0 35%;
    }

    .address__office_button {
        flex: 0 0 50%;

        margin: 0;
        margin-left: auto;
    }
    
    .address__office-button {
        display: flex;
        align-items: flex-end;
    }

    .delivery-setting__item_pickup {
        margin-bottom: 16px;
    }

    .delivery-setting__item_form {
        display: flex;
        flex-direction: column;
        row-gap: 18px;

        margin-bottom: 26px;
    }

    .delivery-setting__item_flex {
        display: flex;
        align-items: end;
        column-gap: 12px;
    }

    .delivery-setting__form {
        background: #FFFFFF;

        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

        border-radius: 6px;

        display: flex;
        flex-direction: column;
        row-gap: 20px;

        padding: 24px 16px 16px;
    }

    .delivery-setting__form_price {
        display: flex;
        align-items: center;
        column-gap: 20px;

        * {
            flex: 1 1 40%;
        }
    }

    .delivery-setting__form_checkbox {
        margin-top: 18px;
    }

    .catalog-setting__textarea {
        height: 64px;
    }
</style>