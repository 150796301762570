<template>
    <div class="bot-setting block-style">
        <AppInputForm
            v-model="button.name"
            :placeholder="'Название кнопки'">
                <template v-slot:title>
                    <AppTitleSettingParam>
                        Название кнопки
                    </AppTitleSettingParam>
                </template>
        </AppInputForm> 
        <AppInputForm
            v-model="button.link"
            :placeholder="'Cсылка на сайт'">
                <template v-slot:title>
                    <AppTitleSettingParam>
                       Ссылка
                    </AppTitleSettingParam>
                </template>
        </AppInputForm> 
      <ChatElementActionButton  v-model="button" :button="button"  :elements="[]" @save="save" @del="del"/>
    </div>
</template>
<script>
import ChatElementActionButton from "@/components/ChatButtonElements/ChatElementActionButton"
export default {
  name: "ElementLink",
  components: {
    ChatElementActionButton
  },
  props: ["model"],
  data() {
    return {
        button: {}
    }
  },
  mounted(){
    this.button = JSON.parse(JSON.stringify(this.model))
  },
  methods: {
    save(){
      //console.log(this.button)
        if (!this.button.name) { this.showMessageNotice('', "Укажите название кнопки",'error'); return;}
        if (!this.button.link) { this.showMessageNotice('', "Укажите ссылку",'error'); return;}
        try {
          let url = new URL(this.button.link);
          url
        
        } catch (_) {
          this.showMessageNotice('', "Такой ссылки не существует. Возможно вы не добавили https://",'error'); return;
        }
        this.$emit('save',this.button);

    },
    del(){
        this.$emit('del',this.button);
    }
  },
  watch: {
    model(){
        this.button = JSON.parse(JSON.stringify(this.model))
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
