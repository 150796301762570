<template>
    <div class="loading-overlay">
      <div class="loading-content">
        <p>Загрузка...</p>
      </div>
    </div>
</template>

  <script>
  export default {
    name: "AppLoadingOverlay",
  }
  </script>
  <style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Белый фон с небольшой прозрачностью */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Перекрывает остальное содержимое страницы */
  }
  
  .loading-content {
    background-color: #fff; /* Белый цвет фона внутри div */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  </style>