<template>
    <div class="card-client" v-if="client.orders">
      <div class="clients-table__content block-style">
        <div class="client-row"> 
          <div class="client-cell">
            <div class="cell-header">Телеграм ID</div>
            <div class="cell-value color-blue">{{ client.id }}</div>
          </div>
          <div class="client-cell">
            <div class="cell-header">Дата</div>
            <div class="cell-value">{{ client.created_at }}</div>
          </div>
          <!--
          <div class="client-cell">
            <div class="cell-header"> Заказы/заявки</div>
            <div class="cell-value">{{ client.count_element }}</div>
          </div>
          -->
          <AppCheckbox v-model="block">
            Заблокировать
        </AppCheckbox>
        </div>
        <div class="client-row">
          <div class="client-cell">
            <div class="cell-header"> Телефон</div>
            <div class="cell-value  color-blue">{{ client.phone || '' }}</div>
          </div>
        </div>
        <div class="client-row">
          <div class="client-cell">
            <div class="cell-header"> ФИО</div>
            <div class="cell-value  color-blue">{{ client.fio || '' }}</div>
          </div>
        </div>
        <div class="client-row">
          <div class="client-cell">
            <div class="cell-header"> Email</div>
            <div class="cell-value  color-blue">{{ client.email || '' }}</div>
          </div>
        </div>
        <div class="client-row" v-if="client.orders.length>0">
          <div class="client-cell" v-if="client.orders.length>0">
            <div class="cell-header">Адрес последней доставки</div>
            <div class="cell-value-long">{{ client.orders[client.orders.length-1].data.address|| 'Not in data' }}</div>
          </div>
        </div>
        <div class="client-row" v-if="client.orders.length">
          <div class="client-cell_row">
            <div class="cell-header">Средний чек</div>
            <div class="cell-value">{{ client.amount_avg}} руб</div>
          </div>
        </div>
        <div class="client-row" v-if="client.orders.length">
          <div class="client-cell_row">
            <div class="cell-header">Сумма выкупа</div>
            <div class="cell-value">{{ client.amount }} руб</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  export default {
    name: "CardClient",
    props: {
      client: [Object],
      
    },
    data() {
      return {
        block: false,
      }
    },
    watch: {
      block (newVal, oldVal) {
        console.log(newVal,oldVal)
        //this.protectContentnUpdate(newVal)
      },
    },
    computed: {
    },
  }
  </script>
  
  
  <style lang="scss" scoped>
  .clients-table__content {
    padding: 11px 13px 0;
    margin-bottom: 14px;
  }
  .clients {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  .client {
    position: relative;
    padding-top: 8px;
    &::before {
      background: var(--global_block_line);
      content: "";
      position: absolute;
      top: 0;
      height: 2px;
      width: 100%;
    }
    &:first-child {
      padding-top: 0;
      &::before {
        background: none;
        width: 0;
        height: 0;
      }
    }
  }
  .client__short-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .client__short-info_item {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .client__short-info_user {
    grid-column: 2/4;
  }
  .client__statistics {
    border-top: 1px solid var(--global_block_line);
    border-bottom: 1px solid var(--global_block_line);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 9px 0;
    margin: 9px 0;
  }
  .client__statistics_item {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .client__user {
    color: var(--global_second_color);
    font-size: 14px;
  }
  .client__statistics_item-text {
    color: rgba(0, 0, 0, 0.75);
    font-size: 12px;
    font-weight: 400;
  }
  .client__interaction_button {
    width: 94px;
    height: 30px;
  }
  .client-row {
    display: flex;
    border-bottom: 1px solid #C3CDD5;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .client-row:last-child {
    border-bottom: none;
  }
  .client-cell {
    margin-right: 20px;
  }
  .client-cell:first-child {
    width: 90px;
  }
  .cell-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 5px;
    white-space: nowrap;
  }
  .cell-value-long {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: black;
    margin-bottom: 5px;
    white-space: nowrap;
  }
  .client-cell_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .client-cell_row .cell-header{
    margin-bottom: 1px;
  }
  .cell-value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.75);
  }
  .client-value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.75);
  }
  .page-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.75);
    margin: 20px 0;
  }
  .color-blue {
    color: #20A0FF;
  }
  </style>