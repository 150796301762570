<template>
    <div class="select-search-form">
        <label class="select-search__title" for="">
            <slot name="title">{{}}</slot>
        </label>
        <div class="select-search__content">
            <div class="select-search__block"
            @click="editShow()">
                <input
                :placeholder="placeholder"
                class="select-search"
                v-model="selectedItemName"
                @input="searchItem">
                <svg class="select-search__svg"
                :class="{'_rotate180': this.isShow}" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.31651 6.86825L6.38094 6.93268L11.6568 1.65681L10.4141 0.414061L5.92969 4.89844L1.51562 0.484375L0.343209 1.65679L5.93559 7.24917L6.31651 6.86825Z" fill="black"/>
                </svg>
            </div> 
            <ul class="select-search__items"
            :class="{'_show': this.isShow}">
                <li class="select-search__item"
                v-for="item in items"
                :key="item.name"
                @click="editSelectItem(item)">
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
export default {
    name: 'AppSelectApiSearch',
    data() {
        return {
           
            isShow: false,
            selectedItemName: null,
        }
    },
    props: {
        
        placeholder: {
            type: String,
            required: false       
        },
        items: {
            type: Array,
            required: true       
        },
        checkMessage: {
            type: String,
            required: false,
            default: null
        },
         modelValue: {
            type: [String, Number]
        }
    },
    computed: {
    },
    mounted(){
         
    },
    methods: {
        
        editSelectItem(item) {
            this.$emit('update:modelValue', item.id);
            this.editShow();
           
            return this.selectedItemName = item.name;
        },
        editShow() {
            return this.isShow = !this.isShow;
        },
        searchItem() {
            this.$emit("search",this.selectedItemName)
            this.isShow = true
        },
        showItem() {
            
        },
        findSelectedItemName(id) {
            let selectedItem = this.items.find(item => item.id == id);
            return selectedItem ? selectedItem.name : this.selectedItemName;
        }
    },
    watch: {
        modelValue() {
            this.selectedItemName = this.findSelectedItemName(this.modelValue);
        },
        items() {
            this.selectedItemName = this.findSelectedItemName(this.modelValue);
            }
    }
}
</script>

<style lang="scss" scoped>
    .select-search-form {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .select-search__title {
        color: #7E7E7E;

        font-size: 12px;
    }

    .select-search__content {
        position: relative;

        &:hover {
            .select-search__svg > * {
                fill: var(--default_background);
            }
        }
    }

    .select-search__block {
        display: flex;
        align-items: center;

        position: relative;
    }

    .select-search__items {
        background: #FFFFFF;
        color: black;
        border: 1px solid #E4E7ED;
        border-top: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        display: none;

        font-size: 12px;

        position: absolute;

        width: 100%;

        z-index: 10;

        &._show {
            display: block;
        }
    }

    .select-search__item {
        border-bottom: 1px solid #E4E7ED;

        cursor: pointer;

        padding: 11px;

        transition: 200ms;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background: var(--default_background);
            color: var(--default_background_color);
        }

        &:active {
            background: var(--default_background_active);
        }
    }

    .select-search {
        background: #EEF4F8;

        border: 1px solid #E4E7ED;
        border-radius: 6px;

        padding: 11px;
        padding-right: 28px;

        width: 100%;
    }

    .select-search__svg {
        position: absolute;
        right: 12px;

        transition: 200ms;

        * {
            transition: 200ms;
        }

        &._rotate180 {
            transform: rotate(180deg);
        }
    }
</style>