export const IMAGE_PATH = 'assets/';

function addZeroDate(int) {
    if (!int) {
        return;
    }

    if (int < 10) {
        int = "0" + int;
    }

    return int;
}

export function dayMonthYear() {
    let day = new Date().getDay();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear().toString();

    year = year.slice(-2);

    day = addZeroDate(day);
    month = addZeroDate(month);

    return `${day}.${month}.${year}`;
}
export function hoursMinutes() {
    let hours = new Date().getHours();
    let minutes = new Date().getMinutes();

    hours = addZeroDate(hours);
    minutes = addZeroDate(minutes);

    return `${hours}:${minutes}`
} 