import axios from 'axios';
import { BACKEND_URL_CLIENT_NOTIFICATION } from '@/store'

export default {
    actions: {
        async getClientNotifications(ctx, data) {
   
            axios.get(BACKEND_URL_CLIENT_NOTIFICATION+"/", {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: data
            })
            .then(res => ctx.commit('setClientNotifications', res.data.data))
        },
        async asyncCreateClientNotification(ctx, newClientNotification) {
            axios.post(BACKEND_URL_CLIENT_NOTIFICATION, newClientNotification, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));
        },
        async asyncUpdateClientNotification(ctx, editedClientNotification) {
            console.log(editedClientNotification);

            axios.put(`${BACKEND_URL_CLIENT_NOTIFICATION}/${editedClientNotification.id}`, editedClientNotification, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));

          //  ctx.commit('updateClientNotification', editedClientNotification)
        },
        async asyncDeleteClientNotification(ctx, clientNotification) {
            axios.delete(`${BACKEND_URL_CLIENT_NOTIFICATION}/${clientNotification.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).
            then(() => ctx.commit('deleteClientNotification', clientNotification))
        }
    },
    mutations: {
        setClientNotifications(state, clientNotification) {
            state.clientNotifications = clientNotification;
        },
        createClientNotification(state, newClientNotification) {
            state.clientNotifications.push(newClientNotification);
        },
        updateClientNotification(state, editedClientNotification) {
            state.clientNotifications = state.clientNotifications.map(clientNotification => {
                if (clientNotification.id === editedClientNotification.id) {
                    clientNotification = editedClientNotification;
                }
            });
           
        },
        deleteClientNotification(state, clientNotification) {
            state.clientNotifications = state.clientNotifications.filter(clientNotifications => clientNotification.id !== clientNotifications.id)
        }
    },
    state: {
        clientNotifications: []
    },
    getters: {
        allClientNotifications(state) {
            return state.clientNotifications
        }
    }
}