import axios from 'axios'

export const STOCKS = `${process.env.VUE_APP_LINK}/api/v1/stocks`;

export default {
    updateStock(id, data) {
        return axios.put(`${STOCKS}/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
} 