import axios from 'axios';
import { BACKEND_URL_SETTING } from '@/store'

export default {
    actions: {
        async getSetting(ctx, shop_id) {
            axios.get(BACKEND_URL_SETTING+"/"+shop_id, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }) 
            .then(res => ctx.commit('setSetting', res.data.data))  
        },
        async asyncUpdateSetting(ctx, editedSetting) {
            axios.put(BACKEND_URL_SETTING+"/"+editedSetting.shop_id, editedSetting, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));
            ctx.commit('updateSetting', editedSetting)
        },
        
    },
    mutations: {
        setSetting(state, setting) {
          
            for (let index = 0; index < setting.payment_systems.length; index++) {
                let shop_payment_system = setting.shop_payment_systems.find(e=>e.payment_system_id==setting.payment_systems[index].id)
                if (shop_payment_system)
                    setting.payment_systems[index].data = shop_payment_system
                else 
                    {
                        let status = false
                        if (setting.payment_systems[index].id==4) status= true
                            setting.payment_systems[index].data = {payment_system_id: setting.payment_systems[index].id, status:status}
                       
                    }
                    
            }
            state.setting = setting;
        },
        updateSetting(state, editedSetting) {
            state.setting = editedSetting
        },
    },
    state: {
        setting: []
    },
    getters: {
        Setting(state) {
            return state.setting
        }
    }
}