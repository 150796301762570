<template>
    <section class="section-order-edit order-edit section">
        <div class="container">
          <div class="flex-container">
        <AppSectionTitle>
            № {{model.id}}
        </AppSectionTitle>
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                 @click="$router.push('/order')"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z" fill="#8EA2B0"/>
            </svg>
          </div>
            <div class="order-edit__content">
                <AppTitleSmall class="order-edit__title-small">
                    Состав заказа
                </AppTitleSmall>
                <div  class="order-edit__products">
                    <AppShortProducts
                    class="cart-result__products" 
                    :products="model.products"
                    :type="model.type" 
                    :shop_id="model.shop_id"
                    />
                   
                    <div class="order-edit__delivery" v-if="model.data && model.type && model.type.id==1">
                        <AppTitleSmall class="order-edit__title-small">
                            Стоимость доставки
                        </AppTitleSmall>
                        <AppProductPrice>
                           {{model.data.price}} руб.
                        </AppProductPrice>
                    </div>
                    <div class="order-edit__amount" v-if="model.type && model.type.id==1" >
                        <AppTitleSmall class="order-edit__title-small">
                            Общая стоимость
                        </AppTitleSmall>
                        <AppProductPrice>
                            {{model.sum}} руб.
                        </AppProductPrice>
                    </div>
                </div>
                <div class="order-edit__client" v-if="model.people">
                    <AppInputLock>
                        <template v-slot:title>
                            Клиент (id: {{model.people.id}})
                        </template>
                        <template v-slot:description>
                          {{model.people.fio}}
                        </template>
                    </AppInputLock>
                   
                    <AppInputLock>
                        <template v-slot:title>
                            Телефон 
                        </template>
                        <template v-slot:description>
                             {{model.people.phone}}
                        </template>
                    </AppInputLock>
                    <AppInputLock>
                        <template v-slot:title>
                            Email
                        </template>
                        <template v-slot:description>
                             {{model.people.email}}
                        </template>
                    </AppInputLock>
                    <AppInputLock v-if="model.data">
                        <template v-slot:title>
                            Условия доставки
                        </template>
                        <template v-slot:description>
                        {{model.data.shop_delivery.title}}
                        {{model.data.address}}

                        </template>
                    </AppInputLock>
                    <div v-if="model.comment">
                        <AppInputLock>
                            <template v-slot:title>
                                Комментарий
                            </template>
                            <template v-slot:description>
                                {{model.comment}}
                            </template>
                        </AppInputLock>
                    </div>
                    <AppInputLock v-if="model">
                        <template v-slot:title>
                            Способ оплаты
                        </template>
                        <template v-slot:description>
                            {{(model.payment)?model.payment.admin_name:""}}
                        </template>
                    </AppInputLock>
                    <div class="order-edit__client_form-edit">
                        <AppInputForm 
                                inputRequired 
                                v-model="model.data.info" 
                                v-if="checkDataInfo">
                            <template v-slot:title>
                                Информация о доставке (данные курьера, трек-номер посылки) 
                            </template>
                        </AppInputForm>
                        <AppSelect 
                        v-model="model.status_id"
                        :parentShow="blockstatus"
                        @isShow="statusShow"
                        :items="statuses">
                            <template v-slot:title>
                                Статус заказа
                            </template>
                        </AppSelect>
                        
                        <AppSelect v-if="model.type && model.type.id==1"
                            v-model="model.pay_status_id"
                            :parentShow="blockstatuspayment"
                            @isShow="statuspaymentShow"
                            :items="statusPayment">
                            <template v-slot:title>
                                Статус оплаты
                            </template>
                        </AppSelect>
                    </div>
                </div> 

                <AppButtonSettingSave @click="save()"
                :styleButton="showSave"
                class="order-edit__save-button"></AppButtonSettingSave>
            </div>
        </div>
    </section>
</template>


<script>

import api from "./api"
export default {
    name: "PageOrderEdit",
    props: ['editpod',"shop_id"],
    data() {
        return {
            model_id: null,
            statuses: [],
            blockstatuspayment:false,
            blockstatus: false,

            statusPayment: [
                {
                    id: 1,
                    name: 'Не оплачен'
                },
                {
                    id: 2,
                    name: 'Оплачен'
                },
                /*
                 {
                    id: 3,
                    name: 'Возврат'
                },
              
                 {
                    id: 4,
                    name: 'Отменен'
                }
                  */
            ],
            products: [],
            model: {},
        }
    },
    computed: {
        showSave(){
            if (this.checkDataInfo){
                if (this.model.status_id!=2) return 'active';
                if (this.model.data.info && this.model.data.info.length>1)  return 'active';
               return '_no-active';
            } 
            return 'active';
        },
         checkDataInfo: function () {
            if (!this.model.data) return false;
            if (this.model.data.shop_delivery.name=='delivery_courier' || this.model.data.shop_delivery.name=='delivery_country')
               {
                 return true
                }
            return false
        }
    
    }, 
     created() {
        /*
        if (!this.shop_id) {this.$router.push("/order")}
        if (this.editpod)
            this.order =  JSON.parse(this.editpod)
            */
     },
     mounted(){
        if (!this.$route.params.id) {window.location.href="/"}
        this.model_id = this.$route.params.id
        this.getModel( this.model_id );

     },
     methods: {
        statusShow(status){
            this.blockstatus = status
            if (status) this.blockstatuspayment = false
        },
        statuspaymentShow(status){
            this.blockstatuspayment = status
            if (status) this.blockstatus = false
        },
       
         async getModel(model_id){
            try {
                const response = await api.getModel(model_id)
                this.model = response.data.data
                if (this.model.type.id==1){
                    this.statuses = [
                    {
                        id: 1,
                        name: 'Оформлен',
                        type: 2
                    },
                    {
                        id: 2,
                        name: 'Доставка'
                    },
                    {
                        id: 3,
                        name: 'Завершен',
                        type: 2
                    },
                    {
                        id: 4,
                        name: 'Отменен',
                        type: 2
                    }
                ]
            }
                if (this.model.type.id==2)
                   
                    this.statuses =  [
                    {
                        id: 1,
                        name: 'Оформлен',
                        type: 2
                    },
                    {
                        id: 3,
                        name: 'Завершен',
                        type: 2
                    },
                    {
                        id: 4,
                        name: 'Отменен',
                        type: 2
                    }
                ]
                if (this.model.status.id==3)
                    this.statuses = [{
                        id: 3,
                        name: 'Завершен',
                        type: 2
                    }]
                if (this.model.status.id==4)
                    this.statuses = [{
                        id: 4,
                        name: 'Отменен',
                        type: 2
                    }]
              
            }
            catch(err) {
               // {window.location.href="/"}
            }
        },
        save(){
            if (this.showSave=="_no-active") return
            api.updateModel( this.model.id,  this.model).then((response) => {
                if (response.data.message){
                    this.showMessageNotice('', response.data.message,'success')
                    this.blockButton = false
                }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
        }
     },
     inject: ['showMessageNotice'],

}
</script>

<style lang="scss" scoped>
    .order-edit__content {
        background: #FFFFFF;
        border: 1px solid #E4E7ED;
        border-radius: 6px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        padding: 16px;
        & > * {
            position: relative;
            padding-top: 12px;
            margin-top: 12px;
            &::before {
                background: #E4E7ED;
                content: "";
                position: absolute;
                top: 0;
                left: -13px;
                width: calc(100% + 26px);
                height: 2px;
            }
            &:first-child {
                padding-top: 0;
                margin-top: 0;
                &::before {
                    content: none;
                }
            }
        }
    }
    .order-edit__title-small {
        color: #7E7E7E;
        font-size: 12px;
    }
    .order-edit__delivery {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .order-edit__amount {
        @extend .order-edit__delivery;
    }
    .order-edit__client {
        & > * {
           border-bottom: 1px solid #E4E7ED;
            padding-bottom: 9px;
            margin-bottom: 9px;
            &:last-child {
                border-bottom: none;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .order-edit__products {
        @extend .order-edit__client;
    }


    .order-edit__client_form-edit {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        margin-bottom: 10px;
    }
    .order-edit__save-button {
        padding-top: 0;
        margin-top: 0;
        &::before {
            content: none;
        }
    }


    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px;
    }

    .flex-container svg {
      cursor: pointer;
    }

</style>