import axios from 'axios'

export const URL = `${process.env.VUE_APP_LINK}/api/v1/tasks`;

export default {
    
    deleteTask(id) {
        return axios.delete(`${URL}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    //////////////////
    createQuestion(data) {
        return axios.post(`${URL}/questions`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }) 
    },
    updateQuestion(id, data) {
        return axios.put(`${URL}/questions/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateQuestionSort(data) {
        return axios.post(`${URL}/questions/sort`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    deleteQuestion(id) {
        return axios.delete(`${URL}/questions/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
}