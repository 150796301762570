<template>
    <div class="textarea-form">
        <label class="textarea__title" for="">
            <slot name="title"></slot>
        </label>
      
        <div class="textarea__content">
<!--            <textarea :value="modelValue" class="textarea"
            @input="updateInputText"
            >

            </textarea>-->
            <ckeditor
                :ref="'editor_'+index"
                :class="'textarea_'+index"
                :editor="editor"
                :model-value="modelValue"
                :style="styleTextarea"
                @input="updateInput"
                :config="editorConfig"
                @ready="onReady">
            </ckeditor> 
          <div v-if="insertButtonData" class="container-button-insert">
            <AppButtonInsertText
                v-for="(button, i) in insertButtonData"
                :key="i"
                @click="insertTextToCursor(button.text)"
            >
              {{ button.title }}
            </AppButtonInsertText>
          </div>
        </div>
  
    </div>
</template> 

<script>
//import ClassicEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default {
    name: 'AppTextareaForm',
  props: {
        modelValue: {
            type: [String, Number]
        },
        styleTextarea: {
            type: String
        },
        insertButtonData: {
            type: Array,
        },
        index: {
            type: Number,
            default: -1,
        }
    },
    data() {
        return {
            local_editor: null,
            loader:false,
            text: "",
            editor: ClassicEditor,
            editorData: "",
            editorConfig: {
                toolbar: {
                    items: [
                        'alignment', '|',
                        'bold', 'italic',  '|', //'strikethrough',  'bulletedList', 'numberedList'
                       
                    ],
                    shouldNotGroupWhenFull: true
                }
            },
        }
    },
    mounted() {

    },
    methods: {
      insertTextToCursor(text) {
        this.local_editor.model.change( writer => {
          writer.insertText( text, this.local_editor.model.document.selection.getFirstPosition() );
        } );
      },
        onReady(editor)  {
          this.local_editor = editor;
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            ); 
        },
        updateInputText(event) {
                this.$emit('update:modelValue', event.target.value);
        },
        updateInput(event) {
                this.$emit('descriptionUpdate', event, this.index);
        }
    }
}
</script>

<style lang="scss" scoped>
    .textarea-form {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .textarea__title {
        color: #7E7E7E;
        font-size: 12px;
    }
    .textarea__content {
        position: relative;
        width: 100%;
        &:hover {
            .textarea__svg > * {
                fill: var(--default_background);
            }
    }
    .textarea {
        min-height: 64px;
    }
    .textarea__svg {
        position: absolute;
        top: 11px;
        right: 12px;
        * {
            transition: 200ms;
        }
    }
      .container-button-insert {
        display: flex;
        justify-content: center;
        align-items: center;
      }
}
</style>