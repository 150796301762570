<template>
  <div class="crop-image_main-container">
    {{ ratio }}
    <div
        :style="{color: success? '#3fa251': '#ED4747'}"
        class="hidden-message">{{ currentMessage }}  
    </div>
    <div class="crop-image_header">{{ title }} </div>
    <div v-if="showButtonCrop" class="crop-button-size_container">
      <div v-if="ratio[0]==='10:10'" class="button-size_first button-size"
           :id="'button_size_10_10'"
           @click="setCurrentRatio('10:10')"
      >
      </div> 
      <div class="button-size"
           v-for="(item, i) in ratio"
           :key="i"
           :id="'button_size_' + item.replace(':', '_')"
           :style="{width: getWidthButton(...item.split(':')) + 'px'}"
           @click="setCurrentRatio(item, i)"
      ></div>

    </div>
    <div class="crop-image_viewer">
      <div v-if="processLoadImg" class="load-spinner">
        <div class="spinner"></div>
      </div>
      <input ref="file" id="input_file" name="input-file" class="input-file" hidden type="file" @change="readyFile">
      <div class="image_container">
        <img class="editImg" src="">
      </div>
    </div>
    <div class="crop-button-rule_container">
      <div class="zoom-button flex-around">
        <div
            class="zoom_minus"
            @click="zoom_minus"
        >
        </div>
        <div
            class="zoom_plus"
            @click="zoom_plus"
        >
        </div>
      </div>
      <div class="inter-button_container flex-around">
        <div class="inter-button button-change"
             @click="loadImg"
             v-if="stateLoadImg">
          Изменить
        </div>
        <div class="inter-button button-load-and-save"
             @click="doit()"
        >
          {{ getNameButton() }}
        </div>
      </div>
    </div>
    <div class="crop-image_prompt">
      {{ '*формат ' + extension.join(' ') + ' до ' + size + 'MB' }}
    </div>
    <!--
    <div v-if="combo" style="margin-top: 10px; font-size:12px">
        <span style="color:red; ">*</span>Если вы ранее загрузили видео, оно будет удалена при добавлении изображения.
        Возможна установка только одного медиа файла.
    </div>
    -->
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import axios from 'axios';
import {BACKEND_URL_MEDIA} from '@/store'

export default {
  name: "cropImage",
  data() {
    return {
      sendButtonShow: false,
      images: [],
      origin_image: {},
      original: [],
      currentIndex: null,
      success: false,
      currentMessage: '',
      currentRatio: [1, 1],
      stateLoadImg: false,
      cropRound: false,
      cropper: null,
      messages: {
        badSize: 'Размер изображения до ' + this.size + 'MB',
        badExt: 'Допустимые форматы: ' + this.extension.join(', '),
        errorLoad: 'Ошибка загрузки на сервер',
      },
      currExt: '',
      processLoadImg: false,
    }
  },
  /*
  inject: {
    cropImage_localWork: ['cropImage_localWork'],
  },
  */
  props: {
    combo: {
      type: Boolean,
      default:false,
    },
    destroyImage:{
      type: Boolean,
      default: () => true,
    },
    type: {
      type: String,
      default: () => "unknow",
    },
    shop_id:{
      type: [String,Number],
      default: () => 0,
    },
    showButtonCrop: {
      type: Boolean,
      default: () => true,
    },
    extension: {
      type: Array,
      default: () => ['png', 'jpg'],
    },
    title: {
      type: String,
      default: "Добавление нового фото*",
    },
    size: {
      type: Number,
      default: 10,
    }, 
    ratio: {
      type: Array,
      default: () => ['1:1', '4:3', '16:9'],
    },
    addRound: {
      type: Boolean,
      default: () => false,
    },
  },

  methods: {
    doit() {

      if (this.stateLoadImg) {
        if (this.currentIndex == -1) {
          this.saveImages();
          return
        }
        this.saveImg();
        return
      }
      this.loadImg();
      return
    },
    getNameButton() {
      if (this.stateLoadImg) {
        if (this.currentIndex == -1) return "Отправить"
        return 'Сохранить'
      }
      return 'Загрузить'
    },
    getWidthButton(w, h) {
      return Math.round(35 / h * w);
    },

    loadImg() {
      this.images = [];
      this.stateLoadImg = false;
      this.currentIndex = null;
      let marked = document.querySelectorAll('.button-selected');
      if (marked) {
        marked = Array.from(marked);
        marked.forEach(el => el.classList.remove('button-selected'));
      }
      this.setCurrentRatio(this.ratio[0])
      let input_file = document.querySelector('#input_file');
      input_file.value = null;
      input_file.click();
    },

    getRoundedCanvas(sourceCanvas) {
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      let width = sourceCanvas.width;
      let height = sourceCanvas.height;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = 'destination-in';
      context.beginPath();
      context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
      context.fill();
      return canvas;
    },
    async saveImages() {
      //создаем formData
      const formData = new FormData(document.createElement('form'));
      formData.append("shop_id", this.shop_id);
      formData.append("original", this.original);
      let name = ""
      for (let i = 0; i < this.ratio.length; i++) {
        name = "image_" + this.ratio[i]
        formData.append(name, this.images[i]);
      }
      //Запускаем блокировку
      this.processLoadImg = true;
      if (this.cropImage_localWork === true) return;
      //console.log(this.shop_id,"formData")
      //Елси это товар меняем endpoint загрузки
      if (this.type=="product" || this.type=="mailling" ||  this.type=="about"){
        let link = BACKEND_URL_MEDIA+"/medias"
        axios.post(link, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('token') 
            }
          }).then((response) => {
          this.$emit('imageData', response.data.data);
          })
          .catch(err => console.log(err))
          .finally(() => {
            //console.log("cropper_destroy")
            this.cropper_destroy();
            this.processLoadImg = false;
           
          })
      }
      else {
       
        axios.post(BACKEND_URL_MEDIA, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('token') 
          }
        })
          .then((response) => {
            // console.log(response.data.data, "ok");
            this.$emit('addImage', response.data.data);
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.processLoadImg = false;
            this.cropper_destroy();
          })
      }
    },

    saveImg() { //Промежуточное
      let canvas = this.cropper.getCroppedCanvas();
      if (this.cropRound) canvas = this.getRoundedCanvas(canvas);
      let img = canvas.toDataURL('image/' + this.currExt);

      let prevRatio = this.ratio[this.currentIndex];
      let prevButton = document.querySelector('#button_size_' + prevRatio.replace(':', '_'));
      if (prevButton) prevButton.classList.add('button-selected');

      this.images[this.currentIndex] = img
      let good = true
      for (let i = 0; i < this.ratio.length; i++) {
        if (!this.images[i]) {
          good = false;
          this.setCurrentRatio(this.ratio[i], i);
          return;
        }
      }
      if (good) this.setCurrentRatio(null, -1)
    },

    showMessage(name) {
      this.currentMessage = this.messages[name];
      setTimeout(function () {
        this.currentMessage = '';
      }.bind(this), 3000)
    },

    zoom_minus() {
      this.cropper.zoom(-0.1);
    },

    zoom_plus() {
      this.cropper.zoom(0.1);
    },

    readyFile(event) {
    
      let editImg = document.querySelector('.editImg');
      let file = event.target.files[0];
      //console.log(file.name.match(/\.(\w+)$/),"readyFile")
      if (this.extension.indexOf(file.name.toLowerCase().match(/\.(\w+)$/)[1]) === -1) {
        this.showMessageNotice('Ошибка 09', "Недопустимое изображение <br> Используйте: "+this.extension.join(', '),'error'); 
        return
      }
    
      if (file.size > 10e+6) {
        this.showMessageNotice('Ошибка 09', "Недопустимый размер изображение <br> Загрузите файл не более "+this.size + 'MB','error'); 
        return;
      }
      let url = URL.createObjectURL(file);
      //console.log(file.name.toLowerCase().match(/\.(\w+)$/),"ddd")
      this.currExt = file.name.match(/\.(\w+)$/)[1];

      //console.log(url);

      if (url) {
        this.stateLoadImg = true
        editImg.src = url;

        if (this.cropper && this.cropper.destroy) this.cropper.destroy();
        this.startCrop();
        let first_bs = document.querySelector('.button-size_first');
        if (!first_bs) return;
        setTimeout(function () {
          first_bs.click();
        }, 100);
      }

    },

    getBase64(file) {
      var _this = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        _this.original = reader.result
        //  console.log(this.original, "this.original")

      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },
    startCrop() {
      const image = document.querySelector('.editImg');

      this.getBase64(this.$refs.file.files[0])

      this.cropper = new Cropper(image, {
        aspectRatio: this.currentRatio[0] / this.currentRatio[1],
      });
    },
    _checkRound(ratioStr) {
      let cvb = document.querySelector('.cropper-view-box');
      let ccb = document.querySelector('.cropper-crop-box');
      let cf = document.querySelector('.cropper-face');
      if (ratioStr !== '10:10') {
        this.cropRound = false;
        if (cvb && cf) {
          cvb.style.borderRadius = 'inherit'
          ccb.style.borderRadius = 'inherit'
          cvb.style.borderColor = 'blue'
        }
        return ratioStr;
      }
      this.cropRound = true;
      //console.log(cvb, cf);
      if (cvb && cf) {
        cf.style.backgroundColor = 'inherit'
        ccb.style.borderRadius = '50%'
        cvb.style.borderRadius = '50%'
        cvb.style.boxShadow = '0 0 0 1px #39f'
        cvb.style.outline = 'inherit'
      }
      return '1:1'
    },

    createRoundCropper() {
      let cvb = document.querySelector('.cropper-view-box');
      let ccb = document.querySelector('.cropper-crop-box');
      let cf = document.querySelector('.cropper-face');
      if (this.cropRound && cvb && cf) {
        cf.style.backgroundColor = 'inherit'
        ccb.style.borderRadius = '50%'
        cvb.style.borderRadius = '50%'
        cvb.style.boxShadow = '0 0 0 1px #39f'
        cvb.style.outline = 'inherit'
      } else if (cvb && cf) {
        cvb.style.borderRadius = 'inherit'
        ccb.style.borderRadius = 'inherit'
        cvb.style.borderColor = 'blue'
      }
    },

    setCurrentRatio(ratioStr, index = 0) {
      let editImg = document.querySelector('.editImg');
      editImg.src = "";
      //console.log(editImg,"editImg")
      editImg.addEventListener('ready', function () {
        this.createRoundCropper(); 
      }.bind(this));

      if (this.currentIndex === -1) return;
      this.currentIndex = index;
      let active = document.querySelector('.button-size_active');
      if (active) active.classList.remove('button-size_active');
      if (ratioStr == null) return
      let currentButton = document.querySelector('#button_size_' + ratioStr.replace(':', '_'));
      if (currentButton) currentButton.classList.add('button-size_active');
      ratioStr = this._checkRound(ratioStr);
      this.currentRatio = ratioStr.split(':');
      if (this.cropper) this.cropper.setAspectRatio(this.currentRatio[0] / this.currentRatio[1]);
    },

    cropper_destroy() {
      
      const image = document.querySelector('.editImg');
      const image2 = document.querySelector('.cropper-hide');

      if (image) image.src = "";
      if (image2) image2.src = "";
      this.stateLoadImg = false;
      this.images = [];
      let input_file = document.querySelector('#input_file');
      if (input_file) input_file.value = null;
      //console.log(this.cropper, 'cropper_destroy')
      if (this.cropper) this.cropper.destroy();
      this.$emit("destCropper")
    }
  },
  created() {
    //console.log("created")
  },
  mounted() {
  
    this.setCurrentRatio(this.ratio[0]);
    //this.cropper_destroy()
  },
  watch: {
    destroyImage(){
      this.cropper_destroy() 
    }
  },
  inject: ['showMessageNotice'],
}

</script>

<style scoped>


.crop-image_main-container {
  width: 330px;
  max-width: 280px;

  min-height: 370px;
  box-sizing: border-box;
}

.crop-button-size_container {
  width: 100%;
  display: flex;
  justify-content: start;
}

.button-size {

}

.flex-around {
  display: flex;
  justify-content: space-between;
}

.zoom-button {
  min-width: 70px;
}

.zoom_minus, .zoom_plus {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.zoom_minus {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.5193 9.4346C11.7189 9.4346 9.44874 11.6826 9.44874 14.4557C9.44874 17.2288 11.7189 19.4768 14.5193 19.4768C15.3301 19.4768 16.0946 19.2888 16.7725 18.9552C17.1307 18.7789 17.5655 18.9236 17.7435 19.2784C17.9216 19.6331 17.7755 20.0636 17.4172 20.2399C16.5434 20.6699 15.5588 20.9114 14.5193 20.9114C10.9188 20.9114 8 18.0211 8 14.4557C8 10.8903 10.9188 8 14.5193 8C18.1198 8 21.0386 10.8903 21.0386 14.4557C21.0386 15.7745 20.6386 17.0026 19.9522 18.0252L23.9595 21.9864C24.2534 22.2552 24.2715 22.709 24 23C23.7285 23.291 23.2702 23.3089 22.9764 23.0401L18.5098 18.6587C18.2159 18.3899 18.1978 17.936 18.4693 17.6451C18.4858 17.6274 18.5031 17.6106 18.5209 17.595C18.5438 17.5309 18.5763 17.469 18.6188 17.4113C19.2296 16.5822 19.5899 15.5616 19.5899 14.4557C19.5899 11.6826 17.3197 9.4346 14.5193 9.4346ZM12 13.875V15.125H17V13.875H12Z' fill='%238EA2B0'/%3E%3Crect x='0.5' y='0.5' width='29' height='29' rx='3.5' stroke='%238EA2B0'/%3E%3C/svg%3E%0A");
}

.zoom_minus:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.5193 9.4346C11.7189 9.4346 9.44874 11.6826 9.44874 14.4557C9.44874 17.2288 11.7189 19.4768 14.5193 19.4768C15.3301 19.4768 16.0946 19.2888 16.7725 18.9552C17.1307 18.7789 17.5655 18.9236 17.7435 19.2784C17.9216 19.6331 17.7755 20.0636 17.4172 20.2399C16.5434 20.6699 15.5588 20.9114 14.5193 20.9114C10.9188 20.9114 8 18.0211 8 14.4557C8 10.8903 10.9188 8 14.5193 8C18.1198 8 21.0386 10.8903 21.0386 14.4557C21.0386 15.7745 20.6386 17.0026 19.9522 18.0252L23.9595 21.9864C24.2534 22.2552 24.2715 22.709 24 23C23.7285 23.291 23.2702 23.3089 22.9764 23.0401L18.5098 18.6587C18.2159 18.3899 18.1978 17.936 18.4693 17.6451C18.4858 17.6274 18.5031 17.6106 18.5209 17.595C18.5438 17.5309 18.5763 17.469 18.6188 17.4113C19.2296 16.5822 19.5899 15.5616 19.5899 14.4557C19.5899 11.6826 17.3197 9.4346 14.5193 9.4346ZM12 13.875V15.125H17V13.875H12Z' fill='%2320A0FF'/%3E%3Crect x='0.5' y='0.5' width='29' height='29' rx='3.5' stroke='%2320A0FF'/%3E%3C/svg%3E%0A");
}

.zoom_plus {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.5193 9.4346C10.7189 9.4346 8.44874 11.6826 8.44874 14.4557C8.44874 17.2288 10.7189 19.4768 13.5193 19.4768C14.3301 19.4768 15.0946 19.2888 15.7725 18.9552C16.1307 18.7789 16.5655 18.9236 16.7435 19.2784C16.9216 19.6331 16.7755 20.0636 16.4172 20.2399C15.5434 20.6699 14.5588 20.9114 13.5193 20.9114C9.9188 20.9114 7 18.0211 7 14.4557C7 10.8903 9.9188 8 13.5193 8C17.1198 8 20.0386 10.8903 20.0386 14.4557C20.0386 15.7745 19.6386 17.0026 18.9522 18.0252L22.9595 21.9864C23.2534 22.2552 23.2715 22.709 23 23C22.7285 23.291 22.2702 23.3089 21.9764 23.0401L17.5098 18.6587C17.2159 18.3899 17.1978 17.936 17.4693 17.6451C17.4858 17.6274 17.5031 17.6106 17.5209 17.595C17.5438 17.5309 17.5763 17.469 17.6188 17.4113C18.2296 16.5822 18.5899 15.5616 18.5899 14.4557C18.5899 11.6826 16.3197 9.4346 13.5193 9.4346ZM12.875 12H14.125V13.875H16V15.125H14.125V17H12.875V15.125H11V13.875H12.875V12Z' fill='%238EA2B0'/%3E%3Crect x='0.5' y='0.5' width='29' height='29' rx='3.5' stroke='%238EA2B0'/%3E%3C/svg%3E%0A");
}

.zoom_plus:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.5193 9.4346C10.7189 9.4346 8.44874 11.6826 8.44874 14.4557C8.44874 17.2288 10.7189 19.4768 13.5193 19.4768C14.3301 19.4768 15.0946 19.2888 15.7725 18.9552C16.1307 18.7789 16.5655 18.9236 16.7435 19.2784C16.9216 19.6331 16.7755 20.0636 16.4172 20.2399C15.5434 20.6699 14.5588 20.9114 13.5193 20.9114C9.9188 20.9114 7 18.0211 7 14.4557C7 10.8903 9.9188 8 13.5193 8C17.1198 8 20.0386 10.8903 20.0386 14.4557C20.0386 15.7745 19.6386 17.0026 18.9522 18.0252L22.9595 21.9864C23.2534 22.2552 23.2715 22.709 23 23C22.7285 23.291 22.2702 23.3089 21.9764 23.0401L17.5098 18.6587C17.2159 18.3899 17.1978 17.936 17.4693 17.6451C17.4858 17.6274 17.5031 17.6106 17.5209 17.595C17.5438 17.5309 17.5763 17.469 17.6188 17.4113C18.2296 16.5822 18.5899 15.5616 18.5899 14.4557C18.5899 11.6826 16.3197 9.4346 13.5193 9.4346ZM12.875 12H14.125V13.875H16V15.125H14.125V17H12.875V15.125H11V13.875H12.875V12Z' fill='%2320A0FF'/%3E%3Crect x='0.5' y='0.5' width='29' height='29' rx='3.5' stroke='%2320A0FF'/%3E%3C/svg%3E%0A");
}

.inter-button {
  width: 86px;
  height: 30px;
  border: 1px solid #20A0FF;
  border-radius: 6px;
  color: #20A0FF;
  font-family: Roboto-Regular;
  font-size: 12px;
  line-height: 2.4;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
}

.inter-button:hover {
  border: 1px solid #1A80CC;
  color: #1A80CC;
}


.inter-button:hover:active {
  border: none;
  color: white;
  background: #1188E0;
}


.button-load-and-save {
  margin: 0 0 0 11px;
}

.crop-button-rule_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zoom-button svg {
  cursor: pointer;
}


.crop-image_viewer {
  width: 280px;
  height: 180px;
  min-width: 280px;
  min-height: 180px;
  background: #D9D9D9;
  margin: 15px auto;
  overflow: hidden;
  position: relative;
}

.load-spinner {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #D9D9D9;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  height: 50px;
  width: 50px;
  border-left: 3px solid greenyellow;
  border-bottom: 3px solid greenyellow;
  border-right: 3px solid greenyellow;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.image_container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 280px;
  width: 100%;
  height: 100%;
  max-height: 180px;
}

.load-spinner {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #D9D9D9;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  height: 50px;
  width: 50px;
  border-left: 3px solid greenyellow;
  border-bottom: 3px solid greenyellow;
  border-right: 3px solid greenyellow;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image_container img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.button-size_first {
  background-image: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17' cy='17' r='16' stroke='%23D9D9D9' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
}

.button-size {
  background-color: #EDEDED;
  border: 3px solid;
  border-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35 0L25 -4.37114e-07L25 3L32 3L32 10L35 10L35 3L35 0Z' fill='%23D9D9D9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 35L10 35L10 32L3 32L3 25L1.31134e-06 25L3.93402e-07 32L0 35Z' fill='%23D9D9D9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0V10H3V3H10V0H3H0Z' fill='%23D9D9D9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35 35L35 25L32 25L32 32L25 32L25 35L32 35L35 35Z' fill='%23D9D9D9'/%3E%3C/svg%3E%0A") 1;
  height: 35px;
  width: 35px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  line-height: 2.7;
  box-sizing: border-box;
  margin-right: 10px;
}

.button-size_active {
  border-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35 0L25 -4.37114e-07L25 3L32 3L32 10L35 10L35 3L35 0Z' fill='%2320A0FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 35L10 35L10 32L3 32L3 25L1.31134e-06 25L3.93402e-07 32L0 35Z' fill='%2320A0FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0V10H3V3H10V0H3H0Z' fill='%2320A0FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35 35L35 25L32 25L32 32L25 32L25 35L32 35L35 35Z' fill='%2320A0FF'/%3E%3C/svg%3E%0A") 1;
}

.button-size:hover {
  border-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35 0L25 -4.37114e-07L25 3L32 3L32 10L35 10L35 3L35 0Z' fill='%2320A0FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 35L10 35L10 32L3 32L3 25L1.31134e-06 25L3.93402e-07 32L0 35Z' fill='%2320A0FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0V10H3V3H10V0H3H0Z' fill='%2320A0FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35 35L35 25L32 25L32 32L25 32L25 35L32 35L35 35Z' fill='%2320A0FF'/%3E%3C/svg%3E%0A") 1;
}

.crop-image_header {
  min-height: 20px;
  margin: 5px 0;
  font-family: Roboto-Regular;
  font-size: 14px;
  text-align: start;
}

.inter-button_item {

}


.hidden-message {
  min-height: 20px;
  margin: 5px 0;
  font-family: Roboto-Regular;
  font-size: 14px;
}

.crop-image_prompt {
  text-align: start;
  font-size: 10px;
  color: #A7A7A7;
  margin-top: 12px;
}


.button-selected {
  position: relative;
}

.button-selected:hover {
  border-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35 0L25 -4.37114e-07L25 3L32 3L32 10L35 10L35 3L35 0Z' fill='%23D9D9D9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 35L10 35L10 32L3 32L3 25L1.31134e-06 25L3.93402e-07 32L0 35Z' fill='%23D9D9D9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0V10H3V3H10V0H3H0Z' fill='%23D9D9D9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35 35L35 25L32 25L32 32L25 32L25 35L32 35L35 35Z' fill='%23D9D9D9'/%3E%3C/svg%3E%0A") 1;
}

.button-selected:after {
  content: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7303 3.67139L8.14863 9.83457L3.79819 2.39473L7.16488 14.2691L16.7303 3.67139Z' fill='%233DDA56'/%3E%3C/svg%3E%0A");
  position: absolute;
  height: 16px;
  width: auto;
  top: -11px;
  right: -9px;
}

</style>