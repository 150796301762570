<template>
  <button
      class="button-icon flex-between-center"
      :class="styleButton + (onlyIcon ? ' only-icon': '') ">
    <div v-if="!onlyIcon" class="button-name-container">
      <slot></slot>
    </div>
    <div v-if="icon" class="icon-container flex-center"
         v-html="icon"
    ></div>
  </button>
</template>

<script>

export default {
  name: "AppButtonIcon",
  props: {
    styleButton: {
      type: String
    },
    icon: {
      type: String
    },
    onlyIcon: {
      type: Boolean
    },
  },
}
</script>

<style lang="scss">

.button-icon {
  padding: 10px 20px;
  font-size: 11px;
  border-radius: 6px;
  cursor: pointer;
  color: white;

  & .button-name-container {
    white-space: nowrap;
    text-align: center;
  }

  & .icon-container {
    margin-left: 10px;
  }

  &._red {
    background: #FF0000;

    &:hover {
      background: #FF4444;
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FF0000;;
    }
  }
  &._blue {
    background: #20A0FF;

    &:hover {
      background: #4894f1;
    }

    &:active {
      background: #056db7;
    }
  }
  &._grey {
    background: none;
    color: #8EA2B0;
    padding: 8px 16px;
    border: 1px solid #8EA2B0;

    &:hover {
      background: none;
      border-color: #8EA2B0;
      color: #8EA2B0;
    }

    &:active {
      background: none;
      border-color: #8EA2B0;
      color: #8EA2B0;
    }
    &._no-active {
      background: none;
      border-color: rgba(142, 162, 176, 0.3);
      color: rgba(142, 162, 176, 0.3);
      svg path {
        fill: rgba(142, 162, 176, 0.3);;
      }
    }
  }
  &._clear-blue {
    background: #FFFFFF;
    border: 1px solid #20A0FF;
    color: #20A0FF;
    &:hover {
      background: #FFFFFF;
      border: 1px solid #6db7e8;
      color: #6db7e8;
    }
    &:active {
      background: #FFFFFF;
      border: 1px solid #076db4;
      color: #076db4;
    }
  }
  &.only-icon {
    padding: 10px;
  }
  &.only-icon._grey {
    padding: 8px;
  }
  &.only-icon .icon-container {
    margin: 0;
  }
}

</style>