<template>
  <div class="container_yookassa">
    <div class="container_yookassa-button">
      <div style="width: 100%; padding: 10px 0">
        <AppCheckbox
            v-model="element.status"
            @change="updateStatus"
        >
          Включить
        </AppCheckbox>
        <AppOpenList
            :header="'Как подключить Юkassa'"
            :items="yooKassaItems"
        />
      </div>
      <AppButton v-if="element.status" :styleButton="'_red'" class="yookassa__button"
                 @click="openref()"
      >
        Подключить Юkassa
      </AppButton>
    </div>
    <br>

    <div v-show="element.status">
      <AppInputForm style="margin-bottom: 10px"
                    v-model="element.name"
                    :placeholder="'Укажите shopId'">
        <template v-slot:title>
          <AppTitleSettingParam>
            shopId
          </AppTitleSettingParam>
        </template>
      </AppInputForm>
      <AppInputForm style="margin-bottom: 10px"
                    v-model="element.token"
                    :placeholder="element.token_status?'Токен скрыт':'Укажите токен'">
        <template v-slot:title>
          <AppTitleSettingParam>
            Секретный ключ/токен
          </AppTitleSettingParam>
        </template>
      </AppInputForm>
      <AppButton :styleButton="'_red'" @click="save()" class="yookassa__button" style="margin: 10px auto">
        Сохранить
      </AppButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppPayYookassa",
  components: {},
  data() {
    return {
      element: {},
      yooKassaItems: [
        ['Зарегистрируйтесь в ЮKassa по реферальной ссылке', 'Нужно будет ввести номер телефона, имя, почту, форму бизнеса и номер ИНН, придумать пароль.'],
        ['Заполните данные для договора.', '(реквизиты счёта вашей организации, сведения о государственной регистрации, фото или скан паспорта руководителя, паспортные данные других владельцев компании) и загрузите копии документов (всё в личном кабинете).'],
        ['Отправьте данные на проверку', '(занимает до 2 рабочих дней).'],
        ['После проверки в личном кабинете ЮKassa сформируется договор.'],
        ['Настройте интеграцию платежной системы', 'с Личным кабинетом Вашего магазина, чтобы видеть платежи по заказам и получать уведомления о них. Для этого в разделе «Интеграция» необходимо: выбрать «HTTP-уведомления», в поле «URL для уведомлений» ввести ссылку:  https://mag.ivitrina.store/api/v1/yookassa/webhook включить все предложенные варианты уведомлений о событиях.'],
        ['Протестируйте прием платежей в тестовом магазине, если нужно']
      ]
    }
  },
  props: {
    payment_system: {
      type: Object,
    }
  },
  mounted() {
    this.element = Object.assign({}, this.payment_system.data)
  },
  methods: {
    openref() {
      window.open('https://yookassa.ru/joinups/?source=mp-240090');

    },
    updateStatus() {
      this.$emit('savePayment', this.element);
    },
    save() {
      if (!this.element.name) {
        this.showMessageNotice('', "Укажите shopId", 'error');
        return;
      }
      if (!this.element.token_status && !this.element.token) {
        this.showMessageNotice('', "Укажите секретный ключ/токен", 'error');
        return;
      }
      if (!this.element.token_status && this.element.token.length < 5) {
        this.showMessageNotice('', "Укажите корректный 0 секретный ключ/токен", 'error');
        return;
      }
      if (this.element.token_status && this.element.token.length < 5) {
        this.showMessageNotice('', "Укажите корректный секретный ключ/токен", 'error');
        return;
      }
      this.$emit('savePayment', this.element);
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style scoped>
.container_yookassa-button {

}

.yookassa__button {
  max-width: 170px;
  margin: auto;
}

</style>