<template>
  <div class="container">
    <section class="setting">
      <AppSettingNavigation></AppSettingNavigation>
      <AppSectionTitle>
          Телефоны <span style="font-size: 13px;" v-if="meta.all">Всего: {{ meta.all }}.  Авторизованы: {{ meta.auth }}. </span>
          <span v-if="meta.delete_db>0" style="font-size: 13px; color: red;"> Будет удалено: {{ meta.delete_db }}. </span>
      </AppSectionTitle>
      <AppModalWarring v-if="warring" @doAction="doAction"/>
      <div class="check_phone_table">
          <div class="showcase-image__info" style="display: flex; justify-content: center">
            <AppButton
                style="margin:2px"
                :styleButton="'_clear-red'"
                @click="openFileInput"
            >
            Загрузить Телефоны
            </AppButton>
            <AppButton
                style="margin:2px"
                :styleButton="'_red'"
                @click="deleteDb"
            >
            Удалить все телефоны
            </AppButton>
          <input type="file" ref="fileInput"  accept=".csv" style="display: none" @change="handleFileChange">
          </div>
          <AppSearchAndFilter
              style="margin-bottom:10px; margin-top:10px"
              class="order__search"
              @goSearch="goSearch"  
              @filtered="startFiltered"
              :filterContent="filterContent"/>
          <EasyDataTable
              :headers="headers"
              :items="phones"
              table-class-name="customize-table"
            
              :hide-footer="true"
          >
          <template
          #item-people="{people}"
          >
            <div>
             <span v-if="people"> {{people.username}} ( {{people.first_name}}  {{people.last_name}})</span>
            </div>
          </template>
            <template
                #item-buttons="item"
            >
              <div class="flex-start-center">
                <!--
                <AppButton
                    style="margin:2px"
                    :styleButton="'_clear-red'"
                    @click="editPhone(item)"
                >
                Редактировать
                </AppButton>
              -->
                <AppButton
                    v-if="!item.people"
                    style="margin:2px"
                    :styleButton="'_clear-red'"
                    @click="deletePhone(item.id)"
                >
                Удалить
                </AppButton>
              </div>
            </template>
          
          </EasyDataTable>
 
           <AppNavigationBottom style="margin-top:5px" v-if="meta"  :meta="meta" @goPage="goPage"/>
   

      </div>
    </section>
  </div>
</template>

<script> 
import api from "./Modules/PhoneCheck/api"
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PageCheckPhone',
  components: {},
  data() {
    return {
      filter: null,
      page: 1,
      pageSize: null,
      warring:false,
      meta:{ pages: 1,},
      search: null,
      headers: [
        {text: "", value: "gg"},
        {text: "Телефон", value: "phone"},
        //{text: "Статус", value: "status"},
        {text: "Username", value: "people"},
        {text: "Дата", value: "created_at"},
        {text: "", value: "buttons"},
      ],
      phones: [],
      shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
      filterType: "all",
          types: [{name: "all", title:"Все"}, {name: "invoice", title:"заявка"},{name: "order", title:"заказ"}],
          filterContent: {
          searchPlaceholder:"Поиск по номеру телефона",
          title: 'Фильтр по телефонам', 
          filters: {
            sort: {},
            auth: {
              title: 'Авторизован',
              type: 'checkbox',
              elem: 'filter',
              name: 'auth',
              items: [{id:1,name:"Авторизован"}, {id:0, name:"Неавторизован"}],
              current: [],
            },
          }
        },
    }
  },
  computed: {
    ...mapGetters(['shopInfo']),
  },
  created() {

    this.getShopInfo()
  },
  methods: {
    ...mapActions(['getShopInfo']),

    deleteDb(){
        this.warring = true
    },
    doAction(action){
        console.log(action)
        if (action) {
          api.deleteItems({shop_id:this.shopInfo.id}).then((response) => {
              if (response.data.message){
                  this.getShopInfo()
                  this.showMessageNotice('', response.data.message,'success')
              }
          }).catch((error) => {
              if (error.response.data)
                  this.showMessageNotice('Ошибка 09', error.response.data,'error')
              if (error.response.data.message)
                  this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
          })
        }
        this.warring = false
        
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      const fileData = new FormData();
      fileData.append('file', file);
      fileData.append("shop_id", this.shopInfo.id);

      api.uploadCsv(fileData).then((response) => {
            //console.log(response.data)
            if (response.data.message){
                  
                this.showMessageNotice('', response.data.message,'success')
     
                this.getShopInfo()
            }
        }).catch((error) => {
            if (error.response.data)
                this.showMessageNotice('Ошибка 09', error.response.data,'error')
            if (error.response.data.message)
                this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
        })
      },
    
    headerCustomize(item, index) {
      let classes = 'customize-header-items-table';
      if (index === 1) classes += ' first-header';
      return classes;
    },
    goPage(page){
          this.page = page
          this.getModels()
    },
    goSearch(input){
      this.search = input
      this.getModels()
    },
    startFiltered(filter) {
      this.filter = filter.filter
      //console.log(filter.filter,"filter.filter")
      this.getModels();
    },   
    async getModels() {
      
      let data = {
        shop_id: this.shopInfo.id,
        page:(this.page>this.meta.pages)?this.meta.pages:this.page,
        pageSize:this.pageSize,
        search: this.search,
        filter: this.filter
      }
      const response = await api.getPhones(data)
      this.phones = response.data.data
      this.meta = response.data.meta
      //console.log(this.phones,"this.phones")
    },
    deletePhone(id){
      api.deleteItem(id)
      this.getModels()
    }
      
  },
  watch: {
    shopInfo() {
      /*
      let data = {
        shop_id: this.shopInfo.id,
      }
      */
      this.getModels()
      //this.getAdminsSetting(data);
      //this.getUserNotices(data);
      //this.getShopNotices(data);
      //this.getUserNotifications();
      //this.getShopNotices(data)
      //  this.getSetting()
    },
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>

</style>
