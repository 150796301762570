<template>
  <div class="radio">
      <AppInputRadio
      @update="update"
      :forAndId="forAndId"
      :checked="modelValue"
      :name="name"></AppInputRadio>
      <div class="radio__text">
          <slot></slot>
      </div>
  </div>
</template>

<script> 
export default {
  name: 'AppRadio',
  props: {
    forAndId: {
      type: [String, Number],
      required: true
    },
    name: {
      type: [String, Number],
      required: true
    },
    modelValue: {
      type: [String, Number],
    },
  },
methods: {
  update(check) {
    this.$emit('update:modelValue', check);
    this.$emit('selectRadio', check);
    }
},
}
</script>

<style lang="scss" scoped>
  .radio {
      display: flex;
      align-items: center;
      column-gap: 10px;
  }

  .radio__text {
      color: #7E7E7E;

      font-size: 12px;
  }
</style>