<template>
    <div class="setting__navigation">
        <AppSectionTitle>
            Настройки
        </AppSectionTitle> 
        <!--show(element)-->
        <nav class="setting__nav">
                <AppButton v-for="element,index in elements" :key="index"
                :style="show(element)"
                @click="go(element.name)"
                :styleButton="$route.name!=element.name ? '_grey' : null"
                class="setting__button">{{element.title}} </AppButton> 
         
        </nav>
    </div>
</template>

<script>
export default {
    name: 'AppSettingNavigation', 
    data() {
        return {
        shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
        shop_elements: JSON.parse(localStorage.getItem('shop_elements')) || [],
        showElements: [],
        elements: [
            {title: "Аккаунт", name:"PageAccountSetting", show:false},
            {title: "Каталог", name:"PageCatalogSetting", show:false},
            {title: "Название и стиль", name:"PageShowcaseSetting", show:false},
            {title: "Акции", name:"PageStockSetting"},
            {title: "О компании", name:"PageAboutSetting", show:false},
            {title: "Доставка", name:"PageDeliverySetting", show:false},
            {title: "Бот", name:"PageBotSetting", show:false},
            {title: "Уведомления", name:"PageAlertSetting", show:false},
            {title: "Авторизации", name:"PageCheckPhone", show:false},
            {title: "Задания", name:"PageTask", show:false},
            {title: "Сообщения для новых пользователей", name:"PageDeferredMessage", show:false},
        ],
        }
    },
    mounted(){

        if (this.shop_type.id==3) {  //Визитка
            let elements = ["PageBotSetting","PageAlertSetting", "PageAccountSetting","PageDeferredMessage"]
            for (let index = 0; index < elements.length; index++) {
                //if (this.shop_elements[index]=='PageTask') continue
                this.elements.find(e=>e.name==elements[index]).show=true
            }
            for (let index = 0; index < this.shop_elements.length; index++) {
               if (this.shop_elements[index]=='PageTask') continue
                this.elements.find(e=>e.name==this.shop_elements[index]).show=true
            }
        }
        if (this.shop_type.id==1) { //Магазин
            this.ul_class = "top__navigation_shop"
            let elements = ["PageBotSetting", "PageAccountSetting",
                    "PageCatalogSetting",
                    "PageShowcaseSetting","PageStockSetting","PageAboutSetting","PageDeliverySetting","PageBotSetting","PageAlertSetting","PageDeferredMessage"]
            for (let index = 0; index < elements.length; index++) {
                this.elements.find(e=>e.name==elements[index]).show=true
            }
            for (let index = 0; index < this.shop_elements.length; index++) {
                if (this.shop_elements[index]=='PageTask') continue
                this.elements.find(e=>e.name==this.shop_elements[index]).show=true
            }
        }
    },
    methods:{
        show(element){
            let f = "display:none;"
            if (element.show) f = "display:block;"
           // console.log(f)
            return f
        },
        go(name){
            this.$router.push({ name: name, params: { shop_id: this.shop_id}})
        }
        // 
    }
}
</script>

<style lang="scss" scoped>
    .setting__nav {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px 14px;
    }
    .setting__button {
        height: 30px;
    }
</style>