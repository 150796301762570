import axios from 'axios';
import { BACKEND_URL_ORDERS } from '@/store'

export default {
    actions: {
        async getOrders(ctx, data) {
           
           let res = await axios.get(BACKEND_URL_ORDERS,  {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: data
            })
            if (typeof res.data === 'object') res.data.add = data.add;
                ctx.commit('setOrders', res.data); 
           return res.data;
        },
        async asyncCreateOrder(ctx, newOrder) { 
            axios.post(BACKEND_URL_ORDERS, newOrder, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('createOrder', res.data.data))
            .catch(err => console.log(err));
        },
        async asyncUpdateOrder(ctx, editedOrder) {
            console.log(editedOrder);

            axios.put(`${BACKEND_URL_ORDERS}/${editedOrder.id}`, editedOrder, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));

           // ctx.commit('updateOrder', editedOrder)
        },
        async asyncDeleteOrder(ctx, order) {
            axios.delete(`${BACKEND_URL_ORDERS}/${order.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).
            then(() => ctx.commit('deleteOrder', order))
        }
    },
    mutations: {
        setOrders(state, data) {
            if (data.add) {
                state.orders.push(...data.data);
            } else {
                state.orders = data.data;
            }
            state.ordersMeta = data.meta;
        },
        createOrder(state, newOrder) {
            state.orders.push(newOrder);
        },
        updateOrder(state, editedOrder) {
            state.orders = state.orders.map(order => {
                if (order.id === editedOrder.id) {
                    order = editedOrder;
                }
            });
        },
        deleteOrder(state, order) {
            state.orders = state.orders.filter(orders => order.id !== orders.id)
        }
    },
    state: {
        orders: [],
        ordersMeta: {}
    },
    getters: {
        allOrders(state) {
            return state.orders
        },
        ordersMeta(state) {
            return state.ordersMeta
        }
    }
}