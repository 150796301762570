<template>
    <AppButton
    class="button-delete"
    :styleButton="'_grey'">
    {{ title }}
<!--    <svg width="16" height="16" viewBox="0 0 16 16" fill="green" xmlns="http://www.w3.org/2000/svg">
<path d="M16.3125 6.72857V9.35499H0.3125L0.3125 6.72857L16.3125 6.72857ZM9.80348 0.313477V16.3135H6.83755L6.83755 0.313477L9.80348 0.313477Z" fill="green"/>
</svg>
-->
    </AppButton>
</template>

<script>
export default {
    name: 'AppButtonSave',
    props: {
        title: {
        type: String,
        required: false,
        default: "Сохранить"
        }
    },
    methods: {
   
    },
} 

</script>

<style lang="scss" scoped> 
    .button-delete {
        min-width: 40px;
        width: 40px;
        height: 40px;
    }
</style>