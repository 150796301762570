<template>
  <div class="bot-setting block-style">
     
      <ElementChatGlobalMessage v-if="button" 
          title="Текст сообщения" 
          :inmessage="button.message" 
          @updateText="updateText"
          @updateMedia="updateMedia"/>
          Время через которое будет отправленно данное сообщение, после регистрации пользователя.
      <div class="div-container">
        
        <AppInputForm v-if="button"
          v-model="days"
          :placeholder="'дни'">
              <template v-slot:title>
                  <AppTitleSettingParam>
                    Дни
                  </AppTitleSettingParam>
              </template>
        </AppInputForm> 
        <AppInputForm v-if="button"
          v-model="hours"
          :placeholder="'часы'">
              <template v-slot:title>
                  <AppTitleSettingParam>
                    Часы
                  </AppTitleSettingParam>
              </template>
        </AppInputForm> 
        <AppInputForm v-if="button"
          v-model="minutes"
          :placeholder="'минуты'">
              <template v-slot:title>
                  <AppTitleSettingParam>
                    Минуты
                  </AppTitleSettingParam>
              </template>
        </AppInputForm> 
    </div>
      <AppButtons v-if="button && button.id && buttons"
          :place="'deferred_message'"
          :shopInfo="shopInfo"
          :model_id="button.id"
          :buttons="buttons"  
          @updateButtons="updateButtons"
      />
      <DeferredMessageActionButton  v-model="button" :button="button"  @save="save" @test="test" @del="del"/>
  </div>
</template>
<script>
import AppButtons from "@/components/AppButtons"
import ElementChatGlobalMessage from "@/components/ElementChatGlobalMessage"
import DeferredMessageActionButton from "@/components/DeferredMessages//DeferredMessageActionButton"
export default {
  name: "DeferredMessage",
  components: {
    ElementChatGlobalMessage, DeferredMessageActionButton, AppButtons
  },
  props: ["model", "index"],
  data() {
    return {
        button: {},
        buttons: null,
        message: {},
        shopInfo: {id:localStorage.getItem('shop_id')},
        days:0,
        hours:0,
        minutes:0
    }
  },
  mounted(){
    
    this.button = JSON.parse(JSON.stringify(this.model))

    this.buttons = this.button.buttons
    this.buttons.sort((a, b) => {
            return a.position - b.position;
        });
    this.convertMinutes(this.button.minutes)
    this.button.textX =  this.button.text
    this.shopInfo = {id:localStorage.getItem('shop_id')}
    
  },
  methods: {
    convertMinutes(minutesTotal) {
      const minutesPerDay = 60 * 24;
      const minutesPerHour = 60;

      this.days = Math.floor(minutesTotal / minutesPerDay);
      this.hours = Math.floor((minutesTotal % minutesPerDay) / minutesPerHour);
      this.minutes = minutesTotal % minutesPerHour;

    },
    toTotalMinutes(days, hours, minutes) {
      const minutesPerDay = 60 * 24;
      const minutesPerHour = 60;
      return (days * minutesPerDay) + (hours * minutesPerHour) + parseInt(minutes);
    },
    updateButtons(value, type="create"){
     
      let index = null
      if (type=='update'){
          index = this.buttons.findIndex(e=>e.id=value.id)
         
           this.buttons[index] = value
           console.log( this.buttons, value, " this.buttons")
          //this.$emit('updateButtons',this.index, value,  index, "update");
        }

        if (type=='delete'){
          index = this.button.buttons.findIndex(e=>e.id=value.id)
          this.buttons.splice(index,1)
          //this.$emit('updateButtons',this.index, null, index, "delete" );
        }
        if (type=='create'){
          this.buttons.push(value)
          //this.$emit('updateButtons',this.index, value, index, "delete" );
        }
        
    },
    updateText(value){
        this.button.message.text = value

    },
    updateMedia(type, media){
        this.button.message.media_type = type
        this.button.message.media = media
    },
    save(){
        if (!this.button.message.text) { this.showMessageNotice('', "Укажите текст сообщения",'error'); return;}
        this.button.minutes = this.toTotalMinutes(this.days,this.hours,this.minutes)
        this.$emit('save',this.button, this.index);
    },
    del(){
        console.log(this.index)
        this.$emit('del',this.index);
    },
    test(){
      this.$emit('test',this.index);
    }
  },
  watch: {
    model(){
        this.button = JSON.parse(JSON.stringify(this.model))
        this.buttons = this.button.buttons
        this.buttons.sort((a, b) => {
            return a.position - b.position;
        });
        this.convertMinutes(this.button.minutes)
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
  .div-container {
      display: flex;
  }

  .div-container > div {
      margin: 10px; /* Отступ со всех сторон */
  }
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
