import axios from 'axios';
import { BACKEND_URL_CHATS } from '@/store'

export default {
    actions: {
        async getChats(ctx, data) {
            data = Object.assign(data, data.search);
            delete data.search;
            axios.get(BACKEND_URL_CHATS,  {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: data
            })
            .then(res => {
            
                ctx.commit('setChats', res.data)
            })
        },
        async getChat(ctx, id) {
          
            axios.get(BACKEND_URL_CHATS+"/"+id,  {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
            .then(res => {
                
                ctx.commit('setChat', res.data)
            })
        },
        async asyncCreateChat(ctx, newChat) { 
            axios.post(BACKEND_URL_CHATS, newChat, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('createChat', res.data.data))
            .catch(err => console.log(err));
        },
        async asyncUpdateChat(ctx, editedChat) {
    

            axios.put(`${BACKEND_URL_CHATS}/${editedChat.id}`, editedChat, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));

           // ctx.commit('updateChat', editedChat)
        },
        async asyncDeleteChat(ctx, order) {
            axios.delete(`${BACKEND_URL_CHATS}/${order.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).
            then(() => ctx.commit('deleteChat', order))
        }
    },
    mutations: {
        setChats(state, data) {
            state.chats = data.data;
            state.chatsMeta = data.meta;
        },
        setChat(state, data) {
            state.chat = data.data;
        },
        createChat(state, newChat) {
            state.chats.push(newChat);
        },
        updateChat(state, editedChat) {
            state.chats = state.chats.map(order => {
                if (order.id === editedChat.id) {
                    order = editedChat;
                }
            });
        },
        deleteChat(state, order) {
            state.chats = state.chats.filter(chats => order.id !== chats.id)
        }
    },
    state: {
        chats: [],
        chatsMeta: {}
    },
    getters: {
        allChats(state) {
            return state.chats
        },
        Chat(state) {
            return state.chat
        },
        chatsMeta(state) {
            return state.chatsMeta
        }
    }
}