import axios from 'axios'

export const DELIVERIES = `${process.env.VUE_APP_LINK}/api/v1/deliveries`;
export const CITIES = `${process.env.VUE_APP_LINK}/api/v1/cities`;

export default {
    getDeliveries(data) {
        return axios.get(`${DELIVERIES}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }, 
            params: data
        })
    }, 
    geCities(data) {
        return axios.get(`${CITIES}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }, 
            params: data
        })
    }, 
    updateDelivery(id, data) { //Обновление основной информации по доставке
        return axios.put(`${DELIVERIES}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateDeliveryNotice(id, data) { //Обновление уведомлений по доставке
        return axios.put(`${DELIVERIES}/notices/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    /*
    createDelivery(data) {
        return axios.post(DELIVERIES,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    */
    /////////////////// Pickup
    createDeliveryPickup(data) {
        return axios.post(`${DELIVERIES}/pickups`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateDeliveryPickup(id, data) {
        return axios.put(`${DELIVERIES}/pickups/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    deleteDeliveryPickup(id) {
        return axios.delete(`${DELIVERIES}/pickups/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
}