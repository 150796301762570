<template>
  <div class="button-container">
    <svg
        style="margin-top: 7px;"
        v-if="openMenu"
        width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.39624 10.6875L11.0837 0L11.7908 0.707107L1.10335 11.3946L0.39624 10.6875Z" fill="white"/>
      <path d="M11.7908 10.6875L1.10327 -5.56145e-07L0.396164 0.707106L11.0837 11.3946L11.7908 10.6875Z" fill="white"/>
      <path d="M11.7908 10.6875L1.10327 -5.56145e-07L0.396164 0.707106L11.0837 11.3946L11.7908 10.6875Z" fill="white"/>
    </svg>
    <svg
        v-else
        width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0H0V1H12V0ZM0 4H12V5H0V4ZM0 8H12V9H0V8Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
export default { 
  name: "AppButtonMenu",
  data() {
    return {
     
    }
  },
  props: {
    openMenu: {
      default: () => false
    }
  }
}
</script>


<style scoped>
.button-container {
  min-width: 12px;
  min-height: 12px;
  cursor: pointer;
}
</style>