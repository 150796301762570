<template>
  <div class="select-container">
    <AppButtonIcon
        :styleButton="'_grey'"
        :icon="openList ? arrow_grey_open: arrow_grey"
        class="page-button"
        @click="openList = !openList"
    >
      Страница: <span style="font-weight: bold">{{ currentPage }}</span></AppButtonIcon>
    <div v-if="openList" class="options-container">
      <div class="option"
           v-for="(item, i) in options"
           @click="setPage(item)"
           :key="i"
      >
        Страница: <span style="font-weight: bold">{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AppSelect2",
  data() {
    return {
      currentPage: 1,
      openList: false,
      arrow_grey: '' +
          '<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M5.87305 5.22424L1.40796 0.759155L0.150879 2.01623L5.80773 7.67309L6.21619 7.26464L6.2815 7.32995L11.9384 1.67309L10.6813 0.416016L5.87305 5.22424Z" fill="#8EA2B0"/>\n' +
          '</svg>',
      arrow_grey_open: '' +
          '<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M6.06531 2.44885L10.5304 6.91394L11.7875 5.65686L6.13062 7.61169e-06L5.72217 0.40846L5.65686 0.343147L1.06357e-06 6L1.25708 7.25708L6.06531 2.44885Z" fill="#8EA2B0"/>\n' +
          '</svg>\n',
    }
  },
  props: {
    options: {
      type: Array,
    },
    selectPage: {
      type: [String,Number],
    },
  },
  methods: {
    setPage(item) {
      this.$emit('selectVariant', item);
      this.openList = false;
      this.currentPage = item;
    }
  },
  watch:{
    selectPage(selectPage){
      console.log(selectPage)
      this.currentPage = selectPage
    }
  }
}

</script>

<style scoped>
.options-container {
  border-right: 1px solid #8EA2B0;
  border-left: 1px solid #8EA2B0;
  padding-top: 8px;
  position: relative;
  top: -7px
}

.page-button {
  display: flex;
}

.option {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #8EA2B0;
  padding: 8px 22px 8px 0;
  border-bottom: 1px solid #8EA2B0;
  text-align: center;
  background: aliceblue;
}
.option:hover {
  color: #759ab6;
  background: #e8ebf5;
}
</style>