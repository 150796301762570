<template>
  <div class="delivery-setting__item_pickup  delivery-setting__item_form">
    <div class="delivery-setting__item_checkbox">

      <AppCheckbox v-model="element.status" @change="save()">
        {{ element.status ? 'Выключить' : 'Включить' }}
      </AppCheckbox>
      <div v-if="element.status">
        <AppDescription
            @setDescription="setDescription"
            :description="element.description" 
            :title="'Условия доставки'+ ' ('+element.title+')'"
            :info="''"
        />
        <div class="delivery-setting__item_flex" v-if="element.status">
          <AppInputForm v-model="element.price" v-if="element">
            <template v-slot:title>
              <AppTitleSettingParam>
                Стоимость
              </AppTitleSettingParam>
            </template>
          </AppInputForm>
          <AppButtonSettingSave @click="save()"></AppButtonSettingSave>
        </div>
        <div
            v-for="item in element.notices.slice().reverse()"
            :key="item.id">
          <AppNotifications
              @updateNotice="updateNotice"
              :model="item"
              :buttonInsert="buttomInsert"
              :title="item.notice.title + ' ('+element.title+')'"
              :info="'Обозначены переменные данные, заполняющиеся автоматически.'"
          />
          <br>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  name: "DeliveryCountry",
  data() {
    return {
      element: {},
      buttomInsert: [
      {
            "id": 5,
            "title": "Трек номер",
            "text": "{%info%}",
            "demo": '453345435345'
          },
          {
            "id": 5,
            "title": "ФИО",
            "text": "{%fio%}",
            "demo": 'Иванов Иван Иванович'
          },
          {
            "id": 5,
            "title": "№ заказа ",
            "text": "{%order_id%}",
            "demo": '№12344'
          }
      ]
    };
  },
  components: {},
  props: {
    delivery: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    ...mapGetters(['allCities']),
  },
  mounted() {
    this.element = Object.assign({}, this.delivery.data)
    let notices = Object.assign([],  this.element.notices) 
      for (let index = 0; index < notices.length; index++) {
          if (notices[index].notice_id==10){ this.element.notices[0]=notices[index]}
          if (notices[index].notice_id==9) this.element.notices[1]=notices[index]
      }
  },
  methods: {
    setDescription(description) { //Изменение description
      this.element.editDescription = description
    },
    save() { //Обновление доставки
      if (this.element.editDescription)
        this.element.description = this.element.editDescription
      this.$emit('updateDelivery', this.element, this.element.name)
    },
    updateNotice(item) { //Обновление уведомлений
      this.$emit('updateNotice', item)
    }, 
  },
  watch: {
    /*
    delivery(delivery){
        this.element = Object.assign({}, delivery.data)
    }
    */
  },
  inject: ['showMessageNotice'],
}
</script>
<style lang="scss" scoped>
.delivery-setting {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  margin-bottom: 20px;
}

.delivery-setting__title {
  margin-bottom: 6px;
}

.delivery-setting__item {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.delivery-setting__items {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.delivery-setting__item_button {
  display: flex;
  column-gap: 10px;

  padding: 10px 15px;

  span {
    font-size: 16px;
  }
}

.delivery-setting__item_edit {
  margin-left: auto;
}

.delivery-setting__item_checkbox {
  align-self: flex-start;
  width: 100%;
  display: inline-block;
}

.delivery-setting__item_title {
  margin-top: 24px;
  margin-bottom: 15px;
}

.delivery-setting__items {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.address {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  margin-bottom: 16px;
}

.address__button {
  display: flex;
  column-gap: 10px;

  padding: 12px;

  height: 40px;

  span {
    font-size: 12px;
  }

  &._active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.address__svg {
  margin-left: auto;
}

.address__form {
  background: #FFFFFF;

  border: 1px solid #E4E7ED;
  border-top: 0;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

  display: flex;
  flex-direction: column;
  row-gap: 12px;

  padding: 16px 16px 19px;
}

.address__form-add {
  @extend .address__form;

  border-top: 1px solid #E4E7ED;
  border-radius: 6px;

  margin-top: 12px;
}

.address__street-house {
  display: flex;
  column-gap: 10px;
}

.address__street_input {
  flex: 1 1 auto;
}

.address__street_house {
  flex: 0 0 24%;
}

.address__office-button {
  display: flex;
  column-gap: 10px;
}

.address__office_input {
  flex: 0 0 35%;
}

.address__office_button {
  flex: 0 0 50%;

  margin: 0;
  margin-left: auto;
}

.address__office-button {
  display: flex;
  align-items: flex-end;
}

.delivery-setting__item_pickup {
  margin-bottom: 16px;
  width: 100%;
}

.delivery-setting__item_form {
  display: flex;
  flex-direction: column;
  row-gap: 18px;

  margin-bottom: 26px;
}

.delivery-setting__item_flex {
  display: flex;
  align-items: end;
  column-gap: 12px;
  margin-bottom: 15px;
}

.delivery-setting__form {
  background: #FFFFFF;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

  border-radius: 6px;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  padding: 24px 16px 16px;
}

.delivery-setting__form_price {
  display: flex;
  align-items: center;
  column-gap: 20px;

  * {
    flex: 1 1 40%;
  }
}

.delivery-setting__form_checkbox {
  margin-top: 18px;
}

.catalog-setting__textarea {
  height: 64px;
}
</style>