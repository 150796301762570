<template>
   <AppCheckbox
          v-model="element.status"  
          @change="updateStatus"
      >
    Включить
  </AppCheckbox>
  <!--
  <AppButton :styleButton="'_red'" class="yookassa__button"  style="margin: 10px auto">
    Сохранить
  </AppButton>
  -->
</template>

<script>
export default {
  name: "AppPayInvoice",
  data() {
      return {
        element: {},
      }
  },
  props: {
    payment_system: {
      type: Object,
    }
  },
  mounted(){
      this.element = Object.assign({}, this.payment_system.data)
  },
  methods: {
    updateStatus(){
      this.$emit('savePayment', this.element);
    },
  },
}
</script>

<style scoped>

.yookassa__button {
  max-width: 170px;
}


</style>