<template>
    <section class="section-product-edit product-edit section">
        <div class="container" v-if="task">
            <div class="flex-container">
                <AppSectionTitle>
                Настройка задания <span v-if="task.name">{{ task.name }}</span>
                </AppSectionTitle>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    @click="goBack"
                >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z" fill="#8EA2B0"/>
                </svg> 
            </div>
            <div class="product-edit__content">
              

                <div class="product-edit__info"> <!--основные параметры-->
                    <AppInputForm
                        inputRequired
                        :subSymbol="''"
   
                        v-model="task.name"
                        > 
                        <template v-slot:title>
                            Название задания*
                        </template>
                    </AppInputForm>
                    
                    <div class="text-container">
                        <h3>Описание (для внутреннего использования)</h3>
                        <CustomCKEditor v-if="task" v-model="task.description"/>
                    </div>

                    <AppQuestions 
                        v-if="task.id"
                        :task="task"
                        :questions="questions"  
                        @updateQuestions="updateQuestions"
                    />
                </div>
                <div class="product-edit__interaction" >
                    <AppButtonSettingSave 
                        @click="save" 
                        :styleButton="(blockButton)?'_no-active':'active'"
                        />  
                </div>
                    <br>
            </div>
            
        </div>
    </section>
</template>

<script>
import api from "./api"
import AppQuestions from "@/components/AppQuestions"

export default {
    name: "PageTaskEdit", 
    data() {
        return {

            shop_id: localStorage.getItem('shop_id'), 
            task:{description:""},
            questions: [],
        };
    },

    components: {AppQuestions},
     computed: {
    
     },
    
     created() {
        //if (!this.shop_id) {window.location.href="/"}
     },
     async mounted(){
     
        if (this.$route.params.id=="create") {
            
            this.task.shop_id = this.shop_id
        }
        else {
  
            await this.getModel( this.$route.params.id );
        }
        if (!this.$route.params.id) {window.location.href="/"}

        
        
     },
     methods: { 
        async getModel(id){
            const response = await api.getModel(id)
            this.task = response.data.data
            this.task.description = this.task.description || " "
            this.questions = this.task.questions
     
        },
        updateQuestions(){
            
           this.getModel(this.$route.params.id)
        },
   
        goBack(){
            this.$router.push({name:"PageTask", params: {back: true}})
        },

        save() {
           
           
            if (!this.task.name) {this.showMessageNotice('Ошибка 09', "Укажите название задания",'error'); return}
                    
            this.blockButton = true
            if (this.task.id) {
                api.updateModel( this.task.id,  this.task).then((response) => {
                if (response.data.message){
                    this.showMessageNotice('', response.data.message,'success')
                    this.blockButton = false
                }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.errors,'error')
                })
            }
            else    {
                        this.task.id = null
                        api.createModel(this.task).then((response) => {
                            if (response.data.message){
                                this.showMessageNotice('', response.data.message,'success')
                                this.model  = response.data.data
                                {window.location.href="/setting/tasks/"+this.model.id}
                                //this.getModel( this.model.id);
                                //this.blockButton = false
                            }
                            }).catch((error) => {
                                if (error.response.data)
                                    this.showMessageNotice('Ошибка 09', error.response.data,'error')
                                if (error.response.data.message)
                                    this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                            })
                    }
        
        }, 
    },

    inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
   
    .product-edit__content {
        border: 1px solid rgba(142, 162, 176, 0.5);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 12px 16px 13px;
        & > * {
            border-top: 1px solid var(--global_block_line);
            padding-top: 20px;
            &:first-child {
                border-top: 0;
                padding-top: 0;
            }
        }
    }
    .product-edit__status {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
    }
    .product-edit__status_ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 13px;
    }
    .product-edit__info {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .product-edit__inputs {
        display: flex;
        column-gap: 15px;
    }
    .product-edit__add-form {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
    .product-edit__add-form_show {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px;
    }

    .flex-container svg {
      cursor: pointer;
    }

    .product-edit__show {
        row-gap: 10px;
    }
    .product-edit__images {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 10px;
    }
    .product-edit__category {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .promo {
      display: flex;
      justify-content: space-between;
      align-items: end;
    }
    .product-edit__bottom {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
    }
    .product-edit__digital__inner {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }
    .product-edit__interaction {
        margin: 16px auto 0;
        width: 95%;
        height: 40px;
    }
</style>