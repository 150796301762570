<template>
    <AppButton
    class="button-archive"
    :styleButton="'_red-external'">
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.60465 0.0689282C2.51771 0.111931 2.01603 0.588931 1.26847 1.33926C0.274367 2.33702 0.0647481 2.56413 0.0319433 2.67897C0.00294094 2.78056 -0.00483683 4.15639 0.00269894 7.86486L0.0129656 12.9125L0.131187 13.1526C0.261439 13.4172 0.485334 13.663 0.722745 13.8021C1.07852 14.0106 0.719842 13.9999 7.35861 13.9999C13.9974 13.9999 13.6387 14.0106 13.9945 13.8021C14.2326 13.6627 14.4581 13.4148 14.587 13.1509L14.7043 12.911L14.7134 7.83278C14.7223 2.83068 14.7214 2.75239 14.6539 2.61298C14.559 2.41687 12.2616 0.126691 12.0864 0.0534765C11.973 0.0060841 11.4317 -3.44229e-05 7.35108 1.44881e-07C2.8189 3.47126e-05 2.74154 0.00114086 2.60465 0.0689282ZM11.8351 1.83206L12.2142 2.2123H7.35861H2.50302L2.88209 1.83206L3.26116 1.45181H7.35861H11.4561L11.8351 1.83206ZM8.83687 6.99129L8.84599 8.08882H9.943H11.04L9.19931 9.92952L7.35861 11.7702L5.51792 9.92952L3.67722 8.08882H4.77471H5.8722V7.00571C5.8722 6.40997 5.88274 5.91206 5.8956 5.89916C5.9085 5.88627 6.57348 5.8798 7.37337 5.88475L8.82774 5.89377L8.83687 6.99129Z" fill="#FF0000"/>
        </svg>
    </AppButton>
</template>

<script>
export default {
    name: 'AppButtonArchive'
}
</script>

<style lang="scss" scoped>
    .button-archive {
        min-width: 30px;
        width: 30px;
        height: 30px;
    }
</style>