<template>
    <div class="bot-setting block-style">
        <AppInputForm
            v-model="button.name"
            :placeholder="'Название кнопки'">
                <template v-slot:title>
                    <AppTitleSettingParam>
                        Название кнопки
                    </AppTitleSettingParam>
                </template>
        </AppInputForm> 
        <AppInputForm v-if="shop_type && shop_type.name!='shop'"
            v-model="button.link"
            :placeholder="'Cсылка на WebApp'">
                <template v-slot:title>
                    <AppTitleSettingParam>
                       Ссылка 
                    </AppTitleSettingParam>
                </template>
        </AppInputForm> 
        <AppSelectSearch v-if="shop_type && shop_type.name=='shop'"
            v-model="selectProductId"
            @search="goSearch" 
            :items="products"
        >
          <template v-slot:title>
            Выберите товар
          </template>
        </AppSelectSearch>

      <ChatElementActionButton  v-model="button" :button="button"  :elements="[]" @save="save" @del="del"/>
    </div>
</template>
<script>
import apiProduct from "@/pages/product/api"
import ChatElementActionButton from "@/components/ChatButtonElements/ChatElementActionButton"
export default {
  name: "ElementWebApp",
  components: {
    ChatElementActionButton
  },
  props: ["model"],
  data() {
    return {
      selectProductId: null,
        search:"",
        products: [],
        button: {},
        shop_type: JSON.parse(localStorage.getItem('shop_type')),
        shop_bot: JSON.parse(localStorage.getItem('shop_bot')),
    }
  },
  mounted(){
    this.button = JSON.parse(JSON.stringify(this.model))
    if (this.button.type_id==8) {
      let url = this.button.link;
      let matches = url.match(/(\d+)(?!.*\d)/);
      let lastNumber = matches ? matches[0] : null;
      if (lastNumber) {
       
        this.goSearch(lastNumber);
          
        }
    }
  },
  methods: {
    goSearch(input){
      this.search = input
      this.getModels()
    },
    async getModels(){
        let data = {search:this.search, shop_id:this.shop_bot.shop_id}
        const response = await apiProduct.getProducts(data)
       
        this.products = response.data.data
        if (this.button.type_id==8) {
          let url = this.button.link;
          let matches = url.match(/(\d+)(?!.*\d)/);
          let lastNumber = matches ? matches[0] : null;
          if (lastNumber) this.selectProductId = lastNumber
        }
    },
   
    save(){
      //console.log(this.button)
        if (!this.button.name) { this.showMessageNotice('', "Укажите название кнопки",'error'); return;}
        if (!this.button.link) { this.showMessageNotice('', "Укажите ссылку",'error'); return;}
        try {
          let url = new URL(this.button.link);
          url
        
        } catch (_) {
          this.showMessageNotice('', "Такой ссылки не существует. Возможно вы не добавили https://",'error'); return;
        }
        this.$emit('save',this.button);

    },
    del(){
        this.$emit('del',this.button);
    }
  },
  watch: {
    selectProductId(selectProductId){
      let but = this.products.find(e=>e.id ==selectProductId )
      if (but) this.button.link = but.link
    },
    model(){
        this.button = JSON.parse(JSON.stringify(this.model))
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
