<template>
      <div>
            <AppCheckbox v-if="elements.includes('protect_content')" style="margin-bottom: 5px;" v-model="localButton.protect_content"  @change="updateProtectContent()">
               Защить от копирования и пересылки
            </AppCheckbox>
            <!--
            <AppCheckbox v-if="elements.includes('likes')" v-model="localButton.likes"  @change="updateProtectContent()">
               Добавить лайк/дизлайк
            </AppCheckbox>
            -->
            <div style="display:flex; justify-content:space-between">
                <AppCheckbox  v-model="localButton.status"  @change="updateStatus()">
                    {{ localButton.status ? 'Выключить' : 'Включить' }}
                </AppCheckbox>
                <div style="display:flex; justify-content:space-between">
                    <AppButtonSave 
                        @click="save(localButton)"
                        style="width: 100px; height: 30px; margin-right: 10px;"
                    />
                    <AppButtonRemove 
                            @click="del(localButton)"
                            style="width: 30px; height: 30px; "
                        />
                
                </div>
            </div>
        </div>
</template>
<script>
export default {
  name: "ElementActionButton",
  components: {
  },
  props: {
    button:{
      type: Object,
    },
    elements: {
        type: Array,
        default: function() {
            return ["protect_content", "likes"];
        }
    }
  },
  data() {
    return {
        localButton: {status:false}
    }
  },
  mounted(){
    this.localButton = this.button
  },
  methods: {
    updateStatus(){
        this.$emit('update:modelValue', this.localButton);
    },
    updateProtectContent(){
        this.$emit('update:modelValue', this.localButton);
    },
    save(){
        this.showMessageNotice('Ошибка 09',"Кнопка обновлена",'success'); 
        this.$emit('save');
    },
    del(){
        this.$emit('del');
    }
  },
  watch: {
    button(button){
        this.localButton = button
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
