<template>
    <div>
      <div class="flex-justify-center" style="margin: 15px 0">
        <!--
        <AppButton
            :styleButton="'_blue-external'"
            style="width: 90px; height: 30px; padding: 0 8px"
        >
          Обращения
        </AppButton>
        -->
        <AppButton
            :styleButton="'_blue-external'"
            style="width: 73px; height: 30px; padding: 0 8px"
        >
          Рассылка
        </AppButton>
        <AppButton
            @click="checkEndList()"
            :styleButton="'_blue-external _no-active'"
            style="width: 111px; height: 30px; padding: 0 8px"
        >
          Чат с клиентом 
        </AppButton>
      </div>
      <div class="flex-justify-center client-name">
        <div class="" v-if="scrollContainer">Чат с клиентом 
       
         
        
        </div>
        <svg @click="setCurrentPage('SubPageClientList')" width="15" height="14" viewBox="0 0 15 14" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z"
                fill="#8EA2B0"/>
        </svg>
      </div>
      <AppSearch
          class="client__search"/>
      <div class="chat_scroll">
        <div
            :style="getTypeMessage(message)"
            v-for="(message, i) in messages"
            :key="i"
        >
       
          <component
              :is="'Chat' + message.type + 'Message'"
              :message="message"
          /> 
        </div>
      </div>
      <div class="message-field-container">
        <AppTextareaLite
            :height="'70px'"
            v-model="text"
            :placeholder="'Введите текст сообщения'"
        />

        <div class="flex-justify-center"
             style="height: 50px"
        >
        <!--
          <AppEditBanner
              style="margin-bottom: -23px; margin-top: 20px; position: relative; top: -16px;"
              @imgData="updateImage"
              @deleteImg="deleteImage"
              :button="'фото'"
              :headerPart="'фото'"
              :ratio="[16, 9]"
          />
          -->
          <AppButton
              style="width: 93px; height: 30px; margin-top: 16px"
              :styleButton="'_blue-external'"
              @click="sendMessage"
          >
            Отправить
          </AppButton>
          <br>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import api from './api'
  import ChatClientMessage from './ChatClientMessage'
  import ChatAdminMessage from './ChatAdminMessage'

  function addSlowscroll(el, callback) {
  el.addEventListener(
      'scroll',
      function () {
        
        setTimeout(function () {
          callback();
          addSlowscroll(el, callback);
        }, 200);
      },
      {once: true}
  );
}

  export default {
    name: "SubPageChatClient",
    components: {
      ChatClientMessage,
      ChatAdminMessage,
    },
    props: {
     // clients: [Array],
      clientId: [Number]
    },
    data() {
      return {
        loadMessage: false,
        scrollLast:0,
        scrollDirection: 0,
        scrollBottom: 0,
        scrollContainer: null,
        currentPage:0,
        page: 0,
        pageSize: 6,
        lastPage: 1,
        text:"",
        messages: []
      }
    },
    methods: {
      getTypeMessage(message){
        //console.log(message.type)
        let cl = "display: flex;"
        if (message.type=='Client') cl = "display: flex; justify-content:right;"
        return cl
      },
      addListeners() { //прокрутка
        this.scrollContainer = document.querySelector('.chat_scroll');
        addSlowscroll(this.scrollContainer, this.checkEndList);
      },
      async checkEndList() {
       // if (this.loadMessage) console.log(this.scrollContainer.scrollTop,"loadMessage")
        if (this.scrollContainer.scrollTop<this.scrollDirection)
        if (this.scrollContainer.scrollTop < 100 && this.page+1<=this.lastPage && this.currentPage==this.page)
          if ( !this.loadMessage) this.getModels(this.clientId);
        this.scrollDirection = this.scrollContainer.scrollTop
      },
      sendMessage(){
        let data = {
          people_id: this.clientId,
          text: this.text,
        }
        api.sendMessage(data).then((response) => {
                if (response.data.message){
                  let mess = response.data.data
                  mess.type=='Client'
                  this.messages.push(mess)
                  this.text=""
                }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.errors,'error')
                })
      },
      async getModels(clientId){
        this.page++
  
        let data = {
          people_id: clientId,
          page: this.page,
          pageSize: this.pageSize,
          search: this.search
        }
        this.loadMessage = true
        const response = await api.getPeopleMessages(data)
       
        if (response && response.status === 200){
          this.pageSize = response.data.meta.pageSize
          this.lastPage = response.data.meta.pages
          this.currentPage = response.data.meta.currentPage
        
          let messages = response.data.data
          for (let index = 0; index < messages.length; index++) {
            this.messages.unshift(messages[index])      
          }
          setTimeout(() => {
              this.checkEndList()
              this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight - this.scrollLast
              this.scrollLast = this.scrollContainer.scrollHeight
            }, 200)
          this.loadMessage = false
        }
        if (this.page==1) {  
          setTimeout(() => {
              this.checkEndList()
              this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight
              this.scrollLast = this.scrollContainer.scrollHeight
            }, 200)
        }
      },
      updateImage(data) {
        console.dir(data[0]);
        this.$emit('data', {action: 'writeData', value: {imgData: data}})
      },
      deleteImage() {
        this.$emit('data', {action: 'writeData', value: {imgData: ''}})
      },
    },
    inject: ['setCurrentPage'],
    
    mounted(){
      this. currentPage=0,
      this.page= 0,
      this.addListeners();
      this.checkEndList();
      this.getModels(this.clientId)
   },

  }
  </script>
  
  <style scoped>
  .chat_scroll {
    position: relative;
    overflow-y: auto;
    padding: 20px 4px;
    max-height: 250px;
  }
  .message-field-container {
    height: 180px;
    border-top: 1px solid #C7D1D8;
    background: white;
    padding: 15px;
    position: relative;
    width: calc(100% + 32px);
    left: -16px;
    bottom: -60px;
  }
  .client-name {
    margin-bottom: 10px;
  }
  </style>