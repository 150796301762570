<template>
    <div class="product-price">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppProductPrice'
}
</script>

<style lang="scss" scoped>
    .product-price {
        color: rgba(0, 0, 0, 0.75);

        font-size: 16px;
        font-weight: 700;
        /*
        &::after {
            content: " руб";
        } 
        */  
    }
</style>