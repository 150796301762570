<template>
  <div class="container">
    <section class="setting">
      <AppSettingNavigation></AppSettingNavigation>
      <AppSectionTitle>
          Задания
      </AppSectionTitle>
      <div class="check_phone_table">
        <AppButton
                style="margin:2px"
                :styleButton="'_clear-red'"
                @click="addModel()"
            >
            Добавить задание
            </AppButton>
        <!--
          <AppSearchAndFilter
              style="margin-bottom:10px; margin-top:10px"
              class="order__search"
              @goSearch="goSearch"  
              @filtered="startFiltered"
              :filterContent="filterContent"/>
        -->
          <EasyDataTable
              :headers="headers"
              :items="models"
              table-class-name="customize-table"
            
              :hide-footer="true"
          >
          <template
          #item-people="{people}"
          >
            <div>
             <span v-if="people"> {{people.username}} ( {{people.first_name}}  {{people.last_name}})</span>
            </div>
          </template>
            <template
                #item-buttons="item"
            >
              <div class="flex-start-center">
                <!--
                <AppButton
                    style="margin:2px"
                    :styleButton="'_clear-red'"
                    @click="editPhone(item)"
                >
                Редактировать
                </AppButton>
              -->
              <AppButton
                    style="margin:2px"
                    :styleButton="'_clear-red'"
                    @click="editModel(item.id)"
                >
                Изменить
                </AppButton>
                <AppButton
                    v-if="!item.people"
                    style="margin:2px"
                    :styleButton="'_clear-red'"
                    @click="deleteModel(item.id)"
                >
                Удалить
                </AppButton>
              </div>
            </template>
          
          </EasyDataTable>
 
           <AppNavigationBottom style="margin-top:5px" v-if="meta"  :meta="meta" @goPage="goPage"/>
   

      </div>
    </section>
  </div>
</template>

<script> 
import api from "./Modules/Task/api"
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PageTask',
  components: {},
  data() {
    return {
      filter: null,
      page: 1,
      pageSize: null,
  
      meta:{ pages: 1,},
      search: null,
      headers: [
        {text: "", value: "gg"},
        {text: "Задание", value: "name"},
        //{text: "Статус", value: "status"},
        {text: "Вопросы", value: "questions_count"},
        {text: "Дата", value: "created_at"},
        {text: "", value: "buttons"},
      ],
      models: [],
      shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
      filterType: "all",
          types: [{name: "all", title:"Все"}, {name: "invoice", title:"заявка"},{name: "order", title:"заказ"}],
          filterContent: {
          searchPlaceholder:"Поиск по номеру телефона",
          title: 'Фильтр по телефонам', 
          filters: {
            sort: {},
            auth: {
              title: 'Авторизован',
              type: 'checkbox',
              elem: 'filter',
              name: 'auth',
              items: [{id:1,name:"Авторизован"}, {id:0, name:"Неавторизован"}],
              current: [],
            },
          }
        },
    }
  },
  computed: {
    ...mapGetters(['shopInfo']),
  },
  created() {

    this.getShopInfo()
  },
  methods: {
    ...mapActions(['getShopInfo']),
    editModel(id){
      this.$router.push({ name: "PageTaskEdit", params: { id: id}})
    },
    addModel(){
      this.$router.push({ name: "PageTaskEdit", params: { id: "create"}})
    },
   
    goPage(page){
          this.page = page
          this.getModels()
    },
    goSearch(input){
      this.search = input
      this.getModels()
    },
    startFiltered(filter) {
      this.filter = filter.filter
      //console.log(filter.filter,"filter.filter")
      this.getModels();
    },   
    async getModels() {
      
      let data = {
        shop_id: this.shopInfo.id,
        page:(this.page>this.meta.pages)?this.meta.pages:this.page,
        pageSize:this.pageSize,
        search: this.search,
        filter: this.filter
      }
      const response = await api.getModels(data)
      this.models = response.data.data
      this.meta = response.data.meta
      console.log(this.models,"this.models")
    },
    deleteModel(id){
      api.deleteModel(id).then(() => {
        this.getModels()
      })
     
      
    }
      
  },
  watch: {
    shopInfo() {
      /*
      let data = {
        shop_id: this.shopInfo.id,
      }
      */
      this.getModels()
      //this.getAdminsSetting(data);
      //this.getUserNotices(data);
      //this.getShopNotices(data);
      //this.getUserNotifications();
      //this.getShopNotices(data)
      //  this.getSetting()
    },
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>

</style>
