import axios from 'axios'


export const CATEGORIES = `${process.env.VUE_APP_LINK}/api/v1/categories`;

export default {
    updateCategorySort(data) {
        return axios.post(`${CATEGORIES}/sort`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    createCategory(data) {
        return axios.post(`${CATEGORIES}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateCategory(id, data) {
        return axios.put(`${CATEGORIES}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    deleteCategory(id) {
        return axios.delete(`${CATEGORIES}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
}