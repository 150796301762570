<template>
  <section class="authorization registration">
    <div class="authorization__title registration__title">
      Регистрация
    </div>
    <div class="registration__subtitle">
      * обозначены поля, обязательные для заполнения
    </div>
    <div class="authorization__inputs registration__inputs">
      <AppInput class="authorization__input">
        <template v-slot:title>
                    <span class="input__title">
                        ФИО*
                    </span>
        </template>
      </AppInput>
      <AppInput class="authorization__input"
                :placeholder="'+7 (_ _ _) _ _ - _ _ - _ _'"
      >
        <template v-slot:title>
                    <span class="input__title">
                        Телефон*
                    </span>
        </template>
      </AppInput>
      <AppInput class="authorization__input">
        <template v-slot:title>
                    <span class="input__title">
                        E-mail*
                    </span>
        </template>
      </AppInput>
    </div>
    <AppCheckbox class="authorization__checkbox">
            <span>
                Я даю согласие на 
                <a class="authorization__checkbox_href">
                    обработку персональных данных
                </a>
            </span>
    </AppCheckbox>
    <AppButton
        class="authorization__button"
        :styleButton="'_red'">
      Зарегистрироваться
    </AppButton>
    <svg class="registration__img" width="94" height="20" viewBox="0 0 94 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 19.7842V5.97126H4.96403V19.7842H0Z" fill="white"/>
      <path
          d="M13.3228 1.36694L17.0998 14.3526H17.2437L21.0207 1.36694H26.6682L20.589 19.7842H13.7545L7.67536 1.36694H13.3228Z"
          fill="white"/>
      <path d="M28.5139 19.7842V5.97126H33.478V19.7842H28.5139Z" fill="white"/>
      <path
          d="M44.2446 5.97126V9.56838H35.1439V5.97126H44.2446ZM36.9065 2.66191H41.8705V15.3418C41.8705 15.5336 41.9035 15.6955 41.9694 15.8274C42.0354 15.9533 42.1373 16.0492 42.2752 16.1151C42.4131 16.1751 42.5899 16.2051 42.8058 16.2051C42.9556 16.2051 43.1295 16.1871 43.3273 16.1511C43.5312 16.1151 43.6811 16.0852 43.777 16.0612L44.4964 19.5504C44.2746 19.6163 43.9568 19.6973 43.5432 19.7932C43.1355 19.8891 42.6499 19.9521 42.0863 19.9821C40.9472 20.042 39.991 19.9251 39.2176 19.6313C38.4442 19.3316 37.8627 18.861 37.473 18.2195C37.0833 17.578 36.8945 16.7746 36.9065 15.8094V2.66191Z"
          fill="white"/>
      <path
          d="M46.2702 19.7842V5.97126H51.0904V8.59716H51.2343C51.4861 7.62594 51.8847 6.91551 52.4303 6.46586C52.9819 6.01622 53.6264 5.7914 54.3638 5.7914C54.5796 5.7914 54.7924 5.80939 55.0023 5.84536C55.2181 5.87534 55.4249 5.9203 55.6228 5.98025V10.2159C55.377 10.1319 55.0742 10.069 54.7145 10.027C54.3548 9.98505 54.046 9.96407 53.7882 9.96407C53.3026 9.96407 52.865 10.075 52.4753 10.2968C52.0916 10.5126 51.7888 10.8184 51.567 11.2141C51.3452 11.6038 51.2343 12.0624 51.2343 12.59V19.7842H46.2702Z"
          fill="white"/>
      <path d="M57.2505 19.7842V5.97126H62.2145V19.7842H57.2505Z" fill="white"/>
      <path
          d="M69.7077 12.0144V19.7842H64.7437V5.97126H69.4559V8.59716H69.5998C69.8996 7.72186 70.4272 7.03541 71.1826 6.53781C71.944 6.04021 72.8312 5.7914 73.8444 5.7914C74.8216 5.7914 75.67 6.01622 76.3894 6.46586C77.1148 6.90951 77.6754 7.52102 78.0711 8.3004C78.4727 9.07977 78.6706 9.97006 78.6646 10.9713V19.7842H73.7005V12.0144C73.7065 11.331 73.5327 10.7944 73.179 10.4047C72.8312 10.015 72.3456 9.82018 71.7221 9.82018C71.3145 9.82018 70.9577 9.91011 70.652 10.09C70.3522 10.2638 70.1214 10.5156 69.9595 10.8454C69.7977 11.1691 69.7137 11.5588 69.7077 12.0144Z"
          fill="white"/>
      <path
          d="M84.964 20C84.0827 20 83.3034 19.8562 82.6259 19.5684C81.9544 19.2746 81.4269 18.831 81.0432 18.2374C80.6595 17.6439 80.4676 16.8885 80.4676 15.9713C80.4676 15.2159 80.5965 14.5714 80.8543 14.0378C81.1121 13.4982 81.4718 13.0576 81.9335 12.7159C82.3951 12.3741 82.9317 12.1133 83.5432 11.9335C84.1607 11.7536 84.8261 11.6367 85.5396 11.5828C86.313 11.5228 86.9335 11.4509 87.4011 11.3669C87.8747 11.277 88.2164 11.1541 88.4263 10.9982C88.6361 10.8364 88.741 10.6235 88.741 10.3598V10.3238C88.741 9.96407 88.6031 9.68829 88.3273 9.49644C88.0516 9.30459 87.6979 9.20867 87.2662 9.20867C86.7926 9.20867 86.4059 9.31359 86.1061 9.52342C85.8124 9.72726 85.6355 10.042 85.5755 10.4677H81.0072C81.0672 9.62833 81.3339 8.85495 81.8076 8.14752C82.2872 7.43409 82.9856 6.86455 83.9029 6.43889C84.8202 6.00723 85.9652 5.7914 87.3381 5.7914C88.3273 5.7914 89.2146 5.90831 90 6.14212C90.7854 6.36994 91.4538 6.69069 92.0054 7.10435C92.557 7.51203 92.9766 7.99164 93.2644 8.5432C93.5582 9.08877 93.705 9.68229 93.705 10.3238V19.7842H89.0648V17.8418H88.9568C88.6811 18.3574 88.3453 18.774 87.9496 19.0918C87.56 19.4095 87.1133 19.6403 86.6097 19.7842C86.1121 19.9281 85.5636 20 84.964 20ZM86.5827 16.8705C86.9604 16.8705 87.3142 16.7926 87.6439 16.6367C87.9796 16.4809 88.2524 16.256 88.4622 15.9623C88.6721 15.6685 88.777 15.3118 88.777 14.8921V13.741C88.6451 13.795 88.5042 13.846 88.3543 13.8939C88.2104 13.9419 88.0546 13.9868 87.8867 14.0288C87.7248 14.0708 87.551 14.1098 87.3651 14.1457C87.1853 14.1817 86.9964 14.2147 86.7986 14.2446C86.4149 14.3046 86.1001 14.4035 85.8543 14.5414C85.6145 14.6733 85.4347 14.8382 85.3148 15.036C85.2008 15.2279 85.1439 15.4437 85.1439 15.6835C85.1439 16.0672 85.2788 16.361 85.5486 16.5648C85.8183 16.7686 86.1631 16.8705 86.5827 16.8705Z"
          fill="white"/>
      <path
          d="M2.44604 4.53237C1.77458 4.53237 1.19904 4.31055 0.719424 3.86691C0.239808 3.42326 0 2.88969 0 2.26619C0 1.64269 0.239808 1.10911 0.719424 0.665467C1.19904 0.221822 1.77458 0 2.44604 0C3.1235 0 3.69904 0.221822 4.17266 0.665467C4.65228 1.10911 4.89209 1.64269 4.89209 2.26619C4.89209 2.88969 4.65228 3.42326 4.17266 3.86691C3.69904 4.31055 3.1235 4.53237 2.44604 4.53237Z"
          fill="#FF0000"/>
      <path
          d="M2.44604 4.53237C1.77458 4.53237 1.19904 4.31055 0.719424 3.86691C0.239808 3.42326 0 2.88969 0 2.26619C0 1.64269 0.239808 1.10911 0.719424 0.665467C1.19904 0.221822 1.77458 0 2.44604 0C3.1235 0 3.69904 0.221822 4.17266 0.665467C4.65228 1.10911 4.89209 1.64269 4.89209 2.26619C4.89209 2.88969 4.65228 3.42326 4.17266 3.86691C3.69904 4.31055 3.1235 4.53237 2.44604 4.53237Z"
          fill="#FF0000"/>
      <path
          d="M2.44604 4.53237C1.77458 4.53237 1.19904 4.31055 0.719424 3.86691C0.239808 3.42326 0 2.88969 0 2.26619C0 1.64269 0.239808 1.10911 0.719424 0.665467C1.19904 0.221822 1.77458 0 2.44604 0C3.1235 0 3.69904 0.221822 4.17266 0.665467C4.65228 1.10911 4.89209 1.64269 4.89209 2.26619C4.89209 2.88969 4.65228 3.42326 4.17266 3.86691C3.69904 4.31055 3.1235 4.53237 2.44604 4.53237Z"
          fill="#FF0000"/>
      <path
          d="M2.44604 4.53237C1.77458 4.53237 1.19904 4.31055 0.719424 3.86691C0.239808 3.42326 0 2.88969 0 2.26619C0 1.64269 0.239808 1.10911 0.719424 0.665467C1.19904 0.221822 1.77458 0 2.44604 0C3.1235 0 3.69904 0.221822 4.17266 0.665467C4.65228 1.10911 4.89209 1.64269 4.89209 2.26619C4.89209 2.88969 4.65228 3.42326 4.17266 3.86691C3.69904 4.31055 3.1235 4.53237 2.44604 4.53237Z"
          fill="#FF0000"/>
      <path
          d="M31.0027 4.53237C30.3312 4.53237 29.7557 4.31055 29.2761 3.86691C28.7964 3.42326 28.5566 2.88969 28.5566 2.26619C28.5566 1.64269 28.7964 1.10911 29.2761 0.665467C29.7557 0.221822 30.3312 0 31.0027 0C31.6801 0 32.2557 0.221822 32.7293 0.665467C33.2089 1.10911 33.4487 1.64269 33.4487 2.26619C33.4487 2.88969 33.2089 3.42326 32.7293 3.86691C32.2557 4.31055 31.6801 4.53237 31.0027 4.53237Z"
          fill="#37F3FF"/>
      <path
          d="M59.5593 4.53237C58.8879 4.53237 58.3123 4.31055 57.8327 3.86691C57.3531 3.42326 57.1133 2.88969 57.1133 2.26619C57.1133 1.64269 57.3531 1.10911 57.8327 0.665467C58.3123 0.221822 58.8879 0 59.5593 0C60.2368 0 60.8123 0.221822 61.2859 0.665467C61.7656 1.10911 62.0054 1.64269 62.0054 2.26619C62.0054 2.88969 61.7656 3.42326 61.2859 3.86691C60.8123 4.31055 60.2368 4.53237 59.5593 4.53237Z"
          fill="#33DB18"/>
    </svg>
  </section>
</template>

<script>
export default {
  name: 'PageRegistration',
  created() {
    if (localStorage.getItem('token')) {
      this.$router.replace("/login");
    }
  },
}
</script>

<style lang="scss" scoped>
.authorization {
  min-height: 100vh;
  background: #20A0FF;

  color: rgba(255, 255, 255, 0.75);

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 0 30px;

  width: 100%;
  height: 100%;
}

.registration__img {
  margin-top: 70px;
}

.authorization__title {
  color: #FFFFFF;

  font-size: 24px;
  font-weight: 500;

  text-align: center;
}

.registration__title {
  margin-bottom: 15px;
}

.registration__subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.75);
  width: 260px;
  margin-bottom: 30px;
}

.registration__inputs {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .input__title {
    color: rgba(255, 255, 255, 0.75);
  }

  width: 260px;
}

.authorization__button {
  font-size: 16px;

  padding: 10px 16px;
  margin-top: 24px;

  width: auto;
}

.authorization__checkbox {
  margin: 25px auto 0;

  max-width: 210px;

  span {
    color: rgba(255, 255, 255, 0.5);
  }

  .authorization__checkbox_href {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.authorization__message {
  font-size: 13px;

  text-align: center;

  margin-top: 60px;
}
</style>