<template>
   
      <AppButton
            @click="handleCheckboxChange"
           class="custom-button"
            :styleButton="'_blue-external'"
        >
          {{ element.name }} <span v-if="checkboxState" class="right-element">✅</span>
        </AppButton>

  </template>
  
  <script>
  export default {
    name: "MaillingElementBot",
    data() {
      return {
        checkboxState: false,
      };
    },
    props: {
      element: [Object],
      selectedShops: [Array], // Принимаем массив выбранных элементов
    },
    created() {
      // Проверяем, есть ли element.id в массиве selectedShops
      if (Array.isArray(this.selectedShops) && this.selectedShops.includes(this.element.id)) {
        this.checkboxState = true;
      }
    },
    methods: {
      handleCheckboxChange() {
        // Инвертируем текущее состояние чекбокса
        this.checkboxState = !this.checkboxState;
  
        if (this.checkboxState) {
          // Если чекбокс включен, добавляем shop.id в массив
          this.$emit("addToSelected", this.element.id);
        } else {
          // Если чекбокс выключен, удаляем shop.id из массива
          this.$emit("removeFromSelected", this.element.id);
        }
      },
    },
    watch: {
      selectedShops: {
        handler() {
          if (Array.isArray(this.selectedShops) && this.selectedShops.includes(this.element.id)) {
            this.checkboxState = true;
          } else {
            this.checkboxState = false;
          }
        },
        deep: true,
      },
    },
  };
  </script>
  
  <style scoped>

.right-element {
  position: absolute;
  right: 0;
  font-size: 20px;
  margin-right: 5px;
  transform: translateY(-50%);

}
.custom-button {
  position: relative;

  width: calc(30% - 10px); /* 20% ширины контейнера с небольшим отступом */
  margin: 5px; /* Отступ между кнопками */
  height: 70px;
  box-sizing: border-box; /* Учесть отступ в расчетах ширины */
  padding: 10px; /* Убираем внутренние отступы */
  text-align: center; /* Выравнивание текста по центру */


}
  .about-setting {
    width: 23%;
    height: 100px; /* Примерная высота дочерних элементов */
    margin-left: 10px;
    box-sizing: border-box; /* Учтем границу в расчете ширины элемента */
 
  }
  
  .mailing-item_cell:first-child {
    width: 78px;
  }
  
  .cell-header {
    display: flex;
    align-items: start;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.5);
    margin: 0 10px 10px 0;
  }
  
  .cell-header svg {
    margin-right: 5px;
  }
  
  .cell-value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.75);
  }
  
  .mailing-item_top, .mailing-item_bottom {
    display: flex;
  }
  
  .mailing-item_bottom {
    border-top: 1px solid #C3CDD5;
    margin-top: 10px;
    padding-top: 10px;
  }
  
  </style>