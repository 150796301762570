<template>
    <div class="container">
        <section class="setting">
            <AppSettingNavigation></AppSettingNavigation>
            <div class="stock-setting">
                <AppSectionTitle>
                  Акции
                </AppSectionTitle>
                <ul class="delivery-system__list">
                    <li v-for="(item, i) in allStocks"
                        :key="item.id">
                    <AppBottomSheet
                        @updateStocks="updateStocks"
                        :name="item.name"
                        v-model="item.status"
                        :prompt="testPrompts[i]"
                        @selectCurrent="function(state){openCurrent(i, state)}"
                        :open="setOpen[i]"
                    >
                        <AppStock :element="item" @updateStatus="updateStatus"/>
                    </AppBottomSheet>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import AppStock from "@/pages/setting/Modules/Stock/AppStock"
import { mapActions, mapGetters } from 'vuex'; 
export default {
    name: "PageStockSetting",
    data() {
        return {
            testPrompts: [/*'Yookassa', 'Card'*/],
            setOpen: [],
        }
    },

    computed:{
        ...mapGetters(['allStocks', 'shopInfo', 'allNameplates']),
    },
    components: { AppStock },
    mounted(){
        this.getShopInfo()
    },
    methods: {
        updateStocks(){
            this.getStocks({shop_id: this.shopInfo.id})
        },
        updateStatus(element){
            this.allStocks.find(item => item.id == element.id).status = element.status;
        },
        openCurrent(i, state) {
          this.setOpen.forEach((im, i, arr)=>arr[i]=false);
          this.setOpen[i]=!state
        },
        setDescription(description){
            this.editItem.data.editDescription = description
        },
         ...mapActions(['getStocks', 'getNameplates', 'getShopInfo']),
        
    }, 
    watch: {
        shopInfo(){
            this.getStocks({shop_id: this.shopInfo.id})
            this.getNameplates({shop_id: this.shopInfo.id})
        },
    }
}
</script>

<style lang="scss" scoped>
    .stock-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .stock-setting__nameplate {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-bottom: 20px;
    }
    .stock-setting__form_add {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 12px 10px 16px;
        margin-bottom: 10px;
    }
    .stock-setting__banner {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        
        margin-bottom: -50px;
    }
    .stock-setting__item_checkbox {
        align-self: flex-start;

        display: inline-block;
    }
    .stock-setting__amount-discount {
        max-width: 45%;
    }
    
    .stock-setting_radio-top {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 20px;
        margin-bottom: 4px;
    }
    .stock-setting_radio-setting {
        display: flex;
        justify-content: space-between;
        max-width: 280px;
    }
    .stock-setting__stock {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .stock-setting__stock_item {
        display: flex;
        column-gap: 3px;
        height: 40px;
    }
    .stock-setting__stock_button {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        span {
            font-size: 16px;
        }
    }
</style>