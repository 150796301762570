<template>
    <AppPage/>
</template>

<script>
import AppPage from '@/components/AppPage';

export default {
  name: 'App',
  components: {
    AppPage
  },
}
</script>

<style lang="scss">
  @import '@/style/style.scss';
   #app {
    background: var(--global_body_background);
    transition: var(--transition-default);
    min-height: 100vh;
  }
</style>
