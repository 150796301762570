<template>
    <ul class="orders">
        <li class="order block-style"
        v-for="order in orders"
        :key="order.id">
            <ul class="order__short-info">
                <li class="order__short-info_item flex flex-column row-4">
                  <div class="container-created_at justify-content-center">
                    <span class="flex flex-column row-4">
                      <AppTitleSmall class="order__title-small">
                        {{ order.created_at }}
                    </AppTitleSmall>
                      <div class="order__id">
                        № {{ order.id }}
                        <AppIconAttention class="node-order"
                        v-if="getAttentionStatus(order)"
                                :message="getAttentionStatus(order)"
                    />
                      </div>
                    </span> 
                  
                  </div>
                </li> 
                <li class="order__short-info_item flex flex-column row-4">
                    <AppTitleSmall class="order__title-small">
                    </AppTitleSmall>
                    <date class="order__pay"  v-if="order.type_id==1">
                        {{ order.pay_status.title }}
                    </date>
                </li>
                <li    class="order__short-info_item flex flex-column row-4">
                    <AppTitleSmall class="order__title-small">
                    </AppTitleSmall>
                    <div class="order__payment">
                            {{  (order.type.id==2)?order.status.title_s:order.status.title }}
                    </div>
                </li>
           
            </ul>
            <!--
            <div class="order__status flex flex-column row-4">
                <AppTitleSmall class="order__title-small">
                    Статус работы
                </AppTitleSmall>
                <AppSelect
                :disabled="(order.status_id==3 ||order.status_id==4 )?true:false"
                @updateC="updateOrder(order)"
                v-model="order.status_id"
                :items="getstatusDelivery(order.status_id)"></AppSelect>
            </div>
    -->
            <!--
            <div v-if="order.data" class="order__address flex flex-column row-4">
                <AppTitleSmall class="order__title-small">
                    Адрес заказа 
                </AppTitleSmall>
                <span>
                    {{order.data.shop_delivery.title}}: 
                   {{ order.data.address}}
                </span>
            </div>
            -->
       
            <div class="order__interaction">
                <div  v-if="order.type_id==1" class="order__interaction_text flex flex-column row-4">
                    <AppTitleSmall  class="order__title-small">
                        Сумма заказа:
                    </AppTitleSmall>
                    <AppProductPrice   v-if="order.type_id==1" class="order__interaction_text-span order__price">
                        {{ order.sum }} руб.
                    </AppProductPrice>
                </div>
                <div  v-if="order.type_id==2" class="order__interaction_text flex flex-column row-4">
                    <AppTitleSmall  class="order__title-small">
                      Заявка
                    </AppTitleSmall>
                    <AppProductPrice  class="order__interaction_text-span order__price">
                        {{ order.products[0].product_name }} 
                    </AppProductPrice>
                </div>
                <router-link
                :to="{name: 'PageOrderEdit', params: {id: order.id, shop_id:order.shop_id, editpod:JSON.stringify(order), backUrl:backUrl}}">
                    <AppButton class="order__interaction_button"
                    :styleButton="'_blue-external'">
                        Подробнее
                    </AppButton>
                </router-link>
            </div>
        </li>
    </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "AppOrders",
    data() {
        return {
            statusDelivery: [
                {
                    id: 1,
                    name: 'Оформлен'
                },
                {
                    id: 2,
                    name: 'Доставка'
                },
                {
                    id: 3,
                    name: 'Завершен'
                },
                {
                    id: 4,
                    name: 'Отменен'
                }
            ]
        }
    },
    props: {
        orders: {
            type: Array,
            required: true
        },
        backUrl: {
            type: String,
            default: "/order"
        }
    },
     computed: {
         ...mapGetters([]),
      
    }, 
    mounted(){
    },
    methods:{
         ...mapActions(['asyncUpdateOrder']),

         getAttentionStatus(order){
           // console.log(order)
            //Заказ отменен завершен
            if(order.status_id==4 || order.status_id==3) return false;
            //Заказ по счету не оплачен
            if(order.payment_system_id==4 && order.type_id==1 && order.pay_status_id==1) return "Необходимо выставить счет";
            //Заказ по счету не отправлен
            if(order.payment_system_id==5 && order.type_id==1 && order.pay_status_id==1) return "Необходимо отправить заказ";
             //Заказ оплачен не доставлен
            if(order.pay_status_id==2 && order.type_id==1 && order.status_id==1) return "Необходимо отправить заказ";
            return false
         
         },
         getstatusDelivery(status_id){
                return this.statusDelivery.filter(e=>e.id>=status_id)
         },
         
         updateOrder(order){
            this.asyncUpdateOrder(order)
         }
    }
}
</script>

<style lang="scss" scoped>
    .container-created_at {
      display: flex;
      align-items: center;
    }
    .node-order {
      margin-left: 5px;
    }
    .orders {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }
    .order {
        color: var(--global_second_color);
        font-size: 12px;
        padding: 10px 13px 16px;
        & > * {
            border-top: 1px solid var(--global_block_line);
            padding-top: 10px;
            margin-top: 10px;
            &:first-child {
                border-top: 0;
                padding-top: 0;
                margin-top: 0;
            }
        }
        &:last-child {
            .order__interaction::after {
                content: none;
            }
        }
    }
    .order__short-info {
        display: grid;
        align-items: start;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        .order__price {
            font-size: 12px;
            text-align: right;
        }
    }
    .order__id {
        color: var(--default_background);
        font-size: 14px;
        font-weight: 500;
    }
    .order__title {
        color: var(--global_second_color);
        font-size: 14px;
    }
    .order__title-small {
        font-size: 12px;
    }
    .order__additional-info_span {
        font-size: 12px;
    }
    .order__interaction_text-span  {
        font-size: 12px;
    }
    .order__interaction {
        display: flex;
        justify-content: space-between;
    }
    .order__interaction_text {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }
    .order__interaction_button {
        width: 80px;
        height: 30px;
    }
</style>