<template>
  <div class="chat-create" style="margin-left: 10px;">
    <div class="date-naw">{{ element.date }}</div>
      <div class="header-new-mailing" style="margin-left: 5px; margin-right: 5px;">
        <span>{{(element.id)?"Редактировать "+element.name:"Создание новой рассылки"}} </span>

        <svg @click="close" style="margin-right: 10px;"
            width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z"
                fill="#8EA2B0"/>
        </svg>
      </div> 
    
      <MaillingElementCreate v-if="step==1  && (element.message.text ||  this.$route.params.id=='create')"
        :element="element" 
        :update="update"
        :shopInfo="shopInfo"
        :availableShops="availableShops"
        @searchShops="searchShops" 
        @goStep = "goStep"
        @next = "step2"
        @prefSave="prefSave"
        />  
        <MaillingElementFilter  v-if="step==2"
          :element="element" 
          @setRecipients="setRecipients"
          @prev = "step1"
          @next = "step3"
          @goStep = "goStep"
          @filter = "setFilter"
          @prefSave="prefSave"
        />
        <MaillingElementSelectBots  v-if="step==3"
          :element="element" 
          @prev = "step1"
          @next = "step3"
          @goStep = "goStep"
          @shop_ids = "setShopIds"
          @prefSave="prefSave"
        />
        <MaillingElementSetting  v-if="step==4"
          :element="element" 
          @prev = "step1"
          @next = "step3"
          @goStep = "goStep"
          @params = "setParams"
          @prefSave="prefSave"
        />
        <MaillingElementSend  v-if="step==5"
          :element="element" 
          @prev = "step2"
          @next = "step3"
          @save="save"
          :blockButton="blockButton"
          :sendShops="sendShops"
          @goStep = "goStep"
          @prefSave="prefSave"
          @send_date="setSendDate"
        />
  </div>
</template>

<script>
import api from "./api"

import { mapActions, mapGetters } from 'vuex';
import MaillingElementSend from "./modules/MaillingElementSend"
import MaillingElementSelectBots from "./modules/MaillingElementSelectBots"
import MaillingElementCreate from "./modules/MaillingElementCreate"
import MaillingElementSetting from "./modules/MaillingElementSetting"
import MaillingElementFilter from "./modules/MaillingElementFilter"
//import AppButtons from "@/components/AppButtons"
export default {
  name: "MaillingElement",
  props: {},
  components: { MaillingElementCreate, 
                MaillingElementSelectBots, 
                MaillingElementSend,
                MaillingElementSetting,
                MaillingElementFilter
              },//AppButtons,
  data() {
    return {
      tasks:[],
      
        types: [
            {
              "name":"Стандартная",
              "id":"std"
            },
            {
              "name":"Задание",
              "id":"task",
            },
        ],
      sendShops: [],
      shop_id: localStorage.getItem('shop_id'),
      step: 1,
      availableShops: [],
      element: {"name":"",message:{"text":"", media:null, media_type:null},bots:[], buttons:[]},
      blockButton:false,
    }
  }, 
  computed: {
    ...mapGetters(['shopInfo']),
  },
  async mounted(){
    this.getShopInfo();
    this.updateURL();
   
        
  },
  beforeRouteUpdate(to, from, next) {
    this.updateURL(to)
    console.log(to, from,"beforeRouteUpdate")
    
    next();
    
  },
  methods: {
    ...mapActions(['getShopInfo']),
    setSendDate(value){
      this.element.send_date = value
    },
    setRecipients(recipients){
      this.element.recipients = recipients
    },
    goStep(id){
        this.step = id
    },
    setParams(value){
      this.element.params = value
    },
    setFilter(value){
        this.element.filter = value
    },
    setShopIds(value){
        this.element.shop_ids = value
    },
    getModel(id){
      api.getMailling(id).then((response) => {
                this.element = response.data.data
                this.element.testStatus = true
                this.element.availableShop_id = localStorage.getItem('shop_id')
                this.searchShops(this.element.availableShop_id)
           
              }).catch(() => {
                this.close()
              })
    },
    
    async updateURL(to){
     
      let id = this.$route.params.id
      let type = this.$route.params.type
      if (to)  id = to.params.id
      if (to)  type = to.params.type
     
      if (id=="create") {
          
            this.element.type = type
            this.element.filter = 'auth'
            this.element.params = []
            this.element.shop_ids = []
            this.element.availableShop_id = localStorage.getItem('shop_id')
            this.searchShops(this.element.availableShop_id)
            return
        }
        else {
            await this.getModel( id );
            this.getAvailableShops()
        }

        if (!this.$route.params.id) {window.location.href="/"}
    },
    prefSave(){
      if (this.element.id) {
        api.updateMailing(this.element.id, this.element).then(() => {
          this.showMessageNotice('Ошибка 09',"Рассылка сохранена",'success');
          }).catch((error) => {
            this.showMessageNotice('Ошибка 09',error.response.data.message,'error');
      })
        return
      }
      api.prefSaveMailing(this.element).then((response) => {
       
        this.$router.push({ name: "MaillingElement", params: { id: response.data.mailling.id, type:response.data.mailling.type}})
          }).catch((error) => {
            this.showMessageNotice('Ошибка 09',error.response.data.message,'error');
      })
    },
    close(){
      this.$router.push({ name: "MaillingList"})
    },
    searchShops(name){
      this.getAvailableShops(name)
    },
    async getAvailableShops(name){
      const response = await api.getAvailableShops({"search":name})
      this.availableShops = response.data.data
    },
    
    save(status, date){
        this.blockButton = true
          //Есть изображение
          if (this.element.message.media)
            this.element.message.media = this.element.message.media.id
          this.element.status = status
          this.element.send_date = date
          if (this.element.id) { 
              api.updateMailing( this.element.id,  this.element).then(() => {
                this.close()
              }).catch((error) => {
                this.showMessageNotice('Ошибка 09',error.response.data.message,'error')
              })
          }
          else  
              api.createMailing(this.element).then(() => {
                  this.close()
                }).catch((error) => {
                  this.showMessageNotice('Ошибка 09',error.response.data.message,'error')
                })
      }, 
  },
  inject: ['showMessageNotice'],
}

</script>

<style scoped>
.horisontal-ul li {
   display: inline-block;
   margin-left: 20px;
}
.date-naw {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.about-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 40px 12px;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

</style>