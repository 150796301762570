<template>
    <div>
        <AppButtonAdd 
            @click="this.showAddressForm = !this.showAddressForm">
            Добавить новый пункт 
        </AppButtonAdd>
        <div
            class="address__form"
            v-if="showAddressForm">
            <AppSelectSearch
                v-model="pickup.city_id"
                @click="updatePickupCity(pickup)"
                @updateList="updateList"
                :items="allCities">
                <template v-slot:title>
                    Город
                </template> 
            </AppSelectSearch>
            <!--
            <span v-if="pickup.city_id===0 && letter.length>1" style="color:red; font-size:12px; margin-top:-5px">Населенный пункт отсутвует в списке. Он будет добавлен после нажатия сохранить</span>
            -->
            <AppInputForm class="address__street_input"
                        v-model="pickup.street"
                        :typeInput="'string100'">
            <template v-slot:title>
                <AppTitleSettingParam>
                Улица
                </AppTitleSettingParam>
            </template>
            </AppInputForm>
            <div class="address__street-house">
                <AppInputForm class="address__street_house"
                    v-model="pickup.house"
                                :typeInput="'integer'"
                >
                    <template v-slot:title>
                        <AppTitleSettingParam>
                            Дом
                        </AppTitleSettingParam>
                    </template>
                </AppInputForm>
                <AppInputForm class="address__street_house"
                    v-model="pickup.building"
                          
                >
                    <template v-slot:title>
                        <AppTitleSettingParam>
                            Корпус
                        </AppTitleSettingParam>
                    </template>
                </AppInputForm>
                <AppInputForm  v-model="pickup.home" class="address__office_input">
                <template v-slot:title>
                    <AppTitleSettingParam>
                    Офис
                    </AppTitleSettingParam>
                </template>
                </AppInputForm>
            </div>
            <AppInputForm class="address__street_house"
                        v-model="pickup.phone"
            >
            <template v-slot:title>
                <AppTitleSettingParam>
                Телефон
                </AppTitleSettingParam>
            </template>
            </AppInputForm>
            <AppInputForm class="address__street_house"
                        v-model="pickup.time_work"
            >
            <template v-slot:title>
                <AppTitleSettingParam>
                Время работы
                </AppTitleSettingParam>
            </template>
            </AppInputForm>
            <AppCheckbox  v-model="pickup.status">
            {{ pickup.status ? 'Выключить' : 'Включить' }}
            </AppCheckbox>
            <div class="address__office-button">
           
            <AppButtonSettingSave
                @click="save()"
                class="address__office_button"/>
            </div>
        </div>
    </div>
</template>

<script>
import api from './api'
import { mapGetters } from 'vuex';
export default {
    name: "DeliveryPickup",
    data() {
        return {
            pickup: {status:true},
            showAddressForm: false,
        };
    },
    props: {
        city_id: {
            type: [String, Number],
            default: "",
    },
    shop_delivery_id: {
            type: [Number],
        },
    },
    computed: mapGetters(['allCities']),
    mounted(){
        this.pickup = {status:true}
        this.pickup.city_id = this.city_id
    },
    methods: {
        updateList(items, letter){
            this.letter = letter
        },
        save(){
            console.log(this.pickup.city_id)
            if (this.pickup.city_id===0)  this.pickup.new_city = this.letter
            if (!this.pickup.city_id && !this.pickup.new_city) { this.showMessageNotice('', "Укажите город",'error'); return;}
            if (!this.pickup.street) { this.showMessageNotice('', "Укажите улицу",'error'); return;}
            if (!this.pickup.house) { this.showMessageNotice('', "Укажите дом",'error'); return;}
            this.pickup.shop_delivery_id = this.shop_delivery_id
            api.createDeliveryPickup(this.pickup).then((response) => {
              if (response.data.message){
                this.pickup = response.data.data
                this.$emit('addPickup',this.pickup)
                this.showAddressForm = false
                this.pickup = {city_id:this.pickup.city_id,status:true}
                this.showMessageNotice('', response.data.message,'success')
              }
              }).catch((error) => {
                  if (error.response.data.message)
                    this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
              })
        },
        updatePickupCity(){
            let city_id = this.pickup.city_id
            this.pickup.city = this.allCities.find(elem => elem.id==city_id);
        },
    }, 
    watch:{
        city_id(city_id){
            this.pickup.city_id = city_id
        }
    },
    inject: ['showMessageNotice'],
}
</script>
<style lang="scss" scoped>
    .delivery-setting {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        margin-bottom: 20px;
    }

    .delivery-setting__title {
        margin-bottom: 6px;
    }

    .delivery-setting__item {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .delivery-setting__items {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .delivery-setting__item_button {
        display: flex;
        column-gap: 10px;

        padding: 10px 15px;

        span {
            font-size: 16px;
        }
    }

    .delivery-setting__item_edit {
        margin-left: auto;
    }

    .delivery-setting__item_checkbox {
        align-self: flex-start;

        display: inline-block;
    }

    .delivery-setting__item_title {
        margin-top: 24px;
        margin-bottom: 15px;
    }

    .delivery-setting__items {
        display: flex; 
        flex-direction: column;
        row-gap: 8px;
    }

    .address {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        margin-bottom: 16px;
    }

    .address__button {
        display: flex;
        column-gap: 10px;

        padding: 12px;

        height: 40px;

        span {
            font-size: 12px;
        }

        &._active {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .address__svg {
        margin-left: auto;
    }

    .address__form {
        background: #FFFFFF;

        border: 1px solid #E4E7ED;
        border-top: 0;
        border-radius: 6px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

        display: flex;
        flex-direction: column;
        row-gap: 12px;

        padding: 16px 16px 19px;
    }

    .address__form-add {
        @extend .address__form;

        border-top: 1px solid #E4E7ED;
        border-radius: 6px;

        margin-top: 12px;
    }

    .address__street-house {
        display: flex;
        column-gap: 10px;
    }

    .address__street_input {
        flex: 1 1 auto;
    }

    .address__street_house {
        flex: 0 0 24%;
    }

    .address__office-button {
        display: flex;
        column-gap: 10px;
    }

    .address__office_input {
        flex: 0 0 35%;
    }

    .address__office_button {
        flex: 0 0 50%;

        margin: 0;
        margin-left: auto;
    }
    
    .address__office-button {
        display: flex;
        align-items: flex-end;
    }

    .delivery-setting__item_pickup {
        margin-bottom: 16px;
    }

    .delivery-setting__item_form {
        display: flex;
        flex-direction: column;
        row-gap: 18px;

        margin-bottom: 26px;
    }

    .delivery-setting__item_flex {
        display: flex;
        align-items: end;
        column-gap: 12px;
    }

    .delivery-setting__form {
        background: #FFFFFF;

        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

        border-radius: 6px;

        display: flex;
        flex-direction: column;
        row-gap: 20px;

        padding: 24px 16px 16px;
    }

    .delivery-setting__form_price {
        display: flex;
        align-items: center;
        column-gap: 20px;

        * {
            flex: 1 1 40%;
        }
    }

    .delivery-setting__form_checkbox {
        margin-top: 18px;
    }

    .catalog-setting__textarea {
        height: 64px;
    }
</style>