<template>
    <div class="container">
        <section class="setting">
            <AppSettingNavigation></AppSettingNavigation>
            <div class="catalog-setting">
                <AppSectionTitle class="catalog-setting__title">
                    Настройки категорий
                </AppSectionTitle>
                <draggable class="button-system__list" :list="allCategories" @end="onDragEnd">
                    <template #item="{ element }">
                        <div style="display:flex; margin-bottom: 7px;">
                            <AppButton
                            class="catalog-setting__category_button"
                            @click="Name(element)">
                                <span>
                                    {{ element.name }}
                                </span>
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0751 0.0446824C13.13 0.0707281 13.8029 0.721463 14.5705 1.49079C15.8074 2.7306 15.9686 2.90323 15.9881 3.00982C16.0366 3.27372 16.0016 3.32131 15.0771 4.24969L14.2209 5.10954L12.5555 3.44466L10.8902 1.77977L11.7186 0.950621C12.214 0.454847 12.5974 0.0965548 12.6721 0.0594406C12.8219 -0.0148505 12.9405 -0.0191966 13.0751 0.0446824ZM11.8905 4.12466L13.5475 5.78185L9.65482 9.6746C7.51386 11.8156 5.74813 13.5673 5.73097 13.5673C5.6901 13.5673 2.43211 10.3097 2.43211 10.2688C2.43211 10.238 10.187 2.46746 10.2177 2.46746C10.2264 2.46746 10.9792 3.21318 11.8905 4.12466ZM4.90933 14.1337C4.85552 14.1832 0.618674 15.9788 0.516587 15.9954C0.243248 16.0398 -0.0400022 15.7549 0.00467874 15.4806C0.0149969 15.4171 0.418814 14.4436 0.90205 13.3172C1.38529 12.1908 1.80111 11.2205 1.82612 11.161L1.87159 11.0529L3.40181 12.5828C4.24346 13.4243 4.92183 14.1222 4.90933 14.1337Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0751 0.0446824C13.13 0.0707281 13.8029 0.721463 14.5705 1.49079C15.8074 2.7306 15.9686 2.90323 15.9881 3.00982C16.0366 3.27372 16.0016 3.32131 15.0771 4.24969L14.2209 5.10954L12.5555 3.44466L10.8902 1.77977L11.7186 0.950621C12.214 0.454847 12.5974 0.0965548 12.6721 0.0594406C12.8219 -0.0148505 12.9405 -0.0191966 13.0751 0.0446824ZM11.8905 4.12466L13.5475 5.78185L9.65482 9.6746C7.51386 11.8156 5.74813 13.5673 5.73097 13.5673C5.6901 13.5673 2.43211 10.3097 2.43211 10.2688C2.43211 10.238 10.187 2.46746 10.2177 2.46746C10.2264 2.46746 10.9792 3.21318 11.8905 4.12466ZM4.90933 14.1337C4.85552 14.1832 0.618674 15.9788 0.516587 15.9954C0.243248 16.0398 -0.0400022 15.7549 0.00467874 15.4806C0.0149969 15.4171 0.418814 14.4436 0.90205 13.3172C1.38529 12.1908 1.80111 11.2205 1.82612 11.161L1.87159 11.0529L3.40181 12.5828C4.24346 13.4243 4.92183 14.1222 4.90933 14.1337Z" fill="white"/>
                                </svg>
                            </AppButton>
                            <AppButtonDelete @click="deleteCategory(element.id)"></AppButtonDelete>
                        </div>
                    </template>
                </draggable>
              
                <AppButtonAdd
                @click="this.showForm = !this.showForm">
                <span class="edit__name"> Добавить новую категорию</span>
                   
                </AppButtonAdd>
                <div
                class="catalog-setting__add-form"
                v-if="showForm">
                    <AppInputForm
                        :typeInput="'string100'"
                        v-model="categoryEditCategory.name"
                    />
                  <div style="min-width: 172px; margin: auto">
                    <AppButtonSettingSave
                        :active="!!categoryEditCategory.name"
                        @click="this.clickSave()"
                    />
                  </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import api from './Modules/Catalog/api'
export default {
    name: 'PageCatalogSetting',
    components: {draggable},
    data() {
        return {
            sort: [],
            categories: [],
            categoryEditCategory: {},
            categoryEditId: null,
            showForm: false,
        }
    },
    computed: mapGetters(['allCategories', 'shopInfo']),
    created() {
        this.getCategories({shop_id:localStorage.getItem("shop_id")});
        this.getShopInfo();
    },
    methods: {
        ...mapActions(['getCategories','getShopInfo']),
        onDragEnd() {
     
            for (let index = 0; index < this.allCategories.length; index++) {
                    this.sort[index] =  this.allCategories[index].id
            }
          
            api.updateCategorySort(this.sort).then((response)=>{
                console.log(response)
                }).catch((error)=>{
                    console.log(error)
                })  
        },
        Name(category) {
            this.categoryEditCategory = category;            
            this.showForm = true;
        }, 
        clickSave() {
          if (!this.categoryEditCategory.name) return;
            if (this.categoryEditCategory.id) {
                this.updateCategory();
                return;
            }
            
            this.createCategory();
        },
        async createCategory() {
            await api.createCategory({
                name: this.categoryEditCategory.name,
                shop_id: this.shopInfo.id
            }).then((response) => {
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                        this.allCategories.push(response.data.data)
                        localStorage.setItem('categories', JSON.stringify(this.allCategories))
                    }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                });
            this.categoryEditCategory = {};
            this.showForm = false;
        },
        async updateCategory() {
            if (this.categoryEditCategory.id) {
                let data = {
                    id: this.categoryEditCategory.id,
                    name: this.categoryEditCategory.name,
                    shop_id: this.shopInfo.id
                }
                await api.updateCategory(data.id,data).then((response) => {
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                        localStorage.setItem('categories', JSON.stringify(this.allCategories))
                    }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
           
            }
            this.categoryEditCategory = {};
            this.showForm = false;
        },
        async deleteCategory(id) {
            await api.deleteCategory(id).then((response) => {
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                        let index = this.allCategories.findIndex(e=>e.id==id)
                        this.allCategories.splice(index,1)
                        localStorage.setItem('categories', JSON.stringify(this.allCategories))
                    }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
            this.categoryEditCategory = {};
            this.showForm = false;
        }
    },
    inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .catalog-setting {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .catalog-setting__add-form {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .catalog-setting__title {
        margin-bottom: 6px;
    }

    .catalog-setting__text {
        color: #000000;

        font-size: 14px;
    }

    .catalog-setting__categories {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .catalog-setting__category {
        display: flex;
        column-gap: 3px;

        height: 40px;
    }
    .edit__name {
        color: var(--default_background);
    }
    .catalog-setting__category_button {
        display: flex;
        justify-content: space-between;
        margin-right: 5px;
        padding: 10px 15px;

        span {
            font-size: 16px;
        }
    }
</style>