<template>
  <div class="container">
    <section class="setting">

      <AppSettingNavigation></AppSettingNavigation>
      <div class="showcase-setting">
        <AppSectionTitle>
          Название и подпись магазина
        </AppSectionTitle>
        <div class="showcse__shop block-style">
          <AppInputForm
              v-model="shopInfo.name">
            <template v-slot:title>
              <AppTitleSettingParam>
                Название магазина
              </AppTitleSettingParam> 
            </template>
          </AppInputForm>
          <AppInputForm v-if="shopInfo.data"
                        v-model="shopInfo.data.sub_name">
            <template v-slot:title>
              <AppTitleSettingParam>
                Подпись магазина
              </AppTitleSettingParam>
            </template>
          </AppInputForm>
          <AppEditBannerLogo
              v-if="shopInfo.data"
              @updateBanner="updateLogo"
              :photo="shopInfo.data.icon"
              :button="'логотип'"
              :title="'Логотип'"
              :ratio = "[10, 10]"
          />
        </div>
        <AppSectionTitle>Выбор стиля</AppSectionTitle>
        <div class="showcase__style block-style" v-if="shopInfo.data">
          <AppTitleSettingParam class="showcase__style_title">
            Цвет основных элементов
          </AppTitleSettingParam>
          <div class="showcase__style_buttons">
            <AppButton
                v-for="color in getterTheme"
                :key="color.id"
                :class="color.name"
                @click="setTheme(color.name)">
              {{ color.name === shopInfo.data.theme ? 'Выбрано' : 'Выбрать' }}
            </AppButton>
          </div>
        </div>
      </div>

      <div style="max-width: 172px; margin: auto">

        <AppButtonSettingSave @click="updateShop()"></AppButtonSettingSave>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "PageShowcaseSetting",
  data() {
    return {}
  },
  created() {
    this.getShopInfo();
    this.getThemes();
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['shopInfo', 'getterTheme'])
  },
  methods: {
    ...mapActions(['getShopInfo', 'asyncUpdateShop', 'getThemes']),
    updateShop() {
      localStorage.setItem("theme", this.shopInfo.data.theme)
      this.asyncUpdateShop(this.shopInfo)
      this.showMessageNotice('','Успешно обновлено','success')
    },

    updateLogo(fileName) {
      console.log(fileName, "fileName")
      this.shopInfo.data.icon = fileName
    },
    getTheme(theme) {
      return localStorage.getItem(theme) ? localStorage.getItem(theme) : null;
    },
    setTheme(theme) {

      this.getterTheme.forEach(classCss => {
        if (document.body.classList.contains(classCss.name)) {
          document.body.classList.remove(classCss.name);
        }
      });
      document.body.classList.add(theme);
      this.shopInfo.data.theme = theme
      
      //this.showMessageNotice('','Успешно обновлено','success')
    },
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
.showcase-setting {
  margin-bottom: 15px;
}

.showcse__shop {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 15px 10px 25px;
}

.showcase__style {
  padding: 15px 10px 18px;
}

.showcase__style_title {
  margin-bottom: 11px;
}

.showcase__style_buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px 16px;

  & > * {
    height: 30px;
  }
}
</style>