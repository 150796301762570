<template>

  <section class="start-preview"
           @click="goToLogin"
  >
    <div class="start-preview__title">
      <svg width="141" height="30" viewBox="0 0 141 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 29.6762V8.95677H7.44604V29.6762H0Z" fill="white"/>
        <path d="M19.9843 2.05029L25.6497 21.5287H25.8656L31.531 2.05029H40.0023L30.8835 29.6762H20.6317L11.513 2.05029H19.9843Z" fill="white"/>
        <path d="M42.7709 29.6762V8.95677H50.217V29.6762H42.7709Z" fill="white"/>
        <path d="M66.3669 8.95677V14.3525H52.7158V8.95677H66.3669ZM55.3597 3.99274H62.8058V23.0125C62.8058 23.3003 62.8552 23.5431 62.9541 23.7409C63.0531 23.9298 63.2059 24.0737 63.4128 24.1726C63.6196 24.2625 63.8849 24.3075 64.2086 24.3075C64.4335 24.3075 64.6942 24.2805 64.991 24.2266C65.2968 24.1726 65.5216 24.1276 65.6655 24.0917L66.7446 29.3255C66.4119 29.4244 65.9353 29.5458 65.3147 29.6897C64.7032 29.8336 63.9748 29.928 63.1295 29.973C61.4209 30.0629 59.9865 29.8875 58.8264 29.4469C57.6664 28.9972 56.7941 28.2913 56.2095 27.3291C55.625 26.3668 55.3417 25.1618 55.3597 23.714V3.99274Z" fill="white"/>
        <path d="M69.4054 29.6762V8.95677H76.6356V12.8956H76.8514C77.2291 11.4388 77.8271 10.3731 78.6455 9.69867C79.4728 9.02422 80.4395 8.68698 81.5456 8.68698C81.8694 8.68698 82.1886 8.71396 82.5034 8.76792C82.8271 8.81288 83.1374 8.88033 83.4341 8.97026V15.3237C83.0654 15.1978 82.6113 15.1034 82.0717 15.0404C81.5322 14.9775 81.069 14.946 80.6823 14.946C79.9539 14.946 79.2974 15.1123 78.7129 15.4451C78.1374 15.7688 77.6832 16.2275 77.3505 16.821C77.0178 17.4055 76.8514 18.0935 76.8514 18.8848V29.6762H69.4054Z" fill="white"/>
        <path d="M85.8757 29.6762V8.95677H93.3217V29.6762H85.8757Z" fill="white"/>
        <path d="M104.562 18.0215V29.6762H97.1156V8.95677H104.184V12.8956H104.4C104.849 11.5827 105.641 10.553 106.774 9.80659C107.916 9.06019 109.247 8.68698 110.767 8.68698C112.232 8.68698 113.505 9.02422 114.584 9.69867C115.672 10.3641 116.513 11.2814 117.107 12.4505C117.709 13.6195 118.006 14.955 117.997 16.4568V29.6762H110.551V18.0215C110.56 16.9963 110.299 16.1915 109.768 15.6069C109.247 15.0224 108.518 14.7302 107.583 14.7302C106.972 14.7302 106.437 14.865 105.978 15.1348C105.528 15.3956 105.182 15.7733 104.939 16.2679C104.697 16.7535 104.571 17.3381 104.562 18.0215Z" fill="white"/>
        <path d="M127.446 29.9999C126.124 29.9999 124.955 29.7841 123.939 29.3525C122.932 28.9118 122.14 28.2463 121.565 27.3561C120.989 26.4658 120.701 25.3327 120.701 23.9568C120.701 22.8237 120.895 21.8569 121.281 21.0566C121.668 20.2472 122.208 19.5863 122.9 19.0737C123.593 18.5611 124.397 18.1699 125.315 17.9001C126.241 17.6303 127.239 17.455 128.309 17.374C129.469 17.2841 130.4 17.1762 131.102 17.0503C131.812 16.9154 132.325 16.731 132.639 16.4972C132.954 16.2544 133.112 15.9352 133.112 15.5395V15.4855C133.112 14.946 132.905 14.5323 132.491 14.2445C132.077 13.9568 131.547 13.8129 130.899 13.8129C130.189 13.8129 129.609 13.9703 129.159 14.285C128.719 14.5908 128.453 15.0629 128.363 15.7014H121.511C121.601 14.4424 122.001 13.2823 122.711 12.2212C123.431 11.151 124.478 10.2967 125.854 9.65821C127.23 9.01073 128.948 8.68698 131.007 8.68698C132.491 8.68698 133.822 8.86234 135 9.21306C136.178 9.55479 137.181 10.0359 138.008 10.6564C138.835 11.2679 139.465 11.9873 139.897 12.8147C140.337 13.633 140.558 14.5233 140.558 15.4855V29.6762H133.597V26.7625H133.435C133.022 27.5359 132.518 28.1609 131.924 28.6375C131.34 29.1141 130.67 29.4604 129.915 29.6762C129.168 29.892 128.345 29.9999 127.446 29.9999ZM129.874 25.3057C130.441 25.3057 130.971 25.1888 131.466 24.955C131.969 24.7212 132.379 24.3839 132.693 23.9433C133.008 23.5026 133.165 22.9676 133.165 22.3381V20.6114C132.968 20.6924 132.756 20.7688 132.531 20.8408C132.316 20.9127 132.082 20.9802 131.83 21.0431C131.587 21.1061 131.326 21.1645 131.048 21.2185C130.778 21.2724 130.495 21.3219 130.198 21.3668C129.622 21.4568 129.15 21.6052 128.781 21.812C128.422 22.0098 128.152 22.2571 127.972 22.5539C127.801 22.8417 127.716 23.1654 127.716 23.5251C127.716 24.1007 127.918 24.5413 128.323 24.8471C128.728 25.1528 129.245 25.3057 129.874 25.3057Z" fill="white"/>
        <path d="M3.66907 6.79856C2.66187 6.79856 1.79856 6.46583 1.07914 5.80036C0.359712 5.13489 0 4.33453 0 3.39928C0 2.46403 0.359712 1.66367 1.07914 0.9982C1.79856 0.332733 2.66187 0 3.66907 0C4.68525 0 5.54856 0.332733 6.25899 0.9982C6.97842 1.66367 7.33813 2.46403 7.33813 3.39928C7.33813 4.33453 6.97842 5.13489 6.25899 5.80036C5.54856 6.46583 4.68525 6.79856 3.66907 6.79856Z" fill="#FF0000"/>
        <path d="M3.66907 6.79856C2.66187 6.79856 1.79856 6.46583 1.07914 5.80036C0.359712 5.13489 0 4.33453 0 3.39928C0 2.46403 0.359712 1.66367 1.07914 0.9982C1.79856 0.332733 2.66187 0 3.66907 0C4.68525 0 5.54856 0.332733 6.25899 0.9982C6.97842 1.66367 7.33813 2.46403 7.33813 3.39928C7.33813 4.33453 6.97842 5.13489 6.25899 5.80036C5.54856 6.46583 4.68525 6.79856 3.66907 6.79856Z" fill="#FF0000"/>
        <path d="M3.66907 6.79856C2.66187 6.79856 1.79856 6.46583 1.07914 5.80036C0.359712 5.13489 0 4.33453 0 3.39928C0 2.46403 0.359712 1.66367 1.07914 0.9982C1.79856 0.332733 2.66187 0 3.66907 0C4.68525 0 5.54856 0.332733 6.25899 0.9982C6.97842 1.66367 7.33813 2.46403 7.33813 3.39928C7.33813 4.33453 6.97842 5.13489 6.25899 5.80036C5.54856 6.46583 4.68525 6.79856 3.66907 6.79856Z" fill="#FF0000"/>
        <path d="M3.66907 6.79856C2.66187 6.79856 1.79856 6.46583 1.07914 5.80036C0.359712 5.13489 0 4.33453 0 3.39928C0 2.46403 0.359712 1.66367 1.07914 0.9982C1.79856 0.332733 2.66187 0 3.66907 0C4.68525 0 5.54856 0.332733 6.25899 0.9982C6.97842 1.66367 7.33813 2.46403 7.33813 3.39928C7.33813 4.33453 6.97842 5.13489 6.25899 5.80036C5.54856 6.46583 4.68525 6.79856 3.66907 6.79856Z" fill="#FF0000"/>
        <path d="M46.504 6.79856C45.4968 6.79856 44.6335 6.46583 43.9141 5.80036C43.1947 5.13489 42.835 4.33453 42.835 3.39928C42.835 2.46403 43.1947 1.66367 43.9141 0.9982C44.6335 0.332733 45.4968 0 46.504 0C47.5202 0 48.3835 0.332733 49.094 0.9982C49.8134 1.66367 50.1731 2.46403 50.1731 3.39928C50.1731 4.33453 49.8134 5.13489 49.094 5.80036C48.3835 6.46583 47.5202 6.79856 46.504 6.79856Z" fill="#37F3FF"/>
        <path d="M89.339 6.79856C88.3318 6.79856 87.4685 6.46583 86.7491 5.80036C86.0296 5.13489 85.6699 4.33453 85.6699 3.39928C85.6699 2.46403 86.0296 1.66367 86.7491 0.9982C87.4685 0.332733 88.3318 0 89.339 0C90.3552 0 91.2185 0.332733 91.9289 0.9982C92.6483 1.66367 93.0081 2.46403 93.0081 3.39928C93.0081 4.33453 92.6483 5.13489 91.9289 5.80036C91.2185 6.46583 90.3552 6.79856 89.339 6.79856Z" fill="#33DB18"/>
      </svg>

      <div class="start-preview__text">Онлайн витрина  или  другой дискриптор</div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageStartPreview",
  methods: {
    goToLogin() {
      this.$router.replace("/login");
    },
  }
}
</script>

<style scoped>
.start-preview__text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
  max-width: 170px;
  margin-top: 4px;
}

.start-preview {
  background: #20A0FF;

  color: rgba(255, 255, 255, 0.75);

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 0 30px;

  width: 100%;
  min-height: 100vh;
}

.start-preview__title {
  color: #FFFFFF;

  font-size: 24px;
  font-weight: 500;

  text-align: center;
}
</style>