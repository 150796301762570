import axios from 'axios'


export const URL = `${process.env.VUE_APP_LINK}/api/v1/check_phones`;

export default {
    uploadCsv(data) {
        return axios.post(`${URL}/upload`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getPhones(data) {
        return axios.get(`${URL}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },
    deleteItem(id) {
        return axios.delete(`${URL}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    deleteItems(data) {
        return axios.post(`${URL}/delete_db`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
}