<template>
    <div class="checkbox">
        <AppInputCheckbox
        @update="update"
        :forAndId="forAndId"
        :checked="modelValue">
        </AppInputCheckbox>
        <div class="checkbox__text">
            <slot></slot>
        </div>
    </div>
</template> 

<script>
export default {
    
    name: 'AppCheckbox',
    props: {
        modelValue: {},
        forAndId: {
            type: [String, Number],
            required: false
        },
       
    },
    methods: {
        update(check) {
          this.$emit('update:modelValue', check);
          this.$emit('update', check);
        }
  
    }
}
</script>

<style lang="scss" scoped>
    .checkbox {
        display: flex;
        align-items: center;
        column-gap: 10px;

    }

    .checkbox__text {
        color: #7E7E7E;

        font-size: 12px;
    }
</style>