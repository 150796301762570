import axios from 'axios'

export const DELIVERIES = `${process.env.VUE_APP_LINK}/api/v1/deliveries`;
export const NOTICES = `${process.env.VUE_APP_LINK}/api/v1/notices`;
export const PRODUCTS = `${process.env.VUE_APP_LINK}/api/v1/products`;
export const SETTINGS = `${process.env.VUE_APP_LINK}/api/v1/settings`;
export const SHOP = `${process.env.VUE_APP_LINK}/api/v1/shops`;



export default { 
    //Получение данных при загрузке
    getData(shop_id) {
        return axios.get(`${SETTINGS}/data/${shop_id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getSocialShopData(id) {
        return axios.get(`${SETTINGS}/socialdata/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getSocialData(data) {
        return axios.get(`${SETTINGS}/socialdata`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data
        })
    },
    //Получение данных о заказах и сообщениях
    getInfo(shop_id) {
        return axios.get(`${SETTINGS}/info/${shop_id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    /////////////
    updateDeliveryNotice(id, data) {
        return axios.put(`${DELIVERIES}/notices/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updatePayment(id, data) {
        return axios.put(`${SETTINGS}/payments/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    createPayment(data) {
        return axios.post(`${SETTINGS}/payments`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    createShopTelegram(data) {
        return axios.post(`${SHOP}/telegram`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },

        })
    },
    updateShopTelegram(data) {
        return axios.put(`${SHOP}/telegram/`+data.id, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }) 
    },
    //////////////

    getEmails() {
        return axios.get(`${SETTINGS}/emails`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getUserNotices() {
        return axios.get(`${SETTINGS}/user_notices`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateUserNotice(data) {
        return axios.put(`${SETTINGS}/user_notices/`+data.id, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    ///////////
    getShopNotices(data) {
        return axios.get(`${SETTINGS}/shop_notices`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data
        })
    },
    updateShopNotices(data, shop_id) {
        return axios.put(`${SETTINGS}/shop_notices/`+shop_id, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    createEmail(data) {
        return axios.post(`${SETTINGS}/emails`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },

        })
    },
    updateEmail(data) {
        return axios.put(`${SETTINGS}/emails/`+data.id, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    deleteEmail(id) {
        return axios.delete(`${SETTINGS}/emails/`+id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },


    ///////
    getProductStatuses() {
        return axios.get(`${PRODUCTS}/statuses`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getMe() {
        return axios.get(`${SETTINGS}/getMe`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    createUserShop(data) {
        return axios.post(`${SHOP}/createusershop`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    saveNotification(data) {
        console.log(data);
    }
}