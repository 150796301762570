import axios from 'axios';
import { BACKEND_URL_STOCKS } from '@/store'

export default {
    actions: {
        async getStocks(ctx, data) {
            let res = await axios.get(BACKEND_URL_STOCKS, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: data
            })
           
            ctx.commit('setStocks', res.data.data)

        },
        async getNameplates(ctx, data) {
            let res = await axios.get(BACKEND_URL_STOCKS+"/nameplates", {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: data
            })
            ctx.commit('setNameplates', res.data.data)
        },
        async asyncCreateStock(ctx, newStock) {
            axios.post(BACKEND_URL_STOCKS, newStock, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('createStock', res.data.data))
            .catch(err => console.log(err));
        },
        async asyncUpdateStock(ctx, editedStock) {
           

            axios.put(`${BACKEND_URL_STOCKS}/${editedStock.id}`, editedStock, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));

           // ctx.commit('updateStock', editedStock)
        },
        async asyncDeleteStock(ctx, stock) {
            axios.delete(`${BACKEND_URL_STOCKS}/${stock.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).
            then(() => ctx.commit('deleteStock', stock))
        }
    },
    mutations: {
        setStocks(state, stock) {
            state.stocks = stock;
        },
        setNameplates(state, nameplates) {
            state.nameplates = nameplates;
        },
        createStock(state, newStock) {
            state.stocks.push(newStock);
        },
        updateStock(state, editedStock) {
            state.stocks = state.stocks.map(stock => {
                if (stock.id === editedStock.id) {
                    stock = editedStock;
                }
            });
        },
        deleteStock(state, stock) {
            state.stocks = state.stocks.filter(stocks => stock.id !== stocks.id)
        }
    },
    state: {
        stocks: []
    },
    getters: {
        allStocks(state) {
            return state.stocks
        },
        allNameplates(state) {
            return state.nameplates
        }
    }
}