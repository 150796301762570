<template>
   <div class="about-setting block-style"> 
    <div style="display: flex;" v-if="shop_elements.find(e=>e=='PageTask')">
      <!--
          <AppButton
            @click="ratioType('standart')"
            style="width: 100%; height: 30px; margin-right: 20px;  margin-bottom: 10px; cursor: pointer;"
            :styleButton="getStyle('standart')"
                > Стандартная 
          </AppButton>
      
          <AppButton
            @click="ratioType('task')"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer;"
                :styleButton="getStyle('task')"> Задание 
          </AppButton>
          -->
    </div>
    <div style="display: flex;">
          <AppButton
            style="width: 49%; height: 30px; margin-right: 20px;  margin-bottom: 10px; cursor: pointer;"
            > 
            Основное >>
          </AppButton>
          <AppButton
            @click="goStep(2)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"> 
            Фильтры >>
          </AppButton>
          <AppButton
          @click="goStep(3)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"> 
            Боты >>
          </AppButton>
          <AppButton
            @click="goStep(4)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"> 
            Настройки >>
          </AppButton>
          <AppButton
            @click="goStep(5)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer;"
            styleButton="_blue-external"> 
            Дата/Время  
          </AppButton>
    </div>
    <!--
    <div v-if="model.type_action =='task'" class="about-setting block-style">  
      <AppSelect
              :style="{maxWidth: '100%' }"
              v-model="model.task_id"
              :items="tasks">
          <template v-slot:title>
              Выберите задание
          </template>
      </AppSelect>     
    </div>
    -->
    <div  class="about-setting block-style">  
     
      <AppInput
          v-model="model.name" 
          :placeholder="'Заголовок рассылки'"
          :cssClass="'input-mailing'"
          :forAndId="'name'">
        <template v-slot:title>
          <AppTitleSettingParam>
    
          </AppTitleSettingParam> 
        </template>
      </AppInput>
      <EditableText 
          v-model="model.message.text"  
          :title="'Текст рассылки'"
          :LengthText="'Внимание! Максимальное количество знаков с пробелами - 970'"   
          :LengthTextInfo="'Использовано: '+(model.name.length+model.message.text.length)+' / 970'"        
          :MaxLength="1000"
      />
      <div>
        <AppAddImage
            :media_type="model.message.media_type"
            :combo="true"
            :shop_id="shop_id"
            @imgData="updateImage"
            @deleteImg="deleteImage"
            :button="'изображение'"  
            :headerPart="'изображения'"
            :ratio="[16, 9]"
            :photo="model.message.media"
        />
        <AppAddVideo 
            :media_type="model.message.media_type"
            :combo="true"
            :shop_id="shop_id"
            :button="'видео'"  
            :headerPart="'видео'"
            :video="model.message.media" 
            @uploadVideo="uploadVideo"
        />
        <AppChatButtons
            :model_id="model.id"
            :button_hidden="model.button_hidden"
            :place="'mass_mailling'"
            :shopInfo="shopInfo"
            :buttons="this.model.buttons"  
            @updateButtons="updateButtons"
            @buttonsHidden="buttonsHidden"
            @prefSave="prefSave"
        />
      </div>
      <div v-if="model.type=='mass'">
        <p>Внимание! Если Вас нет в Боте, в который Вы отправляете тестовую рассылку, Вы получите ошибку</p>
        <AppSelectApiSearch
          :placeholder="'Начните вводить название бота в который будут отправлять тестовую рассылку'"
          v-model="model.availableShop_id"
          @search="searchShops"
          :items="availableShops"/> 
        </div>  
        <div v-if="model.type =='task'" class="about-setting block-style">  
          <AppSelect
                  :style="{maxWidth: '100%' }"
                  v-model="model.task_id"
                  :items="tasks">
              <template v-slot:title>
                  Выберите задание
              </template>
          </AppSelect>     
        </div>
      </div>
    <div style="display: flex; justify-content: right">
      <AppButton 
          @click="sendTest"
          style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
          :styleButton="'_blue-external'"
      >
        Тест
      </AppButton>
      <AppButton
      @click="goStep(2)"
          style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
          :styleButton="'_blue-external'"
      >
        Далее
      </AppButton>
      <AppButton
          @click="prefSave"
          style="width: 83px; height: 30px; margin-top: 16px"
          :styleButton="'_blue-external'"
      >
        Сохранить
      </AppButton>
    </div>
  
</div> 
</template>

<script>
import api from "../api"
import apiTask from "@/pages/setting/Modules/Task/api"
//import apiButton from "@/components/ButtonElements/api"
import AppChatButtons from "@/components/AppChatButtons"
export default {
  name: "MaillingElementCreate",
  props: {   
      element: {
        type: Object,
      },
      update: {
        type: Boolean
      },
      availableShops:{
        type: Array,
      },
      shopInfo:{
        type: Object,
      }

    },
  components: {AppChatButtons},
  data() {
    return {
      selectType: 'standart',
      shop_elements: JSON.parse(localStorage.getItem('shop_elements')) || [],
      availableShop_id:null,
      model: this.element,
      localVideo: null,
  
      showMessageForm: false,
      answer: "",
      tasks: [],
      shop_id: localStorage.getItem('shop_id'),
      
      currentFilterState: '',
    }
  }, 
 
  methods: {
    ratioType(type){
      this.selectType = type
      this.model.type_action = type
      if (type=='task') this.getTasks()
      
    },
    buttonsHidden(status){
      this.model.button_hidden = status
    },
    getStyle(type){
        return this.selectType === type ? null : '_blue-external';
    },
    async getTasks(){
    
    if (this.model.type_action=="task" && this.tasks.length==0) {
      const response = await apiTask.getModels({shop_id: this.shop_id})
      this.tasks = response.data.data
    }
  }, 
    searchShops(name){
      this.$emit("searchShops", name)
    },
    uploadVideo(value){
        if (!value) {
          this.model.message.media = null
          this.model.message.media_type = null
          this.media_type = null
        }
        if (value) {
          this.model.message.media = value
          this.model.message.media_type = 'video'
          this.media_type = 'video'
        }
    },
    updateImage(value){
       this.model.message.media = value
       this.model.message.media_type = 'image'
       this.media_type = 'image'
    },
    deleteImage(){
      this.model.message.media = null
       this.model.message.media_type = null
       this.media_type = null
    },
    prefSave(){
      if ((this.model.message.text.length+this.model.name.length)>970) {
        this.showMessageNotice('Ошибка 09',"Текст рассылки слишком длинный. <br> Пожалуйста сократите его до 970 символов",'error');
        return
      }
      if (this.model.id) {
        api.updateMailing(this.model.id, this.model).then(() => {
          this.showMessageNotice('Ошибка 09',"Рассылка сохранена",'success');
          }).catch((error) => {
            this.showMessageNotice('Ошибка 09',error.response.data.message,'error');
      })
        return
      }
      api.prefSaveMailing(this.model).then((response) => {
       
        this.$router.push({ name: "MaillingElement", params: { id: response.data.mailling.id, type:response.data.mailling.type}})
          }).catch((error) => {
            this.showMessageNotice('Ошибка 09',error.response.data.message,'error');
      })
    },
    sendTest(){
      this.model.recipient = localStorage.getItem("recipient")
      if (this.model.type_action=='task') {
        if (!this.model.task_id) {
            this.showMessageNotice('Ошибка 09',"Выберите задание",'error');
            return
        }
        let task =  this.tasks.find(e=>e.id==this.model.task_id)
        this.model.name = task.name
        this.model.message.text = task.name

      }
      //Проверка для отправки теста
      if (!this.model.availableShop_id) {
        this.showMessageNotice('Ошибка 09',"Выберите бота в который будт отправлена тестовая рассылка",'error');
        return
      }
      if ((this.model.message.text.length+this.model.name.length)>970) {
        this.showMessageNotice('Ошибка 09',"Текст рассылки слишком длинный. <br> Пожалуйста сократите его до 970 символов",'error');
        return
      }
      if (!this.model.recipient) {
        this.showMessageNotice('Ошибка 09',"Отсутствует получатель тестовой рассылки. <br> Вам необходимо авторизоватся через телеграм",'error');
        return
      }
      //Проверка на правильность заполнения
      if (!this.checkMailling()) return

      api.testMailing(this.model).then((response) => {
        this.showMessageNotice('Ошибка 09',response.data.message,'success');
        this.model.test = response.data.mailling.test
        this.$router.push({ name: "MaillingElement", params: { id: response.data.mailling.id, type:response.data.mailling.type}})
          }).catch((error) => {
            this.showMessageNotice('Ошибка 09',error.response.data.message,'error');
          })
    },

    checkMailling(){  
      if (!this.model.name){
          this.showMessageNotice('Ошибка 09',"Укажите название рассылки",'error')
          return false
        }
      if(!this.model.message.text)  {
          this.showMessageNotice('Ошибка 09',"Укажите описание рассылки",'error')
          return false
        }
        return true
    },
    goStep(id){
      console.log(id)
      this.$emit("goStep", id);
    },
    next(){
      this.$emit("next", this.model);
    },
    updateButtons(button, type="add"){
        if (type=="add")
            this.model.buttons.push(button)
        if (type=="update"){
            let index = this.model.buttons.findIndex(e=>e.id==button.id)
            this.model.buttons[index] = button
        }
        if (type=="delete"){
            let index = this.model.buttons.findIndex(e=>e.id==button.id)
            this.model.buttons.splice(index,1)
        }
    },
  
  },
  watch:{
      element(element){
      
        this.model = element
        this.media_type = element.message.media_type
       
      },
     
    },
  inject: ['showMessageNotice'],
}

</script>

<style scoped>
.horisontal-ul li {
   display: inline-block;
   margin-left: 20px;
}
.date-naw {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.about-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 40px 12px;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

</style>