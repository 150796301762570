<template>
   <div class="alert-setting__emails block-style">
                <AppSectionTitle v-if="this.notices.length>0"> 
                  Настройки уведомлений владельца
                </AppSectionTitle>
                <AppSectionTitle v-else> 
                  Владелец
                </AppSectionTitle>
                <div>
                  <AppInputForm
                    :readonly="true"
                    v-model="owner.name">
                        <template v-slot:title>
                        </template>
                    </AppInputForm>
                    <br>
                  <AppCheckbox
                      style="margin-bottom: 10px"
                      v-for="item,index in notices"
                      v-model="item.status"
                      :key="index"
                      :name="'user_notice'"
                      :forAndId="item.id"
                  >
                    {{ item.title }}
                  </AppCheckbox>
                </div>
                <div v-if="this.notices.length>0" style="max-width: 172px; margin: 20px auto auto">
                  <AppButtonSettingSave @click="updateOwnerAlerts()"></AppButtonSettingSave>
                </div>
              </div>   
</template>

<script> 
import api from "./api"

export default {
  name: 'AlertOwner',
  props: {
      shop_id:{
        type:[String, Number],
      },
    },
  data() {
    return {
      owner:{},
      notices: [],
    }
  },
  computed: {
  },
  mounted() {
    this.getOwner();
    //this.getShopInfo()
  },
  methods: {
      async getOwner(){
        const response = await api.getOwner({shop_id:this.shop_id})
        this.owner = response.data.owner
        this.notices = response.data.notices
        if (this.notices.length==0) this.$emit("checkOwner",false)
        else this.$emit("checkOwner",true)
      },
      updateOwnerAlerts(){ 
        api.updateNotices({notices: this.notices, people_id:this.owner.id}).then((response) => {
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                    }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
      },

  },
  watch: {
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .catalog-setting {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .catalog-setting__add-form {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .catalog-setting__title {
        margin-bottom: 6px;
    }

    .catalog-setting__text {
        color: #000000;

        font-size: 14px;
    }

    .catalog-setting__categories {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .catalog-setting__category {
        display: flex;
        column-gap: 3px;

        height: 40px;
    }
    .edit__name {
        color: var(--default_background);
    }
    .catalog-setting__category_button {
        display: flex;
        justify-content: space-between;

        padding: 10px 15px;

        span {
            font-size: 16px;
        }
    }
</style>