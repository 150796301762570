<template>
    <div class="title__setting-param">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppTitleSettingParam'
}
</script>

<style lang="scss">
    .title__setting-param {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.75);
    }
</style>