<template>
    <div class="container">

            <draggable class="button-system__list" :list="buttons" @end="onDragEnd">
                <template #item="{ element, index }">
                    <AppButtonsBottomSheet
                        :button="element" 
                        :prompt="testPrompts[index]"
                        @selectCurrent="function(state){openCurrent(index, state)}"
                        :open="setOpen[index]"
                    > 
                    <ElementWebApp v-if="element.name && element.type.name=='webapp'" :model="element" @save="save" @del="del"/>
                    <ElementLink v-if="element.name && element.type.name=='link'" :model="element" @save="save" @del="del"/>
                    <ElementReklambot v-if="element.name && element.type.name=='reklambot'" :model="element" @save="save" @del="del"/>
                    <ElementLeadgenerator v-if="element.name && element.type.name=='leadgenerator'" :model="element" @save="save" @del="del"/>
                    <ElementMessage v-if="element.name && element.type.name=='message'" :model="element" @save="save" @del="del"/>
                    <ElementPhone v-if="element.name && element.type.name=='phone'" :model="element" @save="save" @del="del"/>
                    <ElementTask v-if="element.name && element.type.name=='task'" :model="element" @save="save" @del="del"/>    
                  
                </AppButtonsBottomSheet>
                </template>
            </draggable>
          

        
    </div>
</template>
<script>
 import ElementWebApp from "@/components/ChatButtonElements/ElementWebApp"
import ElementLink from "@/components/ChatButtonElements/ElementLink"
import ElementReklambot from "@/components/ChatButtonElements/ElementReklambot"
import ElementLeadgenerator from "@/components/ChatButtonElements/ElementLeadgenerator"
import ElementMessage from "@/components/ChatButtonElements/ElementMessage"
import ElementPhone from "@/components/ChatButtonElements/ElementPhone"
import ElementTask from "@/components/ChatButtonElements/ElementTask"


import draggable from 'vuedraggable';

export default {
  name: "AppChatButtonList",
  components: {
    draggable,
    ElementLink, ElementReklambot, ElementLeadgenerator, ElementMessage, ElementPhone, ElementTask,
    ElementWebApp
  },
  props: {
    button_hidden:{
      type: Boolean,
    },
    buttons:{
      type: Array,
    },
  },
  data() {
    return {
        sort: [],
        testPrompts: [],
        setOpen: [],
        editElement: {},
        buttonsHidden: true
    }
  },
  mounted(){
        this.buttonsHidden = this.button_hidden
  },
  methods: {
    updateButtonHidden(){
        this.$emit('buttonsHidden', this.buttonsHidden);
    },
    onDragEnd() {
      for (let index = 0; index < this.buttons.length; index++) {
            this.sort[index] =  this.buttons[index].id
        
      }
      this.$emit('resort', this.sort);
    },
    openCurrent(i, state) {
          this.setOpen.forEach((im, i, arr)=>arr[i]=false);
          this.setOpen[i]=!state
        },
    editButton(button){
        if (this.editElement.id==button.id) 
            this.editElement={}
        else
            this.editElement = button
    },
    save(button){
     
        this.$emit('updateButton',button);
        this.setOpen =[]
    },
    del(button){
        this.setOpen =[]
        this.$emit('del',button);
    }
  },
  watch: {
  
  }
}
</script>
<style lang="scss" scoped>
    .catalog-setting {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .catalog-setting__add-form {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .catalog-setting__title {
        margin-bottom: 6px;
    }

    .catalog-setting__text {
        color: #000000;

        font-size: 14px;
    }

    .catalog-setting__categories {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .catalog-setting__category {
        display: flex;
        column-gap: 3px;

        height: 40px;
    }
    .edit__name {
        color: var(--default_background);
    }
    .catalog-setting__category_button {
        display: flex;
        justify-content: space-between;

        padding: 10px 15px;

        span {
            font-size: 16px;
        }
    }
</style>