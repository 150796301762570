<template>

        <CustomCKEditor v-model="localValue"/>

</template>


<script>
import CustomCKEditor from './CustomCKEditor';


export default {
    name: "EditorText",
    components: { CustomCKEditor},
    props: {
        totle: {
            type: String,
            default: "test"
        },
        value: {
            type: String,
            default: ""
        },
        config: {
            type: Object,
            default: () => ({
                toolbar: false
            })
        }
    },
    data() {
        return {
            editorReady: false,
            localValue: this.value
        };
    },
    watch: {
        value(newVal) {
            if (newVal !== this.localValue) {
                this.localValue = newVal;
            }
        },
        localValue(newVal) {
            if (newVal !== this.value) {
                this.$emit('input', newVal);
            }
        }
    },
    mounted() {
        this.editorReady = true;
    }
}
</script>
<style scoped>
.textarea {
  resize: none;
  min-height: 100px;
  width: 100%;
  box-sizing: border-box;
  background: #EEF4F8;
  border: 1px solid rgba(142, 162, 176, 0.5);
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

}
.text-container h3 {
  font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.75);
    
}

</style>