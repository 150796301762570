<template>
  <div class="container">
    <section class="setting">
      <AppSettingNavigation></AppSettingNavigation>
      <AppSectionTitle>
          Уведомления 
      </AppSectionTitle>
      <div class="bot-setting">
        <ul class="elements">
          <li class="element">
            <div class="alert-setting">
              <AlertOwner v-if="shopInfo.id"  :shop_id="shopInfo.id" @checkOwner="checkOwner"/>

              <AlertAdmins v-if="shopInfo.id && showAdmin"  :shop_id="shopInfo.id"/>
            </div>
          </li>
          <li class="element" v-if="shop_type.id==1">
            <div class="alert-setting">
              <AppSectionTitle>
                Настройки уведомлений клиентов
              </AppSectionTitle>
              <div
                  v-for="item in shopNotices.slice()"
                  :key="item.id">
                <AppNotifications
                    :model="item"
                    @updateNotice="updateNotice"
                    :buttonInsert="buttomInsert"
                    :title="item.notice.title" 
                    :info="'Обозначены переменные данные, заполняющиеся автоматически.'"
                />
                <br>
              </div>
  <!--
              <AppButtonSettingSave @click="saveAdminSetting()"></AppButtonSettingSave>
              -->

            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script> 
import api from "./Modules/Alerts/api"
import apiPeople from '../people/api'
import AlertAdmins from './Modules/Alerts/AlertAdmins'
import AlertOwner from './Modules/Alerts/AlertOwner'
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PageAlertSetting',
  components: {AlertAdmins,AlertOwner},
  data() {
    return {
      showAdmin:true,
      ower:"Вася",
      shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
      adminContacts: [],
      adminNotices: [],
      userNotices: [],
      shopNotices: [],
      newElement: {},
      showEmailForm: false,
      userNotification: {},
      titles: [],
      peoples: [],
      buttomInsert: [
        {
          "id": 5,
          "title": "ФИО",
          "text": "{%fio%}",
          "demo": "Иванов Иван"
        },
        {
          "id": 5,
          "title": "№ заказ",
          "text": "{%order_id%}",
          "demo": "№12354"
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['shopInfo']),
    getNotificationsWithoutDelivery() {
      return this.allClientNotifications.filter(not => {
        return not.action !== 'pay_success_pickup' &&
            not.action !== 'pay_success_digital' &&
            not.action !== 'pay_success_city' &&
            not.action !== 'delivery_pickup' &&
            not.action !== 'delivery_country' &&
            not.action !== 'pay_success_country'
      })
    },
  },
  created() {

    this.getShopInfo()
  },
  methods: {
    ...mapActions(['getShopInfo']),
      checkOwner(status){
        this.showAdmin = status
      },
      deleteItem(index){

          this.adminContacts.splice(index,1)
      },
      updateAdminContact(item){
        let telegram = item.telegram
        this.adminContacts.telegram = this.peoples.find(elem => elem.id==telegram);
      },

      Name(item) {
      this.newElement = item;
      this.showEmailForm = true
    },
    async getPeoples(data){
      data.pageSize=1000
    
      const response = await apiPeople.getPeoples(data) 
      this.peoples = response.data.data
    },
    async getAdminsSetting(data){
       const response = await api.getAdminsSetting(data)
       this.adminContacts = response.data.contacts
       this.adminNotices = response.data.notices
    },
    async updateAdminsSetting(){
      let data = {
          shop_id:  this.shopInfo.id,
          contacts: this.adminContacts,
          notices: this.adminNotices,
      }
      await api.updateAdminsSetting(data).then((response) => {
                    console.log(response.data)
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                    }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
    },
    async getUserNotices(data) {
      const response = await api.getUserNotices(data)
      this.userNotices = response.data.data.data
    },
    async getShopNotices(data) {
      const response = await api.getShopNotices(data)
      let shopNotices = response.data.data
      this.shopNotices = []
      for (let index = 0; index < shopNotices.length; index++) {
          
          if (shopNotices[index].notice.name=="shop_start"){ this.shopNotices[0]=shopNotices[index]}
          if (shopNotices[index].notice.name=="shop_pay_30") this.shopNotices[1]=shopNotices[index]
          if (shopNotices[index].notice.name=="shop_pay_cancel") this.shopNotices[2]=shopNotices[index]
          if (shopNotices[index].notice.name=="shop_end") this.shopNotices[3]=shopNotices[index]
          if (shopNotices[index].notice.name=="shop_cancel") this.shopNotices[4]=shopNotices[index]
      }
    },
    

    
  saveClientNotification() {
    this.asyncCreateClientNotification(this.allClientNotifications)
  },
  updateNotice(item){
            console.log(item, 'updateNotice')
            api.updateNotice(item.id, item).then((response) => {
                if (response.data.message){
                    this.showMessageNotice('', response.data.message,'success')
                }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
        },
  },
  watch: {
    shopInfo() {

      let data = {
        shop_id: this.shopInfo.id,
      }
      
      this.getPeoples(data)
      this.getAdminsSetting(data);
      //this.getUserNotices(data);
      //this.getShopNotices(data);
      //this.getUserNotifications();
      this.getShopNotices(data)
      //  this.getSetting()
    },
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
.delivery-setting__alert_button {
  display: flex;
  column-gap: 10px;

  padding: 10px 15px;

  span {
    font-size: 16px;
  }
}

.alert-setting {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 12px;
}

.alert-setting__emails {
  padding: 12px 10px;
}

.alert-setting__form_add {
  margin-top: 16px;
}

.alert-setting__subtitle {
  color: var(--global_color);
  font-size: 14px;
  margin-top: -3px;
  margin-bottom: 16px;
}

.alert-setting__stock {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-bottom: 12px;
}

.alert-setting__stock_item {
  display: flex;
  align-items: center;
  column-gap: 3px;
  width: 100%;
  height: 40px;
}

.alert-setting__input {
  flex: 1 1 auto;
}

.alert-setting__clients {
  @extend .alert-setting__emails;
}

.element {
  border-radius: 6px;
  padding: 10px 5px;
  border: 1px solid rgba(142, 162, 176, 0.5);
  margin: 5px 0;
}
</style>