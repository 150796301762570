import axios from 'axios';
import { BACKEND_URL_PRODUCTS } from '@/store'

export default {
    actions: {
        async getProducts(ctx, data) {
            let res = await axios.get(BACKEND_URL_PRODUCTS,  {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: data
            })
            ctx.commit('setProducts', {
                data: res.data,
                add: data.add
            });
            return res.data;
        },
        async getProductStatuses(ctx) {
            let res = await axios.get(BACKEND_URL_PRODUCTS+"/statuses",  {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
            ctx.commit('setProductStatuses', {
                data: res.data,
            });
            return res.data;
        },
        async getProductTypes(ctx) {
            let res = await axios.get(BACKEND_URL_PRODUCTS+"/types",  {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
            ctx.commit('setProductTypes', {
                data: res.data,
            });
            return res.data;
        },
        async asyncCreateProduct(ctx, newProduct) { 
            axios.post(BACKEND_URL_PRODUCTS, newProduct, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('createProduct', res.data.data))
            .catch(err => console.log(err));
        },
        
        async asyncUpdateProduct(ctx, editedProduct) {
            console.log(editedProduct);

            axios.put(`${BACKEND_URL_PRODUCTS}/${editedProduct.id}`, editedProduct, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }, 
            })
            .then()
            .catch(err => console.log(err));

           // ctx.commit('updateProduct', editedProduct)
        },
        async asyncDeleteProduct(ctx, product) {
            axios.delete(`${BACKEND_URL_PRODUCTS}/${product.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).
            then(() => ctx.commit('deleteProduct', product))
        },
        /*
        async asyncCreateProperty(ctx, newProperty) { 
            axios.post(BACKEND_URL_PRODUCTS+"/properties", newProperty, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));
        },
        async asyncDeleteProperty(ctx, property) {
            axios.delete(`${BACKEND_URL_PRODUCTS}/properties/${property.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).
            then()
        },
        */
    },
    mutations: {
        setProductStatuses(state, data){
            state.product_statuses = data.data;
        },
        setProductTypes(state, data){
            state.product_types = data.data;
        },
        setProducts(state, _data) {
            let {data, add} = _data;
            if (add) {
                state.products.push(...data.data);
            } else  state.products = data.data;
            state.productsMeta = data.meta;
        },
        createProduct(state, newProduct) {
            state.products.push(newProduct);
        },
        updateProduct(state, editedProduct) {
            state.products = state.products.map(product => {
                if (product.id === editedProduct.id) {
                    product = editedProduct;
                }
            });
        },
        deleteProduct(state, product) {
            state.products = state.products.filter(products => product.id !== products.id)
        }
    },
    state: {
        products: [],
        productsMeta: {}
    },
    getters: {
        allProducts(state) {
            return state.products
        },
        productStatuses(state) {
            return state.product_statuses
        },
        productTypes(state) {
            return state.product_types
        },
        productsMeta(state) {
            return state.productsMeta
        }
    }
}