<template>
    <div class="slider">
        <div class="slider__arrow">
            <AppArrow
            :class="[leftArrowLocked ? '_no-active' : '']"
            @click="clickerLeftArrow()"></AppArrow>
        </div>
        <div class="slider-container">
            <ul class="slider__content"
            :style="{transform: 'translateX('+ translateXPosition + '%)'}">

                <li class="slider__content_item"
                v-for="slide in slides"
                :key="slide.id">

                <div class="slider__content_image">

                    <video  class="slider__content_video"
                    v-if="slide.type === 'video'"
                    controls>

                        <source
                        :src="require(`@/assets/${slide.path}`)">

                    </video>

                    <img v-else class="slider__content_img" 
                    :src="require(`@/assets/${slide.path}`)"
                    alt="">

                </div>
                </li>
            </ul>
        </div>
        <div class="slider__arrow">
            <AppArrow
            :class="[RightArrowLocked ? '_no-active' : '']"
            @click="clickerRightArrow()"></AppArrow>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppSlider',
    data() {
        return {
            translateXPosition: 0,
            leftArrowLocked: true,
            RightArrowLocked: false,
        }
    },
    props: {
        slides: {
            type: Array,
            required: true
        }
    },
    
    methods: {
        clickerLeftArrow() {
            if (this.translateXPosition >= 0) {
                this.leftArrowLocked = true;
                return;
            }

            this.RightArrowLocked = false;
            return this.translateXPosition += 50;
        },
        clickerRightArrow() {
            if (this.translateXPosition <= (-50 * (this.slides.length - 2))) {
                this.RightArrowLocked = true;
                return;
            }

            this.leftArrowLocked = false;
            return this.translateXPosition -= 50;
        }
    }
}
</script>

<style lang="scss" scoped>
    .slider {
        display: flex;
        align-items: center;

        position: relative;
    }

    .slider-container {
        width: 100%;
        overflow: hidden;
    }

    .slider__arrow {
        position: absolute;
        
        &:first-child {
            left: -18px;

            @media (max-width: 400px) {
                left: -15px;
            }
        }

        &:last-child {
            right: -18px;

            transform: rotate(180deg);

            @media (max-width: 400px) {
                right: -15px;
            }
        }
    }

    .slider__content {
        display: flex;

        position: relative;

        transition: 300ms;

        width: 100%;
    }

    .slider__content_item {
        flex: 1 0 50%;

        padding: 0 10px 0 10px;
    }

    .slider__content_image {
        position: relative;

        padding-top: 100%;

        * {
            position: absolute;
            top: 0;
            left: 0;

            object-fit: cover;

            width: 100%;
            height: 100%;
        }
    }
</style>