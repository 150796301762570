<template>
  <div class="edit-banner__container">
    <AppTitleSettingParam style="margin: 10px 0">
      {{ title }}

    </AppTitleSettingParam>
    <div class="container-images">
      <div class="item-gallery"
           v-for="(img, i) in imagesUrl"
           :key="i">
        <div class="item-container">
          <img class="item-gallery__img"
               :src="'https://lk.ivitrina.store/medias/'+img.link+format+img.name+'.webp'">
          <svg @click="deleteImage(img.id)" class="item-gallery__but-close" width="15" height="15" viewBox="0 0 15 15" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.8499 0.535925L13.707 2.39308L2.39331 13.7068L0.536157 11.8496L11.8499 0.535925ZM2.71114 0.602339L14.0248 11.916L11.9276 14.0133L0.613907 2.69957L2.71114 0.602339Z"
                fill="#20A0FF"/>
          </svg>
        </div>
        <AppRadio
            style="margin-top: 5px;"
            :forAndId="'itemGallery_' + img.id"
            :name="'itemGallery'"
            v-model="selectFirst"
            >Главное
        </AppRadio>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppGallery",
  computed: {},
  data() {
    return {
      selectFirst: null,
      images: [],
    }
  },
  props: {
    
    format:{
      type: String,
      default: function () { return 'webp/' }
    },
    selected: {
      type: Number,
    },
    imagesUrl: {
      type: Array,
    },
    title: {
      type: String,
      default() {
        return 'Фотогалерея'
      }
    },
    modelValue: {
      type: Number,
    },
  },
  mounted(){
      this.images = Object.assign([],this.imagesUrl)
      this.selectFirst = (this.images.length>0)?"itemGallery_"+this.images[0].id:null

  },
  methods: {
  
    /*
    instalFirst(id){
      let index = this.images.findIndex(e=>e.id==id)
      if (index>-1){
        let tmp = this.images[0]
        this.images[0] = this.images[index]
        this.images[index] = tmp
       
        this.$emit('updatePosition', this.images)
      }
     
    },
    */
    deleteImage(id){
      this.$emit('delImg',id)
    }
  },
  watch:{
    selectFirst(selectFirst){
      this.images = Object.assign([],this.imagesUrl)
      let id = selectFirst.replace('itemGallery_', '');
      let index = this.images.findIndex(e=>e.id==id)
      if (index>-1){
        let tmp = this.images[0]
        this.images[0] = this.images[index]
        this.images[index] = tmp
        this.$emit('updatePosition', this.images)
      }
    },
    imagesUrl(){
        this.images = Object.assign([],this.imagesUrl)
        this.selectFirst = (this.images.length>0)?"itemGallery_"+this.images[0].id:null
      }
  }
}
</script>

<style scoped>

.container-images {
  display: flex;
  align-items: center;
  justify-content: start;
}

.item-gallery {
  margin: 0 30px 15px 0;
}

.item-gallery__but-close {
  min-width: 16px;
  cursor: pointer;
}

.item-container {
  display: flex;
  align-items: center;
  justify-content: start;
}

.item-gallery__img {
  height: 100%;
  max-height: 50px;
  width: auto;
  margin-right: 4px;
}
</style>