<template>
  <section class="section-product-edit product-edit section">
      <div class="container">
          <div class="flex-container">
              <AppSectionTitle>
            Клиента
              </AppSectionTitle>
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                  @click="$router.push('/')"
              >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z" fill="#8EA2B0"/>
              </svg>
          </div>
          <div class="product-edit__content">
            <div class="product-edit__info"> <!--основные параметры-->
                  <AppInputForm
                      inputRequired
                      :subSymbol="''"
                      pattern="name"
                      :typeInput="'string100'"
                      v-model="client.name"
                      > 
                      <template v-slot:title>
                          Имя пользователя
                      </template>
                  </AppInputForm>

                  <AppInputForm
                      inputRequired
                      :subSymbol="''"
                      pattern="name"
                      :typeInput="'string100'"
                      v-model="client.email"
                      > 
                      <template v-slot:title>
                         Email
                      </template>
                  </AppInputForm>
                  <AppInputForm
                      inputRequired
                      :subSymbol="''"
                      pattern="name"
                      :typeInput="'string100'"
                      v-model="client.password"
                      > 
                      <template v-slot:title>
                          Пароль
                      </template>
                  </AppInputForm>
            </div>
            <AppSectionTitle>
              Магазин
            </AppSectionTitle>
            <div class="product-edit__info"> <!--основные параметры-->
                  <AppInputForm
                      inputRequired
                      :subSymbol="''"
                      pattern="name"
                      :typeInput="'string100'"
                      v-model="newShop.name"
                      > 
                      <template v-slot:title>
                        Название магазина
                      </template>
                  </AppInputForm>

                  <AppInputForm
                      inputRequired
                      :subSymbol="''"
                      pattern="name"
                      :typeInput="'string100'"
                      v-model="newShop.sub_name"
                      > 
                      <template v-slot:title>
                        Подпись магазина
                      </template>
                  </AppInputForm>
            </div>
            <div class="product-edit__interaction">
                      <AppButton class="product-edit__interaction"
                          :styleButton="showSave"
                          @click="save()">
                          Добавить магазин
                      </AppButton>
                  </div>
          </div>
          
      </div>
  </section>
</template>

<script>
import api from "@/api";


export default {
    name: "PageNewShop",
  data() {
    return {
      newShop: {},
      client: {},
      user: null,
    }
  },
    mounted() { 
        this.getMe() 
    },
    created() {

    },
    methods: {
        async getMe(){
            //let response  = await api.getMe();
            //console.log(response.data,'response!')
            this.$router.push("/")
            /*
            if (response.data.id!=12) {
                setTimeout(() => {
                  this.$router.push("/")
                }, 500);
            } 
            */
        },
        async save(){
            let data = {client:this.client, shop:this.newShop}
            await api.createUserShop(data);
           
        },
      },

  watch: {
  },
}

</script>


<style lang="scss" scoped>
  .product-edit__content {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding: 12px 16px 13px;
      & > * {
          border-top: 1px solid var(--global_block_line);
          padding-top: 20px;
          &:first-child {
              border-top: 0;
              padding-top: 0;
          }
      }
  }
  .product-edit__status {
      display: flex;
      flex-direction: column;
      row-gap: 18px;
  }
  .product-edit__status_ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 13px;
  }
  .product-edit__info {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
  }
  .product-edit__inputs {
      display: flex;
      column-gap: 15px;
  }
  .product-edit__add-form {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
  }
  .product-edit__add-form_show {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
  }
  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px;
  }

  .flex-container svg {
    cursor: pointer;
  }

  .product-edit__show {
      row-gap: 10px;
  }
  .product-edit__images {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      margin-top: 10px;
  }
  .product-edit__category {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
  }

  .promo {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .product-edit__bottom {
      display: flex;
      flex-direction: column;
      row-gap: 18px;
  }
  .product-edit__digital__inner {
      display: flex;
      align-items: center;
      column-gap: 5px;
  }
  .product-edit__interaction {
      margin: 16px auto 0;
      width: 95%;
      height: 40px;
  }
</style>