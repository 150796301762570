<template>
  <section class="authorization login">
    <!-- <AppGreeting class="authorization__greeting">
    </AppGreeting> -->
    <div class="authorization__title login__title">
      Выберите ваш продукт
    </div>
    <div class="authorization__inputs login__inputs">
    
      <AppSelectApiSearch
          :placeholder="'Начните вводить название'"
          v-model="shop_id"
          @search="searchShops"
          :items="shops">
      </AppSelectApiSearch>     
    </div>
  </section>
</template>

<script>
import api from "@/api"
export default {
  name: "SelectShop",
  data() {
    return {
      shop_id: null,
      shops: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    searchShops(name){
      this.getData(name)
    },
    async getData(name=""){
      try{
              const response = await api.getSocialData({search:name})
              this.shops = response.data.data
           }
           catch (err){
              localStorage.removeItem('token')
              this.$router.replace("/login")
               console.log(err,"err")
           }
    },
   async go() {
      try{
               const response = await api.getData(this.shop_id)
               localStorage.setItem('shop_id', response.data.shop_id)
               localStorage.setItem('shop_bot', JSON.stringify(response.data.shop_bot) || null)
               localStorage.setItem('product_statues', JSON.stringify(response.data.product_statues))
               localStorage.setItem('product_types', JSON.stringify(response.data.product_types))
               localStorage.setItem('product_sort', JSON.stringify(response.data.product_sort))
               localStorage.setItem('categories', JSON.stringify(response.data.categories))
               localStorage.setItem('stocks', JSON.stringify(response.data.stocks))
               localStorage.setItem('shop_type', JSON.stringify(response.data.shop_type))
               localStorage.setItem('theme', response.data.theme || "_blue")
               //////////////
               localStorage.setItem('order_sort', JSON.stringify(response.data.orders.sort))
               localStorage.setItem('order_statuses', JSON.stringify(response.data.orders.statuses))
               localStorage.setItem('order_paystatuses', JSON.stringify(response.data.orders.paystatuses))
               //////////////
               localStorage.setItem('people_sort', JSON.stringify(response.data.peoples.sort))
               localStorage.setItem('people_types', JSON.stringify(response.data.peoples.types))
               document.body.classList.add(response.data.theme);
               //
               this.load = false
               window.location.replace('/');
               //this.$router.replace("/")
           }
           catch (err){
              localStorage.removeItem('token')
              this.$router.replace("/login")
               console.log(err,"err")
           }
      this.$router.replace("/")
    },
  },
  watch:{
    shop_id(){
      if (this.shop_id) {
        localStorage.setItem('shop_id', this.shop_id)
        this.go()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.password-container {
  position: relative;
}

.password-img {
  position: absolute;
  top: 30px;
  right: 5px;
}

.password-img-hide {
  position: absolute;
  top: 28px;
  right: 8px;
  z-index: 1;
}

.authorization {
  background: #20A0FF;

  color: rgba(255, 255, 255, 0.75);

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 0 30px;

  width: 100%;
  min-height: 100vh;
}

.authorization__title {
  color: #FFFFFF;

  font-size: 24px;
  font-weight: 500;

  text-align: center;
}

.login__title {
  margin-bottom: 15px;
  width: 260px;
}

.authorization__inputs {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 260px;

  .input__title {
    color: rgba(255, 255, 255, 0.75);
  }
}

.authorization__button {
  font-size: 16px;

  padding: 10px 16px;
  margin-top: 24px;
  margin-bottom: 24px;

  width: auto;
}

.authorization__message {
  font-size: 13px;

  text-align: center;

  margin-top: 60px;
  width: 270px;
}
</style>