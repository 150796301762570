<template>
  <div class="about-setting block-style">
    <div style="display: flex;">
          <AppButton
            style="width: 49%; height: 30px; margin-right: 20px;  margin-bottom: 10px; cursor: pointer;"
            @click="goStep(1)"
            styleButton="_blue-external"
            > 
            Основное >>
          </AppButton>
          <AppButton
            @click="goStep(2)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"
            > 
            Фильтры >>
          </AppButton>
          <AppButton
          @click="goStep(3)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"> 
            Боты >>
          </AppButton>
          <AppButton
           
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
           > 
            Настройки >>
          </AppButton>
          <AppButton
            @click="goStep(5)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer;"
            styleButton="_blue-external"> 
            Дата/Время  
          </AppButton>
    </div>


    <div class="about-setting block-style">
      <div class="header-mailing">
        Настройки
      </div>
      <div style="display: flex; flex-wrap: wrap;">
        <MaillingElementSettingElement style="margin-top: 12px" 
              class="button-container"
                v-for="(shop, i) in settings"
                :key="i"
                :element="shop"
                :selectedSettings="selectedSettings"
                @addToSelected="addToSelected"
                @removeFromSelected="removeFromSelected"
                >
        </MaillingElementSettingElement>
      </div>
      </div>
      <div style="display: flex; justify-content: right">
        <AppButton 
            @click="goStep(3)"
            style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
            :styleButton="'_blue-external'"
        >
          Назад
        </AppButton>
        <AppButton
        @click="goStep(5)"
            style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
            :styleButton="'_blue-external'"
        >
          Далее
        </AppButton>
        <AppButton
            @click="prefSave"
            style="width: 83px; height: 30px; margin-top: 16px"
            :styleButton="'_blue-external'"
        >
          Сохранить
        </AppButton>
      </div>
  </div>
</template>

<script>
import MaillingElementSettingElement from "./MaillingElementSettingElement"
export default {
  name: "MaillingElementSetting",
  components: {MaillingElementSettingElement},

  data() {
    return {
      settings:[
        {name:"Закрепить",id:"pin"},
        {name:"Без звука",id:"mute"},
        {name:"👍	👎",id:"likes"},
        {name:"Защить от копирования и пересылки",id:"protect_content"},
        {name:'Скрыть кнопки после нажатия (для кнопок "Информация")', id: "hidden_button"},
      ],
      selectedSettings:[]
    }
  },
  props: {
    element: [Object],
  },
  mounted(){
      this.selectedSettings = this.element.params ?? []
  },
  methods: {
    prefSave(){
      this.$emit('prefSave')
    },
    addToSelected(shopId) {
      this.selectedSettings.push(shopId);
      this.$emit('params', this.selectedSettings)
    },
    removeFromSelected(shopId) {
      const index = this.selectedSettings.indexOf(shopId);
      if (index > -1) {
        this.selectedSettings.splice(index, 1);
      }
      this.$emit('params', this.selectedSettings)
    },
    goStep(id){
      this.$emit("goStep", id);
    },
   
  },
  watch: {
  
  },
  inject: ['showMessageNotice'],
}
</script>

<style scoped>
.date-reg_content {
  border-top: 1px solid #C7D1D8;
}

.date-reg_container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.date-reg_header {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  margin-top: 20px;
}

.order-filter__type_ul {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  margin-bottom: 10px;
}

.header-mailing {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.about-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 16px 12px;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

</style>