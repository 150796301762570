<template>
    <AppButton
    class="setting__button-save"
    :styleButton="active?'_red':'_no-active'">
        Сохранить
    </AppButton>
</template>
<script>
export default {
    name: 'AppButtonSettingSave',
  props: {
      active: {
        type: Boolean,
        default: ()=>true
      }
  }
}
</script>
<style lang="scss" scoped>
    .setting__button-save {
        margin: 0 auto;

        width: 172px;
        height: 40px;
    }
</style>