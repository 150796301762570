<template>
  <div class="input-form">
    <label class="input__title" for="">
      <slot name="title"></slot>
    </label>
    <div class="input__content" :style="styleInputForm" :class="{ input_required: checkRequired }">
      <span class="img_left">
        <slot name="img_left"/>
      </span>
      <input class="input"
             :pattern="patternInput"
             :type="typeInput"
             :style="styleInput"
             :name="inputName"
             :value="modelValue"
             :placeholder="placeholder"
             ref="el_input"
             @input="updateInput">
      <span class="img_right">
        <slot name="img_right"/>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInputImg',
  data() {
    return {
      checkRequired: false,
    }
  },
  props: {
    pattern: {
      type: String
    },
    styleInput: {
      type: String
    },
    placeholder: {
      type: String
    },
    inputName: {
      type: String
    },
    styleInputForm: {
      type: String
    },
    inputRequired: {
      type: Boolean,
      default: false
    },
    typeInput: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number]
    }
  },

  computed: {

    patternInput() {
      let patternInput = ""
      switch (this.pattern) {
        case "name":
          patternInput = '^[a-zA-Zа-яА-Я _]+$'
          break;
        case "number":
          patternInput = '^\\+?[0-9]{1,11}$'
          break;
        case "phone":
          patternInput = '^\\+?[0-9]{4,11}$'
          break;
        case "email":
          patternInput = '^[^@\\n]+@\\w+\\.\\w+$'
          break;

        case "all":
          patternInput = ''
          break;


        default:
          patternInput = this.pattern
          break;
      }
      return patternInput
    }
  },

  mounted() {
    this.checkRequired = false

    if (this.inputRequired && !this.modelValue) {
      this.checkRequired = true;
      return
    }
    if (this.inputRequired && this.modelValue.length < 1) {
      this.checkRequired = true;
      return
    }
  },
  methods: {
    updateInput(event) {
      let value = event.target.value;
      this.$emit('update:modelValue', value);
      this.$emit('updateData', value);
    }
  },
  watch: {
    modelValue() {
      this.checkRequired = false;
      if (!this.inputRequired) {
        this.checkRequired = false;
        return
      }
      if (this.inputRequired && !this.modelValue) {
        this.checkRequired = true;
        return
      }
      if (this.inputRequired && this.modelValue.length < 1) {
        this.checkRequired = true;
        return
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.input-form {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.input__title {
  color: var(--global_fourth_color);

  font-size: 12px;
}

.input__content {
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #EEF4F8;
  border: 1px solid #BECBD4;
  border-radius: 6px;
  padding: 0 10px;
  &:hover {
    .input__svg > * {
      fill: var(--default_background);
    }
  }
}

.input {
  background: #EEF4F8;
  padding: 10px 0;
  height: 100%;
  width: 80px;
  &[type='email'] {
    text-decoration: underline;
  }
}

.input:invalid {
  border: 1px solid #e74a4a;
}

.input_required {
  border: 1px solid #e74a4a;
}

.input__svg {
  position: absolute;
  right: 12px;

  * {
    transition: 200ms;
  }
}

.img_left, .img_right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.img_left {

}

.img_right {

}

</style>