<template>
  <div class="about-setting block-style">
    <div style="display: flex;">
          <AppButton
            style="width: 49%; height: 30px; margin-right: 20px;  margin-bottom: 10px; cursor: pointer;"
            @click="goStep(1)"
            styleButton="_blue-external"
            > 
            Основное >>
          </AppButton>
          <AppButton
            @click="goStep(2)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"
            > 
            Фильтры >>
          </AppButton>
          <AppButton
          @click="goStep(3)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"> 
            Боты >>
          </AppButton>
          <AppButton
            @click="goStep(4)"
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
            styleButton="_blue-external"
           > 
            Настройки >>
          </AppButton>
          <AppButton
           
            style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer;"
           > 
            Дата/Время  
          </AppButton>
    </div>
    <div class="header-mailing">
      <div class="header-name">
      {{ element.name }} 
    </div>
    </div>
    <img class="img-mailing" v-if="element.message.media && element.message.media_type=='image'" 
        :src="getImage(element.message.media)" alt="" 
    >
    <video id="videoElement" v-if="element.message.media && element.message.media_type=='video'" 
        style=" max-width: 280px;" controls>
        <source :src="localVideo">
    </video>
    <div class="header-description" v-html="element.message.text">

    </div>

    <div class="block-mailing-result block-mailing-result_f">
      <div class="block-mailing-header">Получатели рассылки</div>
      <div class="block-mailing-value">

        {{ element.shop_ids.length }} боты.  <br>
        <div style="display: flex; flex-wrap: wrap; margin-bottom: 10px">
            <AppButton v-for="(item, index) in sendShops" :key="index"  
              style="width: 15%; height: 17px; margin: 2px; font-size: 10px;"
                  :styleButton="'_blue-external'"> 
                  {{ item }}
          </AppButton>
        </div>
      </div>
      <div class="block-mailing-header">Дата и время рассылки</div>
      <AppCalendar v-if="date"
        :date="date" @updateDate="updateDate"/>
    </div>

    <div style="display: flex; justify-content: right">
        <AppButton 
            @click="goStep(4)"
            style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;cursor: pointer;"
            :styleButton="'_blue-external'"
        >
          Назад
        </AppButton>
        <AppButton
        @click="prefSave"
            style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;cursor: pointer;"
            :styleButton="'_blue-external'"
        >
          Сохранить
        </AppButton>
       
      </div>
  </div>
</template>

<script>
import AppCalendar from "@/components/AppCalendar";
export default {
  name: "MaillingElementSend",
  components: {AppCalendar},

  data() {
    return {
      localVideo: null,
      date:  null,
    }
  },
  props: {
    element: [Object],
  },

  methods: {
    close(){
      this.$router.push({ name: "MaillingList"})
    },
    send(){
      if (!this.element.message.text){
        this.showMessageNotice('Ошибка 09',"Добавьте текст рассылки",'error'); return
      }
      if (!this.element.name){
        this.showMessageNotice('Ошибка 09',"Укажите название рассылки",'error'); return
      }
      if (!this.element.shop_ids.length==0){
        this.showMessageNotice('Ошибка 09',"Выберите ботов для рассылки",'error'); return
      }
      console.log(this.element)
    },
    prefSave(){
      this.$emit('prefSave')
      this.close()

    },
    goStep(id){
      this.$emit("goStep", id);
    },
    getImage(image){
      return 'https://lk.ivitrina.store/medias'+image.link+'thumb/'+image.name+".webp?"
    },
    setVideo(videoFile){
      this.localVideo = `https://lk.ivitrina.store/medias${videoFile.link}${videoFile.name}`
      var video = document.getElementById('videoElement');
      if (!video) return
      var source = document.createElement('source');
      source.setAttribute('src', this.localVideo);
      source.setAttribute('type', 'video/mp4');
      video.load();
    },
    updateDate(val){
      this.date = val
      this.$emit('send_date', this.date)
    },
   
  },
  mounted(){
    if (this.element.message.media  && this.element.message.media_type=='video') this.setVideo(this.element.message.media)
    console.log(this.element.send_date, this.date)
   
    if (!this.element.send_date){
      const localDate = new Date();
      this.date = localDate.toUTCString();
      /*
    // Получить смещение времени в минутах между локальным временем и GMT
    const offsetMinutes = localDate.getTimezoneOffset();

    // Создать новую дату с учетом смещения и получить дату и время в формате GMT
    const gmtDate = new Date(localDate.getTime() + offsetMinutes * 60000);
    const gmtDateString = gmtDate.toUTCString()
    this.date = gmtDateString;
    */
    }
    else {
      const gmtDateString = this.element.send_date;
      const gmtDate = new Date(gmtDateString); // Это создаст объект Date в GMT
      console.log(gmtDate,"gmtDate")
      // Теперь преобразуем GMT дату в локальное время
      const localDate = new Date(gmtDate.getTime() - gmtDate.getTimezoneOffset() * 60000);
      console.log(localDate,"localDate")
      this.date = localDate;
    }
    console.log( this.date )

   
 
  },
  inject: ['showMessageNotice'],
}
</script>

<style scoped>

.date-naw {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.status-message {
  font-family: Roboto;
  color: #8EA2B0;
  font-size: 9px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.header-mailing {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 12px 0;
}

.header-description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: black;
  margin: 12px 0;
}
.header-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: black;
  margin: 12px 0;
}
.img-mailing {
  max-width: 280px;
  height: auto;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.block-mailing-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
}


.block-mailing-result {
  margin: 10px 0;
}

.block-mailing-result_f {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  padding-top: 15px;
}


.block-mailing-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.75);
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

</style>