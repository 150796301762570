<template>
<!--  v-for="item in meta.pages" :key="item"  -->
    <div v-if="meta.pages>1" class="buttons">
        <AppButton  class="button-counter"
        :styleButton="'_grey'">
            <div class="button-counter_text">
                <span>
                    Страница:
                </span>
                <strong>
                     {{meta.currentPage}} из {{meta.pages}}
                </strong>
            </div>
            <!--
            <svg class="button-counter_svg" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.87298 5.22424L10.6812 0.416016L11.9383 1.67309L6.28143 7.32995L6.21612 7.26464L5.80767 7.67309L0.150818 2.01624L1.4079 0.759161L5.87298 5.22424Z" fill="#8EA2B0"/>
            </svg>
            -->
        </AppButton>
        <div class="button__navigation">
            <AppButton
            v-if="backButton"
            class="button__navigation_arrow"
            :styleButton="backButtonStyle">
                <AppArrow @click="goNext(-1)"></AppArrow>
            </AppButton>
            <AppButton
           
            class="button__navigation_arrow"
            :style="display"
            :styleButton="nextButtonStyle">
                <AppArrow @click="goNext(1)"></AppArrow>
            </AppButton>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppNavigationBottom',
    props: {
        meta:{
            type: Object,
            required: true
        },
    },
    computed: {
        display(){
            let data = {
                 display: "",
            }
            data.display = (this.nextButton) ? "block":"none"
            return data
        },
        backButtonStyle(){
             return this.backButton ? '_grey':'_grey _no-active';
        },
        nextButtonStyle(){
            return this.nextButton ? '_grey':'_grey _no-active';
        },
        backButton(){
            if(parseInt(this.meta.currentPage)>1) return true;
            else return false;
        },
        nextButton(){
            if(parseInt(this.meta.currentPage)<parseInt(this.meta.pages)) return true;
            else return false;
        }
    }, methods:{
            goNext(el){
                 this.$emit("goPage", parseInt(this.meta.currentPage)+el)
            }

    }
}
</script>

<style lang="scss" scoped>
    .buttons {
        display: flex;
        justify-content: space-between;
    }

    .button-counter {
        font-size: 11px;

        display: flex;
        align-items: center;
        column-gap: 5px;
        
        padding: 9px;

        max-width: 115px;
    }

    .button__navigation {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }

    .button__navigation_arrow {
        width: 30px;
        height: 30px;

        &:last-child {
            transform: rotate(180deg);
        }
    }
</style>