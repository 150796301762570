<template>
    <AppButton class="helper">
        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.44678 7.39746H2.26611C2.27035 6.99121 2.30632 6.65902 2.37402 6.40088C2.44596 6.13851 2.56234 5.89941 2.72314 5.68359C2.88395 5.46777 3.09766 5.22233 3.36426 4.94727C3.55892 4.74837 3.73665 4.56217 3.89746 4.38867C4.0625 4.21094 4.1958 4.02051 4.29736 3.81738C4.39893 3.61003 4.44971 3.36247 4.44971 3.07471C4.44971 2.78271 4.39681 2.53092 4.29102 2.31934C4.18945 2.10775 4.03711 1.94482 3.83398 1.83057C3.63509 1.71631 3.38753 1.65918 3.09131 1.65918C2.84587 1.65918 2.61312 1.70361 2.39307 1.79248C2.17301 1.88135 1.99528 2.01888 1.85986 2.20508C1.72445 2.38704 1.65462 2.62614 1.65039 2.92236H0.476074C0.484538 2.44417 0.603027 2.03369 0.831543 1.69092C1.06429 1.34814 1.37744 1.08577 1.771 0.903809C2.16455 0.721842 2.60465 0.630859 3.09131 0.630859C3.62874 0.630859 4.08577 0.72819 4.4624 0.922852C4.84326 1.11751 5.13314 1.39681 5.33203 1.76074C5.53092 2.12044 5.63037 2.54785 5.63037 3.04297C5.63037 3.42383 5.55208 3.77507 5.39551 4.09668C5.24316 4.41406 5.04639 4.7124 4.80518 4.9917C4.56396 5.271 4.30794 5.5376 4.03711 5.7915C3.80436 6.00732 3.64779 6.25065 3.56738 6.52148C3.48698 6.79232 3.44678 7.08431 3.44678 7.39746ZM2.21533 9.40967C2.21533 9.21924 2.27458 9.05843 2.39307 8.92725C2.51156 8.79606 2.68294 8.73047 2.90723 8.73047C3.13574 8.73047 3.30924 8.79606 3.42773 8.92725C3.54622 9.05843 3.60547 9.21924 3.60547 9.40967C3.60547 9.59163 3.54622 9.74821 3.42773 9.87939C3.30924 10.0106 3.13574 10.0762 2.90723 10.0762C2.68294 10.0762 2.51156 10.0106 2.39307 9.87939C2.27458 9.74821 2.21533 9.59163 2.21533 9.40967Z" fill="white"/>
        </svg>
    </AppButton>
</template>

<script>
export default {
    name: 'AppHelper'
}
</script>

<style lang="scss" scoped>
    .helper {
        border-radius: 50%;

        min-width: 20px;
        width: 20px;
        height: 20px;
    }
</style>