<template>
    <div class="select-form">
        <label class="select__title" for="">
            <slot name="title"></slot>
        </label>
        <div class="select__content">
            <div class="select__block"
            @click="editShow()">
                <img v-if="model && model.icon_dark" :src="require('@/assets/'+model.icon_dark)" style="margin-right: 5px; margin-top: 2px;"/>
                <input class="select" 
                    @click="update()"
                    :value="getValue()" 
                    disabled>
                    <svg class="select__svg"
                    :class="{'_rotate180': this.isShow}" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.31651 6.86825L6.38094 6.93268L11.6568 1.65681L10.4141 0.414061L5.92969 4.89844L1.51562 0.484375L0.343209 1.65679L5.93559 7.24917L6.31651 6.86825Z" fill="black"/>
                    </svg>
            </div>
            
            <ul class="select__items" v-if="!disabled"
            :class="{'_show': this.isShow}">
                <li class="select_item"
                    v-for="item in items" 
                    :key="item.name" 
                    @click="editSelectItem(item.name)">
                    <img v-if="item.icon_dark" :src="require('@/assets/'+item.icon_dark)" style="margin-right: 5px; margin-top: 2px;"/>
                    {{ item.title }} 
                </li>
            </ul>
        </div>
    </div>
</template>

<script> 
export default {
    name: 'AppSelectV2',
    data() {
        return {
            
            isShow: false,
            selectedItem: null
        }
    },
  
    props: {
        parentShow: {
            type: Boolean,
            required: false       
        },
        block: {
            type: Boolean,
            required: false       
        },
        items: {
            type: Array,
            required: true       
        },
        disabled: {
            type: Boolean,
            default: false       
        },
        modelValue: {
            type: [String, Number]
        }
    },
    computed: {
        model: function () {
            let f = this.modelValue
            return this.items.find(e=> e.name == f)
        }
    },
    methods: {
        check(){
            console.log('check')
        },
        getValueIcon(){
            if (this.model==undefined) return ""
          return  this.model.icon || ""
        },
        getValue(){
            if (this.model==undefined) return ""
            return  this.model.title || this.items[0].title
        },
        editSelectItem(item) {
            this.editShow();
            this.$emit('update:modelValue', item);
        }, 
        editShow() {
            if (this.block) return this.isShow = false
            let result = this.isShow = !this.isShow;
            this.$emit("isShow",result)
            return result
        }
    },
    watch:{
        parentShow(parentShow){
            if (!parentShow) this.isShow = false
        },
    }
}
</script>

<style lang="scss" scoped>
    .select-form {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .select__title {
        color: #7E7E7E;
        font-size: 12px;
    }
    .select__content {
        position: relative;
        &:hover {
            .select__svg > * {
                fill: var(--default_background);
            }
        }
    }
    .select__block {
        display: flex;
        align-items: center;
        position: relative;
        background: #EEF4F8;
        border: 1px solid #E4E7ED;
        border-radius: 6px;
        padding-left: 10px;
        width: 100%;
    }
    .select__items {
        background: #FFFFFF;
        border: 1px solid #E4E7ED;
        border-top: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: none;
        font-size: 12px;
        position: absolute;
        width: 100%;
        z-index: 10;
        &._show {
            display: block;
        }
    }
    .select_item {
        border-bottom: 1px solid #E4E7ED;
        cursor: pointer;
        color: #000;
        padding: 11px;
        transition: 200ms;
        &:last-child {
            border-bottom: 0;
        }
        &:hover {
            background: var(--default_background_hover);
            
            color: #FFFFFF;
        }
        &:active {
            background: var(--default_background_active);
        }
    }
    .select {
        background: #EEF4F8;
        border-bottom: 1px solid #E4E7ED;
        border-right: 1px solid #E4E7ED;
        border-top: 1px solid #E4E7ED;
        border-radius: 6px;
        padding: 11px;
        padding-right: 28px;
        width: 100%;
    }
    .select__svg {
        position: absolute;
        right: 12px;
        transition: 200ms;
        * {
            transition: 200ms;
        }
        &._rotate180 {
            transform: rotate(180deg);
        }
    }
</style>