<template>
  <div class="container-notifications-description" >
    <h3 class="notes-title">{{ title }}</h3>
    <!--
    <AppButtonInsertText
          @click="insertTextToCursor()"
      >Удалить теги не поддерживаемые телеграм
      </AppButtonInsertText>
    -->
    <div class="button-insert_cont" 
         v-if="buttonInsert">
      <AppButtonInsertText
          v-for="(but, i) in buttonInsert"
          :key="i"
          @click="insertTextToCursor(but.text)"
      >{{ but.title }}
      </AppButtonInsertText>
    </div>
    <div class="textarea-notes" :style="(showStyle)?'display:blok':'display:none'">
      <div ref="demoText" class="demo-text" v-html="get_demo_text()" :style="{display: showDemo? 'block': 'none'}"></div>
      <ckeditor 
          :ref="'editor_'+index" 
          :class="'textarea_'+index" 
          :editor="editor"
          :model-value="description"
    
          @input="saveCurrentDescription"
          :config="geteditorConfig()"
          
          @ready="onReady">
      </ckeditor>
      <span v-if="LengthText" style="margin-top: -20px; color:red; font-size: 12px;" > {{LengthText}}
      <br>
    </span>
      <span v-if="LengthTextInfo" style="margin-top: -20px; color:red; font-size: 12px;" > {{LengthTextInfo}}
      <br>
    </span>
    </div>
    <div v-if="info" class="info">
      {{info}} 
    </div>
  </div>
</template>

<script>
//const sleep = ms => new Promise(r => setTimeout(r, ms));

//import HtmlFilter from '@ckeditor/ckeditor5-engine';
//import ClassicEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import CKEditor from '@ckeditor/ckeditor5-vue';

export default {
  name: "AppDescription",
  components: {
   // ckeditor: CKEditor.component,
  },

  props: {
   
    MaxLength:{
      type: Number,
    },
    LengthTextInfo:{
      type: String,
    },
    LengthText: {
      type: String,
      default: null
    },
    title: {
      type: String,
    },
    buttonInsert: {
      type: Array,
      default() {
        return [];
      }
    },
    readOnly:{
        type: Boolean.apply,
        default: false
    },
    info: {
      type: String,
    },
    index: {
      type: String, Number,
    },
    description: {
      type: Object
    },
    styleTextarea: {
      type: String
    },
  },
  data() {
    return {
      showStyle: false,
      local_editor: null,
      editor: ClassicEditor,
      currentDescription: " ",
      editorConfig: {
        // Define a custom data processor that removes the "h2" tag.
        dataProcessor: {
          htmlFilter: {
            elements: {
              h2: (element) => {
                // Remove the "h2" tag and return its content.
                return element.getChild(0);
              },
            },
          },
        },
      },
    }
  },
  methods: {
    geteditorConfig(){
      let data =  {
          extraAllowedContent: 'p(*)[*]{*};div(*)[*]{*};li(*)[*]{*};ul(*)[*]{*}',
          contentFilter: {
            allowedContent: 'p strong',
            disallowedContent:"h1 h2",
          },
          fontSize: {
            options: [ 9, 11, 13, 15, 17, 19, 21 ],
            defaultSize: '5px'
        },
          autoFormat: {
            removeEmptyElements: true,
            removeUnknownElements: true 
          },
          removePlugins: ['toolbar'],
          isReadOnly: true, 
          resize_minHeight: 500,
          autoGrow_minHeight: 500,
          height: 300,
          shouldNotGroupWhenFull: true,
        
          toolbar: {
              items: [
                /*
                'alignment', '|',
                'bold', '|', 'italic', //'strikethrough',  'bulletedList', 'numberedList'
                */
              ],
            }
          }
        //console.log(data,"data")
      return data
    },
    setShowDemo() {
      this.showDemo = !this.showDemo;
    },
    insertTextToCursor() {
      let text = this.description
      const regex = /<\/?(?!p|strong|em|i|del|br|u)[^>]*>/g;
      text = text.replace(regex, '');
      var d = document.createElement('div');
            d.innerHTML = text.replace(regex, '');
      this.local_editor.setData(d.innerHTML)
    
    
    },
    onReady(editor) {
     
      this.local_editor = editor;
      editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );

      let elem = document.getElementsByClassName('ck-toolbar');
      for (let index = 0; index < elem.length; index++) {
        elem[index].style.display = 'none'
      }
      elem = document.getElementsByClassName('ck-content');
      for (let index = 0; index < elem.length; index++) {
        elem[index].style.height = '100px'
        elem[index].style.frontSize = '5px'
      }
    
      
      if (this.readOnly){
        this.local_editor.enableReadOnlyMode('textarea_'+this.index)
       
      
     
      } 
       
      console.log(this.local_editor.config)
    },
    setFont(){

      setTimeout(() => {
            const editorStyle = document.getElementsByClassName('ck-content')
            for (let index = 0; index < editorStyle.length; index++) {
            // Select all p elements inside the div and set their font size
              const pElements = editorStyle[index].querySelectorAll('p');
              pElements.forEach(p => {
                p.style.fontSize = '12px';
            });
            }
            this.showStyle = true;
            }, 600);
        
    },
    get_demo_text() {
      let text = this.currentDescription;
      this.buttonInsert.forEach(but=>{
        text = text.replace(but.text, `<span style="color: #0A72C0">${but.demo}</span>`)
      });
      return text;
    },
    saveCurrentDescription(text) {
     /*
      if (typeof text === 'string' || text instanceof String){
          const regex = /<\/?(?!p|strong|em|i|del|br|u)[^>]*>/g;
          var d = document.createElement('div');
            d.innerHTML = text.replace(regex, '');
        
          if (d.innerHTML!=text) console.log("error")
          else console.log("good")
     
        }
        */
      this.currentDescription = text
   
      this.$emit('setDescription', this.currentDescription, this.index)
    },
  
  },
  watch:{
    local_editor(){
        this.setFont()
        console.log("setFont")
    },
    readOnly(readOnly){
      if (readOnly)
        this.local_editor.enableReadOnlyMode('textarea_'+this.index)
    }
  },
  created() {
           
  },
  mounted() {
    this.showStyle = false
    this.currentDescription = this.description;
  },
}
</script>


<style scoped lang="scss">
.container-notifications-description .textarea-form {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.container-notifications-description .textarea__title {
  color: #7E7E7E;
  font-size: 12px;
}

.container-notifications-description .textarea-notes {
  position: relative;
}
.textarea-notes p{
  font-size: 10px;
}
.ck-editor__editable {
    min-height: 150px;
}
.container-notifications-description .textarea-notes .ck-toolbar {
  border-radius: var(--default_border-radius) !important;
  border: 1px solid rgba(142, 162, 176, 0.5);
  background: #EEF4F8;
  display: inline-block;
  transform: scale(0.8);
  position: relative;
  left: -12px;
  z-index: 10;
}

.container-notifications-description .textarea-notes .ck-content {
  margin-top: 4px;
  margin-bottom: 10px;
  background: #EEF4F8;
  border: 1px solid rgba(142, 162, 176, 0.5);
  border-radius: var(--default_border-radius) !important;
  position: relative;
  padding-right: 30px;
}

.container-notifications-description .textarea-notes .ck-content:after {
  // content: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.0751 0.0446824C13.13 0.0707281 13.8029 0.721463 14.5705 1.49079C15.8074 2.7306 15.9686 2.90323 15.9881 3.00982C16.0366 3.27372 16.0016 3.32131 15.0771 4.24969L14.2209 5.10954L12.5555 3.44466L10.8902 1.77977L11.7186 0.950621C12.214 0.454847 12.5974 0.0965548 12.6721 0.0594406C12.8219 -0.0148505 12.9405 -0.0191966 13.0751 0.0446824ZM11.8905 4.12466L13.5475 5.78185L9.65482 9.6746C7.51386 11.8156 5.74813 13.5673 5.73097 13.5673C5.6901 13.5673 2.43211 10.3097 2.43211 10.2688C2.43211 10.238 10.187 2.46746 10.2177 2.46746C10.2264 2.46746 10.9792 3.21318 11.8905 4.12466ZM4.90933 14.1337C4.85552 14.1832 0.618674 15.9788 0.516587 15.9954C0.243248 16.0398 -0.0400022 15.7549 0.00467874 15.4806C0.0149969 15.4171 0.418814 14.4436 0.90205 13.3172C1.38529 12.1908 1.80111 11.2205 1.82612 11.161L1.87159 11.0529L3.40181 12.5828C4.24346 13.4243 4.92183 14.1222 4.90933 14.1337Z' fill='%238EA2B0'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: 10px;
  right: 10px;
}

.container-notifications-description .main-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-notifications-description .notes-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.75);
}

.container-notifications-description .button-insert_cont {
  display: flex;
  justify-content: start;
  align-items: center;
}

.container-notifications-description .button-with-icon {
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid #20A0FF;
  border-radius: 6px;
  padding: 0 10px;
  height: 36px;
  color: #20A0FF;
  font-size: 13px;
  width: 120px;
  cursor: default;
}

.container-notifications-description .button-with-icon:hover {
  color: #48B1FF;
  border-color: #48B1FF;
}


.container-notifications-description .button-with-icon:active {
  color: #1A80CC;
  border-color: #1A80CC;
}

.container-notifications-description .button-with-icon svg {
  margin-right: 5px;
}

.container-notifications-description .info {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.5);
  text-indent: 1em;
  position: relative;
}

.container-notifications-description .demo-text {
  font-family: Arial;
  width: 100%;
  height: calc(100% - 44px);
  background: #EEF4F8;
  z-index: 2;
  padding: 14px 10px;
  border-radius: var(--default_border-radius);
  border: 1px solid rgba(142, 162, 176, 0.5);
}

.container-notifications-description .info:before {
  content: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' rx='3' fill='%2320A0FF'/%3E%3Crect x='0.5' y='0.5' width='5' height='5' rx='2.5' stroke='%238EA2B0' stroke-opacity='0.5'/%3E%3C/svg%3E%0A");
  position: absolute;
  left: -10px;
  top: -1px;
}

.container-notifications-description-description {
  border: 1px solid rgba(142, 162, 176, 0.5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 15px;
}

</style>