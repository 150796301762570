<template>
    <div class="bot-setting block-style">

        <span class="info"> <span style="color: red;">*</span> Внимание! При подключении данной кнопки, остальные кнопки будут отключены, текст и медиа файлы будут заменены текстом и медиа файлами из данной кнопки. Любые действия пользователя будут заблокированы, до того момента, пока он не отправит свой номер телефона. После того, как он укажет свой номер телефона ему будет отправлено сообщение и кнопки.</span>
        <AppInputForm
            v-model="button.name"
            :placeholder="'Название кнопки'">
                <template v-slot:title>
                    <AppTitleSettingParam> 
                        Название кнопки
                    </AppTitleSettingParam> 
                </template>
        </AppInputForm>  
        <ElementGlobalMessage v-if="button" 
            title="Текст сообщения если нет номера телефона" 
            :inmessage="button" 
            @updateText="updateText"
            @updateMedia="updateMedia"/>
        <ElementActionButton  v-model="button" :button="button"  @save="save" @del="del"/>
    </div>
</template>
<script>
import ElementGlobalMessage from "@/components/ElementGlobalMessage"
import ElementActionButton from "@/components/ButtonElements/ElementActionButton"
export default {
  name: "ElementPhone",
  components: {
    ElementGlobalMessage, ElementActionButton
  },
  props: ["model"],
  data() {
    return {
        button: {}
    }
  },
  mounted(){
   
    this.button = JSON.parse(JSON.stringify(this.model))
    this.button.textX =  this.button.text
    
  },
  methods: {
    updateText(value){
        if (this.button.textX)  {value = this.button.textX; this.button.textX = null}
        this.button.text = value

    },
    updateMedia(type, media){
        this.button.media_type = type
        this.button.media = media
    },
    save(){
        if (!this.button.name) { this.showMessageNotice('', "Укажите название кнопки",'error'); return;}
        if (!this.button.text) { this.showMessageNotice('', "Укажите текст сообщения",'error'); return;}
        this.$emit('save',this.button);

    },
    del(){
        this.$emit('del',this.button);
    }
  },
  watch: {
    model(){
        this.button = JSON.parse(JSON.stringify(this.model))
        console.log(this.button.message)
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
    .info {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
    }
</style>
