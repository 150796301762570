<template>
    <a class="link">
        <slot></slot>
    </a>
</template>

<script>
export default {
    name: 'AppHref'
}
</script>

<style lang="scss" scoped>
    .link {
        color: var(--default_background);
        text-decoration: underline;
    }
</style>