<template>
  <section class="section-chat chat section">
    <div class="container">
      <AppLoadingOverlay v-if="loadModels"/>
      <component
          :is="currentPage"
          :clients="allPeoples" 
          :clientId="currentIdClient"
          :filterContent="filterContent"
          :peoplesMeta="peoplesMeta"
          @exec="exec"
      />
    </div> 
  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import SubPageCardClient from '@/pages/people/SubPageCardClient';
import SubPageChatClient from '@/pages/people/SubPageChatClient';
import SubPageClientList from '@/pages/people/SubPageClientList';
import api from './people/api'
function addSlowScroll(el, callback) {
  let ctx = this;
  document.addEventListener(
      'scroll', function () {
        setTimeout(function () {
          callback();
          if (ctx.c_isLive) addSlowScroll.call(ctx, el, callback);
        }, 300);
      },
      {once: true}
  );
}
export default { 
  name: "PageClient",
  data() { 
      return {
        loadModels: true,
        shop_id: JSON.parse(localStorage.getItem('shop_id')),
        allPeoples:[], 
        peoplesMeta: [],
        search: "",
        page: 1,
        pageSize: 10,
        lastPage: 2,
        c_isLive: true,
        filterContent: {
          title: 'Фильтр', 
          placeholderSearch: "Поиск по имени, id",
          filters: {
            sort: {
              title: 'Сортировать:',
              type: 'radio',
              elem: 'sort',
              name: 'sort',
              items: JSON.parse(localStorage.getItem('people_sort')),
              current: "id,desc",
            },
            status: {
              title: 'Выбрать тип',
              type: 'radio',
              elem: 'filter',
              name: 'types',
              items: JSON.parse(localStorage.getItem('people_types')),
              current: "",
            }
          }
        }, 
        currentPage: 'SubPageClientList',
        currentIdClient: 'SubPageClientList',
         
      };
  },
  components: {
    SubPageCardClient,
    SubPageChatClient,
    SubPageClientList,
  },
  computed: mapGetters([ 'allCategories']),
  created() {
  
  },
  methods: {
    ...mapActions(['getCategories', 'asyncDeletePeople', 'asyncCreatePeople', 'asyncUpdatePeople']),
    exec(data){
      this[data.action](data.value);
    },
   
    async checkEndList() {
      let scrollContainer = document.querySelector('html');
      let scrollBottom =
          scrollContainer.scrollHeight -
          scrollContainer.clientHeight -
          scrollContainer.scrollTop;
      scrollBottom
          /*
      if (scrollBottom < 300 && this.page < this.lastPage) {
        let data = await this.getPeoples({
          page: ++this.page,
          pageSize: this.pageSize,
          shop_id: this.shopInfo.id,
          add: true,
        });
        this.lastPage = data.meta.lastPage;
      }
      */
    },
    addListeners() {
      let scrollContainer = document.querySelector('html');
      addSlowScroll.call(this, scrollContainer, this.checkEndList.bind(this));
    },
    Name(item) {
      this.userNotification = item;
      this.showEmailForm = true
    },
    goSearch(input) {
      this.search = input
      this.page =1;
      this.getModels()
    },
  
    deletePeople(id) {
      this.asyncDeletePeople({
        id
      })
    },
    goPage(page) {

      this.page = page
      this.getModels()
    },
    async getModels() {
      this.loadModels = true
      let data = {
        shop_id: this.shop_id,
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        filter: this.filter
      }
      const response = await api.getPeoples(data)
      this.allPeoples = response.data.data
      this.peoplesMeta= response.data.meta
      this.loadModels = false
      //const ids = this.allPeoples.map(people => people.id);
      //await this.getPeoplesInfo(ids);
    },
    async getPeoplesInfo(ids){
        const response = await api.getPeoplesInfo({ids:ids})
        const info = response.data.data
        let peopleId=null
        let infoData = null
        for (let index = 0; index < this.allPeoples.length; index++) {
          peopleId =  this.allPeoples[index].id
          infoData = info.find(e=>e.id==peopleId)
          if (infoData == undefined) continue
          if (infoData.messages_count) this.allPeoples[index].messages_count = infoData.messages_count
          
        }
      },
    startFiltered(filter) {
      this.filter = filter.filter
      /*
      for (const filterName in this.filterContent.filters) {
        let filter = this.filterContent.filters[filterName];
        this.search[filterName] = ''
        filter.items.forEach(itm => {
          if (itm.selected) {
            this.search[filterName] += itm.id + ',';
          }
        })
        if (this.search[filterName]) this.search[filterName] = this.search[filterName].replace(/,$/g, '');
        else delete this.search[filterName];
      }
      */
      this.getModels();
    },
  },
  watch: { 
    
  },
  provide() {
    let self = this;
    return {
      setCurrentPage(name, id) {
        self.currentPage = name
        self.currentIdClient = id
      },
      startFiltered: this.startFiltered.bind(this),
    }
  },
  mounted() {
    //this.getModels();
    //this.addListeners();
  },
  unmounted() {
    this.c_isLive = false
  }
}
</script>

<style lang="scss" scoped>
.clients-table__content {
  padding: 11px 13px;
  margin-bottom: 14px;
}
.clients {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}
.client {
  position: relative;
  padding-top: 8px;
  &::before {
    background: var(--global_block_line);
    content: "";
    position: absolute;
    top: 0;
    height: 2px;
    width: 100%;
  }
  &:first-child {
    padding-top: 0;
    &::before {
      background: none;
      width: 0;
      height: 0;
    }
  }
}
.client__short-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.client__short-info_item {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.client__short-info_user {
  grid-column: 2/4;
}
.client__statistics {
  border-top: 1px solid var(--global_block_line);
  border-bottom: 1px solid var(--global_block_line);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 9px 0;
  margin: 9px 0;
}
.client__statistics_item {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.client__user {
  color: var(--global_second_color);
  font-size: 14px;
}
.client__statistics_item-text {
  color: rgba(0, 0, 0, 0.75);
  font-size: 12px;
  font-weight: 400;
}
.client__interaction_button {
  width: 94px;
  height: 30px;
}
</style>