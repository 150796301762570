import axios from 'axios';
import { BACKEND_URL_PEOPLES } from '@/store'

export default {
    actions: {
        async getPeoples(ctx, data) {
            if (!data.shop_id) return
            //console.log(data,"data")
            let res = await axios.get(BACKEND_URL_PEOPLES,  {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: data
            })
            res.data.add = data.add
                ctx.commit('setPeoples', res.data);
            return res.data;
        },
        async getPeople(ctx, id) {
          
            axios.get(BACKEND_URL_PEOPLES+"/"+id,  {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
            .then(res => {
                
                ctx.commit('setPeople', res.data)
            })
        },
        async asyncCreatePeople(ctx, newPeople) { 
            axios.post(BACKEND_URL_PEOPLES, newPeople, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('createPeople', res.data.data))
            .catch(err => console.log(err));
        },
        async asyncUpdatePeople(ctx, editedPeople) {
            console.log(editedPeople);

            axios.put(`${BACKEND_URL_PEOPLES}/${editedPeople.id}`, editedPeople, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));

           // ctx.commit('updatePeople', editedPeople)
        },
        async asyncDeletePeople(ctx, order) {
            axios.delete(`${BACKEND_URL_PEOPLES}/${order.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).
            then(() => ctx.commit('deletePeople', order))
        }
    },
    mutations: {
        setPeoples(state, data) {
            if (data.add) {
                state.peoples.push(...data.data);
            } else {
                state.peoples = data.data;
            }
            state.peoplesMeta = data.meta;
        },
        setPeople(state, data) {
            state.people = data.data;
        },
        createPeople(state, newPeople) {
            state.peoples.push(newPeople);
        },
        updatePeople(state, editedPeople) {
            state.peoples = state.peoples.map(order => {
                if (order.id === editedPeople.id) {
                    order = editedPeople;
                }
            });
        },
        deletePeople(state, order) {
            state.peoples = state.peoples.filter(peoples => order.id !== peoples.id)
        }
    },
    state: {
        peoples: [],
        peoplesMeta: {}
    },
    getters: {
        allPeoples(state) {
            return state.peoples
        },
        People(state) {
            return state.people
        },
        peoplesMeta(state) {
            return state.peoplesMeta
        }
    }
}