<template>
  <div class="container-notifications">
    <h3 class="notes-title">{{ title }}</h3>
    
    <div class="info"> {{ info }} </div>
    <div class="textarea-notes">
      <div ref="demoText" class="demo-text" v-html="get_demo_text()"
        :style="{display: showDemo? 'block': 'none', margin: '10px 0'}">
      </div>
      <div :style="{display: showDemo? 'none' : 'block'}">
        <ckeditor
            :ref="'editor_'+index"
            :class="'textarea_'+index"
            :editor="editor"
            :model-value="model.text"
            :style="styleTextarea"
            @input="saveCurrentDescription"
            :config="editorConfig"
            @ready="onReady">
        </ckeditor>
      </div>
    </div>
    <div class="button-insert_cont"
         v-if="buttonInsert">
      <AppButtonInsertText
          v-for="(but, i) in buttonInsert"
          :key="i"
          @click="insertTextToCursor(but.text)"
      >{{ but.title }}
      </AppButtonInsertText>
    </div>
    <div class="main-buttons">
      <button class="button-with-icon" @click="setShowDemo">
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.46709 0.0192479C6.13752 0.211102 2.995 1.99453 0.489636 5.11406C-0.164527 5.92857 -0.164015 6.07329 0.496016 6.89733C2.99622 10.0189 6.14957 11.8013 9.49485 11.9839C13.2811 12.1906 16.8586 10.4017 19.6769 6.89241C20.3311 6.07789 20.3306 5.93318 19.6706 5.10913C17.8442 2.82886 15.6713 1.26217 13.2683 0.493095C12.5219 0.254183 11.6175 0.0796158 10.9102 0.0378347C10.1384 -0.0077267 9.97197 -0.00985317 9.46709 0.0192479ZM10.9162 1.85265C12.048 2.06569 13.1356 2.83551 13.7246 3.84058C14.4355 5.05349 14.5104 6.52067 13.9249 7.76044C13.6964 8.24425 13.4781 8.55893 13.102 8.94653C10.997 11.1158 7.4106 10.4526 6.18978 7.66825C5.74921 6.66346 5.7409 5.43606 6.16761 4.3887C6.58274 3.36973 7.48546 2.47752 8.51085 2.07282C9.29063 1.76507 10.0712 1.6936 10.9162 1.85265ZM9.66981 3.75706C9.02943 3.89555 8.46261 4.29501 8.13801 4.83663C7.89937 5.23479 7.81932 5.53041 7.82207 6.00323C7.82479 6.45948 7.86539 6.6435 8.04776 7.02606C8.21535 7.37756 8.70479 7.86546 9.06365 8.03877C10.3274 8.64899 11.7972 8.0283 12.252 6.69236C12.3821 6.31019 12.3857 5.71104 12.2604 5.31245C11.9741 4.40275 11.1386 3.76887 10.1817 3.73536C9.98681 3.72855 9.75645 3.73831 9.66981 3.75706Z"
                fill="#20A0FF"/>
        </svg>
        <span v-if="!showDemo">Просмотр</span>
        <span v-else>Закрыть</span>
      </button>
      <AppButton
          :style="{width: '120px', height: '36px'}"
          :styleButton="'_red'"
          @click="save()"
      >
        Сохранить
      </AppButton>
    </div>
    
  </div>
</template>

<script>
//import ClassicEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
  name: "AppNotifications",
  components: {},

  props: {
    title: {
      type: String,
    },
    buttonInsert: {
      type: Array,
      default() {
        return [
          {
            "id": 5,
            "title": "Адрес",
            "text": "{%address%}"
          },
          {
            "id": 5,
            "title": "ФИО",
            "text": "{%fio%}"
          },
          {
            "id": 5,
            "title": "Заказ №",
            "text": "{%order_id%}"
          }
        ];
      }
    },
    info: {
      type: String,
    },
    model: {
      type: Object
    },
    styleTextarea: {
      type: String
    },
  },
  data() {
    return {
      local_editor: null,
      editor: ClassicEditor,
      showDemo: false,
      editorData: "",
      currentDescription: "",
      editorConfig: {
        toolbar: {
          items: [
            /*
            'alignment', '|',
            'bold', '|', 'italic', //'strikethrough',  'bulletedList', 'numberedList'
            */
          ],
          shouldNotGroupWhenFull: true
        }
      },
    }
  },
  methods: {
    setShowDemo() {
      this.showDemo = !this.showDemo;
    },
    insertTextToCursor(text) {
      this.local_editor.model.change(writer => {
        writer.insertText(text, this.local_editor.model.document.selection.getFirstPosition());
      });
    },
    onReady(editor) {
      let elem = document.getElementsByClassName('ck-toolbar');
      for (let index = 0; index < elem.length; index++) {
        elem[index].style.display = 'none'
      }
      this.local_editor = editor;
      editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );
      
    },
    get_demo_text() {
      let text = this.currentDescription;
      if (!text || typeof text !== 'string') return;
      this.buttonInsert.forEach(but => {
        text = text.replace(but.text, `<span style="color: #0A72C0">${but.demo}</span>`)
      });
      return text;
    },
    saveCurrentDescription(text) {
      this.currentDescription = text;
    },
    save() {
      console.log(this.model, this.currentDescription)
      let data = {
        id: this.model.id,
        text: this.currentDescription,
      }
      this.$emit('updateNotice', data)
     
    },
  },
  mounted() {
    this.currentDescription = this.model.text;
  }
}

</script>


<style lang="scss">

.container-notifications .textarea-form {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.container-notifications .textarea__title {
  color: #7E7E7E;
  font-size: 12px;
}

.container-notifications .textarea-notes {
  position: relative;
}

.container-notifications .textarea-notes .ck-toolbar {
  border-radius: var(--default_border-radius) !important;
  border: 1px solid rgba(142, 162, 176, 0.5);
  background: #EEF4F8;
  display: inline-block;
  transform: scale(0.8);
  position: relative;
  left: -12px;
  z-index: 10;
}

.container-notifications .textarea-notes .ck-content {
  margin-top: 4px;
  margin-bottom: 10px;
  background: #EEF4F8;
  border: 1px solid rgba(142, 162, 176, 0.5);
  border-radius: var(--default_border-radius) !important;
  position: relative;
  padding-right: 30px;
}

.container-notifications .textarea-notes .ck-content:after {
  // content: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.0751 0.0446824C13.13 0.0707281 13.8029 0.721463 14.5705 1.49079C15.8074 2.7306 15.9686 2.90323 15.9881 3.00982C16.0366 3.27372 16.0016 3.32131 15.0771 4.24969L14.2209 5.10954L12.5555 3.44466L10.8902 1.77977L11.7186 0.950621C12.214 0.454847 12.5974 0.0965548 12.6721 0.0594406C12.8219 -0.0148505 12.9405 -0.0191966 13.0751 0.0446824ZM11.8905 4.12466L13.5475 5.78185L9.65482 9.6746C7.51386 11.8156 5.74813 13.5673 5.73097 13.5673C5.6901 13.5673 2.43211 10.3097 2.43211 10.2688C2.43211 10.238 10.187 2.46746 10.2177 2.46746C10.2264 2.46746 10.9792 3.21318 11.8905 4.12466ZM4.90933 14.1337C4.85552 14.1832 0.618674 15.9788 0.516587 15.9954C0.243248 16.0398 -0.0400022 15.7549 0.00467874 15.4806C0.0149969 15.4171 0.418814 14.4436 0.90205 13.3172C1.38529 12.1908 1.80111 11.2205 1.82612 11.161L1.87159 11.0529L3.40181 12.5828C4.24346 13.4243 4.92183 14.1222 4.90933 14.1337Z' fill='%238EA2B0'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: 10px;
  right: 10px;
}

.container-notifications .main-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-notifications .notes-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.container-notifications .button-insert_cont {
  margin-top: -10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.container-notifications .button-with-icon {
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid #20A0FF;
  border-radius: 6px;
  padding: 0 10px;
  height: 36px;
  color: #20A0FF;
  font-size: 13px;
  width: 120px;
  cursor: default;
}

.container-notifications .button-with-icon:hover {
  color: #48B1FF;
  border-color: #48B1FF;
}


.container-notifications .button-with-icon:active {
  color: #1A80CC;
  border-color: #1A80CC;
}

.container-notifications .button-with-icon svg {
  margin-right: 5px;
}

.container-notifications .info {
  margin-top: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.5);
  text-indent: 1em;
  position: relative;
}

.container-notifications .demo-text {
  width: 100%;
  height: calc(100% - 44px);
  background: #EEF4F8;
  z-index: 2;
  padding: 14px 10px;
  border-radius: var(--default_border-radius);
  border: 1px solid rgba(142, 162, 176, 0.5);
}

.container-notifications .info:before {
  content: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' rx='3' fill='%2320A0FF'/%3E%3Crect x='0.5' y='0.5' width='5' height='5' rx='2.5' stroke='%238EA2B0' stroke-opacity='0.5'/%3E%3C/svg%3E%0A");
  position: absolute;
  left: -10px;
  top: -1px;
}

.container-notifications {
  border: 1px solid rgba(142, 162, 176, 0.5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 15px;
}

</style>