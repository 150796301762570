import axios from 'axios'

export const MAILLING = `${process.env.VUE_APP_LINK}/api/v1/maillings`;

export default {
 
    createMailingData(data) {
        return axios.post(`${MAILLING}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateMailingData(id, data) {
        return axios.put(`${MAILLING}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getMaillings(data) {
        return axios.get(`${MAILLING}`,  {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },
    sendMailling(id,data=null) {
        return axios.post(`${MAILLING}/send/${id}`,data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    sendTestMailling(data) {
        return axios.post(`${MAILLING}/send/test`,data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    deleteMailling(id) {
        return axios.delete(`${MAILLING}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    
   
}