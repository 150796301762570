<!-- EditableText.vue -->
<template>
  <div class="text-container">
    
    <h3 v-if="title">{{ title }}</h3>
    <slot 
        name="title"
        v-else
    />
    <div
      ref="inputArea"
      contenteditable="true"
      @paste="handlePaste"
      @input="handleInput"
      class="textarea"
    />
    <span v-if="LengthText" style="margin-top: -20px; color:red; font-size: 12px;" > {{LengthText}}
      <br>
    </span>
    <span v-if="LengthTextInfo" style="margin-top: -20px; color:red; font-size: 12px;" > {{LengthTextInfo}}
    <br>
    </span>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'EditableText',
  props: ['modelValue','MaxLength','LengthTextInfo','LengthText','title'],
  setup(props, { emit }) {
    const linkText = ref('');
    const linkUrl = ref('');
    const isModalOpen = ref(false);

    const addLink = () => {
      const linkHtml = `<a href="${linkUrl.value}">${linkText.value}</a>`;
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        let range = sel.getRangeAt(0);
        range.deleteContents();
        const el = document.createElement("div");
        el.innerHTML = linkHtml;
        const frag = document.createDocumentFragment();
        let node;
        let lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
      isModalOpen.value = false;
      linkText.value = '';
      linkUrl.value = '';
    };
    const inputArea = ref(null);
    
    onMounted(() => {
    
      if (inputArea.value) {
        inputArea.value.innerHTML = props.modelValue;
      }
    });
    const sanitizeText = (text) => {
      const temp = document.createElement('div');
      text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      temp.innerHTML = text;

      // Remove comments
      Array.from(temp.childNodes).forEach((node) => {
        if (node.nodeType === Node.COMMENT_NODE) {
          node.remove();
        }
      });

      // Remove style elements
      Array.from(temp.querySelectorAll('style, script, link, xml')).forEach((el) => el.remove());

      // Remove all attributes except for some allowed ones
      const allowedAttributes = ['href', 'src', 'alt', 'title'];
      Array.from(temp.querySelectorAll('*')).forEach((el) => {
        Array.from(el.attributes).forEach((attr) => {
          if (!allowedAttributes.includes(attr.name)) {
            el.removeAttribute(attr.name);
          }
        });
        // Remove unwanted styles
        el.style.cssText = '';
      });

      // Remove empty style attributes
      Array.from(temp.querySelectorAll('*')).forEach((el) => {
        if (el.getAttribute('style') === '') {
          el.removeAttribute('style');
        }
      });

      // Remove unwanted tags
      const unwantedTags = ['html', 'head', 'body', 'meta'];
      unwantedTags.forEach((tag) => {
        Array.from(temp.getElementsByTagName(tag)).forEach((el) => el.remove());
      });

      return temp.innerHTML;
    };

    const handlePaste = (event) => {
      event.preventDefault();
      const text = event.clipboardData.getData('text/html') || event.clipboardData.getData('text/plain');
      const sanitizedText = sanitizeText(text);
      console.log(sanitizedText, "sanitizedText")
      document.execCommand('insertHTML', false, sanitizedText);
      emit('update:modelValue', inputArea.value.innerHTML);
    };

    const handleInput = () => {
      emit('update:modelValue', inputArea.value.innerHTML);
    };

    return {
      inputArea,
      handlePaste,
      handleInput,
      linkText,
      linkUrl,
      isModalOpen,
      addLink
    };
  },
};
</script>

<style scoped>
.textarea {
  resize: none;
  min-height: 100px;
  width: 100%;
  box-sizing: border-box;
  background: #EEF4F8;
  border: 1px solid rgba(142, 162, 176, 0.5);
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

}
.text-container h3 {
  font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.75);
}

</style>