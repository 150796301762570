<template>
  <div class="open-list_container">
    <div class="open-list_header"
         @click="open=!open">
      {{ header }}
      <svg class="arrow-open"
           :style="{transform: open ? '':'rotateZ(180deg)'}"
           width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.49578 1.76378L9.52716 1.7324L7.79495 0.000187874L7.76357 0.0315676L7.73219 0.000187874L5.99998 1.7324L6.03136 1.76378L0 7.79513L1.73221 9.52734L7.76357 3.49599L13.7949 9.52734L15.5271 7.79513L9.49578 1.76378Z" fill="#0A72C0"/>
      </svg>
    </div>
    <ul v-if="open">
      <li class="message-container"
          v-for="(item, i) in items"
          :key="i"
      >
        <span class="open-list_main-message">{{(i+1)+ '. ' + item[0] + ' ' }} </span>
        <span class="open-list-message">{{ item[1] }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AppOpenList",
  data() {
    return {
      open: false
    }
  },
  props: {
    header: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
}
</script>

<style scoped>

.open-list_container {
  padding: 20px 0;
}

.open-list_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0A72C0;
  margin-bottom: 10px;
}


.message-container {

}
.arrow-open {
  transition: 0.2s;
}
.open-list_main-message {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 136.4%;
  color: #000000;
}

.open-list-message {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 136.4%;
  color: rgba(0, 0, 0, 0.5);
}

</style>