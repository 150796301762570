import axios from 'axios';
import { BACKEND_URL_SHOP } from '@/store'
import { BACKEND_URL_SHOP_THEMES } from '@/store'


export default {
    actions: {
        async getShopInfo(ctx) {
            axios.get(`${BACKEND_URL_SHOP}/`+localStorage.getItem("shop_id"), {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('setShopInfo', res.data.data))
        }, 
        async getThemes(ctx) {
            axios.get(BACKEND_URL_SHOP_THEMES, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('setThemes', res.data.data))
        },
        async asyncUpdateShop(ctx, shop) {
            axios.put(`${BACKEND_URL_SHOP}/${shop.id}`, shop, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
        }
    },
    mutations: {
        setShopInfo(state, shop) {
            state.shop = shop;
        },
        setThemes(state, themes) {
            state.themes = themes;
        },
        setBotInfo(state, shop) {
            state.shop = shop;
        },
        updateShop(state, shop) {
            state.shop = shop;
        }

    },
    state: {
        shop: []
    },
    getters: {
        getterTheme(state) {
            return state.themes
        },
        shopInfo(state) {
            return state.shop
        }
    }
}