<template>
    <router-link class="catalog__link" to="/catalog">
        <AppButton class="catalog__button">
            <div class="catalog__button_text">
                Категории товаров
            </div>
            <svg class="catalog__button_svg" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="8" height="1.77778" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 7.0647 6.41602)" fill="#D9D9D9"/>
                <rect width="8" height="1.77778" transform="matrix(-0.707107 0.707107 0.707107 0.707107 10.6812 0.416016)" fill="#D9D9D9"/>
            </svg>
        </AppButton>
    </router-link>
</template>

<script>
export default {
    name: 'AppCatalogButtonLink'
}
</script>

<style lang="scss" scoped>
    .catalog__link {
        display: inline-block;

        width: 100%;
    }

    .catalog__button {
        font-weight: 500;

        padding: 12px 0;

        column-gap: 15px; 
    }
</style>