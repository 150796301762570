<template>
    <div class="bot-setting block-style">

        <span class="info"> <span style="color: red;">*</span> Внимание! При добавлении рекламной кнопки вы не можете использовать другие виды кнопок. Если вы уже их добавили, пожалуйста, удалите.</span>
        <AppInputForm
            v-model="button.name"
            :placeholder="'Название кнопки'">
                <template v-slot:title>
                    <AppTitleSettingParam>
                        Название кнопки
                    </AppTitleSettingParam>
                </template>
        </AppInputForm> 
        <div class="bot-setting__lock">
            <AppTitleSettingParam>
                Ссылка на бота  
            </AppTitleSettingParam> 
            <div class="bot-setting__text">
                <a :href="button.link" target="_blank">{{ button.link }}</a>
            </div>
        </div>
      
        <ElementGlobalMessage v-if="button" 
            title="Текст сообщения при переходе с кнопки" 
            :inmessage="button.message" 
            @updateText="updateText"
            @updateMedia="updateMedia"/>
        <ChatElementActionButton  v-model="button" :button="button" :elements="[]" @save="save" @del="del"/>
    </div>
</template>
<script>
import ElementGlobalMessage from "@/components/ElementGlobalMessage"
import ChatElementActionButton from "@/components/ChatButtonElements/ChatElementActionButton"
export default {
  name: "ElementReklambot",
  components: {
    ElementGlobalMessage, ChatElementActionButton
  },
  props: ["model"],
  data() {
    return {
        button: {}
    }
  },
  
  mounted(){
    //console.log(this.model,"mounted")
    this.button = JSON.parse(JSON.stringify(this.model))
    
  },
  methods: {
    updateText(value){
        this.button.message.text = value

    },
    updateMedia(type, media){
        this.button.message.media_type = type
        this.button.message.media = media
    },
    save(){
        
        if (!this.button.name) { this.showMessageNotice('', "Укажите название кнопки",'error'); return;}
        if (!this.button.message.text) { this.showMessageNotice('', "Укажите текст сообщения",'error'); return;}
        this.$emit('save',this.button);

    },
    del(){
        this.$emit('del',this.button);
    }
  },
  watch: {
    model(){
        //console.log(this.model,"model")
        this.button = JSON.parse(JSON.stringify(this.model))
    }
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
    .info {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
    }
</style>
