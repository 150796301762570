<template>
    <div>
        <AppButtonAdd
            @click="this.showAddressForm = !this.showAddressForm">
          Добавить зону доставки
        </AppButtonAdd>
        <div
            class="address__form-add"
            v-if="this.showAddressForm">
            <AppSelectSearch
                v-model="element.city_id"
                @click="updateCity()"
                @updateList="updateList"
                :items="allCities">
                <template v-slot:title>
                    Город
                </template>
               
            </AppSelectSearch>
            <!--
            <span v-if="element.city_id===0 && letter.length>1" style="color:red; font-size:12px; margin-top:-5px">Населенный пункт отсутвует в списке. Он будет добавлен после нажатия сохранить</span>
            -->
            <AppInputForm class="address__street_input"
                    v-model="element.street"
                    :typeInput="'string100'">
                <template v-slot:title>
                <span style="font-size: 14px">Зона доставки (например, район)</span>
                </template>
            </AppInputForm>
        <AppInputForm class="address__street_input"
                      v-model="element.price"
                      :typeInput="'string100'">
          <template v-slot:title>
            <span style="font-size: 14px">Стоимость</span>
          </template>
        </AppInputForm>
        <div class="address__office-button">
            <AppButtonSettingSave @click="save()" class="address__office_button"/>
        </div>
        </div>
    </div>
</template>

<script>
import api from './api'
import { mapGetters } from 'vuex';
export default {
    name: "DeliveryCityAdd",
    data() {
        return {
            //showHelp: false,
            element: {status:true},
            showAddressForm: false,
            letter:null,
        };
    },
    props: {
        city_id: {
            type: [String, Number],
            default: "",
    },
    shop_delivery_id: {
            type: [Number],
        },
    },
    computed:{ 
        ...mapGetters(['allCities']),
        
    },
    mounted(){
        this.element = {status:true}
        this.element.city_id = this.city_id
    },
    methods: {
       
        updateList(items, letter){
            this.letter = letter
        },
        save(){
            if (this.element.city_id===0)  this.element.new_city = this.letter
            if (!this.element.city_id && !this.letter) { this.showMessageNotice('', "Укажите город",'error'); return;}
            if (!this.element.street) { this.showMessageNotice('', "Укажите зону доставки",'error'); return;}
            if (!this.element.price) { this.showMessageNotice('', "Укажите стоимость",'error'); return;}
            this.element.shop_delivery_id = this.shop_delivery_id
            api.createDeliveryPickup(this.element).then((response) => {
                    this.element = response.data.data
                    this.$emit('addCity',this.element)
                })
            
            this.showAddressForm = false
            this.element = {city_id:this.element.city_id,status:true}
        },
        updateCity(){
            let city_id = this.element.city_id
            this.element.city = this.allCities.find(elem => elem.id==city_id);
        },
    }, 
    watch:{
        city_id(city_id){
            this.element.city_id = city_id
        }
    },
    inject: ['showMessageNotice'],
}
</script>
<style lang="scss" scoped>
.width-add-container {
  max-width: 40px;
}
    .delivery-setting {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        margin-bottom: 20px;
    }

    .delivery-setting__title {
        margin-bottom: 6px;
    }

    .delivery-setting__item {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    .delivery-setting__items {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .delivery-setting__item_button {
        display: flex;
        column-gap: 10px;

        padding: 10px 15px;

        span {
            font-size: 16px;
        }
    }

    .delivery-setting__item_edit {
        margin-left: auto;
    }

    .delivery-setting__item_checkbox {
        align-self: flex-start;

        display: inline-block;
    }

    .delivery-setting__item_title {
        margin-top: 24px;
        margin-bottom: 15px;
    }

    .delivery-setting__items {
        display: flex; 
        flex-direction: column;
        row-gap: 8px;
    }

    .address {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        margin-bottom: 16px;
    }

    .address__button {
        display: flex;
        column-gap: 10px;

        padding: 12px;

        height: 40px;

        span {
            font-size: 12px;
        }

        &._active {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .address__svg {
        margin-left: auto;
    }

    .address__form {
        background: #FFFFFF;

        border: 1px solid #E4E7ED;
        border-top: 0;
        border-radius: 6px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

        display: flex;
        flex-direction: column;
        row-gap: 12px;

        padding: 16px 16px 19px;
    }

    .address__form-add {
        @extend .address__form;

        border-top: 1px solid #E4E7ED;
        border-radius: 6px;

        margin-top: 12px;
    }

    .address__street-house {
        display: flex;
        column-gap: 10px;
    }

    .address__street_input {
        flex: 1 1 auto;
    }

    .address__street_house {
        flex: 0 0 24%;
    }

    .address__office-button {
        display: flex;
        column-gap: 10px;
    }

    .address__office_input {
        flex: 0 0 35%;
    }

    .address__office_button {
        flex: 0 0 50%;

        margin: 0;
        margin-left: auto;
    }
    
    .address__office-button {
        display: flex;
        align-items: flex-end;
    }

    .delivery-setting__item_pickup {
        margin-bottom: 16px;
    }

    .delivery-setting__item_form {
        display: flex;
        flex-direction: column;
        row-gap: 18px;

        margin-bottom: 26px;
    }

    .delivery-setting__item_flex {
        display: flex;
        align-items: end;
        column-gap: 12px;
    }

    .delivery-setting__form {
        background: #FFFFFF;

        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

        border-radius: 6px;

        display: flex;
        flex-direction: column;
        row-gap: 20px;

        padding: 24px 16px 16px;
    }

    .delivery-setting__form_price {
        display: flex;
        align-items: center;
        column-gap: 20px;

        * {
            flex: 1 1 40%;
        }
    }

    .delivery-setting__form_checkbox {
        margin-top: 18px;
    }

    .catalog-setting__textarea {
        height: 64px;
    }
</style>