<template>
    <div class="clients-table clients__container">
      <section class="section-client client section">
        <div class="container">
          <AppSearchAndFilter
              class="client__search"
              @goSearch="goSearch"
              @filtered="filtered"
              :filterContent="filterContent"
              />
        </div>
      </section>
      <div class="page-header">{{(shop_type.id==1)?"Клиенты/посетители":"Посетители"}}</div>
      <AppSectionNote>
        Требуют внимания администратора
      </AppSectionNote>
    
      <CardItemClient 
          v-for="(client, i) in clients" 
    
          :key="i"
          :client="client"
      />
      <!--
      <div style="display: flex; justify-content: center">
        <AppButton 
            @click="prevPage" 
            style="width: 100px; height: 30px; margin-top: 16px; margin-right: 10px; cursor: pointer;"
            
            :styleButton="'_blue-external'"
        >
          Предыдущая
        </AppButton>
        <AppButton 
            @click="nextPage"
            style="width: 100px; height: 30px; margin-top: 16px; margin-right: 10px; cursor: pointer;"
            :styleButton="'_blue-external'"
        >
          Следующая
        </AppButton>
      </div>
      -->
    </div>
  </template>
  
  <script>
  import CardItemClient from './CardItemClient'
  export default {
    name: "SubPageClientList",
    components: {CardItemClient},
    data() {
      return {
      
        shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
        messageUser: ['Hello']
      }
    },
   
    props: {
      peoplesMeta:{
        type: Object,
        required: true
      },
      filterContent:{
        type: Object,
        required: true
      },
      clients: {
        type: Array,
        required: true
      }
    },
   
    mounted(){
      this.$emit("exec", {action:"getModels", value: ""})
    },
    methods:{
      prevPage(){
        let page = parseInt(this.peoplesMeta.currentPage)
        if (page==1 ) return
        if (page<1 ) page = 2
        page = page - 1
        this.goPage(page)
      },
      nextPage(){
        let page = parseInt(this.peoplesMeta.currentPage)
        let pages = parseInt(this.peoplesMeta.pages)
        if (page==pages ) return
        if (page>pages ) page =pages-1
        page = page + 1
        this.goPage(page)
      },

      getAttention(client){
          
          if (client.messages_count.appeals.new>0) {return {status:true,message:"Новое обращение"}}
          if (client.messages_count.chats.new>0) {  return {status:true,message:"Новое сообщение"}}

          return {status:false,message:""} 
      },
      filtered(input){
        this.$emit("exec", {action:"startFiltered", value: input})
      },
      goSearch(input){
          //console.log(input, "goSearch")
           this.$emit("exec", {action:"goSearch", value: input})
        },
        goPage(page){
          
          this.$emit("exec", {action:"goPage", value: page})
      },
    }
    /*
    watch: {

      clients(){
        let filter = this.clients.filter((e)=>e.messages_count.chats.new>0)
      if (filter.length>0) localStorage.setItem('attentionsChat', true)
      else localStorage.setItem('attentionsChat', false)
      window.dispatchEvent(new CustomEvent('chat-localstorage-changed', {
        detail: {
          storage: localStorage.getItem('attentionsChat')
        }
      }));
      }
    }
      */
  }
  </script>
  
  <style lang="scss" scoped>
  .clients-table__content {
    padding: 11px 13px;
    margin-bottom: 14px;
  }
  .clients {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  .client {
    position: relative;
    padding-top: 8px;
    &::before {
      background: var(--global_block_line);
      content: "";
      position: absolute;
      top: 0;
      height: 2px;
      width: 100%;
    }
    &:first-child {
      padding-top: 0;
      &::before {
        background: none;
        width: 0;
        height: 0;
      }
    }
  }
  .client__short-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .client__short-info_item {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .client__short-info_user {
    grid-column: 2/4;
  }
  .client__statistics {
    border-top: 1px solid var(--global_block_line);
    border-bottom: 1px solid var(--global_block_line);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 9px 0;
    margin: 9px 0;
  }
  .client__statistics_item {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .client__user {
    color: var(--global_second_color);
    font-size: 14px;
  }
  .client__statistics_item-text {
    color: rgba(0, 0, 0, 0.75);
    font-size: 12px;
    font-weight: 400;
  }
  .client__interaction_button {
    width: 94px;
    height: 30px;
  }
  .client-row {
    display: flex;
    border-bottom: 1px solid #C3CDD5;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .client-row:last-child {
    border-bottom: none;
  }
  .client-cell {
    margin-right: 20px;
  }
  .client-cell:first-child {
    width: 90px;
  }
  .cell-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 5px;
    white-space: nowrap;
  }
  .cell-value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.75);
  }
  .client-value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.75);
  }
  .page-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.75);
    margin: 20px 0;
  }
  </style>