<template>
    <div class="appeals-table appeals__container">
        <div class="appeals-table__content">
            <ul class="appeals">
                <li class="appeal"
                v-for="appeal in appeals"
                :key="appeal.id">
                    <div class="appeal__info">
                        <div class="appeal__id">
                            № {{ appeal.id }}
                        </div>
                        <AppTitleSmall>
                            <date class="appeal__date">
                                {{ appeal.date }}
                            </date>
                        </AppTitleSmall>
                        <div class="appeal__user">
                            {{ appeal.user }}
                        </div>
                        <div class="appeal__message">
                            {{ appeal.message }}
                        </div>
                    </div>
                    <div class="appeal__interaction">
                        <AppButton class="appeal__interaction_button"
                        :styleButton="'_blue-external'">
                            <span class="appeal__interaction_button_span">
                                Написать ответ
                            </span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4395 0.0390971C11.4875 0.0618871 12.0763 0.63128 12.7478 1.30444C13.8301 2.38928 13.971 2.54033 13.9881 2.63359C14.0305 2.8645 13.9999 2.90614 13.1911 3.71848L12.442 4.47085L10.9849 3.01407L9.52789 1.5573L10.2527 0.831793C10.6861 0.397991 11.0215 0.0844855 11.087 0.0520105C11.2179 -0.0129942 11.3218 -0.0167971 11.4395 0.0390971ZM10.4031 3.60907L11.8528 5.05912L8.44708 8.46527C6.57394 10.3386 5.02909 11.8714 5.01407 11.8714C4.97832 11.8714 2.12788 9.02096 2.12788 8.9852C2.12788 8.95823 8.91265 2.15903 8.93957 2.15903C8.94718 2.15903 9.60575 2.81154 10.4031 3.60907ZM4.29521 12.367C4.24813 12.4103 0.541283 13.9815 0.451966 13.996C0.21282 14.0348 -0.0349982 13.7856 0.00409346 13.5455C0.0131209 13.49 0.366424 12.6382 0.78921 11.6526C1.212 10.667 1.5758 9.81795 1.59769 9.76589L1.63747 9.67125L2.97627 11.01C3.71264 11.7463 4.30615 12.357 4.29521 12.367Z" fill="#20A0FF"/>
                            </svg>
                        </AppButton>
                    </div>
                </li>
            </ul>
        </div>
       <!-- <AppNavigationBottom class="appeals-table__buttons"></AppNavigationBottom> -->
    </div>
</template>

<script>
export default {
    name: "AppAppeals",
    props: {
        appeals: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss">
    .appeals-table__content {
        background: #FFFFFF;

        border: 1px solid #E4E7ED;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 6px;

        padding: 11px 13px;
        margin-bottom: 14px;
    }

    .appeals {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }

    .appeal {
        position: relative;

        padding-top: 8px;

        &::before {
            background: #E4E7ED;

            content: "";
            
            position: absolute;
            top: 0;

            height: 2px;
            width: 100%;
        }

        &:first-child {
            padding-top: 0;

            &::before {
                background: none;

                width: 0;
                height: 0;
            }
        }
    }

    .appeal__info {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        font-size: 12px;

        .appeal__price {
            font-size: 12px;

            text-align: right;
        }
    }

    .appeal__id {
        color: #20A0FF;

        font-size: 14px;
        font-weight: 500;
    }

    .appeal__message {
        color: rgba(0, 0, 0, 0.4);

        font-size: 10px;
    }

    .appeal__user {
        color: rgba(0, 0, 0, 0.75);

        font-size: 14px;
    }

    .appeal__additional-info_span {
        font-size: 12px;
    }

    .appeal__interaction_text-span  {
        font-size: 12px;
    }

    .appeal__interaction {
        border-top: 1px solid #E4E7ED;

        display: flex;
        justify-content: space-between;

        margin-top: 9px;
        padding-top: 9px;
    }

    .appeal__interaction_button {
        column-gap: 5px;

        width: 131px;
        height: 30px;
    }
</style>