<template>
  <div class="calendar-container">
 
      <v-date-picker style=" width: 100%;"
          v-model="localDate"
          mode="dateTime" :timezone="timezone"
          is24hr 
          :min-date='new Date()'
          is-expanded
          color="red"
      />
  </div>
</template>

<script>
import 'v-calendar/dist/style.css';
export default {
  props:{
    date:[String],
  },
  data() {
    return {
      timezone: "",
      localDate: null
    };
  },
  mounted(){
    this.localDate = this.getLocalTime(this.date)
  },
  methods:{
    getLocalTime(date){
      let lDate = new Date(Date.parse(date))
      if (lDate<new Date()) return new Date()
      //console.log(lDate)
      return new Date(Date.parse(lDate))
      
     
    }
  },
  watch:{
    date(){
      //this.localDate = this.getLocalTime(this.date)
    },
   
    localDate(localDate){
      this.$emit("updateDate",localDate)
    }

  }
}
</script>

<style scoped>

</style>