<template>
    <div class="banner">
        <div class="banner__background"
        v-lazy:background-image="require(`@/assets/${bannerData.img}`)">
            <div class="banner__text">
                {{ bannerData.text }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppBanner',
    props: {
        bannerData: {
            type: [Object, Array],
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .banner {
        display: flex;

        margin-bottom: 16px; 
    }

    .banner__background {
        background-repeat: no-repeat;
        background-size: cover;
        
        width: 100%;
    }

    .banner__text {
        color: #FFFFFF;

        font-size: 24px;
        font-weight: 500;

        padding: 42px;
        padding-left: 52%;

        width: 20%;

        @media (max-width: 400px) {
            font-size: 18px;

            padding-top: 25px;
            padding-bottom: 25px;
        }
    }
</style>