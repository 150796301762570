import axios from 'axios';
import { BACKEND_URL_NOTIFICATION } from '@/store'

export default {
    actions: {
        async getUserNotifications(ctx) {
            axios.get(BACKEND_URL_NOTIFICATION, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('setUserNotifications', res.data.data))
        },
        async asyncCreateUserNotification(ctx, newUserNotification) {
            axios.post(BACKEND_URL_NOTIFICATION, newUserNotification, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('createUserNotification', res.data.data))
            .catch(err => console.log(err));
        },
        async asyncUpdateUserNotification(ctx, editedUserNotification) {
            console.log(editedUserNotification);

            axios.put(`${BACKEND_URL_NOTIFICATION}/${editedUserNotification.id}`, editedUserNotification, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));

          //  ctx.commit('updateUserNotification', editedUserNotification)
        },
        async asyncDeleteUserNotification(ctx, userNotification) {
            axios.delete(`${BACKEND_URL_NOTIFICATION}/${userNotification.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).
            then(() => ctx.commit('deleteUserNotification', userNotification))
        }
    },
    mutations: {
        setUserNotifications(state, userNotification) {
            state.userNotifications = userNotification;
        },
        createUserNotification(state, newUserNotification) {
            state.userNotifications.push(newUserNotification);
        },
        updateUserNotification(state, editedUserNotification) {
            state.userNotifications = state.userNotifications.map(userNotification => {
                if (userNotification.id === editedUserNotification.id) {
                    userNotification = editedUserNotification;
                }
            });
           
        },
        deleteUserNotification(state, userNotification) {
            state.userNotifications = state.userNotifications.filter(userNotifications => userNotification.id !== userNotifications.id)
        }
    },
    state: {
        userNotifications: []
    },
    getters: {
        allUserNotifications(state) {
            return state.userNotifications
        }
    }
}