<template>
    <div class="section-title">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppSectionTitle'
}
</script>

<style lang="scss">
.section-title {
    font-size: 16px;
    font-weight: 500;

    margin-top: 16px;
    margin-bottom: 10px;
}
</style>