<template>
    <div >
        
        <EditableText v-if="text"
            v-model="message.text"  
            :title="title"
            :LengthText="'Внимание! Максимальное количество знаков с пробелами - 1000'"   
            :LengthTextInfo="'Использовано: '+LengthText+' / 1000'"        
            :MaxLength="1000"
        /> 
        <br>
        <div>        
            <AppAddImage 
                :media_type="message.media_type"
                :combo="true"
                :desctroyImage="desctroyImage"
                :shop_id="shop_id"
                @imgData="updateImage"
                @deleteImg="deleteImage"
                :button="'изображение'" 
                :headerPart="'изображения'"
                :ratio="[16, 9]"
                :photo="message.media"
            />
            <AppAddVideo 
                :media_type="message.media_type"
                :combo="true"   
                :desctroyVideo="desctroyVideo"
                :shop_id="shop_id"
                :button="'видео'"  
                :headerPart="'видео'"
                :video="message.media"
                @uploadVideo="uploadVideo" 
            />
        </div>
    </div>
</template>
<script>
export default {
  name: "ElementGlobalMessage",
  components: {

  },
  props: {
    inmessage:{
        type: Object
    },
    title:{
        type: String,
        default: "Сообщение"
    }
  },
  data() {
    return {
        desctroyVideo: false,
        desctroyImage:false,
        text: "",
        description:"",
        message: {text:' '},
        shop_id: localStorage.getItem("shop_id")
    }
  },
  computed: {
    messageText() {
        return this.message.text
    },
    LengthText() {
        if (!this.message.text) return 0
        return this.message.text.length
    }
  },
  mounted(){
   
    if (this.inmessage){
        this.message = JSON.parse(JSON.stringify(this.inmessage))
        
        this.text = this.message.text
        if (this.message.media_type == "image") this.desctroyVideo = true
        if (this.message.media_type == "video") this.desctroyImage = true
    }
    
  
  }, 
  methods: {
    updateImage(value){ 
        this.desctroyVideo = true
        this.desctroyImage = false
        this.message.media_type = "image"
     
        this.message.media = value
        this.$emit("updateMedia",this.message.media_type, this.message.media)
    },
    deleteImage(){
        this.message.media_type = "none"
        this.message.media = ""
        this.$emit("updateMedia",this.message.media_type, this.message.media)
    },
    uploadVideo(value){
        
        this.desctroyVideo = false
        this.desctroyImage = true
        if (!value){ 
            this.message.media_type = "none"
        this.message.media = ""
        }
        else {
            this.message.media_type = "video"
            this.message.media = value
        }
        this.$emit("updateMedia",this.message.media_type, this.message.media)
    },
  },
  watch: {
    inmessage(){
        this.message = JSON.parse(JSON.stringify(this.inmessage))
        //console.log(this.message,"this.message")
        this.text = (this.message.text)?this.message.text:"123"
    
        if (this.message.media_type == "image") this.desctroyVideo = true
        if (this.message.media_type == "video") this.desctroyImage = true
    },
    messageText(){
        if (this.LengthText>1000)  {this.showMessageNotice('Ошибка 09',"Длина теста не должна превышать 1000 символов ",'error');   return false}
        this.$emit("updateText",this.message.text)
    },

  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
