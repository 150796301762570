import axios from 'axios'

export const URL = `${process.env.VUE_APP_LINK}/api/v1/chat_buttons`;

export default {
 
    getTypes(data) {
        return axios.get(`${URL}/types`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: {place:data}
        })
    },
    updateButtonHidden(data) {
        return axios.get(`${URL}/hidden`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },
    getButtons(data) {
        return axios.get(`${URL}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },
    createButton(data) {
        return axios.post(`${URL}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }) 
    },
    updateButton(id, data) {
        return axios.put(`${URL}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateButtonSort(data) {
        return axios.post(`${URL}/sort`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    deleteButton(id) {
        return axios.delete(`${URL}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
}