<template>
    <section class="section-products products section">
        <div class="container">
            <router-link 
                :to="{name: 'editProduct', params: {id: 'create', shop_id:shopInfo.id}}"
                >
                <AppButton class="products__button">
                    <span class="products__button_text">
                        Добавить новый товар 
                    </span>
                    <svg class="products__button_svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 6.41509V9.04151H0V6.41509H16ZM9.49098 0V16H6.52505V0H9.49098Z" fill="white"/>
                    </svg> 
                </AppButton>
            </router-link>

            <AppSearchAndFilter
              @goSearch="goSearch"  
              @filtered="startFiltered"
              :filterContent="filterContent"
              class="products__search" 
            />
            <AppProducts
              @updateProduct="updateProduct"
              :productsMeta="productsMeta"
              @updateProducts="getModels()"
              :routeSetting="routeSetting"
              :products="allProducts"/>
              <AppNavigationBottom  :meta="productsMeta" @goPage="goPage"/>
        </div>
    </section>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import api from './api'


export default {
    name: "PageProduct",
  data() {
    return {
      shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
      routeSetting: {},
      search: "",
      filter: [],
      page: 1,
      pageSize: 10,
      lastPage: 2,
      shop_id: 27,
      filterContent: {
        title: 'Фильтр по товарам', 
        filters: {
          sort: {
            title: 'Сортировать:',
            type: 'radio',
            elem: 'sort',
            name: 'sort',
            items: JSON.parse(localStorage.getItem('product_sort')),
            current: "id,desc",
          },
          category: {
            title: 'Выбрать категорию',
            type: 'checkbox',
            elem: 'filter',
            name: 'category',
            items: JSON.parse(localStorage.getItem('categories')),
            current: [],
          },
          status: {
            title: 'Выбрать статус',
            type: 'radio',
            elem: 'filter',
            name: 'status',
            items: JSON.parse(localStorage.getItem('product_statues')),
            current: "",
          }
        }
      },
      products: [],
    }
  },
    computed: {
      ...mapGetters(['allProducts', 'productsMeta', 'shopInfo']),
      //...mapState(['categories'])
    },
     created() {
      if (this.shop_type.id!=1) {
        this.$router.push({ name: 'PageClient'})
        return
      }
      //console.log(localStorage.getItem('categories'))

        this.getShopInfo();
        //  this.loadStatusFilter();
     },
     methods: {
       ...mapActions(['getCategories', 'getProducts', 'getStocks','getShopInfo',]),
       updateProduct(item, element, type){
          if (type=="status"){
            api.updateProductStatus(item.id,  {status_id:element, shop_id:item.shop_id}).then((response) => {
                  if (response.data.message){
                      this.showMessageNotice('', response.data.message,'success')
                      this.getModels();
                  }
                  }).catch((error) => {
                      if (error.response.data)
                          this.showMessageNotice('Ошибка 09', error.response.data,'error')
                      if (error.response.data.message)
                          this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                  })
          }
          if (type=="position"){
              api.updateProductPosition(item.id,  {position:element, shop_id:item.shop_id}).then((response) => {
                      if (response.data.message){
                          this.showMessageNotice('', response.data.message,'success')
                          this.getModels();
                      }
                      }).catch((error) => {
                          if (error.response.data)
                              this.showMessageNotice('Ошибка 09', error.response.data,'error')
                          if (error.response.data.message)
                              this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                      })
            }
       },
       startFiltered(filter) {
          this.filter = filter.filter
          this.getModels();
       },


        Name(item) {
            this.userNotification = item; 
            this.showEmailForm = true
        },    
        goSearch(input){
            this.search = input
            this.getModels()
        },
       
        addProducts(){
          
            this.page++
            this.getModels(true)
        
             setTimeout(function() {
                   
                }, 2000, this);
                
        },
        goPage(page){
            this.page = page
            this.getModels()
        },
        getModels(add=false){
         
          let data = {}
          if (this.$route.params.back) {
            data = JSON.parse(localStorage.getItem('currentProductList'))
            this.$route.params.back = false
          }
          else
            data = {
                shop_id: this.shopInfo.id,
                page:(this.page>this.productsMeta.pages)?this.productsMeta.pages:this.page,
                pageSize:this.pageSize,
                search: this.search,
                filter: this.filter
            }
          localStorage.setItem('currentProductList', JSON.stringify(data))
          this.getProducts(data,add)
        },

  },

  watch: {
    shopInfo() {
      this.shop_id = this.shopInfo.id
      this.getModels();
    },
  },
  mounted() {
    //console.log(this.$router,this.$route)
  },
  inject: ['showMessageNotice'],
}
</script>


<style lang="scss" scoped>
    .products__button {
        display: flex;
        align-items: center;
        column-gap: 12px;

        font-size: 16px;

        padding: 12px;
    }

    .products__search {
        margin-top: 13px;
        margin-bottom: 11px;
    }
</style>