<template>
    <div class="container">
        <section class="setting">
            <AppSettingNavigation></AppSettingNavigation>
            <div>
                <AppSectionTitle>
                    Сообщения для новых пользователей
                </AppSectionTitle>
                <div class="bot-setting block-style">
                    <draggable class="button-system__list" :list="messages" @end="onDragEnd">
                        <template #item="{ element, index }">
                            <AppDeferredMessagesBottomSheet
                                :button="element"
                                :prompt="testPrompts[index]"
                                @selectCurrent="function(state){openCurrent(index, state)}"
                                :open="setOpen[index]"
                            > 
                
                            <DeferredMessage 
                                :model="element" 
                                :index="index"  
                                @save="save" 
                                @test="test"
                                @del="del"
                                @updateButtons="updateButtons"
                                />
                            </AppDeferredMessagesBottomSheet>
                        </template>
                    </draggable>
                </div>

                <div class="button-add__container" style="cursor: pointer;">
                    <AppButtonAdd 
                        @click="createDeferredMessage()" 
                    >
                        <span class="edit-banner__name">Добавить сообщение</span>
                    </AppButtonAdd>
                </div>
            </div>
          <div style="max-width: 172px; margin: auto">
          
          </div>
        </section>
    </div> 
</template>

<script>

import apiDeferredMessage from '@/components/DeferredMessages/api'
import DeferredMessage from '@/components/DeferredMessages/DeferredMessage'


import draggable from 'vuedraggable';


export default {
    name: 'PageDeferredMessage',
    components: {draggable, DeferredMessage},
    data() {
        return {
            messages:[
            {
                buttons:[],
                "id": 89,
                "shop_id": 35,
                "type_id": 3,
                "messanger": "tg",
                "place": "start",
                "name": "Хочу стать волонтером!",
                "link": null,
                "text": "<p>Спасибо, мы свяжемся с вами в ближайшее время!&nbsp;<br><br>Очень скоро!&nbsp;<br><br>Становитесь участником проекта в <strong>Екатеринбурге</strong>.</p>",
                "media_type": "image",
                "media": {
                    "id": 604,
                    "name": "63810235a01ab",
                    "link": "/public/shop_35/",
                    "file_hash": "e5c1940f895fb6c11887e71166e56ed6",
                    "file_format": "jpeg",
                    "type_id": 1,
                    "data": [
                        {
                            "ratio": [
                                1280,
                                720
                            ],
                            "url": "/public/shop_35/thumb/63810235a01ab.webp",
                            "type": "image/webp"
                        }
                    ],
                    "created_at": "2022-11-25T17:58:14.000000Z",
                    "updated_at": "2023-03-16T04:43:18.000000Z",
                    "deleted_at": null,
                    "mime": "image/jpeg",
                    "telegram_id": null
                },
                "status": true,
                "model_id": 35,
                "position": 0,
                "protect_content": false,
                "likes": false,
                "task_id": null,
                "type": {
                    "id": 3,
                    "name": "leadgenerator",
                    "title": "Лидогенератор",
                    "status": true,
                    "icon_dark": "leadgenerator_dark.svg",
                    "icon_white": "leadgenerator.svg"
                }
            }
 
            ],
            testPrompts: [],
            setOpen: [],
            ///////////
        }
    },
    created() {},
   
    mounted() {
        this.getDeferredMessage()
    },

    computed: {},
    methods: {
        updateButtons(messageIndex, value, index, type){
            console.log(value)
            if (type=='update'){
                this.messages[messageIndex].buttons[index] = value
                //console.log(this.messages[messageIndex].buttons, "update")
            }
            if (type=='delete'){
                this.messages[messageIndex].buttons.splice(index,1)
            }
                
        },
        del(index){
            if(this.messages[index].id) {
                
                apiDeferredMessage.deleteDeferredMessage(this.messages[index].id).then((response)=>{
                    console.log(response)
                }).catch((error)=>{
                    console.log(error)
                })  
            }
            this.messages.splice(index,1)
            
        },
        save(value, index){
            
            value.shop_id=localStorage.getItem("shop_id")
            if (!value.id){
                apiDeferredMessage.createDeferredMessage(value).then((response)=>{
                    this.messages[index] = response.data.data
                    this.messages[index].protect_content = this.messages[index].message.protect_content
                    this.messages[index].likes = this.messages[index].message.likes
                }).catch((error)=>{
                    console.log(error)
                })  
            }
            if (value.id){
                apiDeferredMessage.updateDeferredMessage(value.id, value).then((response)=>{
                    this.messages[index] = response.data.data
                    this.messages[index].protect_content = this.messages[index].message.protect_content
                    this.messages[index].likes = this.messages[index].message.likes
                }).catch((error)=>{
                    console.log(error)
                })  
            }
            
            
        },
        test(index){
                let data = {recipient: localStorage.getItem("recipient")}
                apiDeferredMessage.testDeferredMessage(this.messages[index].id, data).then((response)=>{
                    console.log(response)
                }).catch((error)=>{
                    console.log(error)
                })  
        },
        createDeferredMessage(){
            let data = {
                message: {
                    "text":"",
                },
                "minutes":50,
                "buttons":[],
            }
            this.messages.push(data)
            this.setOpen = new Array(this.messages.length).fill(false);
            this.setOpen[this.messages.length-1] = true
            
            console.log("createDeferredMessage")
        },
        openCurrent(i, state) {
          this.setOpen.forEach((im, i, arr)=>arr[i]=false);
          this.setOpen[i]=!state
        },
        async getDeferredMessage() {
            let data = {shop_id:localStorage.getItem("shop_id")}
            const response = await apiDeferredMessage.getDeferredMessages(data)
            this.messages = response.data.data
            for (let index = 0; index < this.messages.length; index++) {
                this.messages[index].protect_content = this.messages[index].message.protect_content
                this.messages[index].likes = this.messages[index].message.likes
                
            }
            console.log(this.messages,"this.messages")
        },
       
    },
    watch: { },

    inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
    .bot-setting {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 16px 10px;
        margin-bottom: 16px;
    }
    .bot-setting__text {
        color: var(--global_color);
        font-size: 14px;
    }
</style>
