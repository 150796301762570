<template>
    <div class="admin-message-container">
      <div class="flex-justify-center"  style="margin-bottom: 10px">
        <div>
          <span v-if="message.admin_type" class="time">{{ message.admin_type.title }}</span>
          <span class="date">{{ message.date }}</span>
          <span class="time">{{ message.time }}</span>
         
    
        </div>
       
        <div>
          <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z"
                  fill="#2CBA15"/>
            <path v-if="message.read" fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.3964 0.548282C10.6991 0.849835 10.9468 1.1 2254 10.9468 1.15426C10.9468 1.18593 9.86929 2.28919 8.55226 3.60595L6.1577 6L5 4.73356C5 4.73356 5.0029 4.76421 5.0029 4.73356C5.0029 4.66003 6.08295 3.5771 6.15631 3.5771C8.10231 5.46389 5.63855 3.05829 6.15809 3.5771L7.94667 1.78852C8.93032 0.804872 9.76016 0 9.79064 0C9.82112 0 10.0938 0.24673 10.3964 0.548282Z"
                  fill="#2CBA15"/>
          </svg>
        <!--  <span class="message-state"> {{ message.people.name }} {{ message.people.fio || "" }} </span> -->
        </div>
      </div>
      <div v-if="message.type_message=='text'" class="message-content" v-html="message.info" />
      
    

    </div>
  </template>
  
  <script>
  export default {
    name: "ChatClientMessage",
    props: ['message']
  }
  </script>
  
  <style scoped>
  .admin-message-container {
    width: 280px;
    background: #FFFFFF;
    border: 1px solid rgba(142, 162, 176, 0.5);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin: 10px;
    padding: 13px;
  }
  .date, .time {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.5);
    margin-right: 5px;
  }
  .message-state {
    margin-left: 5px;
    color: #8EA2B0;
    font-size: 9px;
  }
  .message-content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.4);
  }
  </style>