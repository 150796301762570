<template>
  <div class="container">
    <section class="setting">
      <AppSettingNavigation></AppSettingNavigation>
      <AppSectionTitle>
        Настройка страницы о компании
        {{ text }}
      </AppSectionTitle>
      <div class="about-setting block-style">
        <AppInputForm
            v-model="Company.h1"
            :forAndId="'name'">
          <template v-slot:title>
            <AppTitleSettingParam>
              Заголовок
            </AppTitleSettingParam> 
          </template>
        </AppInputForm>
        <AppDescription
            style="border: none; padding: 0"
            :title="'Текст'"
            :description="Company.h1_text || ''"
            @setDescription="updateText1"
        />
        <AppInputForm
            
            v-model="Company.h2">
          <template v-slot:title>
            <AppTitleSettingParam>
              Заголовок
            </AppTitleSettingParam>
          </template>
        </AppInputForm>
        <AppDescription
            style="border: none; padding: 0"
            :title="'Текст'"
            :description="Company.h2_text || ''"
            @setDescription="updateText2"
        />
        <AppInputForm
            v-model="Company.h3">
          <template v-slot:title>
            <AppTitleSettingParam>
              Заголовок
            </AppTitleSettingParam>
          </template>
        </AppInputForm>
        <AppDescription
            style="border: none; padding: 0; "
            :title="'Текст'"
            :description="Company.h3_text || ''"
            @setDescription="updateText3"
        />
        <div class="about-setting__upload-img">
          <AppGallery v-if="Company.media_images"
              :format="'thumb/'"
              @updatePosition="updatePosition"
              :imagesUrl="Company.media_images" 
              @delImg="deleteElement"
              v-model="Company.first"
          />
        </div>
      </div>
      <AppModalCropImage
          :ratio="['16:9']" 
          @addImage="addImage" 
          :title="'фото'" 
          :type="'about'"
          :shop_id="this.shopInfo.id"

      /> 
      <div style="max-width: 172px; margin: auto">
        <AppButtonSettingSave @click="save" class="about-setting__button"></AppButtonSettingSave>
      </div>
    </section>
  </div>
</template>

<script>
import api from './Modules/About/api';
import {mapActions, mapGetters} from 'vuex';

export default {

  name: "PageAboutSetting",
  data() {
    return {
      images: [],
      texts: {},
    }
  },
  computed: mapGetters(['Company', 'shopInfo']),
  mounted() {
    this.getShopInfo()
  },
  methods: {
    ...mapActions(['getCompany', 'asyncUpdateCompany', 'getShopInfo']),
    save(){
    
      this.Company.h1_text = (this.Company.edit_h1)?this.Company.edit_h1_text:this.Company.h1_text
      this.Company.h2_text = (this.Company.edit_h2)?this.Company.edit_h2_text:this.Company.h2_text
      this.Company.h3_text = (this.Company.edit_h3)?this.Company.edit_h3_text:this.Company.h3_text
      api.updateAbout(this.Company.id, this.Company).then((response) => {
                    console.log(response.data)
                    if (response.data.message){
                        this.showMessageNotice('', response.data.message,'success')
                    }
                }).catch((error) => {
                    if (error.response.data)
                        this.showMessageNotice('Ошибка 09', error.response.data,'error')
                    if (error.response.data.message)
                        this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                })
    },
    deleteElement(id) {
      console.log(id)
      let index = this.Company.media_images.findIndex(e => e.id == id)
      this.Company.media_images.splice(index, 1)
      this.Company.media_ids.splice(index, 1)
     
    },
    addImage(value) {
      
      this.Company.media_ids.push(value.id)
      this.Company.media_images.push(value)
      console.log(this.Company.media_images,"this.Company.media_images")
      // this.images.push(value)
    },
    updateText1(text) {
      this.Company.edit_h1 = true
      this.Company.edit_h1_text = text
    },
    updateText2(text) {
      this.Company.edit_h2 = true
      this.Company.edit_h2_text = text
    },
    updateText3(text) {
      this.Company.edit_h3 = true
      this.Company.edit_h3_text = text
    },
    updatePosition(images){   
      this.Company.media_ids = []
      for (let index = 0; index < images.length; index++) {
        this.Company.media_ids.push(images[index].id)
      }
    }
  },
  watch: {
    shopInfo() {
      this.getCompany({shop_id: this.shopInfo.id})
    },
  },
  inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
.about-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 16px 12px;
}

.about-setting__button {
  margin-top: 12px;
}

.about-setting__images-list {
  margin-top: 12px;
}

.about-setting__upload-img {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
</style>