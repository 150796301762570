<template>
    <div class="loading-overlay" style="padding: 20px;">
      <div class="loading-content" style="justify-content: center; font-family: Roboto; font-size: 13px;">
          <p style="text-align: center;  font-family: Roboto; margin-bottom: 5px;">Внимание!</p>
          <p style=" text-align: center; font-family: 'Roboto'; margin-bottom: 5px;">
            После удаления базы телефонов необходимо сразу загрузить новую базу в требуемом формате.
          </p>
          <p style=" text-align: center; font-family: 'Roboto'; margin-bottom: 5px;">
            После загрузки новой базы пользователи, отсутствующие в новой базе телефонов, будут отключены от бота. Они останутся в боте, пока сами не выйдут из него, однако все действия в боте с момента обновления базы им будут недоступны ( получение новых рассылок, отправка сообщений администратору).
          </p>
          <p  style="text-align: center; color: blue;">
            После загрузки новой базы рекомендуем подождать 10 минут для полного обновления системы, после этого приступать к работе.
          </p>
          
           
        <div class="showcase-image__info" style="display: flex; justify-content: center; margin-top: 20px;">
          <AppButton
                style="margin:2px"
                :styleButton="'_red'"
                @click="doAction(true)"
            >
            Удалить
            </AppButton>
            <AppButton
                style="margin:2px"
                :styleButton="'_clear-red'"
                @click="doAction(false)"
            >
            Отменить
            </AppButton>
        </div>
      </div>
    </div>
</template>

  <script>
  export default {
    name: "AppModalWarring",
    methods: {
      doAction(type){
         this.$emit("doAction", type)
      },
    }
  }
  </script>
  <style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Белый фон с небольшой прозрачностью */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Перекрывает остальное содержимое страницы */
  }
  
  .loading-content {
    background-color: #fff; /* Белый цвет фона внутри div */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  </style>