<template>
    <ul class="images__list">
        <li 
        class="images__item"
        v-for="image in images"
        :key="image.id">
            <img
                :src="'https://lk.ivitrina.store/media/full/'+image.name" 
            alt="">
           
             <AppX
                @click="deleteElement(image.id)"
                class="image__delete"
                v-show="showDelete"></AppX>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'AppImageItems',
    props: {
        images: {
            type: Array,
            required: true
        },
        showDelete: {
            type: Boolean,
            required: false
        }
    },
    methods:{
        deleteElement(id){
            this.$emit('deleteElement',id)
        }
    }
}
</script>

<style lang="scss" scoped>
    .images__list {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 10px;
    }
    .images__item {
        position: relative;
        width: 36px;
        height: 36px;
    }
     .image__delete {
        display: block;
        position: absolute;
        top: 3px;
        right: 3px;
    }
    img {
            position: absolute;
            left: 0;
            top: 0;

            object-fit: cover;

            width: 100%;
            height: 100%;
        }
</style>