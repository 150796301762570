<template>
  <div class="text-container">
    <h3 v-if="title">{{ title }}</h3>
    <slot
        name="title"
        v-else
    />
    <textarea
        @input="update"
        :value="modelValue"
        :placeholder="placeholder" 
        ref="autoResizeTextarea"
    >
    </textarea>
    <span v-if="LengthText" style="margin-top: -20px; color:red; font-size: 12px;" > {{LengthText}}
      <br>
    </span>
    <span v-if="LengthTextInfo" style="margin-top: -20px; color:red; font-size: 12px;" > {{LengthTextInfo}}
    <br>
    </span>
  </div>
</template>
 

<script>
export default {
  name: "AppTextareaLite",
  props: {
    MaxLength:{
      type: Number,
    },
    LengthTextInfo:{
      type: String,
    },
    LengthText: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: "100px"
    },
    modelValue: {
      type: String
    },
    title: {
      type: String
    },
    placeholder: {
      type: String
    },
  },
  methods: {
    update(event) {
      const textarea = this.$refs.autoResizeTextarea;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
      this.$emit('update:modelValue', event.target.value);
      this.$emit('inputData', event.target.value);
    }
  }
}
</script>

<style scoped>
textarea {
  resize: none;
  min-height: 100px;
  width: 100%;
  box-sizing: border-box;
  background: #EEF4F8;
  border: 1px solid rgba(142, 162, 176, 0.5);
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
}

textarea::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8EA2B0;

}

.text-container h3 {
  font-size: 14px;

  font-weight: 400;
}

</style>