<template>
    <div class="search-filter">
        <AppSearch class="search-filter__input" :placeholder="filterContent.placeholderSearch" @input="updateSearch"></AppSearch>
        <AppFilter v-if="!hiddenFilter"
        :filterContent="filterContent"
        @filtered="startFiltered"
        class="search-filter__filter"/>
    </div>
</template> 

<script>
export default {  
    name: 'AppSearchAndFilter',
    props: {
        filterContent: {
            type: Object
        },
        hiddenFilter:{
            type:Boolean,
            default:false
        }
    },
    methods:{
      startFiltered(e) {
        //console.log(e,"startFiltered")
        this.$emit('filtered', e);
      },

      updateSearch(event){
            let input = event.target.value
            this.$emit("goSearch", input)
        }
    }
}
</script>

<style lang="scss" scoped>
    .search-filter {
        display: flex;
        column-gap: 10px;
    }

    .search-filter__input {
        flex: 1 1 auto;
    }
</style>