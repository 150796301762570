<template>
  <div class="button-container">
    <button class="button-prompt"
            :class="_active ? '_active':''"
    >
      <slot></slot>
    </button>
    <div
        @click.stop="showPrompt = !showPrompt"
        class="prompt-to-button"
        :class="_active ? '_active':''">
      ?

    </div>
    <div v-if="showPrompt && (prompt || header)" class="prompt-container">
      <div class="prompt-header">{{ header }}</div>
      {{ prompt }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AppButtonPrompt",
  props: ['_active', 'prompt', 'header'],
  data() {
    return {
      showPrompt: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.prompt-container {
  font-size: 12px;
  padding: 10px;
  position: absolute;
  bottom: 120%;
  right: 0;
  border: 1px solid #ffffff;
  border-radius: 8px 8px 0 8px;
  z-index: 1;
  background: #20A0FF;
}

.prompt-header {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.button-prompt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 60px);
  height: 100%;
  background: var(--default_background);
  color: var(--default_color);
  font-size: 16px;
  border-radius: 6px 0 0 6px;
  padding: 0 15px;

  &:hover {
    background: var(--default_background_hover);
  }

  &._active {
    background: var(--default_background_active_confirm);
  }

  &:active {
    background: var(--default_background_active);
  }
}

.prompt-to-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 6px 6px 0;
  background: var(--default_background);
  color: var(--default_color);
  height: 100%;
  margin-left: 2px;
  width: 60px;
  cursor: default;

  &:hover {
    background: var(--default_background_hover);
  }

  &:active {
    background: var(--default_background_active);
  }
}
</style>