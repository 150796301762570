<template>
  <div class="section-note">
    <div class="section-note_content">
    <span>
    <slot/>
    </span>
      <AppIconAttention
          class="note-icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSectionNote"
}
</script>

<style scoped>


.section-note {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}


.section-note span {
  margin: auto;
}

.note-icon {
  position: relative;
  top: 3px;
  margin-left: 3px;
}


</style>