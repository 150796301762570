import axios from 'axios';
import { BACKEND_URL_DELIVERIES } from '@/store'
import { BACKEND_URL_PICKUPS } from '@/store'
import { BACKEND_URL_CITIES } from '@/store'

export default {
    actions: {
        async getDeliveryTypes(ctx) {
            axios.get(BACKEND_URL_DELIVERIES+"/types", {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
            .then(res => ctx.commit('setDeliveryTypes', res.data.data))
        },
        async getCities(ctx) {
            axios.get(BACKEND_URL_CITIES, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
            .then(res => ctx.commit('setCities', res.data.data))
        },
      
        async asyncUpdateDelivery(ctx, editedDelivery) {
            axios.put(`${BACKEND_URL_DELIVERIES}/${editedDelivery.id}`, editedDelivery, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('updateDelivery', res.data.data))
            .catch(err => console.log(err));

            ctx.commit('updateDelivery', editedDelivery)
           
        },
        async asyncCreatePickup(ctx, newPickup) {
            axios.post(BACKEND_URL_PICKUPS, newPickup, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('createPickup', res.data.data))
            .catch(err => console.log(err));
        },
        async asyncUpdatePickup(ctx, editedPickup) {
           // console.log(editedPickup);

            axios.put(`${BACKEND_URL_PICKUPS}/${editedPickup.id}`, editedPickup, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));
        },
       
    },
    mutations: {
        setDeliveryTypes(state, deliveryTypes) {
           
            state.deliveryTypes= deliveryTypes;
        },
        setCities(state, cities) {
            state.cities = cities;
        },
        createCategory(state, newCategory) {
            state.deliveries[newCategory.delivery_id].push(newCategory);
        },
        /*
        createDelivery(state, newDelivery) {
            state.deliveries.push(newDelivery);
        },
        */

        updateDelivery() { //state, editedDelivery
            
           // console.log(editedDelivery,'editedDelivery') 
            /*
            state.deliveries = state.deliveries.map(delivery => {
                if (delivery.id === editedDelivery.id) {
                    delivery = editedDelivery;
                }
            });
            */
        },
      
    },
    state: {
        deliveries: [],
        cities: [],
    },
    getters: {
        allDeliveryTypes(state) {
            return state.deliveryTypes
        },
        allCities(state) {
            return state.cities
        }
    }
}