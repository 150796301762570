<template>
    <label class="radio" :for="forAndId">
        <input class="radio__input"
        type="radio"
        :name="name"
        :id="forAndId"
        @input="setChecked"
        :checked="forAndId==checked"
        hidden>
        <svg class="radio__svg" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" fill="#343434" stroke="#E4E7ED"/>
        </svg>
    </label>
</template>

<script>
export default {
    name: 'AppInputRadio',
    props: {
        forAndId: {
            type: [String, Number],
            required: true
        },
        name: {
            type: [String, Number],
            required: true
        },
       
        checked: {
            type: [String, Number],
        },
      
    },
  methods: {
    setChecked(ev) {  
      this.$emit('update', ev.target.id);
    }
  },
}
</script>

<style lang="scss" scoped>
    @import '@/style/vars';
    .radio {
        background: #EEF4F8;
        border: 1px solid #E4E7ED;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 20px;
        width: 20px;
        height: 20px;
        &:hover {
            border: 1px solid var(--default_background_hover);
        }
        &:active {
            border: 1px solid var(--default_background_active);
        }
    }
    .radio__input {
        width: 100%;
        height: 100%;
        &:checked + .radio__svg {
            opacity: 1;
        }
    }
    .radio__svg {
        opacity: 0;
        transition: 200ms;
    }
</style>