import PageLogin from '@/pages/PageLogin';
import PageRegistration from '@/pages/PageRegistration';
import PageClient from '@/pages/PageClient';
import PageClientInfo from '@/pages/PageClientInfo';

import PageProductEdit from '@/pages/product/PageProductEdit';
import PageOrder from '@/pages/order/PageOrder';
import PageOrderEdit from '@/pages/order/PageOrderEdit';
import PageAccountSetting from '@/pages/setting/PageAccountSetting';
import PageCatalogSetting from '@/pages/setting/PageCatalogSetting';
import PageShowcaseSetting from '@/pages/setting/PageShowcaseSetting';
import PageBotSetting from '@/pages/setting/PageBotSetting';
import PageDeliverySetting from '@/pages/setting/PageDeliverySetting';
import PageStockSetting from '@/pages/setting/PageStockSetting';
import PageAlertSetting from '@/pages/setting/PageAlertSetting';
import PageAboutSetting from '@/pages/setting/PageAboutSetting';
import PageCheckPhone from '@/pages/setting/PageCheckPhone';

import PageTask from '@/pages/setting/PageTask';
import PageTaskEdit from '@/pages/setting/Modules/Task/PageTaskEdit';

import PageDeferredMessage from '@/pages/setting/PageDeferredMessage';


import PageTest from '@/pages/PageTest';
import PageNewShop from '@/pages/PageNewShop';
import { createRouter, createWebHistory } from 'vue-router';
import PageChat from '@/pages/chat/PageChat';
import PageChatUser from '@/pages/chat/PageChatUser';
import PageStartPreview from '@/pages/PageStartPreview';
import PageSuperLogin from '@/pages/PageSuperLogin';
import SelectShop from '@/pages/SelectShop';
import AdminRedirect from '@/pages/AdminRedirect';
import PageProduct from '@/pages/product/PageProduct';
import MaillingList from '@/pages/MassMailling/MaillingList';
import MaillingElement from '@/pages/MassMailling/MaillingElement';






const routes = [
    {
        path: '/login',
        component: PageLogin
    },
    {
        path: '/admin_redirect',
        component: AdminRedirect
    },
    {
        path: '/select_shop',
        component: SelectShop
    },
    {
        path: '/super_admin',
        component: PageSuperLogin
    },
    {
        path: '/preview',
        name: 'preview',
        component: PageStartPreview
    },
    {
        path: '/test',
        component: PageTest
    },
    {
        path: '/registration',
        component: PageRegistration
    },
    {
        path: '/new_shop',
        component: PageNewShop
    },
    {
        name: "PageClient",
        path: '/client',
        component: PageClient
    },
    {
        path: '/client/:id',
        component: PageClientInfo,
        name: "PageClientInfo",
        props: true,
    },
    {
        path: '/chat',
        component: PageChat
    },
    {
        path: '/chat/:id',
        component: PageChatUser,
        name: "PageChatUser",
        props: true
    },
    {
        path: '/',
        component: PageProduct,
        name: "PageProduct",
        props: true
    }, 
    {
        path: '/product/:id',
        component: PageProductEdit,
        name: "editProduct",
        props: true
    },
    {
        path: '/order',
        component: PageOrder,
        name: "PageOrder",
        props: true
    },
    {
        path: '/order/:id',
        component: PageOrderEdit,
        name: "PageOrderEdit",
        props: true
    },
    {
        path: '/setting',
        component: PageAccountSetting,
        name: "PageAccountSetting",
        props: true
    },
    {
        path: '/setting/catalog',
        component: PageCatalogSetting,
        name: "PageCatalogSetting",
        props: true
    },
    {
        path: '/setting/showcase',
        component: PageShowcaseSetting,
        name: "PageShowcaseSetting",
        props: true
    },
    {
        path: '/setting/bot',
        component: PageBotSetting,
        name: "PageBotSetting",
        props: true
    },
    {
        path: '/setting/deferred_message',
        component: PageDeferredMessage,
        name: "PageDeferredMessage",
        props: true
    },
    
    {
        path: '/setting/delivery',
        component: PageDeliverySetting,
        name: "PageDeliverySetting",
        props: true
    },
    {
        path: '/setting/stock',
        component: PageStockSetting,
        name: "PageStockSetting",
        props: true
    },
    {
        path: '/setting/alert',
        component: PageAlertSetting,
        name: "PageAlertSetting",
        props: true
    },
    {
        path: '/setting/about',
        component: PageAboutSetting,
        name: "PageAboutSetting",
        props: true
    },
    {
        path: '/setting/check_phones',
        component: PageCheckPhone,
        name: "PageCheckPhone",
        props: true
    },
    {
        path: '/setting/tasks',
        component: PageTask,
        name: "PageTask",
        props: true
    },
    {
        path: '/setting/tasks/:id',
        component: PageTaskEdit,
        name: "PageTaskEdit",
        props: true
    },
    {
        path: '/maillings',
        component: MaillingList,
        name: "MaillingList",
        props: true
    },
    {
        path: '/maillings/:id/:type',
        component: MaillingElement,
        name: "MaillingElement",
        props: true
    },
    
    
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;