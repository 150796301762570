<template>
  <div class="bot-setting block-style">
    <AppInputForm
            v-model="question.name"
            :placeholder="'Название задачи'">
                <template v-slot:title>
                    <AppTitleSettingParam>
                      Название действия (для внутреннего использования)'
                    </AppTitleSettingParam>
                </template>
        </AppInputForm> 
      <AppSelect
            :style="{maxWidth: '100%' }"
            v-model="question.type"
            :items="types">
        <template v-slot:title>
          Что должен сделать пользователь?
        </template>
      </AppSelect>
      <ElementFoMessage 
          title="Описание задачи для пользователя:" 
          :inmessage="question.message" 
          @updateText="updateQuestionMessage" 
          @updateMedia="updateQuestionMedia"/>
          <div v-if="question.type=='buttons'">
            Кнопки
            <div v-for="element,index in question.data.items" :key="index"  style="display: -webkit-box;">
            <AppInputForm 
              v-model="question.data.items[index].text"
              :placeholder="'Текст кнопки'"
            >
            
            </AppInputForm>
            <AppButtonRemove 
     
                            @click="delQuestionButton(index)"
                            style="width: 35px;
                                  height: 35px;
                                  margin-left: 10px;
                                  margin-top: 6px;"
                        />
                     
            </div>
              <AppButton 
                @click="addQuestionButton"
                style="width: 120px; height: 30px; margin-top: 16px"
                :styleButton="'_blue-external'"
              >
              Добавить кнопку
            </AppButton>
          </div>
          <ElementFoMessage v-if="question" 
          title="Текст после выполнения задачи" 
          :inmessage="question.error_message" 
          @updateText="updateQuestionErrorMessage"
          @updateMedia="updateQuestionErrorMedia"/>

      <ElementActionQuestion  v-model="question" :question="question"  @save="save" @del="del"/>
  </div>
</template>
<script>

import ElementFoMessage from "@/components/ElementFoMessage"
import ElementActionQuestion from "@/components/QuestionElements/ElementActionQuestion"
export default {
name: "ElementQuestion",
components: {
  ElementFoMessage, ElementActionQuestion
},
props: ["model"], 
data() {
  return {
      buttons: [],
      question: {type:"text", data: {}},
      types:[
      {
        "name": "Нажать на кнопку",
        "id":"buttons"
        },
        /*
        {
        "name": "Отправить текст",
        "id":"text"
        },
           {
        "name": "Оценить",
        "id":"like"
        },
        */
        {
        "name": "Отправить изображение",
        "id":"image"
        },
      /*
        {
        "name": "Перейти по ссылке",
        "id":"link"
        }
        */
       
    ]
  }
},
mounted(){
 
  this.question = JSON.parse(JSON.stringify(this.model))
  if (!this.question.data)this.question.data = {}
  
},
methods: {
  updateQuestionErrorMessage(value){
    this.question.error_message.text = value
  },
  addQuestionButton(){
      if (!this.question.data.items) this.question.data.items = []
      this.question.data.items.push({text:""})
  },
  delQuestionButton(index){
    this.question.data.items.splice(index,1)
  },
  updateQuestionMessage(value){
    this.question.message.text = value
  },
  updateQuestionMedia(type, media){
      this.question.message.media_type = type
      this.question.message.media = media
  },
  updateQuestionErrorMedia(type, media){
    this.question.error_message.media_type = type
      this.question.error_message.media = media
  },
  ////
  save(){
      if (!this.question.message.text) { this.showMessageNotice('', "Укажите текст вопроса",'error'); return;}
      if (!this.question.error_message.text) { this.showMessageNotice('', "Укажите текст ошибки",'error'); return;}
      if (!this.question.type) { this.showMessageNotice('', "Укажите тип ответа на вопрос",'error'); return;}
      this.$emit('save',this.question);

  },
  del(){
      this.$emit('del',this.question);
  }
},
watch: {
  model(){
      this.question = JSON.parse(JSON.stringify(this.model))
      console.log(this.question.message)
  }
},
inject: ['showMessageNotice'],
}
</script>

<style lang="scss" scoped>
  .bot-setting {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding: 16px 10px;
      margin-bottom: 16px;
  }
  .bot-setting__text {
      color: var(--global_color);
      font-size: 14px;
  }
</style>
