import AppNavigationTop from '@/components/UI/AppNavigationTop';
import AppButton from '@/components/UI/AppButton';
import AppCatalogButtonLink from '@/components/UI/AppCatalogButtonLink';
import AppBanner from '@/components/UI/AppBanner';
import AppSearch from '@/components/UI/AppSearch';
import AppProductSearch from '@/components/UI/AppProductSearch';
import AppProducts from '@/components/UI/AppProducts';
import AppInputCounter from '@/components/UI/AppInputCounter';
import AppSectionTitle from '@/components/UI/AppSectionTitle';
import AppSlider from '@/components/UI/AppSlider';
import AppArrow from '@/components/UI/AppArrow';
import AppProductPrice from '@/components/UI/AppProductPrice';
import AppProductButton from '@/components/UI/AppProductButton';
import AppX from '@/components/UI/AppX';
import AppInputForm from '@/components/UI/AppInputForm';
import AppTextarea from '@/components/UI/AppTextarea';
import AppInputCheckbox from '@/components/UI/AppInputCheckbox';
import AppInput from '@/components/UI/AppInput';
import AppCheckbox from '@/components/UI/AppCheckbox';
import AppHref from '@/components/UI/AppHref';
import AppSelect from '@/components/UI/AppSelect';
import AppModal from '@/components/UI/AppModal';
import AppInputRadio from '@/components/UI/AppInputRadio';
import AppRadio from '@/components/UI/AppRadio';
import AppFilter from '@/components/UI/AppFilter';
import AppTitleSmall from '@/components/UI/AppTitleSmall';
import AppButtonRemove from '@/components/UI/AppButtonRemove';
import AppButtonEdit from '@/components/UI/AppButtonEdit';
import AppNavigationBottom from '@/components/UI/AppNavigationBottom';
import AppOrders from '@/components/UI/AppOrders';
import AppAppeals from '@/components/UI/AppAppeals';
import AppSearchAndFilter from '@/components/UI/AppSearchAndFilter';
import AppClients from '@/components/UI/AppClients';
import AppGreeting from '@/components/UI/AppGreeting';
import AppSettingNavigation from '@/components/UI/AppSettingNavigation';
import AppHelper from '@/components/UI/AppHelper';
import AppButtonSettingSave from '@/components/UI/AppButtonSettingSave';
import AppButtonDelete from '@/components/UI/AppButtonDelete';
import AppUploadImage from '@/components/UI/AppUploadImage';
import AppButtonAdd from '@/components/UI/AppButtonAdd';
import AppTitleSettingParam from '@/components/UI/AppTitleSettingParam';
import AppTextareaForm from '@/components/UI/AppTextareaForm';
import AppImageItems from '@/components/UI/AppImageItems';
import AppButtonArchive from '@/components/UI/AppButtonArchive';
import AppShortProducts from '@/components/UI/AppShortProducts';
import AppImage from '@/components/UI/AppImage';
import AppInputLock from '@/components/UI/AppInputLock';
import AppButtonTheme from '@/components/UI/AppButtonTheme';
import AppMessages from '@/components/UI/AppMessages';
import AppSelectSearch from '@/components/UI/AppSelectSearch';
import AppObserver from '@/components/UI/AppObserver';
import AppAlertsType from '@/components/UI/AppAlertsType';
import AppButtonMenu from '@/components/UI/AppButtonMenu';
import AppHeaderMenu from '@/components/UI/AppHeaderMenu';
import AppButtonInsertText from '@/components/UI/AppButtonInsertText';
import AppNotifications from '@/components/UI/AppNotifications';
import AppBottomSheet from '@/components/UI/AppBottomSheet';
import AppMessageNotice from '@/components/UI/AppMessageNotice';
import AppButtonPrompt from '@/components/UI/AppButtonPrompt';
import AppDescription from '@/components/UI/AppDescription';
import AppTextareaLite from '@/components/UI/AppTextareaLite';
import AppEditBanner from '@/components/UI/AppEditBanner';
import AppEditBannerLogo from '@/components/UI/AppEditBannerLogo';

import AppGallery from '@/components/UI/AppGallery';
import AppModalCropImage from '@/components/UI/AppModalCropImage';
import AppIconAttention from '@/components/UI/AppIconAttention';
import AppSectionNote from '@/components/UI/AppSectionNote';
import AppOpenList from '@/components/UI/AppOpenList';
import AppInputImg from '@/components/UI/AppInputImg';
import AppButtonIcon from '@/components/UI/AppButtonIcon';
import AppSelect2 from '@/components/UI/AppSelect2';
import AppMailingUser from '@/components/UI/AppMailingUser';
import AppProductCounter from '@/components/UI/AppProductCounter';
import AppUploadVideo from '@/components/AppUploadVideo';
import AppAddVideo from '@/components/UI/AppAddVideo';
import AppSelectV2 from '@/components/UI/AppSelectV2';
import AppButtonSave from '@/components/UI/AppButtonSave'
import AppAddImage from '@/components/UI/AppAddImage'
import AppTelegramImage from '@/components/UI/AppTelegramImage'
import AppButtonsBottomSheet from '@/components/UI/AppButtonsBottomSheet'
//import AppMinimalText from '@/components/UI/AppMinimalText'
import EditableText from '@/components/UI/EditableText'
import AppSelectRating from '@/components/UI/AppSelectRating'
import EditorText from '@/components/UI/EditorText'
import CustomCKEditor from '@/components/UI/CustomCKEditor'
import AppSelectApiSearch from '@/components/UI/AppSelectApiSearch'
import AppLoadingOverlay from '@/components/UI/AppLoadingOverlay'
import AppModalWarring from '@/components/UI/AppModalWarring'
import AppDeferredMessagesBottomSheet from '@/components/UI/AppDeferredMessagesBottomSheet'


export default [
    AppSelectApiSearch,
    AppDeferredMessagesBottomSheet,
    AppLoadingOverlay,
    AppModalWarring,
    //AppMinimalText,
    CustomCKEditor,
    EditorText,
    AppSelectRating,
    EditableText,
    AppButtonsBottomSheet, 
    AppTelegramImage,
    AppAddImage,
    AppButtonSave,
    AppSelectV2,
    AppUploadVideo,
    AppAddVideo,
    AppProductCounter,
    AppDescription,
    AppMailingUser,
    AppSelect2,
    AppOpenList,
    AppModalCropImage,
    AppButtonIcon,
    AppEditBanner,
    AppEditBannerLogo,
    AppGallery,
    AppTextareaLite,
    AppInputLock,
    AppNavigationTop,
    AppButton,
    AppCatalogButtonLink,
    AppBanner,
    AppSearch,
    AppProductSearch,
    AppProducts,
    AppInputCounter,
    AppSectionTitle,
    AppSlider,
    AppArrow,
    AppProductPrice,
    AppProductButton,
    AppX,
    AppInputForm,
    AppTextarea,
    AppInputCheckbox,
    AppInput,
    AppCheckbox,
    AppHref,
    AppSelect,
    AppModal,
    AppInputRadio,
    AppRadio,
    AppFilter,
    AppTitleSmall,
    AppButtonRemove,
    AppButtonEdit,
    AppNavigationBottom,
    AppOrders,
    AppAppeals,
    AppSearchAndFilter,
    AppClients,
    AppGreeting,
    AppSettingNavigation,
    AppHelper,
    AppButtonSettingSave,
    AppButtonDelete,
    AppUploadImage,
    AppButtonAdd,
    AppTitleSettingParam,
    AppTextareaForm,
    AppImageItems,
    AppButtonArchive,
    AppShortProducts,
    AppImage,
    AppButtonTheme,
    AppMessages,
    AppSelectSearch,
    AppObserver,
    AppAlertsType,
    AppButtonMenu,
    AppHeaderMenu,
    AppButtonInsertText,
    AppNotifications,
    AppBottomSheet,
    AppIconAttention,
    AppButtonPrompt,
    AppMessageNotice,
    AppSectionNote,
    AppInputImg,
];