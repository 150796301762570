<template>
  <div class="input-content">
    <label class="input__title" for="">
      <slot name="title"></slot>
    </label>
    <div class="input__content">
      <input class="input"
             :class="cssClass"
             :type="typeInput"
             :value="modelValue"
             :placeholder="placeholder"
             @input="updateInput">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    typeInput: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number]
    },
    placeholder: {
      type: [String, Number]
    },
    cssClass: [String]
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
      this.$emit('inputData', event.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
.input-content {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.input__title {
  color: #7E7E7E;

  font-size: 12px;
}

input::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8EA2B0;
}

.input-mailing {
  background: #EEF4F8;
  border: 1px solid rgba(142, 162, 176, 0.5);
  border-radius: 4px;
}

.input {
  background: #EEF4F8;

  border: 1px solid #E4E7ED;
  border-radius: 6px;

  padding: 11px;

  width: 100%;
  height: 40px;

  &[type='email'] {
    text-decoration: underline;
  }
}


.input-mailing {
  background: #EEF4F8;
  border: 1px solid rgba(142, 162, 176, 0.5);
  border-radius: 4px;
}
</style>