<template>
  <div class="chat-create">

    <div class="date-naw">{{ mailingData.date }}</div>
     
      <div class="header-new-mailing">
        <span>{{(mailingData.id)?"Редактировать "+mailingData.name:"Создание новой рассылки"}}</span>

        <svg @click="close"
            width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 1.493L5.71078 7.20379L0.407516 12.5071L1.90052 14.0001L7.3262 8.57438L12.7519 14.0001L14.2449 12.5071L8.94162 7.20379L14.6524 1.493L13.1594 0L7.3262 5.8332L1.493 0L0 1.493Z"
                fill="#8EA2B0"/>
        </svg>
      </div> 
      <div class="about-setting block-style"> 
      
        <div class="product-edit__status" v-if="shop_elements.find(e=>e=='PageTask')">
            <ul class="horisontal-ul">
                <li
                    v-for="element,index in types" 
                    :key="index">
                    <AppRadio
                      v-model = "model.type"
                      :forAndId="element.id" 
                      :name="'status'"
                      > 
                          <AppTitleSettingParam>
                              {{element.name}} 
                          </AppTitleSettingParam> 
  
                    </AppRadio>
                </li>
            </ul>
        </div>
        <div v-if="model.type !='task'" class="about-setting block-style">  
          <AppInput
              v-model="model.name" 
              @inputData="headerUpdate" 
              :placeholder="'Заголовок рассылки'"
              :cssClass="'input-mailing'"
              :forAndId="'name'">
            <template v-slot:title>
              <AppTitleSettingParam>
        
              </AppTitleSettingParam> 
            </template>
          </AppInput>
        
          <EditableText 
              v-model="model.description"  
              :title="'Текст рассылки'"
              :LengthText="'Внимание! Максимальное количество знаков с пробелами - 970'"   
              :LengthTextInfo="'Использовано: '+LengthText+' / 970'"        
              :MaxLength="1000"
          />
          <AppCheckbox style="margin-top: -10px;" v-model="model.protect_content">
              Защить от копирования и пересылки
          </AppCheckbox>
          <AppCheckbox style="margin-top: -10px;" v-model="model.likes">
            Добавить лайк/дизлайк
          </AppCheckbox>
          <div>
      
            <AppAddImage
                :media_type="media_type"
              
                :combo="true"
                :shop_id="shop_id"
                @imgData="updateImage"
                @deleteImg="deleteImage"
                :button="'изображение'"  
                :headerPart="'изображения'"
                :ratio="[16, 9]"
                :photo="model.image"
            />
      
            <AppAddVideo 
                :media_type="media_type"
                :combo="true"
                :shop_id="shop_id"
                :button="'видео'"  
                :headerPart="'видео'"
                :video="model.video" 
                @uploadVideo="uploadVideo"
            />
            
            <AppButtons
                :model_id="model.id"
                :place="'mailling'"
                :shopInfo="shopInfo"
                :buttons="buttons"  
                @updateButtons="updateButtons"
            />
          </div>
        </div>  
        <div v-if="model.type =='task'" class="about-setting block-style">  
          <AppSelect
                  :style="{maxWidth: '100%' }"
                  v-model="model.task_id"
                  :items="tasks">
              <template v-slot:title>
                  Выберите задание
              </template>
          </AppSelect>     
        </div>
      </div>
    
      <div style="display: flex; justify-content: right">
        <AppButton 
            @click="sendTest"
            style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
            :styleButton="'_blue-external'"
        >
          Тест
        </AppButton>
        <AppButton v-if="testStatus"
            @click="next"
            style="width: 83px; height: 30px; margin-top: 16px"
            :styleButton="'_blue-external'"
        >
          Далее
        </AppButton>
      </div>
  </div>
</template>

<script>

import AppButtons from "@/components/AppButtons"
import apiButton from '@/components/ButtonElements/api'
import { mapActions, mapGetters } from 'vuex';
import api from "./api"
import apiTask from "@/pages/setting/Modules/Task/api"
export default {
  name: "chatCreate",
  components: {AppButtons},
  
  //components: {AppCalendar},
  provide: {
    cropImage_localWork: true
  },
  data() {
    return {
        tasks:[],
        shop_elements: JSON.parse(localStorage.getItem('shop_elements')) || [],
        types: [
            {
              "name":"Стандартная",
              "id":"std"
            },
            {
              "name":"Задание",
              "id":"task",
            },
        ],
        media_type: "none",
        buttons: [],
        testStatus:false,
        description:"",
        model: Object.assign({},this.mailingData),
        shop_bot: JSON.parse(localStorage.getItem('shop_bot')) || {link:null}
    }
  },
  props: {
    mailingData: [Object],
    shop_id: [String, Number],
  },
  computed: {
    ...mapGetters(['shopInfo']),
    // a computed getter
    messageText() {
        return this.model.description
    },
    LengthText() {
        let f = "<strong>"+this.model.name+" (Тестовая)</strong>\n " + this.model.description
        return f.length-30
    }
  },
  mounted(){
    this.getButtons()
    this.getShopInfo();
    if (this.model.type=="task") this.getTasks()
    if (this.model.id) this.testStatus = true;
    this.description = JSON.parse(JSON.stringify(this.model.description))
    this.media_type = JSON.parse(JSON.stringify(this.model.media_type))
  },
  methods: {
    ...mapActions(['getShopInfo']),
    async getTasks(){
      console.log(this.model.type)
      if (this.model.type=="task" && this.tasks.length==0) {
        const response = await apiTask.getModels({shop_id: this.shop_id})
        this.tasks = response.data.data
      }
     

    },  
    updateButtons(button, type="add"){
           if (type=="add")
               this.buttons.push(button)
           if (type=="update"){
               let index = this.buttons.findIndex(e=>e.id==button.id)
               this.buttons[index] = button
           }
           if (type=="delete"){
               let index = this.buttons.findIndex(e=>e.id==button.id)
               this.buttons.splice(index,1)
           }
       },
    async getButtons() {
            let data = {place:'mailling',shop_id:localStorage.getItem("shop_id"),model_id:this.model.id}
            const response = await apiButton.getButtons(data)
            this.buttons = response.data.data
        },
   sendTest(){
    let recipient = localStorage.getItem("recipient")
    if (!recipient) {
      this.showMessageNotice('Ошибка 09',"Отсутствует получатель тестовой рассылки. <br> Вам необходимо авторизоватся через телеграм",'error');
      return
    }
    
    if (!this.checkMailling()) return
    let data = JSON.parse(JSON.stringify(this.mailingData))
    data.recipient = recipient

    api.sendTestMailling(data).then((response) => {
        this.showMessageNotice('Ошибка 09',response.data.message,'success');
        this.testStatus = true
                    }).catch((error) => {
                        console.log( error.response.data.data)
                    })
   },
    updatePosition() {

    },
    addLink() {
      this.linkUpdate(this.shop_bot.link)
      this.model.button_link = this.shop_bot.link
    },
    deletemailingData() {

    },
    startMessageUpdate(data){
      this.$emit('data', {action: 'writeData', value: {start_message: data}})
    },
    descriptionUpdate(data) {
      this.description = data
      this.$emit('data', {action: 'writeData', value: {description: data}})
    },
    protectContentnUpdate(data) {
      this.$emit('data', {action: 'writeData', value: {protect_content: data}})
    },
    likesUpdate(data) {
      this.$emit('data', {action: 'writeData', value: {likes: data}})
    },
    headerUpdate(data) {
      this.$emit('data', {action: 'writeData', value: {name: data}})
    },
    buttonUpdate(data) {
      this.$emit('data', {action: 'writeData', value: {button_text: data}})
    },
    linkUpdate(data) {
    
      this.$emit('data', {action: 'writeData', value: {button_link: data}})
    },
    uploadVideo(data){
      this.desctroyVideo = false
      this.desctroyImage = true
      //
      this.testStatus = false
      this.media_type = "video"
      if (!data) this.media_type = "none"
      this.model.image = null
      this.$emit('data', {action: 'writeData', value: {video: data, image: null, media_type:this.media_type}})     
      
    },
    updateImage(data) {
      this.testStatus = false
      this.media_type = "image"
      if (!data) this.media_type = "none"
      this.$emit('data', {action: 'writeData', value: {image: data, video: null,  media_type:this.media_type}})
    },
    deleteImage() {
      this.testStatus = false
      this.media_type = "none"
      this.$emit('data', {action: 'writeData', value: {image: null, media_type:this.media_type}})
    },
    close() {
      this.$emit('data', {action: 'nextPage', value: {name:'ChatsList'}})
    },
    checkMailling(){
      if (this.mailingData.button_link || this.mailingData.button_text)
      {
        if (this.LengthText>970)  {this.showMessageNotice('Ошибка 09',"Длина Заголовка с Текстом рассылки  не должна превышать 970 символов ",'error');   return false}
        if (!this.mailingData.button_text)  {this.showMessageNotice('Ошибка 09',"Укажите название кнопки или удалите ссылку",'error');   return false}
        let url = null
        try {
          url = new URL(this.mailingData.button_link)
        
        } catch (error) {
          url = 'error'
        }
        if ( url == 'error'){
          this.showMessageNotice('Ошибка 09',"Укажите правильную ссылку для кнопки или удалите название кнопки",'error')
          return false
        }
      } 
     
      if (!this.mailingData.name){
          this.showMessageNotice('Ошибка 09',"Укажите название рассылки",'error')
          return false
        }
      if(!this.mailingData.description)  {
          this.showMessageNotice('Ошибка 09',"Укажите описание рассылки",'error')
          return false
        }
        return true
    },
    next() {
      if (this.checkMailling())
        this.$emit('data', {action: 'nextPage', value: {name:'ChatSettingPublicNew'}})
      else return
    }
  },
  watch: {
    'model.type'(newVal, oldVal) {
      oldVal
      this.$emit('data', {action: 'writeData', value: {type: newVal}})
      if (newVal=="task") this.getTasks()
    },
    'model.task_id'(newVal, oldVal) {
      let task = this.tasks.find(e=>e.id==newVal)
      this.headerUpdate(task.name)
      this.$emit('data', {action: 'writeData', value: {description: "Задание"}})
      oldVal
      this.$emit('data', {action: 'writeData', value: {task_id: newVal}})
    },
    'model.protect_content'(newVal, oldVal) {
      oldVal
      this.protectContentnUpdate(newVal)
    },
    'model.likes'(newVal, oldVal) {
      oldVal
      this.likesUpdate(newVal)
    },

    messageText(){
        if (this.LengthText>970)  {this.showMessageNotice('Ошибка 09',"Длина Заголовка с Текстом рассылки  не должна превышать 970 символов ",'error');   return false}
        this.$emit('data', {action: 'writeData', value: {description: this.model.description}})
    },

  },
  inject: ['showMessageNotice'],
}
</script>

<style scoped>
.horisontal-ul li {
   display: inline-block;
   margin-left: 20px;
}
.date-naw {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.about-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 40px 12px;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

</style>