<template>
    <div class="modal">
        <AppX class="modal__close"></AppX>
        <div class="modal__titles">
            <AppSectionTitle class="modal__title">
                Заказать звонок
            </AppSectionTitle>
            <div class="modal__subtitle">
                Мы перезвоним вам в течение 10 минут
            </div>
        </div>
        <div class="modal__inputs">
            <AppInput class="modal__input">
                <template v-slot:title>
                    Телефон*
                </template>
            </AppInput>
            <AppInput class="modal__input">
                <template v-slot:title>
                    E-mail*
                </template>
            </AppInput>
        </div>
        <AppCheckbox class="modal__consent" :forAndId="'consent'">
            Я даю согласие на <AppHref>обработку моих персональных данных</AppHref>
        </AppCheckbox>
        <AppButton class="modal__button _red">
            Отправить
        </AppButton>
    </div>
</template>

<script>
export default {
    name: "AppModal"
}
</script>

<style lang="scss">
    .modal {
        background: #FFFFFF;
        border-radius: 8px;

        box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.05);

        display: flex;
        flex-direction: column;
        row-gap: 22px;

        position: fixed;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;

        padding: 28px 28px 36px;

        width: 25%;

        z-index: 5;

        @media (max-width: 1366px) {
            width: 40%;
        }

        @media (max-width: 800px) {
            width: 50%;
        }

        @media (max-width: 600px) {
            width: 60%;
        }

        @media (max-width: 450px) {
            width: 75%;
        }
    }

    .modal__close {
        margin-left: auto;
    }

    .modal__titles {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
    }

    .modal__subtitle {
        color: #7E7E7E;

        font-size: 14px;
    }

    .modal__inputs {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .modal__button {
        font-size: 16px;

        margin: 0 auto;
        padding: 10px;

        max-width: 172px;
    }

    .modal__consent {
        width: 85%;
    }
</style>