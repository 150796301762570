import axios from 'axios'


export const SETTINGS = `${process.env.VUE_APP_LINK}/api/v1/settings`;

export default {
    getOwner(data) {
        return axios.get(`${SETTINGS}/alerts/owner`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },
    getAdmins(data) {
        return axios.get(`${SETTINGS}/alerts/admins`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },
    getNotices(people_id) {
        return axios.get(`${SETTINGS}/alerts/admins/${people_id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateNotices(data) {
        return axios.put(`${SETTINGS}/alerts/update`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },

    deleteAdmin(people_id) {
        return axios.delete(`${SETTINGS}/alerts/admins/${people_id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },

    getAdminsSetting(data) {
        return axios.get(`${SETTINGS}/admins_settings`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },
    
    updateAdminsSetting(data) {
        return axios.post(`${SETTINGS}/admins_settings`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },

    getShopNotices(data) {
        return axios.get(`${SETTINGS}/shop_notices`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },
    updateNotice(id, data) { //Обновление уведомлений
        return axios.put(`${SETTINGS}/shop_notices/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
}