<template>
    <div class="select-form">
        <label class="select__title" for="">
            <slot name="title"></slot>
        </label>
        <div class="select__content">
            <div class="select__block"
            @click="editShow()">
            <span style="margin-right:7px">{{ model.title }}</span>  
            <svg width="150" height="15" viewBox="0 0 113 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46663 0L7.15487 3.31937L10.9333 3.81966L8.17236 6.38953L8.8452 10L5.45063 8.2689L2.08807 10L2.75101 6.36025L0 3.81966L3.8043 3.30127L5.46663 0Z" :fill="(model.value>=1)?'#20A0FF':'#8EA2B0'"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7166 0L18.4049 3.31937L22.1833 3.81966L19.4224 6.38953L20.0952 10L16.7006 8.2689L13.3381 10L14.001 6.36025L11.25 3.81966L15.0543 3.30127L16.7166 0Z" :fill="(model.value>=2)?'#20A0FF':'#8EA2B0'"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9666 0L29.6549 3.31937L33.4333 3.81966L30.6724 6.38953L31.3452 10L27.9506 8.2689L24.5881 10L25.251 6.36025L22.5 3.81966L26.3043 3.30127L27.9666 0Z" :fill="(model.value>=3)?'#20A0FF':'#8EA2B0'"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2166 0L40.9049 3.31937L44.6833 3.81966L41.9224 6.38953L42.5952 10L39.2006 8.2689L35.8381 10L36.501 6.36025L33.75 3.81966L37.5543 3.30127L39.2166 0Z" :fill="(model.value>=4)?'#20A0FF':'#8EA2B0'"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.4666 0L52.1549 3.31937L55.9333 3.81966L53.1724 6.38953L53.8452 10L50.4506 8.2689L47.0881 10L47.751 6.36025L45 3.81966L48.8043 3.30127L50.4666 0Z" :fill="(model.value>=5)?'#20A0FF':'#8EA2B0'"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M61.7166 0L63.4049 3.31937L67.1833 3.81966L64.4224 6.38953L65.0952 10L61.7006 8.2689L58.3381 10L59.001 6.36025L56.25 3.81966L60.0543 3.30127L61.7166 0Z" :fill="(model.value>=6)?'#20A0FF':'#8EA2B0'"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M72.9666 0L74.6549 3.31937L78.4333 3.81966L75.6724 6.38953L76.3452 10L72.9506 8.2689L69.5881 10L70.251 6.36025L67.5 3.81966L71.3043 3.30127L72.9666 0Z" :fill="(model.value>=7)?'#20A0FF':'#8EA2B0'"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M84.2166 0L85.9049 3.31937L89.6833 3.81966L86.9224 6.38953L87.5952 10L84.2006 8.2689L80.8381 10L81.501 6.36025L78.75 3.81966L82.5543 3.30127L84.2166 0Z" :fill="(model.value>=8)?'#20A0FF':'#8EA2B0'"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M95.4666 0L97.1549 3.31937L100.933 3.81966L98.1724 6.38953L98.8452 10L95.4506 8.2689L92.0881 10L92.751 6.36025L90 3.81966L93.8043 3.30127L95.4666 0Z" :fill="(model.value>=9)?'#20A0FF':'#8EA2B0'"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M106.717 0L108.405 3.31937L112.183 3.81966L109.422 6.38953L110.095 10L106.701 8.2689L103.338 10L104.001 6.36025L101.25 3.81966L105.054 3.30127L106.717 0Z" :fill="(model.value>=10)?'#20A0FF':'#8EA2B0'"/>
            </svg>
                <input class="select" 
                    @click="update()"
                    :value="getValue()" 
                    disabled>
                
            </div>
            
            <ul class="select__items" 
            :class="{'_show': this.isShow}">
                <li class="select_item"
                    v-for="item in items" 
                    :key="item.name" 
                    @click="editSelectItem(item)">
                    {{ item.title }}
                <svg width="120" height="10" viewBox="0 0 113 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46663 0L7.15487 3.31937L10.9333 3.81966L8.17236 6.38953L8.8452 10L5.45063 8.2689L2.08807 10L2.75101 6.36025L0 3.81966L3.8043 3.30127L5.46663 0Z" :fill="(item.value>=1)?'#20A0FF':'#8EA2B0'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7166 0L18.4049 3.31937L22.1833 3.81966L19.4224 6.38953L20.0952 10L16.7006 8.2689L13.3381 10L14.001 6.36025L11.25 3.81966L15.0543 3.30127L16.7166 0Z" :fill="(item.value>=2)?'#20A0FF':'#8EA2B0'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9666 0L29.6549 3.31937L33.4333 3.81966L30.6724 6.38953L31.3452 10L27.9506 8.2689L24.5881 10L25.251 6.36025L22.5 3.81966L26.3043 3.30127L27.9666 0Z" :fill="(item.value>=3)?'#20A0FF':'#8EA2B0'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2166 0L40.9049 3.31937L44.6833 3.81966L41.9224 6.38953L42.5952 10L39.2006 8.2689L35.8381 10L36.501 6.36025L33.75 3.81966L37.5543 3.30127L39.2166 0Z" :fill="(item.value>=4)?'#20A0FF':'#8EA2B0'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M50.4666 0L52.1549 3.31937L55.9333 3.81966L53.1724 6.38953L53.8452 10L50.4506 8.2689L47.0881 10L47.751 6.36025L45 3.81966L48.8043 3.30127L50.4666 0Z" :fill="(item.value>=5)?'#20A0FF':'#8EA2B0'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M61.7166 0L63.4049 3.31937L67.1833 3.81966L64.4224 6.38953L65.0952 10L61.7006 8.2689L58.3381 10L59.001 6.36025L56.25 3.81966L60.0543 3.30127L61.7166 0Z" :fill="(item.value>=6)?'#20A0FF':'#8EA2B0'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.9666 0L74.6549 3.31937L78.4333 3.81966L75.6724 6.38953L76.3452 10L72.9506 8.2689L69.5881 10L70.251 6.36025L67.5 3.81966L71.3043 3.30127L72.9666 0Z" :fill="(item.value>=7)?'#20A0FF':'#8EA2B0'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M84.2166 0L85.9049 3.31937L89.6833 3.81966L86.9224 6.38953L87.5952 10L84.2006 8.2689L80.8381 10L81.501 6.36025L78.75 3.81966L82.5543 3.30127L84.2166 0Z" :fill="(item.value>=8)?'#20A0FF':'#8EA2B0'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M95.4666 0L97.1549 3.31937L100.933 3.81966L98.1724 6.38953L98.8452 10L95.4506 8.2689L92.0881 10L92.751 6.36025L90 3.81966L93.8043 3.30127L95.4666 0Z" :fill="(item.value>=9)?'#20A0FF':'#8EA2B0'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M106.717 0L108.405 3.31937L112.183 3.81966L109.422 6.38953L110.095 10L106.701 8.2689L103.338 10L104.001 6.36025L101.25 3.81966L105.054 3.30127L106.717 0Z" :fill="(item.value>=10)?'#20A0FF':'#8EA2B0'"/>
                
                </svg>
                </li>
            </ul>
        </div>
    </div>
</template>

<script> 
export default {
    name: 'AppSelectRating',
    data() {
        return {
            
            isShow: false,
            selectedItem: null
        }
    },
  
    props: {
        parentShow: {
            type: Boolean,
            required: false       
        },
        block: {
            type: Boolean,
            required: false       
        },
        items: {
            type: Array,
            required: true       
        },
        disabled: {
            type: Boolean,
            default: false       
        },
        modelValue: {
            type: [String, Number]
        }
    },
    computed: {
        model: function () {
            let f = this.modelValue
            return this.items.find(e=> e.position == f)
            
        }
    },
    methods: {
        editSelectItem(item) {
            this.editShow();
            this.$emit('update:modelValue', item.position); 
            this.$emit('update');
        }, 
        getValue(){
             return ""
        },
        editShow() {
            if (this.block) return this.isShow = false
            let result = this.isShow = !this.isShow;
            this.$emit("isShow",result)
            return result
        }
    },
    watch:{
        parentShow(parentShow){
            if (!parentShow) this.isShow = false
        },
    }
}
</script>

<style lang="scss" scoped>
    .select-form {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .select__title {
        color: #7E7E7E;
        font-size: 12px;
    }
    .select__content {
        position: relative;
        &:hover {
            .select__svg > * {
                fill: var(--default_background);
            }
        }
    }
    .select__block {
        white-space: nowrap;
        display: flex;
        font-size: 12px;
        align-items: center;
        position: relative;
        background: #EEF4F8;
        border: 1px solid #E4E7ED;
        border-radius: 6px;
        padding-left: 10px;
        width: 90%;
        
    }
    .select__items {
        overflow: auto;
        height:200px;
        background: #FFFFFF;
        border: 1px solid #E4E7ED;
        border-top: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: none;
        font-size: 12px;
        position: absolute;
        width: 100%;
        z-index: 10;
        &._show {
            display: block;
        }
    }
    .select_item {
        border-bottom: 1px solid #E4E7ED;
        cursor: pointer;
        color: #000;
        padding: 11px;
        transition: 200ms;
        &:last-child {
            border-bottom: 0;
        }
        &:hover {
            background: var(--default_background_hover);
            
            color: #FFFFFF;
        }
        &:active {
            background: var(--default_background_active);
        }
    }
    .select {
        background: #EEF4F8;
        border-bottom: 1px solid #E4E7ED;
        border-right: 1px solid #E4E7ED;
        border-top: 1px solid #E4E7ED;
        border-radius: 6px;
        padding: 11px;
        padding-right: 0px;
 
       
    }
    .select__svg {
        position: absolute;
        right: 12px;
        transition: 200ms;
        * {
            transition: 200ms;
        }
        &._rotate180 {
            transform: rotate(180deg);
        }
    }
</style>