import { createApp } from 'vue';

import componentsUI from '@/components/UI';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueLazyload from 'vue-lazyload';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VCalendar from 'v-calendar';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

// import ClassicEditorBase from '@ckeditor/ckeditor5-build-decoupled-document';
// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';

// export default class ClassicEditor extends ClassicEditorBase {}

// ClassicEditor.builtinPlugins = [
//     Alignment,
//     Bold,
//     Italic
// ];

const app = createApp(App);



componentsUI.forEach(component => {
    app.component(component.name, component);
    app.component('EasyDataTable', Vue3EasyDataTable);
});

app
    .use(router)
    .use(VCalendar)
    .use(store)
    .use(VueLazyload)
    .use(CKEditor)
    .mount('#app')
