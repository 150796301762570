import axios from 'axios';
import { BACKEND_URL_CATEGORIES } from '@/store'

export default {
    actions: {
        async getCategories(ctx, data) {
            axios.get(BACKEND_URL_CATEGORIES, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                params: data
            })
            .then(res => ctx.commit('setCategories', res.data.data))
        },
        async asyncCreateCategory(ctx, newCategory) {
            axios.post(BACKEND_URL_CATEGORIES, newCategory, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => ctx.commit('createCategory', res.data.data))
            .catch(err => console.log(err));
        },
        async asyncUpdateCategory(ctx, editedCategory) {
            console.log(editedCategory);

            axios.put(`${BACKEND_URL_CATEGORIES}/${editedCategory.id}`, editedCategory, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then()
            .catch(err => console.log(err));

            //ctx.commit('updateCategory', editedCategory)
        },
        async asyncDeleteCategory(ctx, category) {
            axios.delete(`${BACKEND_URL_CATEGORIES}/${category.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).
            then(() => ctx.commit('deleteCategory', category))
        }
    },
    mutations: {
        setCategories(state, category) {
            state.categories = category;
        },
        createCategory(state, newCategory) {
            state.categories.push(newCategory);
        },
        updateCategory(state, editedCategory) {
            state.categories = state.categories.map(category => {
                if (category.id === editedCategory.id) {
                    category = editedCategory;
                }
            });
        },
        deleteCategory(state, category) {
            state.categories = state.categories.filter(categories => category.id !== categories.id)
        }
    },
    state: {
        categories: []
    },
    getters: {
        allCategories(state) {
            return state.categories
        }
    }
}