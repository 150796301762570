<template>
  <div class="edit-banner__container" style="margin-top: -30px;">
    <div class="edit-banner__image" v-if="imagesCropped">
      <div :style="{width: ratio[0]*10/2+'px', height: ratio[1]*10/2+'px'}" class="image-container">
        <img :src="imagesCropped">
      </div>
      <svg class="edit-banner__delete-img"
           @click="deleteImg()"
           width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.8499 0.535925L13.707 2.39308L2.39331 13.7068L0.536157 11.8496L11.8499 0.535925ZM2.71114 0.602339L14.0248 11.916L11.9276 14.0133L0.613907 2.69957L2.71114 0.602339Z"
            fill="#20A0FF"/>
      </svg>
    </div>
    <div class="button-add__container">
      <AppButtonAdd
          @click="showCropImageStock = true"
      >
      <span class="edit-banner__name">{{ (imagesCropped) ? 'Изменить' : 'Добавить' }} {{button}}</span>
    </AppButtonAdd>
    
    </div>
    <teleport to="body">
      <div class="edit-banner__cropper-container"
           :ref="'cropCont'"
           v-if="showCropImageStock"
           @click.stop="closeCropper"
      >
        <div class="crop-image-container">
          <div class="header-cropper-img">
            <span>{{ (imagesCropped) ? 'Изменить' : 'Добавить' }} {{title}}*</span>
            <svg @click="showCropImageStock = false;"
                 style="cursor: pointer"
                 width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="19.7996" width="28" height="4" transform="rotate(-45 0 19.7996)" fill="black"/>
              <rect width="28" height="4" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 22.627 19.7996)"
                    fill="black"/>
            </svg>
          </div> 
          <AppCropImage v-if="renderComponent"
              :ref="'AppCropImage'"
              :ratio="[ratio.join(':')]"
              :showButtonCrop="false"
              :extension="['png', 'jpg', 'jpeg']"
              @imageData="addNewImgBanner"
              @addImage="addImage"
              :destroyImage="destroyImage"
              @destCropper="destCropper"
          />
        </div>
      </div>
    </teleport>
  </div> 
</template>

<script>
import AppCropImage from '@/components/AppCropImage'

export default {
  name: "AppTelegramImage",
  components: {
    AppCropImage
  },
  props: {
    photo: {
      type: String,
    },
    button: {
      type: String,
    },
    title: {
      type: String,
    },
    ratio: {
      type: Array,
    },
  },
  data() {
    return {
      destroyImage: true,
      renderComponent: false,
      //ratio: [10, 10],
      imagesCropped: '',
      showCropImageStock: false,
    }
  },
  mounted(){

    if (this.photo) this.imagesCropped = "https://lk.ivitrina.store/media/full/" + this.photo
  },
  methods: {
    closeCropper2() {
      this.destroyImage = !this.destroyImage;
      return
    },
    forceRerender() {
        // Сначала скроем компонент
        this.renderComponent = false;
        
        this.$nextTick(() => {
          // А потом покажем снова
          this.renderComponent = true;
        });
    },
    destCropper(){
      this.showCropImageStock = false;
    },
    deleteImg() {
      this.$refs.AppCropImage?.cropper_destroy(); // ???
      this.imagesCropped = '';
      this.$emit("updateBanner",null)
    },
    addNewImgBanner(imgData) {
      console.log("addNewImgBanner")
      this.imagesCropped = imgData[0]; 
      this.showCropImageStock = false;
    },
    addImage(data) {
      console.log("addImage")
      this.$emit('updateBanner', data.name);
      let f = document.querySelector('.editImg')
      f.src = ""
      this.showCropImageStock = false;
    },
    closeCropper(ev) {
      if (this.$refs.cropCont === ev.target) {
        this.showCropImageStock = false;
      }
    }
  },
  watch: {
    showCropImageStock(){
      this.forceRerender()
    },
    photo(photo) {
      if (photo) this.imagesCropped = "/media/full/" + this.photo
    },
    
  }
}
</script>
<style scoped>
.edit-banner__cropper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(56, 55, 55, 0.65);
  z-index: 20;
}

.crop-image-container {
  background: white;
  border: 1px solid rgba(65, 65, 65, 0.75);
  border-radius: 6px;
  padding: 15px;
  position: relative;
}

.header-cropper-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 20;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
}

.edit-banner__delete-img {
  margin: 15px;
  cursor: pointer;
}

.image-container img {
  height: 100%;
  width: auto;
}

.edit-banner__image {
  display: flex;
  align-items: center;
  justify-content: start;
}

.button-add__container {
  margin: 15px 0;
  color: #20A0FF;
  display: flex;
  align-items: center;
  justify-content: start;
}
.edit-banner__name {
  color: var(--default_background);
}
</style>