<template>
  <div class="icon-attention__container" :style="(!type)?'display: inline;':''">
    <svg class="icon-attention" width="16" height="16" viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg">
      <circle  cx="8" cy="8" r="8" fill="#FF406E" />
      <path v-if="!type" d="M9.11752 4.21045L8.84015 9.89495H7.07273L6.79536 4.21045H9.11752ZM6.7373 11.666C6.7373 11.3918 6.84911 11.1632 7.07273 10.9804C7.29634 10.7938 7.58661 10.7005 7.94354 10.7005C8.30046 10.7005 8.58858 10.7938 8.8079 10.9804C9.03151 11.1632 9.14332 11.3918 9.14332 11.666C9.14332 11.9402 9.03151 12.1706 8.8079 12.3573C8.58858 12.5401 8.30046 12.6315 7.94354 12.6315C7.58661 12.6315 7.29634 12.5401 7.07273 12.3573C6.84911 12.1706 6.7373 11.9402 6.7373 11.666Z" fill="white"/>
      <text  v-if="type" :x="(number>10)?3:5" y="11" font-size="8px">{{ number }}</text>
    </svg>
    <div v-if="message" class="attention-prompt">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AppIconAttention",
  props: {
    type:{
      type: String,
    },
    number:{
      type: String,
    },
    message: {
      type: String,
    }
  },
}
</script>

<style scoped>
.icon-attention__container {
  position: relative;
}

.icon-attention {
  min-width: 16px;
}
.attention-prompt {
  bottom: 18px;
  left: 19px;
  border: 1px solid #dd456a;
  position: absolute;
  transform: scale(0);
  transform-origin: bottom left;
  transition: 0.3s;
  border-radius: 10px 5px 10px 0;
  background: white;
  padding: 5px;
}

.icon-attention__container:hover .attention-prompt {
  transform: scale(1);
}

</style>