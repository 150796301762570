import axios from 'axios'


export const URL = `${process.env.VUE_APP_LINK}/api/v1/tasks`;

export default {
    createModel(data) {
        return axios.post(`${URL}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }) 
    },

    updateModel(id, data) {
        return axios.put(`${URL}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },

    getModels(data) {
        return axios.get(`${URL}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data
        })
    },

    getModel(id) {
        return axios.get(`${URL}/`+id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    
    deleteModel(id) {
        return axios.delete(`${URL}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
}