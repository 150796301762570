import axios from 'axios'


export const PEOPLE = `${process.env.VUE_APP_LINK}/api/v1/peoples`;
 
export default {
    getPeopleFromFilter(data){
        return axios.get(`${PEOPLE}/from_filter`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }, params: data
        })
    },
    unBlock(id){
        console.log(localStorage.getItem('token'))
        return axios.post(`${PEOPLE}/${id}/unblock`, null, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    updatePhone(id, phone){
        let data = {phone:phone}
        console.log(localStorage.getItem('token'))
        return axios.post(`${PEOPLE}/${id}/update_phone`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    
    getPeoples(data) {
        return axios.get(`${PEOPLE}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }, params: data
        })
    },
    getPeoplesInfo(data) {
        return axios.get(`${PEOPLE}/info`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }, params: data
        })
    },
    getPeople(id) {
        return axios.get(`${PEOPLE}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    getFilters(data) {
        return axios.get(`${PEOPLE}/filters`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }, params: data
        })
    },
    getPeopleMessages(data) {
        return axios.get(`${PEOPLE}/messages`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }, params: data
        })
    },
    sendMessage(data) {
        return axios.post(`${PEOPLE}/messages/send`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
}