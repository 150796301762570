<template>
    <div class="bot-setting__lock">
        <div class="bot-setting__title">
            <slot name="title"></slot>
        </div>
        <div class="bot-setting__text">
            <slot name="description"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppInputLock'
}
</script>

<style lang="scss" scoped>
    .bot-setting__lock {
        display: flex;
        flex-direction: column;
        row-gap: 7px;
    }
    
    .bot-setting__title {
        color: #7E7E7E;
        font-size: 12px;
    }
</style>