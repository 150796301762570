<template>
  <div class="stock-container">
    <AppInputForm
        v-model = "editItem.name"
        :typeInput="'string100'">
      <template v-slot:title>
        <AppTitleSettingParam>
          Название акции
        </AppTitleSettingParam> 
      </template>
    </AppInputForm>
    <AppEditBannerLogo
      v-if="editItem.data" 
      :photo="editItem.data.banner"  
      @updateBanner="updateBanner"
      :button="'баннер'"
      :title="'Банер к акции'"
      :ratio = "[16, 5]"
    />
    <AppTitleSettingParam style="margin: 15px 0">
      Описание акции
    </AppTitleSettingParam>
    <AppDescription style="border: none; padding: 0" 
          :description="editItem.data.description"
          @setDescription ="setDescription"
    />
    <AppInputForm
        v-model="editItem.data.discount"
        :subSymbol="'%'"
        :typeInput="'integer'">
      <template v-slot:title>
        <AppTitleSettingParam style="margin: 15px 0">
          Размер скидки
        </AppTitleSettingParam>
      </template>
    </AppInputForm>
    <AppTitleSettingParam style="margin: 15px 0">
      Добавить шильдик
    </AppTitleSettingParam>
    <div class="stock-setting_radio-top" v-if="editItem.data">
      <div
          v-for="element in allNameplates"
          :key="element.id"
          style="display: flex"
      >
        <AppRadio
            v-model="selectNameplate"
            :forAndId="element.id+'nameplate'+editItem.id"
            :name="'nameplate'+editItem.id">
          <span v-if="element.name === 'пусто'">
            Без шильдика
          </span>
          <img v-else
              :src="'https://lk.ivitrina.store/media/'+element.link"
              alt="">
        </AppRadio>
      </div>
    </div>
    <div class="stock-setting_radio-setting">
      <AppCheckbox v-model="editItem.status" @change="updateStatus">
        Активна
      </AppCheckbox>
      <AppCheckbox v-model="editItem.data.first">
        Показать первой
      </AppCheckbox>
    </div>
    <br>
    <AppButtonSettingSave style="max-width: 170px" @click="save(editItem)"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from "./api";
export default {
  name: "AppStock",
  data() {
    return {
      description:"",
      selectNameplate: 4,
      editItem: {
        data: {}
      },
    }
  },
  props: {
        element: {
            type: Object,
            default: () => {},
        },
    },
  mounted(){
    this.editItem = Object.assign({},this.element)
    
    if (this.editItem.data) this.selectNameplate = this.editItem.data.nameplate_id+'nameplate'+this.editItem.id
  },
  methods:{
    setDescription(description){
      this.description = description
    },
    updateStatus(){
      this.$emit('updateStatus', this.editItem)
    },
    updateBanner(fileName){
      this.editItem.data.banner = fileName
    },
    save(){

      if (!this.editItem.name) { this.showMessageNotice('', "Укажите Название",'error'); return;}
      this.editItem.data.description = this.description
      api.updateStock(this.editItem.id, this.editItem).then((response) => {
              if (response.data.message){
                this.showMessageNotice('', response.data.message,'success')
                this.$emit("updateStocks")
              }
              }).catch((error) => {
                  if (error.response.data.message)
                    this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
              })

    }
  },
  watch:{
    selectNameplate(selectNameplate){
      this.editItem.data.nameplate_id = selectNameplate.replace('nameplate'+this.editItem.id, '');
    }
  },
  computed:{
    ...mapGetters(['allStocks', 'shopInfo', 'allNameplates']),
  },
  components: {

  },
  inject: ['showMessageNotice'],
}
</script>

<style scoped>
.stock-setting_radio-top {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  margin-bottom: 25px;
}
.stock-container {
  border: 1px solid rgba(142, 162, 176, 0.5);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
}

.stock-setting_radio-setting {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
}
</style>