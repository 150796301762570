<template>
  <div></div>
</template>

<script>

import api from "@/api"

export default {
  
  name: 'AdminRedirect',
  mounted() {
    // Get shop_id from the query parameters
    const shopId = this.$route.query.shop_id;

    // Check if shop_id is present
    if (shopId) {
      // Store shop_id in the localStorage
      localStorage.setItem('shop_id', shopId);
    }

    // Redirect to the root URL
    this.getDefaultData()
    this.$router.push('/setting/bot');
  },
  methods:{
    async getDefaultData(){
      try{
               const response = await api.getData(localStorage.getItem('shop_id'))
               localStorage.setItem('shop_bot', JSON.stringify(response.data.shop_bot) || null)
               localStorage.setItem('product_statues', JSON.stringify(response.data.product_statues))
               localStorage.setItem('product_types', JSON.stringify(response.data.product_types))
               localStorage.setItem('product_sort', JSON.stringify(response.data.product_sort))
               localStorage.setItem('categories', JSON.stringify(response.data.categories))
               localStorage.setItem('stocks', JSON.stringify(response.data.stocks))
               localStorage.setItem('shop_id', response.data.shop_id)
               localStorage.setItem('shop_type', JSON.stringify(response.data.shop_type))
               localStorage.setItem('theme', response.data.theme || "_blue")
               //////////////
               localStorage.setItem('order_sort', JSON.stringify(response.data.orders.sort))
               localStorage.setItem('order_statuses', JSON.stringify(response.data.orders.statuses))
               localStorage.setItem('order_paystatuses', JSON.stringify(response.data.orders.paystatuses))
               //////////////
               localStorage.setItem('people_sort', JSON.stringify(response.data.peoples.sort))
               localStorage.setItem('people_types', JSON.stringify(response.data.peoples.types))
               document.body.classList.add(response.data.theme);
               //
               this.load = false
           }
           catch (err){
              localStorage.clear();
              localStorage.removeItem('token')
              this.$router.replace("/login")
               console.log(err,"err")
           }
          
    },
  },
  
};
</script>