<template>
    <section class="section-chat-user chat-user section">
 
        <div class="container">
           <!--
            <AppSearchAndFilter
            class="chat-user__search"
            :filterContent="filterContent"></AppSearchAndFilter>
            -->
            <AppSectionTitle class="chat-user__title">
                Чат с клиентом
            </AppSectionTitle>
            <AppMessages
            :messages="messages"></AppMessages>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'PageChatUser',
    data() {
        return {
            filterContent: {
                title: 'Фильтр по заказам',
                filters: [
                    {
                        title: 'Сортировать:',
                        type: 'radio',
                        name: 'type-sort', // для правильной работы радио
                        items: [
                            'По сумме заказа (сначала больше)',
                            'По сумме заказа (сначала меньше)',
                            'Сначала старые',
                            'Сначала новые'
                        ]
                    },
                    {
                        title: 'Выбрать статус',
                        type: 'checkbox',
                        items: [
                            'Оформлен',
                            'Доставляется',
                            'Доставлен',
                            'Возврат',
                            'Архив'
                        ]
                    },
                    {
                        title: 'Выбрать статус платежа',
                        type: 'checkbox',
                        items: [
                            'Ожидает оплаты',
                            'Оплачен'
                        ]
                    }
                ]
            },
            messages: [ ]
        };
    },
    computed: mapGetters(['Chat']),
    mounted() {
        console.log(this.$route);
       this.id = this.$route.params.id
       this.getChat(this.id)
    },
    methods: {
        ...mapActions(['getChat']),
        
    },
    watch:{
        Chat(){
            this.messages = [ {
                        id: this.Chat.id,
                        date: this.Chat.created_at,
                        //time: "10:45",
                        user: this.Chat.people.FCs,
                        text: this.Chat.text,
                        fromMe: false,
                        answer: this.Chat.answer,
                    },]
            if (this.Chat.answer!=null){
                this.messages.push({
                     id: this.Chat.id,
                        date: this.Chat.updated_at,
                        user: "Служба поддержки",
                        text: this.Chat.answer,
                        fromMe: true
                })
            }
                   
            console.log(this.Chat,'Chat')
        }
    }
}
</script>

<style lang="scss" scoped>
    .chat-user__search {
        margin-bottom: 11px;
    }
    
    .chat-user__title {
        font-weight: 400;
    }
</style>