<template>
    <div class="search">
        <input 
            class="search__input" 
            type="text" 
            :placeholder="placeholder"
            v-model="value"
        >
        <svg class="search__svg" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.46885 7C1.46885 3.99312 3.77055 1.55556 6.60983 1.55556C9.44912 1.55556 11.7508 3.99312 11.7508 7C11.7508 8.19921 11.3856 9.30579 10.7663 10.2048C10.7232 10.2674 10.6902 10.3345 10.667 10.404C10.6489 10.421 10.6314 10.4391 10.6147 10.4583C10.3394 10.7738 10.3578 11.2659 10.6557 11.5574L12.7672 13.6234C13.0651 13.9149 13.5298 13.8954 13.805 13.5799C14.0803 13.2644 14.0619 12.7723 13.764 12.4808L12.1181 10.8704C12.814 9.76162 13.2197 8.42999 13.2197 7C13.2197 3.13401 10.2603 0 6.60983 0C2.95932 0 0 3.13401 0 7C0 10.866 2.95932 14 6.60983 14C7.66376 14 8.66206 13.7382 9.54797 13.272C9.9112 13.0808 10.0593 12.614 9.87881 12.2293C9.6983 11.8446 9.25751 11.6878 8.89428 11.8789C8.20694 12.2407 7.43191 12.4444 6.60983 12.4444C3.77055 12.4444 1.46885 10.0069 1.46885 7Z" fill="#8EA2B0"/>
        </svg>
    </div>
</template>
 
<script> 
export default {
    name: 'AppSearch',
    data() {
        return {
            value:"",
        }
    },
    props: {
        placeholder: {
            type: String
        },
    },
    watch: {
        value(value){
            this.$emit('updateModel', value)
        }
    }

}
</script>

<style lang="scss">
    .search {
        display: flex;
        align-items: center;

        position: relative;

        &:hover {
            .search__svg > * {
                fill: var(--default_background);
            }
        }
    }

    .search__input {
        background: #EEF4F8;

        border: 1px solid #E4E7ED;
        border-radius: 4px;

        font-size: 12px;

        padding: 8px 28px 8px 12px;

        width: 100%;

        &::placeholder {
            color: #8EA2B0;
        }
    }

    .search__svg {
        position: absolute;
        right: 8px;
    }
</style>