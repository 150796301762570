import axios from 'axios'

export const ORDER = `${process.env.VUE_APP_LINK}/api/v1/orders`;

export default {
    getModel(id) {
        return axios.get(`${ORDER}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateModel(id, data) {
        return axios.put(`${ORDER}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
}