<template>
  <div class="container">
    <section class="setting">
      <AppSettingNavigation></AppSettingNavigation>
      <AppSectionTitle>
        Настройки аккаунта
      </AppSectionTitle>
      <div class="account-setting block-style">
        <AppInputForm v-if="Setting.user"
                      v-model="Setting.user.name"
                      :forAndId="'name'">
          <template v-slot:title>
            <AppTitleSettingParam>
              ФИО
            </AppTitleSettingParam>
          </template>
        </AppInputForm>
        <AppInputForm v-if="Setting.user"
                      pattern="phone"
                      :placeholder="'+70000000000'"
                      :forAndId="'telephone'" v-model="Setting.user.phone">
          <template v-slot:title>
            <AppTitleSettingParam>
              Телефон
            </AppTitleSettingParam>
          </template>
        </AppInputForm>
        <AppInputLock v-if="Setting.user">
          <template v-slot:title>
            <AppTitleSettingParam>
              E-mail аккаунта*
            </AppTitleSettingParam>
          </template>
          <template v-slot:description>
            <div class="account-setting__text">
              {{ Setting.user.email }}
            </div>
          </template>
        </AppInputLock>
        <AppInputForm v-if="Setting.user" v-model="Setting.user.password"
                      :typeInput="'password'">
          <template v-slot:title>
            <AppTitleSettingParam>
              Пароль*
            </AppTitleSettingParam>
          </template>
        </AppInputForm>
        <div class="pay-system__container" v-if="shop_type.id==1">
          <AppTitleSettingParam>
            Способ приема платежей:
          </AppTitleSettingParam>
          <ul class="pay-system__list">
            <li v-for="(payment_system, i) in Setting.payment_systems"
                :key="payment_system.id">
              <AppBottomSheet
                  :name="payment_system.admin_name"
                  v-model="payment_system.data.status"
                  :prompt="testPrompts[i]"
                  @selectCurrent="function(state){openCurrent(i, state)}"
                  :open="setOpen[i]"
              >
                <component
                    @savePayment="savePayment"
                    :is="'AppPay' + payment_system.type.replace(/^./, payment_system.type[0].toUpperCase())"
                    :payment_system="payment_system"
                    @active="function(val){payment_system.status = val}"
                ></component>
              </AppBottomSheet>
            </li>
          </ul>
          <!--
          <AppButtonPrompt
              :prompt="'Подсказывает, что делать'"
              :header="'Заголовок подсказки!'"
          >
            Подсказка
          </AppButtonPrompt>
          -->
        </div>
      </div>
      <div style="max-width: 172px; margin: auto">
        <AppButtonSettingSave @click="save()"></AppButtonSettingSave>
      </div>
    </section>
  </div>
</template>

<script>

import AppPayUponreceipt from './Modules/Account/AppPayUponreceipt';
import AppPayInvoice from './Modules/Account/AppPayInvoice';
import AppPayYookassa from './Modules/Account/AppPayYookassa';
import {mapActions, mapGetters} from 'vuex';
import api from "@/api";

export default {
  name: "PageAccountSetting",
  components: {AppPayYookassa, AppPayInvoice, AppPayUponreceipt},
  computed: mapGetters(['Setting', 'shopInfo']),
  data() {
    return {
      shop_type: JSON.parse(localStorage.getItem('shop_type')) || {"id":2},
      setOpen: [],
      showEditItem: false,
      editItem: {},
      checkPayment: {},
      shop_payment: {},
      select_payment: null,
      testPrompts: [/*'Yookassa', 'Card'*/]
    }
  },
  async mounted() {
    await this.getShopInfo();
    console.log(this.Setting.payment_systems)
    //this.setOpen = new Array(this.Setting.payment_systems.length).fill(false)
  },
  methods: {
    openCurrent(i, state) {
      this.setOpen.forEach((im, i, arr) => arr[i] = false);
      this.setOpen[i] = !state
    },
    ...mapActions(['getSetting', 'asyncUpdateSetting', 'getShopInfo',]),
    editPaymentSystem(item) {
      if (this.editItem == item.data)
        this.showEditItem = !this.showEditItem
      else
        this.showEditItem = true
      this.editItem = item.data
      console.log(item)
    },
    selectPaySystem(type) {
      console.log(type)
    },
    savePayment(data) {
     
      if (!data.id) {
        console.log(data)
        data.shop_id = this.shopInfo.id
        api.createPayment(data).then((response) => {
        if (response.data.message) {
          let item = this.Setting.payment_systems.find(item => item.data.id == data.id);
          item.data.status = data.status
          this.showMessageNotice('', response.data.message, 'success')
        }
        }).catch((error) => {
          if (error.response.data.message)
            this.showMessageNotice('Ошибка 09', error.response.data.message, 'error')
        })
      }
      else {
        api.updatePayment(data.id, data).then((response) => {
        if (response.data.message) {
          let item = this.Setting.payment_systems.find(item => item.data.id == data.id);
          item.data.status = data.status
          this.showMessageNotice('', response.data.message, 'success')
        }
      }).catch((error) => {
        if (error.response.data.message)
          this.showMessageNotice('Ошибка 09', error.response.data.message, 'error')
      })

      }
     
    },
    save() {
      let data = {
        "user": this.Setting.user,
        "shop_id": this.shopInfo.id,
        "payment_systems": this.Setting.payment_systems
      };
      this.asyncUpdateSetting(data)
    },

  },
  watch: {
    shopInfo() {
      this.getSetting(this.shopInfo.id)
    },
  },
  inject: ['showMessageNotice'],
}

</script>
<style lang="scss" scoped>
.account-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 16px 10px;
  margin-bottom: 16px;
}

.account-setting__text {
  color: var(--global_color);
  font-size: 14px;
}

.account-setting__checkbox {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.account-setting__checkbox_title {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.account-setting__admin-add {
  column-gap: 5px;
  max-width: 200px;
  height: 30px;
}

.account-setting_radio-elems {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.stock-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.pay-system__container {
  padding: 15px;
  border: 1px solid rgba(142, 162, 176, 0.5);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.pay-system__list {
  margin: 10px 0;
}

.stock-setting__nameplate {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 20px;
}

.stock-setting__form_add {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 12px 10px 16px;
  margin-bottom: 10px;
}

.stock-setting__banner {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  margin-bottom: -50px;
}

.stock-setting__item_checkbox {
  align-self: flex-start;

  display: inline-block;
}

.stock-setting__amount-discount {
  max-width: 45%;
}

.stock-setting_radio-top {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  margin-bottom: 4px;
}

.stock-setting_radio-setting {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
}

.stock-setting__stock {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.stock-setting__stock_item {
  display: flex;
  column-gap: 3px;
  height: 40px;
}

.stock-setting__stock_button {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;

  span {
    font-size: 16px;
  }
}
</style>